import {MocksService, TIMES2} from "app/services/mocks/mocks.service";
import moment from "moment/moment";
import {IOwnedVenue, ISchedule, IScheduleAbstract, IScheduleTime, ISectionState} from "shared-types/WidgetTypes";
import {IAccountDetails, IGroupWidgetSchedule, IGroupWidgetService} from "app/models";
import {isEmpty, sortBy, uniqBy} from "lodash-es";
import {Moment} from "moment";
import {MockScheduleService} from "app/services/mocks/mockSchedule.service";
import {ClientService} from "app/services/client/client.service";
import authenticationService from "app/services/authentication.service";
import { BookingService } from "app/services/booking/booking.service";
import { IServicePaymentDetails, servicePaymentType } from "shared-types/SharedTypes";
import config from '../../_helpers/app.constants';

export class VenueGridService {

    static getMockSchedule() {
        return new Promise((resolve, reject) => {
            setTimeout(function () {
                const didSucceed = [MockScheduleService.getAdiMaxOccupancySchedule(),
                    MocksService.getSchedule('service_TEST3', TIMES2, 'Drav Coffee Centre', 15)]
                didSucceed.length > 0 ? resolve({data: didSucceed}) : reject('Error');
            }, 0);
        })
    }

    static getABCSchedule(date: Moment, covers: number, accId: string, showWalkInTables: boolean, venueIds: number[]) {
        return ClientService.getABCSchedule(date, covers, accId, showWalkInTables, venueIds)
    }

    static getTimesBetweenStartAndEndTimes(openTime: string, closeTime: string, currentDate: string, slotInterval: number, biggestInterval: number): string[] {

        const midnight = moment('0:00:00', 'HH:mm:ss');

        //Format the time
        const startTime = getFormattedMomentTime(openTime);

        //Format the end time and the next day to it
        let endTime = getFormattedMomentTime(closeTime).add(biggestInterval, 'minutes');
        // let finalEndTime: moment.Moment;

        if (endTime.isBetween(midnight, startTime) || endTime.isSame(midnight)) { // If endtime is next day then take the last time as midnight.
            endTime = midnight.add(1, 'days');
        }

        //Times
        const allTimes = [];

        //Loop over the times - only pushes time with 30 minutes interval
        while (startTime <= endTime) {
            //Push times
            allTimes.push(startTime.format('YYYY-MM-DDTHH:mm:ss'));
            //Add interval of 30 minutes
            startTime.add(slotInterval, 'minutes');
        }
        return allTimes;
    }

    static getVenueTimes(startTime: string, venueTimes: IScheduleTime[]): IScheduleTime[] {
        const timeVal: IScheduleTime[] = []

        for (let i = 0; i < venueTimes.length; i++) {
            const time = getFormattedMomentTime(venueTimes[i].time);
            const startTimeMoment = moment(startTime);
            const endTimeMoment = moment(startTime).add(30, 'minutes');
            if (time.isAfter(endTimeMoment)) {
                break; // break if the times is already past. no need to check anymore.
            }
            if (time.isBetween(startTimeMoment, endTimeMoment, null, '[)')) {
                timeVal.push(venueTimes[i]);
            }

        }
        return timeVal;
    }

    static checkIfVenueTimesToBeCalled(startTime: string, venueTimes: IScheduleTime[], viewDate: string) {

        const midnight = moment(viewDate).add(1, 'days');
        const filteredTimes = venueTimes.filter((time) => {
            const timeVal = moment(time.time);
            return timeVal.isBefore(midnight);
        })
        if (filteredTimes.length === 0) {
            return false;
        }

        const startTimeMoment = moment(startTime);
        const venueStartTime = getFormattedMomentTime(filteredTimes[0].time);
        const venueEndTime = getFormattedMomentTime(filteredTimes.slice(-1)[0].time);
        // To check if the header times is in between the Service start time and end time - Then return true so that the method will NOT be called for blank times
        return startTimeMoment.isBetween(venueStartTime.startOf('hour'), venueEndTime, null, '[]');
    }

    static getTimesFromAllServices(schedules: IScheduleAbstract[]): { times: IScheduleTime[], interval: number } {
        const allTimes: IScheduleTime[][] = [];
        let biggestInterval = 0;
        schedules.map((schedule: ISchedule) => {
            biggestInterval = schedule.bookingInterval > biggestInterval ? schedule.bookingInterval : biggestInterval;
            schedule.services.map(service => allTimes.push(formatServiceTimes(service.times.filter(time => VenueGridService.shouldShowTimeInterval(time)))))
        })

        // todo - Filter the allTimes to only contain times from 6am to 12pm
        const uniqueTimes: any = uniqBy(flatten(allTimes), 'time');
        return {times: sortBy(uniqueTimes, 'time'), interval: biggestInterval};
    }

    static getVenueIdsFromAccount(account: IAccountDetails) {
        const ownedVenues: IOwnedVenue[] = account.ownedVenues;
        return ownedVenues.map(venue => venue.id)
    }

    static addVenueToSchedule(ownedVenues: IOwnedVenue[], schedules: ISchedule[]) {
        return schedules.map((schedule: ISchedule) => {
            const venue = ownedVenues.find(venue => venue.id === schedule.venueId);
            return {
                ...schedule,
                venueDetails: venue
            }
        })
    }

    static isTableAvailable(sections: ISectionState[]) {
        return sections.some(s => s.sectionState);
    }

    static getStartTime(time: string) {
        const m = moment(time);
        const roundDown = m.startOf('hour');
        return roundDown.format('YYYY-MM-DDTHH:mm:ss');
    }

    static getEndTime(time: string) {
        const m = moment(time);
        const roundUp = m.minute() || m.second() || m.millisecond() ? m.add(1, 'hour').startOf('hour') : m.startOf('hour');
        return roundUp.format('YYYY-MM-DDTHH:mm:ss')
    }

    static async goToDiary(venueId: number, accountId: string) {
        const authorisationCode = await authenticationService.getAuthorizationToken();
        const redirectURL = `${config.diaryRedirectUrl}#/support-login:${accountId}:${venueId}:${authorisationCode.code}`;
        window.open(redirectURL, '_blank');
    }

    static getSmallestBookingInterval(services: IGroupWidgetService[]) {

        if (isEmpty(services)) {
            return 0;
        }

        const service = services.reduce(function (prev, curr) {
            return prev.bookingInterval < curr.bookingInterval ? prev : curr;
        });

        return service.bookingInterval;
    }

    /**
     * Check whether to display "$" sign on the booking interval or not.
     */
    static shouldShowDollarSignInBookingInterval(paxCount: number, paymentDetails: IServicePaymentDetails): boolean {

        // paymentDetails must not be null
        if (!paymentDetails) return false;

        // Payment type must not be 'No payment'
        if (paymentDetails.paymentType === servicePaymentType.noPayment) return false;

        // Number of pax must be greater than or equal to the people required value
        if (paxCount < paymentDetails.peopleRequired) return false;

        // Incase of booking options, must've atleast one paid booking option
        if (paymentDetails.options?.length > 0 && !BookingService.checkIfPaidBOPresent(paymentDetails.options)) return false;

        return true;
    }

    /**
     * Determine whether to show/hide the time interval.
     */
    static shouldShowTimeInterval(time: IScheduleTime) {

        // Must've not expired
        if (time.expired) return false;

        // When it's a blockout time then, display blockout pill
        if (time.isBlockOut) return true;

        // When it's a stand-by time then, display stand-by pill
        if (time.isStandByListAvailable) return true;

        // When table is available then, display time pill
        if (VenueGridService.isTableAvailable(time.sections)) return true;

        // Hide the time pill
        return false;
    }

    static isVenueClosed(gridRow: IGroupWidgetSchedule): boolean {
        return !gridRow.isVenueOpen || isEmpty(gridRow.services);
    }

    /** Determines whether standby booking is exhausted for the selected day */
    static isStandbyExhausted(schedule: IScheduleAbstract) {

        // maxBookingsOnStandbyList is null or empty
        if (!schedule.maxBookingsOnStandbyList) return true;

        // currentBookingsOnStandbyList exceeds or equal to the maxBookingsOnStandbyList limit
        if (schedule.currentBookingsOnStandbyList >= schedule.maxBookingsOnStandbyList) return true;

        return false;
    }
}

function getFormattedMomentTime(time: string): Moment {
    return moment(moment(time).format('HH:mm:ss'), 'HH:mm:ss'); // Returns the time with todays date so as to make all date date independent
}

function formatServiceTimes(times: IScheduleTime[]): IScheduleTime[] {
    return times.map((time) => {
        return {...time, time: getFormattedMomentTime(time.time).format('YYYY-MM-DDTHH:mm:ss')}; // Fixes the DateTime String having different Dates
    })
}

function flatten(arr: any[][]): any {
    return arr.reduce((flat, toFlatten) => {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
}

