import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Observable, of} from "rxjs";
import {catchError, first, map} from "rxjs/operators";
import {
  bookingErrorType,
  IErrorResponse,
  IErrorResponseData,
  IEwaySummaryResponseData,
  IFunctionPaymentSummaryResponseData,
  IHasPromoCode,
  IPaymentDetailsGenericData,
  IPaymentSettings,
  IPrepareEwayData,
  IProcessPayment,
  IProcessStripePayment,
  ISavePreAuthResponseData,
  IStripePaymentError,
  IStripePaymentSuccessData,
  IWidgetBooking,
  PaymentKind,
  servicePaymentType,
  IApplyPromoCode,
  IBookingError,
  IBookingErrorMinimal,
  IOwnedVenue, bookingErrorMessageType,
} from "shared-types/index";
import {RootState} from "app/main/rootReducer";
import {PaymentService} from "shared-services/payment-service/index";
import {PaymentApiRequests} from "shared-services/payment-service/paymentApiRequests";
import config from '../../_helpers/app.constants';
import {IPaymentOverride, PaymentMethod} from "app/services/booking/booking.types";
import {bookingSlice} from "app/reducers/bookingSlice";
import {ErrorMessageService} from "shared-services/error-message-service";


const NS = 'PaymentSlice';

// Define a type for the slice state
interface PaymentSlice {
  isStripe: boolean;
  stripePublishableKey: string;
  paymentOverride: IPaymentOverride;
  originalPayment: IPaymentOverride;
  paymentComplete: {
    paymentKind: PaymentKind;
    data: ISavePreAuthResponseData | IStripePaymentSuccessData | IEwaySummaryResponseData | IFunctionPaymentSummaryResponseData
  };
  showPromoCode: boolean;
  paymentMethod: PaymentMethod;
  bookingError: IBookingErrorMinimal | IBookingError;
  emailConfirmation: boolean;
  smsConfirmation: boolean;
  showTryAgainBtn: boolean;
  isPaymentOverride: boolean;
}

// Define the initial state using that type
const initialState: PaymentSlice = {
  isStripe: false,
    stripePublishableKey: null,
    paymentOverride: {
        price: 0,
        paymentType: servicePaymentType.noPayment
    },
    originalPayment: {
        price: 0,
        paymentType: servicePaymentType.noPayment
    },
    paymentComplete: null,
    bookingError: null,
    showPromoCode: false,
    paymentMethod: PaymentMethod.Email,
    emailConfirmation: true,
    smsConfirmation: true,
    showTryAgainBtn: true,
    isPaymentOverride: false
}

interface IPaymentFullfilled extends IProcessStripePayment {
    paymentKind: PaymentKind;
    savedBooking: IWidgetBooking;
}

interface IEwayPayment {
  paymentKind: PaymentKind;
  success?: IEwaySummaryResponseData | IFunctionPaymentSummaryResponseData | ISavePreAuthResponseData;
  errorType?: bookingErrorType;
  errorWithCode?: IEwaySummaryResponseData | IFunctionPaymentSummaryResponseData;
  genericError?: IErrorResponse;
  activeVenue: IOwnedVenue;
  errorMessage?: string;
}

/**
 * Defines end points for ABC payments
 */
PaymentApiRequests.addEndpoints({
    payNowUrl: `${config.backendApiUrl}/booking-centre/payments/paynow`,
    preAuthUrl: `${config.backendApiUrl}/booking-centre/payments/preauth`,
    getPaymentIntent3DSecureUrl: (venueId: number, bookingId: string) => `${config.backendApiUrl}/booking-centre/venues/${venueId}/payment-intent/bookings/${bookingId}`,
    finilisePayment3DSecureUrl: `${config.backendApiUrl}/booking-centre/payments/finalise-payment-3d-secure`,
    ewayPaymentSummaryUrl: `${config.backendApiUrl}/booking-centre/payments/completed`,
    applyPromoCodeUrl: `${config.backendApiUrl}/booking-centre/payments/apply-promotion-code`,
    hasPromoCodeUrl: `${config.backendApiUrl}/booking-centre/payments/has-promotion-code`,

    // may be able to remove these, as I think they are just for private functions, which ABC doesn't support
    eventPayNowUrl: `${config.backendApiUrl}/booking-centre/payments/event-paynow`, // @todo: this doesn't exist yet for events
    eventCompletedUrl: `${config.backendApiUrl}/booking-centre/payments/event-completed/`, // @todo: this doesn't exist yet for events
    setupPreAuthStripe3DUrl: `${config.backendApiUrl}/booking-centre/payments/stripe-3d-preauth/setup`,
    finalizePreAuthStripe3DUrl: `${config.backendApiUrl}/booking-centre/payments/stripe-3d-preauth/finalize`
});

export const prepareEwayPayment = createAsyncThunk(
    'test/prepareEwayPayment',
    async (payload, {dispatch, getState}): Promise<{
      success: boolean, data: IPrepareEwayData | IErrorResponse, activeVenue: IOwnedVenue
    }> => {
        const { bookingReducer, appInitReducer } = getState() as RootState;

        const bookingId = bookingReducer.savedBooking._id;

        const activeVenue = appInitReducer.activeVenue;

        /**
         * First contacts the back end to get a token to use for eway API.
         */
        return PaymentService.payNowEway(activeVenue.id, bookingId)
          .toPromise()
          .then(data => ({...data, activeVenue}));
    }
);

export const submitEwayPayment = createAsyncThunk(
  'test/submitEwayPayment',
  async (payload: HTMLFormElement, {dispatch, getState}): Promise<IEwayPayment> => {

      const { bookingReducer, appInitReducer} = getState() as RootState;

      const bookingId = bookingReducer.savedBooking._id;
      const customerId = bookingReducer.savedBooking.customer._id;
      const paymentType = bookingReducer.savedBooking.payment.paymentType;
      const activeVenue = appInitReducer.activeVenue;

      /**
       * Do not trigger any redux state changes, like global loaders, because it will cause the payment page to unmount,
       * which will break the Stripe Elements (they need to be mounted until the payment is complete).
       */

      if (paymentType === servicePaymentType.preAuth) {
          return PaymentService.handleEwayPreauthPayment(activeVenue.id, bookingId, customerId, payload,
            activeVenue.paymentSettings.eway.clientSideEncryptionKey, activeVenue.paymentSettings.preAuthReleasingWindow
          )
            .toPromise()
            .then(data => ({...data, paymentKind: PaymentKind.ewayPreauth, activeVenue}))
      }
      return PaymentService.handleEwayStandardPayment(activeVenue.id, null, bookingId, payload)
        .toPromise()
        .then(data => ({...data, paymentKind: PaymentKind.ewayStandard, activeVenue}));
  }
);

export const submitStripePayment = createAsyncThunk(
    'test/submitStripePayment',
    async ({stripeInstance, card, token, paymentDetails}: {
        stripeInstance: stripe.Stripe,
        card: stripe.elements.Element,
        token: stripe.Token,
        paymentDetails: IPaymentDetailsGenericData
    }, {dispatch, getState}): Promise<IPaymentFullfilled & {activeVenue: IOwnedVenue}> => {
        const { bookingReducer, venueGridReducer, appInitReducer } = getState() as RootState;

        const paymentType = bookingReducer.savedBooking.payment.paymentType;
        const customerId = bookingReducer.savedBooking.customer._id;
        const activeVenue = appInitReducer.activeVenue;

        /**
         * Do not trigger any redux state changes, like global loaders, because it will cause the payment page to unmount,
         * which will break the Stripe Elements (they need to be mounted until the payment is complete).
         */

        if (paymentType === servicePaymentType.preAuth) {
            return handleStripePreauth(bookingReducer.savedBooking, customerId, stripeInstance, {
                id: activeVenue.id, paymentSettings: activeVenue.paymentSettings
            }, card, token, paymentDetails)
            .then(data => ({...data, activeVenue}));
        }
        return handleStripeStandardPayment(bookingReducer.savedBooking, stripeInstance, {
            id: activeVenue.id, paymentSettings: activeVenue.paymentSettings
        }, card, token, paymentDetails)
        .then(data => ({...data, activeVenue}));
    }
);

export const checkHasPromoCode = createAsyncThunk(
  'test/checkHasPromoCode',
  async (payload, {dispatch, getState}): Promise<IHasPromoCode> => {
    const { bookingReducer, venueGridReducer, appInitReducer } = getState() as RootState;

    const bookingId = bookingReducer.savedBooking._id;
    const activeVenue = appInitReducer.activeVenue;

    return PaymentService.handleHasPromoCode(bookingId, activeVenue.id).toPromise();
  }
);

export const applyPromoCode = createAsyncThunk(
  'test/applyPromoCode',
  async (payload: string, {dispatch, getState}): Promise<IApplyPromoCode> => {
    const { bookingReducer, appInitReducer} = getState() as RootState;

    const bookingId = bookingReducer.savedBooking._id;

      const activeVenue = appInitReducer.activeVenue;
    return PaymentService.handleApplyPromoCode(payload, bookingId, activeVenue.id).toPromise()
      .then(data => {
        dispatch(bookingSlice.actions.applyPromoCodePayment(data.payment));
        return data;
      });
  }
);


export const paymentSlice = createSlice({
    name: 'Payment',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setPayment: (state, action: PayloadAction<IPaymentOverride>): PaymentSlice => {
            return {
                ...state,
                paymentOverride: {
                    ...state.paymentOverride,
                    paymentType: action.payload.paymentType,
                    price: action.payload.price
                }
            }
        },
        setOriginalPayment: (state, action: PayloadAction<IPaymentOverride>): PaymentSlice => {
            return {
                ...state,
                originalPayment: {
                    ...state.paymentOverride,
                    paymentType: action.payload.paymentType,
                    price: action.payload.price
                }
            }
        },
        paymentComplete: (state, action: PayloadAction<void>): PaymentSlice => {
            return {
                ...state
            }
        },
        setPaymentMethodState: (state, action: PayloadAction<PaymentMethod>): PaymentSlice => {
            return {
                ...state,
                paymentMethod: action.payload
            }
        },
        setEmailConfirmation: (state, action: PayloadAction<boolean>) => {
            state.emailConfirmation = action.payload
        },
        setSmsConfirmation: (state, action: PayloadAction<boolean>) => {
            state.smsConfirmation = action.payload
        },
        setTryAgainBtn: (state, action: PayloadAction<boolean>) => {
          state.showTryAgainBtn = action.payload;
        },
        setBookingError: (state, action: PayloadAction<IBookingErrorMinimal>) => {
          state.bookingError = action.payload;
        },
        setIsPaymentOverride: (state, action: PayloadAction<boolean>) => {
            state.isPaymentOverride = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submitStripePayment.fulfilled,
            (state, {payload}: PayloadAction<IPaymentFullfilled & {activeVenue: IOwnedVenue}>) => {

                const handleStripeError = () => {
                  const {backEndError, stripeError} = payload.errorStripePayload;
                  state.paymentComplete = null;
                  state.bookingError = stripeError
                    ? {
                      heading: 'Payment Error',
                      message: stripeError.message,
                      buttonText: 'Try Again',
                      messageType: bookingErrorMessageType.paymentError,
                      name: bookingErrorType.paymentError
                    } as IBookingErrorMinimal
                    : ErrorMessageService.getPaymentErrorFromResponse(backEndError.status, backEndError.data, payload.activeVenue);
                }

                switch (payload.paymentKind) {

                    case PaymentKind.stripe3DSecure:
                        if (payload.successPayload) {
                            const {response} = payload.successPayload as {response: {paymentIntent: stripe.paymentIntents.PaymentIntent}};
                            const amountAs2Decimals = response?.paymentIntent ? response.paymentIntent.amount / 100 : null;

                            state.paymentComplete = {
                                paymentKind: payload.paymentKind,
                                data: {
                                    transactionId: response?.paymentIntent.id,
                                    amountPaid: amountAs2Decimals
                                }
                            };
                        } else if (payload.errorStripePayload) {
                          handleStripeError();
                        }
                        break;

                    case PaymentKind.stripeStandard:
                        if (payload.successPayload) {

                            const {transactionId, amountPaid, response} = payload.successPayload as IStripePaymentSuccessData;

                            state.paymentComplete = {
                                paymentKind: payload.paymentKind,
                                data: {
                                    transactionId,
                                    amountPaid
                                }
                            };
                        } else if (payload.errorStripePayload) {
                          handleStripeError();
                        }
                        break;

                    case PaymentKind.stripePreauth:
                        if (payload.successPayload) {
                            const {amountPaid, tokenCustomerId} = payload.successPayload as ISavePreAuthResponseData;
                            state.paymentComplete = {
                                paymentKind: payload.paymentKind,
                                data: {
                                    transactionId: null,
                                    amountPaid
                                }
                            }
                        } else if (payload.errorPreauthPayload) {
                          state.bookingError = ErrorMessageService.getBookingErrorFromType(payload.errorPreauthPayload, payload.activeVenue);
                        }  else if (payload.errorStripePayload) {
                          handleStripeError();
                        }
                        break;
                }
            });

        builder.addCase(prepareEwayPayment.fulfilled,
            (state, {payload}: PayloadAction<{
              success: boolean, data: IPrepareEwayData | IErrorResponse, activeVenue: IOwnedVenue
            }>) => {
                if (!payload.success) {
                  const response: IErrorResponse = payload.data as IErrorResponse;
                  const errorType = ErrorMessageService.getPaymentErrorTypeFromStatus(response.status);
                  state.bookingError = ErrorMessageService.getBookingErrorFromType(errorType, payload.activeVenue);
                  if (response?.data?.message) {
                    state.bookingError.message += ` <br>(${response.data.message})`;
                  }
                }
            });

        builder.addCase(submitEwayPayment.fulfilled,
            (state, {payload}: PayloadAction<IEwayPayment>) => {

              if (payload.success) {
                state.bookingError = null;
                state.paymentComplete = {
                    paymentKind: payload.paymentKind,
                    data: payload.success
                };
              } else {
                state.paymentComplete = null;
                if (payload.errorType) { // if a specific error type is passed (preauth or full payment)
                  state.bookingError = ErrorMessageService.getBookingErrorFromType(payload.errorType, payload.activeVenue);
                  if (payload.errorMessage) {
                    state.bookingError.message += ` <br>(${payload.errorMessage})`;
                  }
                } else if (payload.errorWithCode) {
                  // private function payments may get set with errorWithCode, but are not supported on ABC
                } else if (payload.genericError) { // if a response returns just a status (possible on payment summary)
                  const errorType = ErrorMessageService.getPaymentErrorTypeFromStatus(payload.genericError.status);
                  state.bookingError = ErrorMessageService.getBookingErrorFromType(errorType, payload.activeVenue);
                  if (payload.errorMessage) {
                    state.bookingError.message += ` <br>(${payload.errorMessage})`;
                  }
                }
              }
            });

        builder.addCase(checkHasPromoCode.fulfilled,
          (state, {payload}: PayloadAction<IHasPromoCode>) => {
            state.showPromoCode = payload.showPromoCode;
            // @todo: handle payload.status === loadStatus.failed
          });

    }
})

export const {setPayment, setPaymentMethodState, setEmailConfirmation, setSmsConfirmation, setIsPaymentOverride, setOriginalPayment} = paymentSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default paymentSlice.reducer;

function handleStripeStandardPayment(
    savedBooking: IWidgetBooking, stripeInstance: stripe.Stripe, venue: {id: number, paymentSettings: IPaymentSettings},
    card: stripe.elements.Element, token: stripe.Token, paymentDetails: IPaymentDetailsGenericData
): Promise<IPaymentFullfilled> {

    const is3DSecure = venue.paymentSettings.stripe.stripe3DEnabled;
    const processFn$: Observable<IProcessPayment> = is3DSecure
        ? PaymentService.processStripe3DSecurePayment(savedBooking._id, stripeInstance, venue.id, card)
        : PaymentService.processStripeStandardPayment(savedBooking._id, stripeInstance, venue.id, card, token, paymentDetails);

    return processFn$
        .pipe(first())
        .pipe(catchError((err, caught) => {
                const error = {...err};
                console.log(NS, 'error', error)
                return of({
                    errorPayload: {
                        stripeError: null,
                        backEndError: error.response
                    },
                });
            }),
            map(({ successPayload, errorPayload }: IProcessPayment): IPaymentFullfilled & IProcessStripePayment => ({
                successPayload,
                errorStripePayload: errorPayload as IStripePaymentError,
                paymentKind: is3DSecure ? PaymentKind.stripe3DSecure : PaymentKind.stripeStandard,
                savedBooking
            }))
        ).toPromise();
}

function handleStripePreauth(
    savedBooking: IWidgetBooking, customerId: string, stripeInstance: stripe.Stripe, venue: {id: number, paymentSettings: IPaymentSettings},
    card: stripe.elements.Element, token: stripe.Token, paymentDetails: IPaymentDetailsGenericData
): Promise<IPaymentFullfilled> {
    return PaymentService.processStripePreAuth(
        savedBooking._id, customerId, {
            id: venue.id,
            clientSideEncryptKey: null, //venue.paymentSettings?.eway.clientSideEncryptionKey || null, // only needed for eway
            preAuthReleasingWindow: venue.paymentSettings.preAuthReleasingWindow,
        }, stripeInstance, card, token, paymentDetails, venue.paymentSettings.stripe.stripe3DEnabled
    )
        .pipe(
            first(),
            map(({ successPayload, errorStripePayload, errorPreauthPayload }: IProcessStripePayment) => ({
                successPayload, errorStripePayload, errorPreauthPayload,
                paymentKind: PaymentKind.stripePreauth,
                savedBooking
            }))
        ).toPromise();
}
