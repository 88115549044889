import {IWidgetTheme, servicePaymentType, PricingTypes} from "shared-types/index";
import {IMenuOptionsExtrasItem} from "../MenuOptionsExtras/types";
import {ReactElement} from 'react';

export interface IDispatchFromProps {
  updateCachedMenuOptionDetails?: (ids: string[], fullRefresh?: boolean) => Promise<void>;
  handleIdleCachedMenuOptionDetails?: (extraDetails: IMenuOptionsExtrasItem[]) => void;
}

// all these props must be optional, since the component can be rendered without the container as well
export interface IStateFromProps {
  isStacked: boolean;
  theme: IWidgetTheme;
  currency: string; // AUD, GBP, USD, etc
  extraDetails?: IMenuOptionsExtrasItem[];
  extrasHasPayment: boolean;
  bookingCancellationTimeWindowInHours?: string;
  hideLabel?: boolean;
}

export interface IContainerProps extends IStateFromProps, IDispatchFromProps {

}

export interface IOwnProps {
  children?: ReactElement;
  id: string;
  price: number;
  paymentType?: servicePaymentType;
  pricingType?: PricingTypes;
  paymentTypeError?: boolean;
  label?: string;
  description: string;
  link: string;
  type: menuOptionType;
  bgColor?: string;
  isUpsell?: boolean;
  hideLabel?: boolean;
  isChecked?: boolean; // only relevant when type === menuOptionType.checkboxes
  allChildMenuOptionIds?: string[]; // only relevant when MenuOption is a parent type
  handleOpenExtras?: () => void;
  handleCheckboxChanged?: (evt: any, checked: boolean) => void;
  handleIdleCachedMenuOptionDetails?: (extraDetails: IMenuOptionsExtrasItem[]) => void;
}


export enum menuOptionType {
  checkboxes = 'checkboxes',
  radioButtons = 'radioButtons',
  spinners = 'spinners'
}

export interface IMenuOption {
  isDark: boolean;
  hasCb : boolean;
  isStacked : boolean;
  isChecked?: boolean; // only relevant when type === menuOptionType.checkboxes
  extrasHasPayment : boolean;
  children?: ReactElement;
  id: string;
  price: number;
  paymentType?: servicePaymentType;
  pricingType?: PricingTypes;
  paymentTypeError?: boolean;
  label?: string;
  description: string;
  link: string;
  bgColor?: string;
  theme: IWidgetTheme;
  currency: string; // AUD, GBP, USD, etc
  linkUrl: string;
  handleOpenExtras?: () => void;
  handleCheckboxChanged?: (evt: any, checked: boolean) => void;
  extraDetails?: IMenuOptionsExtrasItem[];
  currencyValue:ReactElement;
  bookingCancellationTimeWindowInHours?: string;
  hoursText: string;
  hideLabel?: boolean;
}

export interface IMenuOptionPaymentType {
  paymentType: servicePaymentType;
  pricingType: PricingTypes;
  paymentTypeError?: boolean;
  bgColor?: string;
  theme: IWidgetTheme;
  extrasHasPayment : boolean;
  bookingCancellationTimeWindowInHours: string;
  hoursText: string;
  currencyValue:ReactElement;
}
