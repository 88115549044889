import {RootState} from "app/main/rootReducer";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect, ConnectedComponent} from "react-redux";
import {getPaymentSummaryMenuOption} from 'shared-components/payment-summary/helpers';
import PaymentSummary from "shared-components/payment-summary/index";
import {IProps} from "shared-components/payment-summary/types";
import {IHandlers} from "./types";
import {
    wrapperStyleType,
    IStripeInfo,
    IEwayInfo,
    IOwnedVenue,
    IPaymentSummaryMenuOption
} from "shared-types/WidgetTypes";
import PaymentUtilsService from "shared-services/payment-utils-service";
import {IServicePaymentDetails} from "shared-types/SharedTypes";
import BookingService from "shared-services/booking-service/index";


const NS = 'PaymentMessageContainer';

const mapStateToProps = (all: RootState): IProps => {
    const {
        appInitReducer, groupWidgetReducer,
        customerReducer,
        bookingReducer, bookingOptionReducer
    } = all;

    const activeVenue = appInitReducer.activeVenue;

    const { selectedService } = groupWidgetReducer;

    const paymentDetails: IServicePaymentDetails = selectedService?.paymentDetails || null;
    let venuePaymentSettings: IEwayInfo | IStripeInfo = null;
    if (activeVenue?.paymentSettings) {
        venuePaymentSettings = PaymentUtilsService.getPaymentSettings(activeVenue?.paymentSettings);
    }

    const {payment, selectedMenuOptions} = bookingReducer.savedBooking || BookingService.getBookingObj(bookingReducer.bookingResponse);

    const menuOptions: IPaymentSummaryMenuOption[] = selectedMenuOptions
        ? getPaymentSummaryMenuOption(paymentDetails, selectedMenuOptions, bookingOptionReducer.cachedMenuOptionDetails)
        : [];

    return {
        theme: appInitReducer.theme,
        wrapperStyle: wrapperStyleType.wide,
        currency: activeVenue ? (activeVenue as IOwnedVenue).currency : null,
        venuePaymentProvider: venuePaymentSettings?.paymentProvider,
        venuePaymentSettings,
        menuOptions,
        payment,
        email: customerReducer?.customerDetails?.email,
        warningMessage: null
    };
};

/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IHandlers => {
    return {

    }
};

const PaymentSummaryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentSummary as any);

export default PaymentSummaryContainer as ConnectedComponent<any, any>;
