import React, {ReactElement, ReactNode} from "react";
import {Moment} from "moment/moment";
import { useField } from 'formik';
import MuiCheckbox from '@material-ui/core/Checkbox';

export class UtilsService {

    static rangeCheck(val: number, max: number, min: number): number {
        if (val < min) {
            val = min;
        } else if (val > max) {
            val = max;
        }
        return val;
    }

    // removes any falsey values (except false)
    static omitFalseyProps(data: any, omitFalse = false): any {
        return Object.keys(data).reduce((a: any, key: string) => {
            const val: any = data[key];
            if (val !== undefined &&
                val !== null &&
                val !== '' &&
                (omitFalse ? val !== false : true)
            ) {
                a[key] = val;
            }
            return a;
        }, {});
    }

    /**
     * Renders a ReactNode based on a truthy condition. Else can be optionally provided either as a ReactNode or a function returning a ReactNode.
     * If you are using `elseContent` it is recommended that you wrap you're ReactNodes in functions to avoid them getting executed even when conditions are not met.
     * For example, if `elseContent` is provided, and you have functions within it that will error without some specific data, you should wrap the ReactNode in a function,
     * so it only gets called when the condition becomes falsy. Otherwise you'll get errors even though the condition is still true, as both ReactNodes will get executed,
     * even though they are not both rendered.
     * @param condition
     * @param content
     * @param elseContent
     */
    static renderIf(condition: any, content: ReactNode | (() => ReactNode), elseContent: ReactNode | (() => ReactNode) = null): ReactNode {
        return condition ? (
            typeof content === 'function' ? content() : content
        ) : (
            typeof elseContent === 'function' ? elseContent() : elseContent
        );
    }

    // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
    static hexToRgb(hex: string): number[] {
        return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
            ,(m, r, g, b) => '#' + r + r + g + g + b + b)
            .substring(1).match(/.{2}/g)
            .map(x => parseInt(x, 16))
    }

    /**
     * Runs through each of `propNames` on object and assigns an empty object if `undefined`.
     * @param obj - any object
     * @param propNames - array of property names in order
     */
    static fillWithEmpty(obj: any, propNames: string[]): void {
        const prop = propNames[0];
        if (prop && obj[prop] === undefined) {
            obj[prop] = {};
        }
        propNames.shift();
        if (propNames.length) {
            this.fillWithEmpty(obj[prop], propNames);
        }
    }

    static getViewDateFromMoment(moment: Moment): string {
        return moment.format('dddd, MMMM D, YYYY');
    }

    // workaround for initial checked state on load (https://github.com/mui-org/material-ui/issues/16434)
    static getMuiFormikCheckBox({ ...props }) {
        const [field] = useField(props.name);
        return <MuiCheckbox {...field} checked={field.value} />;
    }

    /**
     * Make links open in a new window when using ReactMarkdown
     * Use like this:
     * <ReactMarkdown source={text}
     renderers={{
        paragraph: 'span',
        link: UtilsService.reactMarkDownBlankTargets
      }}
     escapeHtml={false} />
     */
    static reactMarkDownBlankTargets(props:{href: string, children: string}): ReactElement {
        return (<a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>);
    }

    static getNumericalName(num: number) {
        if (num === null) {
            return '';
        }
        const special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
        const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

        const stringifyNumber = (n: number) => {
            if (n < 20) return special[n];
            if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
            return deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
        }
        return stringifyNumber(num + 1);
    }
}

export const renderIf = UtilsService.renderIf;