import React from "react";
import classNames from "classnames";
import style from "./ChildMenuOptionExplicitList/style.module.scss";
import {Grid, GridSpacing} from '@material-ui/core';


const NS = 'BookingOptionsHelpers';

type IGridSize = boolean | 2 | 1 | "auto" | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface IGridSizes {
  xs: IGridSize;
  sm: IGridSize;
  md: IGridSize;
  lg: IGridSize;
}

export default class BookingOptionsHelpers {

  static muiGridOrCustomElementInner(
    id: string,
    useMuiGrid: boolean, {xs, sm, md, lg}: IGridSizes,
    gridClasses: string, customElClasses: string,
    gridItemRef: React.RefObject<HTMLDivElement>,
    children: JSX.Element
  ) {
    return (
      useMuiGrid ?
        <Grid key={id} item xs={xs} sm={sm} md={md} lg={lg} className={gridClasses} ref={gridItemRef}>
          {children}
        </Grid>
        :
        <div key={id} className={customElClasses} ref={gridItemRef}>
          {children}
        </div>
    );
  }

  static muiGridOrCustomElementOuter(useMuiGrid: boolean, gridClasses: string, customElClasses: string, gridGutter: GridSpacing, children: JSX.Element) {
    return (
      useMuiGrid ?
        <Grid container spacing={gridGutter} className={gridClasses} >
          {children}
        </Grid>
        :
        <div className={customElClasses}>
          {children}
        </div>
    )
  }

}
