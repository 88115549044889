import {
  IBookingAbstract,
  IBookingMenuOption,
  IPaymentSummaryMenuOption,
  IServicePaymentDetails,
  ISimpleBookingOption
} from "shared-types/index";

const NS = 'PaymentSummaryHelpers';

// copied from widget payment.service.tsx -> getBOForPaymentSummary
export function getPaymentSummaryMenuOption(
  paymentDetails: IServicePaymentDetails, selectedMenuOptions: IBookingMenuOption[], cachedMenuOptionDetails: ISimpleBookingOption[] = []
): IPaymentSummaryMenuOption[] {

  if (!paymentDetails) {
    return null;
  }

  return paymentDetails.options
    .reduce((acc, {id, label, price}) => {
      const selectedMenuOption: IBookingMenuOption = selectedMenuOptions.find(({menuOptionId}) => menuOptionId === id);

      if (selectedMenuOption && selectedMenuOption.quantity) {
        const { extras, quantity } = selectedMenuOption;
        const childOpts: IBookingMenuOption[] = [];
        if (extras) {
          if (extras.explicitChildMenuOptions) {
            extras.explicitChildMenuOptions.forEach(opts => {
              opts.forEach(o => o && childOpts.push(o));
            });
          }
          if (extras.implicitChildMenuOptions) {
            extras.implicitChildMenuOptions.forEach(o => o && childOpts.push(o));
          }
        }

        const childLineItems: IPaymentSummaryMenuOption[] = childOpts.length ? childOpts.reduce((acc, {menuOptionId, quantity}) => {
          const details = cachedMenuOptionDetails.find(o => o.id === menuOptionId);
          if (details && quantity) {
             acc.push({
               quantity,
               label: details.label,
               price: details.price
             });
          }
          return acc;
        }, []) : null;

        acc.push({ label, price, childLineItems, quantity: selectedMenuOption.quantity });
      }

      return acc;
    }, []);
}
