import {
  servicePaymentType,
  IBookingAbstract, IBookingTag
} from "shared-types/index";
import {ICustomer} from "app/models";


export interface IServiceDetails {
  id: string;
  duration: number; // mins
  serviceType: string;
  name: string; // 'Dinner'
}

export interface IBookingMenuOptionExtras {
  isSameForAll: boolean;
  explicitChildMenuOptions: IBookingMenuOption[][]; // multi-dimensional array
  implicitChildMenuOptions: IBookingMenuOption[];
}

export interface IBookingMenuOptionExtrasUpdater extends IBookingMenuOptionExtras {
  parentId: string;
  parentLabel: string;
  parentQuantity: number;
}

export interface IBookingMenuOption {
  quantity: number;
  menuOptionId: string;
  extras?: IBookingMenuOptionExtras; // expanded list of child booking options
}

export interface ISavedBookingMenuOption {
  quantity: number;
  menuOptionId: string;
  extras?: ISavedExtrasOption[]; // flat list of child booking options for backend
}

export interface ISavedExtrasOption {
  quantity: number;
  menuOptionId: string;
  isExplicit: boolean;
}

export interface IBookingTagType {
  name: string; // "Occasion"
  type: string; // "tagtype"
  _id: string; // "tagtype_occasion"
}

export interface IPaymentOverride {
  paymentType: servicePaymentType;
  price: number;
}

export enum PaymentMethod {
  Email = 'Email',
  Sms = 'Sms',
  Direct = 'Direct'
}

export interface IBookingOutgoing {
  customer: ICustomer;
  time: string; // "2019-11-14T19:30:00"
  notes: string;
  selectedMenuOptions: ISavedBookingMenuOption[];
  numOfPeople: number;
  sectionId: string; // 'all'
  service: IServiceDetails;
  NoDelete?: boolean; // Just used for updateBooking. Should be determined by BookingService.shouldPay
  tags: IBookingTag[];
  paymentOverride: IPaymentOverride;
  sendSmsNotifications: boolean;
  sendEmailNotifications: boolean;
  seatingSpecification: {
    tableIds: string[];
    allowBookingWhenNominatedTablesNotAvailable: boolean;
  };
  paymentFlow?: PaymentMethod;
}

export interface IBookingCustomer extends ICustomer {
  birthdayDate: unknown;
  birthdayMonth: unknown;
  birthdayYear: unknown;
  lastModifiedDate: string; // "2019-11-29T02:11:07.2713648+00:00"
  managerNotes: string;
  noShowCount: number; // 0
  phone2: string;
  postcode: unknown;
  streetAddress: unknown;
  suburb: unknown;
  tags: IBookingTag[];
  type: string; // "customer"
}

export interface ISavedBooking extends IBookingAbstract {
  time: string; // "2019-11-14T19:30:00" (used for savedBooking)
  bookingId: string;
  duration: number; // mins
  notes: string;
}

export interface IManageBookingConfig {
  title: string;
  message: string;
  hideDetails: boolean;
  hideButtons: boolean;
}

export interface IStandbyRequest {
  covers: number;
  serviceId: string;
  sectionId: string;
  desiredBookingTime: string;
  isFlexibleTime?: boolean;
  tags: IBookingTag[];
  customer: ICustomer;
}

