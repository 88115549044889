import React from "react";
import TableSeater from 'shared-components/table-seater/index';
import {IProps, ITableSelectionListItem} from 'shared-components/table-seater/types';
import {IContainerProps, IHandlers} from './types';
import {RootState} from "app/main/rootReducer";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {setSelectedTablesData} from "app/reducers/tableSlice";
import {cloneDeep} from "lodash-es";
import {IBlockout, ITableItem, BlockoutType} from "shared-types/SharedTypes";
import moment from "moment";
const NS = 'TableSeaterContainer';

/**
 * Gets the interface types as array of strings so we don't need hand code them each time (and reduces chance for mistakes).
 */

const mapStateToProps = (all: RootState & IContainerProps): IProps => {

    const { venueGridReducer } = all;
    const data = venueGridReducer.tableSectionData;
    const preselectedTableIds = all.tableReducer.selectedTableIds;
    const bookingTime = venueGridReducer.bookingTime;
    const bookingStart = moment(bookingTime.time).toDate();

    /**
     * Filters blockouts to be within the start time and duration of the booking.
     */
    const blockoutsWithinSelectedBookingTime = venueGridReducer.selectedSchedule.blockouts
      .filter(({startDateTime, endDateTime, type}) => {
          const boStart = moment(startDateTime).toDate();
          const boEnd = moment(endDateTime).toDate();
          const boStartsBeforeOrOnBooking = boStart <= bookingStart;
          const boEndsAfterBookingBegins = boEnd > bookingStart;

          /**
           * Don't include events as blockouts if the current service is an event.
           * We don't have to worry about other events blockout out because they cannot
           * overlap each other anyway.
           */
          if (venueGridReducer.selectedServiceIsEvent && type === BlockoutType.event) {
              return false;
          }

        return boStartsBeforeOrOnBooking && boEndsAfterBookingBegins;
      });

    /**
     * Checks if the table is attached to the blockout
     */
    const isBlockedOut = (t: ITableSelectionListItem, b: IBlockout): boolean => {
        const sectionIsBlocked = b.blockedSections.some(bs => {
            const sectionId = t.sectionId || t.tablesList?.length && t.tablesList[0].sectionId;
            return bs === sectionId
        });
        if (sectionIsBlocked) return true;

        const tableIsBlocked = b.blockedTables.some(bt => {
            if (t.tables?.length) {
                return t.tables.some(tId => tId === bt._id);
            }
            return bt._id === t._id;
        });
        return tableIsBlocked;
    }

    let tableSelectionList: ITableItem[] = cloneDeep(data.tableSelectionList).filter(t => !blockoutsWithinSelectedBookingTime.some(b => isBlockedOut(t, b)));

    let tableSelectionListWithJoins: ITableItem[] = cloneDeep(data.tableSelectionListWithJoins).filter(t => !blockoutsWithinSelectedBookingTime.some(b => isBlockedOut(t, b)));

    if (!venueGridReducer.isWalkIn) {
        tableSelectionList = tableSelectionList.filter(t => !t.walkinOnly);
        tableSelectionListWithJoins = tableSelectionListWithJoins.filter(t => !t.walkinOnly);
    }

    // removes invalid (red) tables from the list
    tableSelectionList = tableSelectionList.filter(t => t.valid || t.free);
    tableSelectionListWithJoins = tableSelectionListWithJoins.filter(t => t.valid || t.free);

    return {
        isWhiteTheme: false,
        label: 'Table',
        preselectedTableIds,
        sectionsLookup: venueGridReducer.sectionsLookup,
        showEmptyTableValidationMessage: undefined,
        // cloning arrays so their objects can be extensible (isAltBg
        tableSelectionList,
        tableSelectionListWithJoins,
        useReversedColors: false
    };
};

/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IHandlers => {
    return {
        handleSelectedChanged: (selectedTableIds: string[], tables: ITableItem[], isManualJoin: boolean) => {
            const payload = {selectedTableIds, tableList: cloneDeep(tables)};
            dispatch(setSelectedTablesData(payload));
        }
    }
};


const TableSeaterContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TableSeater as any);

export default TableSeaterContainer;
