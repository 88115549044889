import React, {useEffect, useState} from 'react';
import {ICountdownTimer} from './types';
import style from './style.module.scss';
import {renderIf} from "shared-services/react-utils-service";
import classNames from "classnames";
import {createStyles, Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {IWidgetTheme} from "shared-types/WidgetTypes";
import {ThemeColorsService} from "shared-services/theme-colors-service";


const NS = 'CountdownTimer';

const useStyles = makeStyles((theme: IWidgetTheme) => {
    const {secondary} = ThemeColorsService.getCommonThemeStyles(theme);

    return createStyles({
        sessionBanner: {
            backgroundColor: secondary.main,
            color: theme.palette.getContrastText(secondary.main)
        }
    })
});

export default function CountdownTimer({initialMinute = 0, theme, timerExpired}: ICountdownTimer) {



    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(0);

    const classes = useStyles({theme});

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    timerExpired();
                    clearInterval(myInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });


    return (

        <div>
            
            {renderIf(!(minutes === 0 && seconds === 0), () => (
                <div className={classNames({
                    [style.sessionRemaining]: true,
                    [style.sessionRemainingIsRounded]: true,
                    [style.sessionRemainingIsStacked]: false,
                    [classes.sessionBanner]: true
                })}>
                    <Typography variant="body1" className={style.sessionRemainingText}>
                        Your booking will <span className={style.hideBreak}><br/></span>
                        expire in: {minutes < 60 ? `${minutes}`:`${Math.floor(minutes/60)}:${(minutes%60) < 10 ? `0${minutes%60}`:`${minutes%60}`}`}:{seconds < 10 ? `0${seconds}` : seconds}
                    </Typography>
                </div>
            ))}
        </div>

    )
}