import {Moment} from "moment/moment";
import {from, Observable, of} from "rxjs";
import {
    IAccountDetails,
    ICustomerDetails,
    IScheduleParams,
    ITablePickerData
} from "app/models";
import axios, {AxiosAdapter, AxiosInstance} from "axios";
import {setupCache} from "axios-cache-adapter";
import {map} from "rxjs/operators";
import config from '../../../_helpers/app.constants';
import {
    ISchedule,
    IServicePaymentOption,
    IBookingResponseData,
    ISavedBookingMenuOption,
    IPaymentType
} from "shared-types/index";
import {IBookingOutgoing, IStandbyRequest} from "app/services/booking/booking.types";
import {IResponse} from "app/models/common.types";
import PhoneNumberService from "shared-services/phone-number-service/index";
import ObjectCopyService from "shared-services/object-copy-service/index";

// @toDo: check cache time required. severity: high
const cache = setupCache({
    maxAge: 24 * 60 * 60 * 1000 // cached for 1 day
});

// Create `axios` instance passing the newly created `cache.adapter`
const api: AxiosInstance = axios.create({
    adapter: cache.adapter as AxiosAdapter
});

// Adding Authorisation code with every request
api.interceptors.request.use(request => {
    // add auth header with jwt if account is logged in and request is to the api url
    const isLoggedIn = localStorage.token || false;
    const isApiUrl = request.url.includes(config.backendApiUrl);

    if (isLoggedIn && isApiUrl) {
        request.headers.common.Authorization = ClientService.getAuth();
    }

    return request;
});

export class ClientService {

    static getScheduleUrl(accId: string): string {
        return `${config.backendApiUrl}/booking-centre/accounts/${accId}/schedules`;
    }

    static getAccountUrl(accId: string): string {
        return `${config.backendApiUrl}/booking-centre/accounts/${accId}`;
    }

    static getCustomerEndpointsURL(accId: string) {
        return `${config.backendApiUrl}/restaurants/search-customers/by-account/${accId}`;
    }

    static getTablePickerEndPointUrl(venueId: number) {
        return `${config.backendApiUrl}/booking-centre/table-picker/venue/${venueId}`;
    }

    static getManageBookingDetailsURL(venueId: string, emailToken: string) {
        return `${config.backendApiUrl}/booking-centre/venues/${venueId}?token=${emailToken}`;
    }

    static getPaymentTypeUrl(venueId: number): string {
        return `${config.backendApiUrl}/booking-centre/payments/venues/${venueId}/calculate-payment`;
    }

    static getCancelBookingUrl(venueId: number, bookingToken: string): string {
        return `${config.backendApiUrl}/booking-centre/venues/${venueId}/cancel-booking` + (bookingToken ? `?token=${bookingToken}` : '');
    }

    /**
     * Takes menuOption ids and response with full details of those menuOptions
     * @param childMenuOptionIds - comma separated list of menuOption ids to query
     * @param venueId
     */
    static getBookingOptions(childMenuOptionIds: string, venueId: number): Observable<IServicePaymentOption[]> {
        if (!childMenuOptionIds) {
            return of(null)
        }

        return from(
            api.post(`${config.backendApiUrl}/bookings/venues/${venueId}/booking-options`, {childMenuOptionIds})
        ).pipe(
            map(({data}: any) => data as IServicePaymentOption[]),
        );
    }

    static saveBooking(booking: IBookingOutgoing, venueId: number): Observable<IResponse<IBookingResponseData>> {
        return from(
            api.post(`${config.backendApiUrl}/booking-centre/venues/${venueId}/bookings`, booking)
        );
    }

    static saveStandbyBooking(booking: IStandbyRequest, venueId: number) {
        return from(
            api.post(`${config.backendApiUrl}/booking-centre/venues/${venueId}/add-standby`, booking)
        );
    }

    /**
     * Actually deletes the booking (all records of it). Used when a user gets to payment page, but then cancels.
     * Similar to cancelBooking.
     */
    static deleteBooking(bookingId: string, venueId: number): Observable<any> {
        return from(
            api.delete(`${config.backendApiUrl}/booking-centre/venues/${venueId}/delete-booking?bookingId=${bookingId}`)
        );
    }

    static confirmBooking(bookingToken: string, venueId: number): Observable<IResponse<IBookingResponseData>> {
        return from(
            api.post(`${config.backendApiUrl}/booking-centre/venues/${venueId}/confirm-booking?token=${bookingToken}`)
        );
    }

    /**
     * Loads the schedule for a date
     * @param date
     * @param covers
     * @param accId
     * @param showWalkInTables
     * @param venueIds
     *  so that it can become available again. This is useful when editing an existing booking.
     */
    static getABCSchedule(date: Moment, covers: number, accId: string, showWalkInTables: boolean, venueIds: number[]): Observable<ISchedule[]> {

        const params: IScheduleParams = {
            date: date.format('YYYY-MM-DD'),
            numOfPeople: covers,
            showWalkInTables,
            venueIds
        };

        return from(
            api.post(this.getScheduleUrl(accId), {...params})
        ).pipe(
            map(({data}: any) => data as ISchedule[]),
        );
    }

    static getAccount(accId: string): Observable<IAccountDetails> {
        return from(
            api.get(this.getAccountUrl(accId))
        ).pipe(
            map(({data}: any) => data as IAccountDetails),
        );
    }

    /**
     * Cancels a booking that has been saved (if payment is required, then it has also passed the payment page).
     * Similar to deleteBooking.
     */
    static cancelBooking(bookingToken: string, venueId: number): Observable<any> {

        return from(
            api.post(this.getCancelBookingUrl(venueId, bookingToken))
        );
    }

    static searchCustomer(inputs: any, isAccountLevel: boolean, accId: string): Observable<ICustomerDetails[]> {
        const inputVal = {
            q: inputs,
            count: 15
        }

        const params: any = {
            params: inputVal
        }

        return from(
            api.get(this.getCustomerEndpointsURL(accId), params)
        ).pipe(
            map(({data}: any) => data as ICustomerDetails[]),
        );
    }

    static getAuth() {
        const token = localStorage.token;
        return 'Bearer ' + token;
    }

    static getTablePicker(venueId: number, serviceId: string, bookingTime: string, numOfPeople: number): Observable<ITablePickerData> {

        const params: any = {
            params: {venueId, serviceId, bookingTime, numOfPeople}
        }

        return from(
            api.get(this.getTablePickerEndPointUrl(venueId), params)
        ).pipe(
            map(({data}: any) => data as ITablePickerData),
        );
    }

    static getManageBookingDetails(venueId: string, emailToken: string): Observable<IBookingResponseData> {
        return from(
            api.get(this.getManageBookingDetailsURL(venueId, emailToken))
        ).pipe(
            map(({data}: any) => data as IBookingResponseData),
        );
    }

    /**
     * Gets the paymentType and amount.
     * `selectedMenuOptions` must be in save format as when saving booking (transform using `menuOptionsService.getFlatExtras`).
     * Sample Response
     * {
     *   "amount": 132.5,
     *   "paymentTypeName": "FullPayment"
     * }
     */
    static getPaymentType(
        venueId: number, selectedMenuOptions: ISavedBookingMenuOption[], serviceId: string, bookingDateTime: string,
        covers: number, bypassValidation = false
    ): Observable<IResponse<IPaymentType>> {
        return from(
            api.post(this.getPaymentTypeUrl(venueId), {
                serviceId,
                time: bookingDateTime,
                numberOfPeople: covers,
                selectedMenuOptions,
                bypassValidation
            })
        );
    }

    // private static formatCustomerPhoneNumber(booking: IStandbyRequest | IBookingOutgoing): IStandbyRequest | IBookingOutgoing {
    //     const temp: IStandbyRequest | IBookingOutgoing = ObjectCopyService.angularSafeCopy(booking);
    //     temp.customer.phone = PhoneNumberService.formatInterNationalPhoneNumber(temp.customer.phone, temp.customer.country);
    //     return temp;
    // }
}
