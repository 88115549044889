import React from 'react';
import {IWalkInToggle} from './types';
import {FormControlLabel, FormGroup, Switch} from "@material-ui/core";
import style from './style.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "app/main/rootReducer";
import {toggleWalkIns} from 'app/reducers/venueGridSlice';

const NS = 'WalkInToggle';

export default function WalkInToggle({}: IWalkInToggle) {

    const dispatch = useDispatch();
    const {isWalkIn} = useSelector((state: RootState) => state.venueGridReducer);

    function handleChange(isWalkIn: boolean) {
        dispatch(toggleWalkIns(isWalkIn));
    }

    return (
        <FormGroup row className={style.root}>
            <FormControlLabel
                control={<Switch checked={isWalkIn} onChange={() => handleChange(!isWalkIn)} name="walkIn"/>}
                label="Show walk in tables"
            />
        </FormGroup>
    )
}