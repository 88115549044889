import {sortBy, uniqBy} from 'lodash-es';
import {ISectionLookup, ISchedule, IScheduleAbstract} from "shared-types/index";

const NS = 'SectionsService';


/**
 * Singleton service, independent of AngularJS
 */
export default class SectionsService {
    /**
     * In some cases 'order' seems to be duplicating (bad data), so here we try and fix that using alpha-numeric sorting
     */
    static manageDuplicateOrder(sectionsLookup: any): any {
        let arr: { propName: string, section: {order: number, name: string} }[] = [];
        let duplicateOrderDetected = false;

        // loops through the existing section lookup object and creates array that we can reliably sort
        for (const propName in sectionsLookup) {
            const sectionLU: ISectionLookup = sectionsLookup[propName];
            const thisOrder = sectionLU.order;
            arr.push({
                propName,
                section: sectionLU
            });

            if (arr.find(({section}) => section.order === thisOrder)) {
                duplicateOrderDetected = true;
            }
        }

        // if no duplicates detected, returns the same object that was passed in with no modifications
        if (!duplicateOrderDetected) {
            return sectionsLookup;
        }

        // first sort by name (alpha-numeric) and then by order
        arr = sortBy(arr, ({section}) => section.name);
        arr = sortBy(arr, ({section}) => section.order);

        // recreate the lookup object
        const newLookup: any = {};
        arr.forEach(({section, propName}, i) => {
            newLookup[propName] = {...section, order: i};
        });
        return newLookup;
    }

    /**
     * Returns all the sections
     */
    static getSectionsLookUp(schedule: IScheduleAbstract, layouts: {sectionId: string}[]): any {

        let allSections = schedule.services.reduce((acc, service) => {
            return acc.concat(service.sections);
        }, []);

        allSections = uniqBy(allSections, 'id');

        let sectionsLookup: any = {};

        layouts.forEach(lo => {
            const section = allSections.find(({id}) => lo.sectionId === id);
            if (section) {
                sectionsLookup[lo.sectionId] = section;
            }
        });

        sectionsLookup = this.manageDuplicateOrder(sectionsLookup);

        return sectionsLookup;
    }
}
