import {from, Observable, of} from "rxjs";
import {switchMap, map} from "rxjs/operators";
import axios, {AxiosAdapter, AxiosInstance, AxiosRequestConfig} from 'axios';
import {setupCache} from 'axios-cache-adapter';
import {
    IResponse,
    IFunctionPaymentSummaryResponseData,
    IPayNowParams,
    IPrepareEwayData,
    IPrepareEwayFunctionData,
    ISavePreAuthData,
    ISavePreAuthResponseData,
    IFinilisePayment3DSecureParams,
    IStripePaidData, IEwaySummaryResponseData, IPromoCodeResponseData, IHasPromoCodeResponseData, IErrorResponse,
    ISetupPreAuth3DData,
    ISetupPreAuth3DResponse,
    IFinalizePreAuth3DData,
    IFinalizePreAuth3DResponse
} from "shared-types/index";

const NS = 'PaymentApiRequests';

// @todo: check cache time required. severity: low
const cache = setupCache({
    maxAge: 24 * 60 * 60 * 1000 // cached for 1 day
});

// Create `axios` instance passing the newly created `cache.adapter`
const api: AxiosInstance = axios.create({
    adapter: cache.adapter as AxiosAdapter
});

interface IAddEndpoints {
    payNowUrl: string;
    eventPayNowUrl: string;
    eventCompletedUrl: string;
    preAuthUrl: string;
    applyPromoCodeUrl: string;
    hasPromoCodeUrl: string;
    getPaymentIntent3DSecureUrl: (venueId: number, bookingId: string) => string;
    finilisePayment3DSecureUrl: string;
    ewayPaymentSummaryUrl: string;
    setupPreAuthStripe3DUrl: string,
    finalizePreAuthStripe3DUrl: string
}

class PaymentApiRequests {
    private payNowUrl: string;
    private eventPayNowUrl: string;
    private eventCompletedUrl: string;
    private preAuthUrl: string;
    private hasPromoCodeUrl: string;
    private applyPromoCodeUrl: string;
    private getPaymentIntent3DSecureUrl: (venueId: number, bookingId: string) => string;
    private finilisePayment3DSecureUrl: string;
    private ewayPaymentSummaryUrl: string;
    private setupPreAuthStripe3DUrl: string;
    private finalizePreAuthStripe3DUrl: string;

    addEndpoints({
         payNowUrl, eventPayNowUrl, eventCompletedUrl, preAuthUrl, applyPromoCodeUrl, hasPromoCodeUrl,
         getPaymentIntent3DSecureUrl, finilisePayment3DSecureUrl, ewayPaymentSummaryUrl, setupPreAuthStripe3DUrl, finalizePreAuthStripe3DUrl
    }: IAddEndpoints): void {
        this.payNowUrl = payNowUrl;
        this.eventPayNowUrl = eventPayNowUrl;
        this.eventCompletedUrl = eventCompletedUrl;
        this.preAuthUrl = preAuthUrl;
        this.applyPromoCodeUrl = applyPromoCodeUrl;
        this.hasPromoCodeUrl = hasPromoCodeUrl;
        this.getPaymentIntent3DSecureUrl = getPaymentIntent3DSecureUrl;
        this.finilisePayment3DSecureUrl = finilisePayment3DSecureUrl;
        this.ewayPaymentSummaryUrl = ewayPaymentSummaryUrl;
        this.setupPreAuthStripe3DUrl = setupPreAuthStripe3DUrl;
        this.finalizePreAuthStripe3DUrl = finalizePreAuthStripe3DUrl;
    }

    /**
     * Should only get called once per session/app load.
     * Used for better SEQ logging.
     * Same thing also in ClientService.
     */
    setUpRequestInterceptor(source: string, globalSessionCorrelationId: string): void {
        const requestInterceptor = (config: AxiosRequestConfig) => {
            config.headers['X-NBI-CorrelationId'] = globalSessionCorrelationId;
            config.headers['X-NBI-Source'] = source; // 'widget2';
            return config;
        };
        api.interceptors.request.use(requestInterceptor);
    }


    /**
     * The calls to eway and stripe are used to create a token, but the actual payment gets done on the back end
     * after this call gets made. FYI, using test credit cards that reject the payment, tend to always return 400 (Bad Request) responses.
     */
    payNow(params: IPayNowParams): Observable<IResponse<IPrepareEwayData>> {

        if (!this.payNowUrl) {
            console.warn(NS, `'payNowUrl' has not been defined!`)
            return of(null);
        }

        return from(
            api.post(this.payNowUrl, {...params})
        );
    }

    getFunctionPaymentSummary(
        venueId: number,
        eventId: string,
        accessCode: string = null,
        stripePaymentToken: string = null
    ): Observable<IResponse<IFunctionPaymentSummaryResponseData>> {

        if (!this.eventCompletedUrl) {
            console.warn(NS, `'eventCompletedUrl' has not been defined!`)
            return of(null);
        }

        return from(
            api.get(this.eventCompletedUrl, {
                params: {
                    venueId, eventId, accessCode, stripePaymentToken
                }
            })
        );
    }

    eventPaynow(params: IPayNowParams): Observable<IResponse<IPrepareEwayFunctionData>> {

        if (!this.eventPayNowUrl) {
            console.warn(NS, `'eventPayNowUrl' has not been defined!`)
            return of(null);
        }

        return from(
            api.post(this.eventPayNowUrl, {...params})
        )
    }

    ewayPaymentSummary(bookingId: string, venueId: number, accessCode: string): Observable<IResponse<IEwaySummaryResponseData>> {
        const params = {
            venueId,
            bookingId,
            accessCode
        };
        return from(
          api.get(this.ewayPaymentSummaryUrl, {params})
        );
    }

    savePreAuth(params: ISavePreAuthData): Observable<IResponse<ISavePreAuthResponseData>> {
        return from(
            api.post(this.preAuthUrl, {...params})
        );
    }


    paymentIntent3DSecure(bookingId: string, venueId: number): Observable<IResponse<string>> {
        return from(
            api.get(this.getPaymentIntent3DSecureUrl(venueId, bookingId))
        );
    }


    finilisePayment3DSecure(bookingId: string, venueId: number, TransactionId: string, AmountPaidId: number): Observable<IResponse<IStripePaidData>> {
        const params: IFinilisePayment3DSecureParams = {
            venueId,
            bookingId,
            TransactionId,
            AmountPaidId
        };
        return from(
            api.post(this.finilisePayment3DSecureUrl, {...params})
        )
        //     .pipe(
        //     map(({data}: IResponse<IProcessPayment>) => data)
        // );
    }

    setupPreAuthStripe3D(params: ISetupPreAuth3DData): Observable<ISetupPreAuth3DResponse> {
        console.log("setupPreAuth3D");
        return from(
          api.post(this.setupPreAuthStripe3DUrl, {...params})
        );
      }
    
      finalizePreAuthStripe3D(params: IFinalizePreAuth3DData): Observable<IFinalizePreAuth3DResponse> {
        console.log("finalizePreAuth3D");
        return from(
          api.post(this.finalizePreAuthStripe3DUrl, {...params})
        );
      }


    hasPromoCode(bookingId: string, venueId: number): Observable<IResponse<IHasPromoCodeResponseData>> {
        const params = {
            venueId,
            bookingId
        }
        return from(
          api.post(this.hasPromoCodeUrl, { ...params })
        );
    }

    applyPromoCode(promotionCode: string, bookingId: string, venueId: number): Observable<IResponse<IPromoCodeResponseData>> {
        const params = {
            venueId,
            bookingId,
            promotionCode
        }
        return from(
          api.post(this.applyPromoCodeUrl, {...params})
        );
    }
}

// instance needed for stripeInstance reference
const instance = new PaymentApiRequests();
export {instance as PaymentApiRequests}