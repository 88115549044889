import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ILoggedInUser} from "app/models";


// Define a type for the slice state
interface AuthenticationSlice {
    loggedInUser: ILoggedInUser,
}

// Define the initial state using that type
const initialState: AuthenticationSlice = {
    loggedInUser: {} as ILoggedInUser
}

export const authenticationSlice = createSlice({
    name: 'groupWidgetAuthentication',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setLoggedInUser: (state, action: PayloadAction<ILoggedInUser>) => {
            state.loggedInUser = action.payload;
        },
        removeLoggedInUser: (state, {payload}: PayloadAction<ILoggedInUser>) => {
            return {
                ...state,
                loggedInUser: initialState.loggedInUser
            };
        }
    }
})

export const {setLoggedInUser, removeLoggedInUser} = authenticationSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default authenticationSlice.reducer