import React from 'react';
import {IVenueGridRow} from './types';
import Grid from "@material-ui/core/Grid";
import style from './style.module.scss';
import {VenueGridService} from "app/services/venueGrid.service";
import VenueGridCell from "app/components/VenueGridComponent/VenueGridCell";
import {Box, createStyles, Link, Theme, Typography} from "@material-ui/core";
import {CELL_WIDTH, IScheduleServiceABC} from "app/models";
import venueGridStyle from "app/components/VenueGridComponent/VenueGrid/style.module.scss";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import {renderIf} from "app/services/utils.service";
import {loadStatus} from "shared-types/WidgetTypes";

const NS = 'VenueGridRow';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fixedColumnShift: {
            // marginLeft: `${CELL_WIDTH}px`
        },
    }),
);

export default function VenueGridRow({accountId, gridRow, venueStartEndTimes, bookingInterval, viewDate}: IVenueGridRow) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid item className={venueGridStyle.venueCellSticky}>
                <Box style={{width: `${CELL_WIDTH}px`}} className={classNames({
                    [style.venueNameCell]: true
                })}
                     title={VenueGridService.isVenueClosed(gridRow)  ? 'This venue is closed' : ''}
                >
                    <Typography className={classNames({
                        [style.truncate]: true,
                        [style.venueName]: true
                    })}>
                        <Link href="#" onClick={() => {
                            VenueGridService.goToDiary(gridRow.venueDetails.id, accountId)
                        }}>
                            {gridRow.venueDetails.name}
                        </Link>
                        {VenueGridService.isVenueClosed(gridRow) ? '( Closed )' : ''}
                    </Typography>
                    <ul style={{margin: 0}}>
                        {gridRow.services.map((service: IScheduleServiceABC, index: number) => (
                            <li key={index} className={style.servicesCell}>
                                {service.name}
                            </li>
                        ))}
                    </ul>
                </Box>
            </Grid>
            {renderIf(!VenueGridService.isVenueClosed(gridRow), () => (
                    <Box style={{display: 'inline-flex'}} className={classNames({
                        [classes.fixedColumnShift]: true,
                    })}>

                        {venueStartEndTimes.map((time: string, index) => {
                                return (
                                    <Grid key={index} className={style.gridCell} id={`gridCell_${index}_${time}`}>
                                        {gridRow.services.map((service: IScheduleServiceABC, index: number) => {
                                            return (
                                                <Box id={`gridService_${index}`} key={index} style={{width: `${CELL_WIDTH}px`}}
                                                     className={style.cellValue}>
                                                    {renderIf(VenueGridService.checkIfVenueTimesToBeCalled(time, service.times, viewDate), () => {
                                                        return (
                                                            <VenueGridCell
                                                                times={VenueGridService.getVenueTimes(time, service.times)}
                                                                interval={bookingInterval} service={service}
                                                                schedule={gridRow}/>
                                                        )
                                                    })}

                                                </Box>
                                            )
                                        })}
                                    </Grid>
                                )
                            }
                        )}
                    </Box>
                ),
                <Typography className={classNames({
                    [style.venueClosed]: true
                })}>
                    The Venue is closed for the day
                </Typography>
            )}

        </React.Fragment>

    )
}