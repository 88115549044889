import React from 'react';
import style from './style.module.scss';
import {IStateFromProps, IDispatchFromProps,IHandlers} from './types';
import ReactMarkdown from "react-markdown";
import {Divider, Paper, Typography, Button} from "@material-ui/core";
import {renderIf} from "app/services/utils.service";
import classNames from 'classnames';

const NS = 'ThankYou';


export default function ThankYou({message, title, bodyIsMarkDown, booking, shouldShowBookingReferenceNumber, shouldShowMakeAnotherBookingButton, handleRebook}: IStateFromProps & IDispatchFromProps & IHandlers) {

    const isDark = false;
    return (
        <Paper elevation={1} className={style.root}>

            {renderIf(title, () => (
                <>
                    <Typography variant="h2" className={style.title} data-testid="title">
                        {title}
                    </Typography>

                    <Divider className={classNames({
                        [style.divider]: true,
                        [style.dividerIsDark]: isDark
                    })}/>
                </>
            ))}

            {renderIf(message, () => (
                <div>
                    <Typography
                        variant="body1"
                        data-testid="body"
                        component={bodyIsMarkDown ? 'div' : 'p'}
                        className={style.body}
                    >
                        {bodyIsMarkDown
                            ? <ReactMarkdown source={message} escapeHtml={false}/>
                            : message
                        }
                    </Typography>
                    {
                        renderIf(shouldShowBookingReferenceNumber, () => (
                            <Typography component="h3" variant="body2">
                                Booking Ref: {booking.bookingRef}
                            </Typography>
                        ))
                    }

                    {
                        renderIf(shouldShowMakeAnotherBookingButton, ()=> (
                            <Button 
                            data-testid="book-btn"
                            variant="contained" 
                            color="primary" 
                            onClick={handleRebook}>
                            Make Another Booking
                            </Button>
                        ))
                    }
                </div>

            ))}
        </Paper>
    )
}

