import React from 'react';
import style from './style.module.scss';
import classNames from 'classnames';
import { IProps } from './types';

const NS = 'ColumnWrap2';

export default function ColumnWrap2({isLandscape, children, grow}: IProps) {
  
  return (
    <div className={classNames({
      [style.container]: !isLandscape,
      [style.containerIsLandscape]: isLandscape
    })}>
      <div className={classNames({
        [style.leftColumn]: true,
        [style.leftColumnDoGrow]: isLandscape && grow === 'left',
        [style.leftColumnIsWide]: isLandscape && !grow,
        [style.leftColumnIsLandscape]: isLandscape
      })}>
        {children[0]}
      </div>
      <div className={classNames({
        [style.rightColumnDoGrow]: isLandscape && grow === 'right',
        [style.rightColumnIsWide]: isLandscape && !grow
      })}>
        {children[1]}
      </div>
    </div>
  )
}