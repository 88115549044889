import React, { ReactElement } from 'react';
import { FormattedNumber } from 'react-intl/dist';

export class IntlService {
  
  static currencyValue(val: number, currency: string): ReactElement {
    if (!val) {
      return null;
    }

    // for these countries just use a dollar symbol. Everyone else gets internationalization
    const isDollarCurrency = currency === 'AUD' || currency === 'USD' || currency === 'NZD' || currency === 'CAD';
    if (isDollarCurrency) {
      return <span>${val.toFixed(2)}</span>;
    }

    const isSGD = currency === 'SGD'; // bug in intl renders as `SGD` instead of `$S` so we need to add exception 
    return isSGD ? 
      <span>S${val.toFixed(2)}</span>
      : <FormattedNumber
        currencyDisplay='symbol'
        value={val}
        style="currency"
        minimumFractionDigits={2}
        maximumFractionDigits={2}
        currency={currency} />
      
  }
  
  static getLocale = () => navigator.language || (navigator as any).browserLanguage || (navigator.languages || ["en"])[0];

}