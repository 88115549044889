export enum ROUTE_NAMES {
    // SETUP = 'setup',
    // SITTING = 'sitting',
    // VENUES = 'venues',
    // CUSTOMER_DETAILS = 'customerDetails',
    // SUMMARY = '/summary',
    STAND_BY = '/standby',
    THANK_YOU = '/thankyou',
    ERROR_PAGE = '/errorPage',
    CANCEL = '/cancel',
    MANAGE_BOOKING = '/manage-booking',
    PAYMENTS = '/payments',
    PAYMENT_COMPLETE = '/paymentComplete',
    BOOKING = '/booking',
    DEFAULT = '/',
    // SAMPLE = '/sample',
}

export interface IRouteTransitions {
    id: number;
    from: ROUTE_NAMES;
    to: ROUTE_NAMES;
}


export interface IRouteState {
    name: string;
    component: (props: any) => React.ReactElement;
    url?: string; // only needed for external routes, such as edit/cancel - or the style guide
    analyticsTitle?: string;
    analyticsUrl?: string;
}

export interface INavigation {
    stepRoutes: ROUTE_NAMES[];
    currentStepIndex: number;
    currentRouteName: string;
    transitionIdIncrementer: number;
    transitions: IRouteTransitions[];
    loadingPaymentOnNext: boolean;
}