import React, { Component } from 'react';
import { IProps } from './types';
import style from './style.module.scss';
import { Popover, Typography, Button } from '@material-ui/core';
import { themeTypes } from 'app/models';
import classNames from 'classnames';

const NS = 'CvcPopover';


interface IState {
  isCVCPopoverOpen: boolean;
}

export default class CvcPopover extends Component<IProps, IState> {

  cvcLink: any = React.createRef();

  readonly state: IState = {
    isCVCPopoverOpen: false
  }

  render() {
    const {theme, cvcImagePath} = this.props;

    const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;

    return (
      <>
        <Popover
          open={this.state.isCVCPopoverOpen}
          anchorEl={this.cvcLink.current}
          onClose={() => {
            this.setState({
              isCVCPopoverOpen: false
            })
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <div className={style.cvcPopover}>
            <Typography variant="body1" className={classNames({
              [style.cvcPopoverText]: true,
              [style.cvcPopoverTextIsDark]: isDark
            })}>
              The CVC (also referred to as CVV) is the three- or four-digit number printed directly on the credit card, usually either on the signature strip or the front of the card. 
            </Typography>
            <img className={style.cvcPopoverImg} src={cvcImagePath} alt="CVC description" ></img>
          </div>
        </Popover>
        <Button className={style.cvcLink} ref={this.cvcLink} color="default" variant="contained" onClick={() => {
          this.setState({
            isCVCPopoverOpen: true
          })
        }}>
          What&rsquo;s this?
        </Button>
      </>
    )
  }
}
