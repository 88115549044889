import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {loadStatus} from "app/models/common.types";

// Define a type for the slice state
interface LibPhoneSlice {
    libPhoneNumberLoaded: boolean,
    libPhoneNumberLoading: boolean,
}

// Define the initial state using that type
const initialState: LibPhoneSlice = {
    libPhoneNumberLoaded: false,
    libPhoneNumberLoading: false
}

export const libPhoneSlice = createSlice({
    name: 'libPhoneSlice',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        libPhoneLoaded: (state, action: PayloadAction<loadStatus>) => {

            return {
                ...state,
                libPhoneNumberLoaded: action.payload === loadStatus.success,
                libPhoneNumberLoading: action.payload === loadStatus.loading
            }

        }
    }
})

export const {libPhoneLoaded} = libPhoneSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default libPhoneSlice.reducer