import {IState, IStateFromProps, IDispatchFromProps, expandPanelType} from "./types";

/**
 * Moved a few functions into separate file as main index file was getting a bit too long
 */
const NS = 'SittingPanels (helpers)';

/**
 * Some global props can affect state (such as max pax count and alert messages), so here we manage those exceptions.
 */
export function getDerivedStateFromProps(nextProps: IStateFromProps & IDispatchFromProps, currentState: IState) {
    const {
        maxPeoplePerBooking,
        hasMenuOptionAlert, sectionAlertMessage, serviceAlertMessage, hasTimesAlertMessage
    } = nextProps;

    const coversTooMuch: boolean = nextProps.coversPrefill > maxPeoplePerBooking;

    // const displaySections: boolean = hasMoreThan1Section && !nextProps.scheduleLoading;
    // const displayTimes: boolean = nextProps.activeService && !nextProps.scheduleLoading;
    // const coversCount: number = coversTooMuch ? maxPeoplePerBooking : nextProps.coversPrefill;

    const newState: IState = {
        ...currentState,
    };

    // expands services if it contains an alert
    if (serviceAlertMessage) {
        newState.servicesSelectorOpen = true;
    }

    // expands sections if it contains an alert
    if (sectionAlertMessage) {
        // newState.sectionsSelectorOpen = true;
    }

    // expands times if it contains an alert (only on stacked layout)
    if (hasTimesAlertMessage || !nextProps.viewTime) {
        // newState.timesSelectorOpen = true;
    }

    // expands menu options if it contains an alert
    if (hasMenuOptionAlert) {
        newState.bookingOptionsSelectorOpen = true;
    }

    /**
     * While `handleExpansionPanelChange` generally covers expand state, there are cases where the application state
     * can override it here, such as when a new date is selected while `servicesSelectorOpen` is true. But this should
     * not override when there is an alert message for services.
     */
    // if (!serviceAlertMessage) {
    //     if (canCustomersChooseSection && hasMoreThan1Section) {
    //         // this is for when the sections panel is allowed
    //         if (!displaySections) {
    //             newState.servicesSelectorOpen = !newState.coversInputOpen && !nextProps.activeService || nextProps.scheduleLoading;
    //         }
    //         if (displaySections && newState.sectionsSelectorOpen && !sectionAlertMessage) {
    //             newState.servicesSelectorOpen = false;
    //         }
    //     }
    // }
    return newState;
}

/**
 * returns the new component state for when and expansionPanel is opened
 */
export function getStateForExpansionPanelChange(state: IState, type: expandPanelType, typeToLeaveOpen?: expandPanelType): IState {

    switch (type) {
        case expandPanelType.services:

            return {
                ...state,
                servicesSelectorOpen: true,
                tableSelectorOpen: false,
                bookingOptionsSelectorOpen: false,
                paymentSelectorOpen: false,
            }

        case expandPanelType.tables:

            return {
                ...state,
                servicesSelectorOpen: false,
                tableSelectorOpen: true,
                bookingOptionsSelectorOpen: false,
                paymentSelectorOpen: false,
            }

        case expandPanelType.bookingOptions:

            let paymentSelectorKeepOpen = typeToLeaveOpen ? typeToLeaveOpen === expandPanelType.payment : false;

            return {
                ...state,
                servicesSelectorOpen: false,
                tableSelectorOpen: false,
                bookingOptionsSelectorOpen: !paymentSelectorKeepOpen,
                paymentSelectorOpen: paymentSelectorKeepOpen,
            }

        case expandPanelType.payment:
            return {
                ...state,
                servicesSelectorOpen: false,
                tableSelectorOpen: false,
                bookingOptionsSelectorOpen: false,
                paymentSelectorOpen: true,
            }
    }

    return null;
}
