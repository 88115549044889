import { IPaymentSummaryMenuOption, IWidgetTheme } from 'shared-types/WidgetTypes';

export interface IMenuOptionSummary {
  theme: IWidgetTheme;
  currency: string;
  menuOptions?: IPaymentSummaryMenuOption[];
  look: lookType;
}

export enum lookType {
  manageBooking = 1,
  paymentSummary = 2
}