import {RootState} from "app/main/rootReducer";
import {connect, ConnectedComponent} from "react-redux";
import PaymentComplete from "shared-components/payment-complete/index";
import {IProps, IHandlers} from "shared-components/payment-complete/types";
import {
    ISavePreAuthResponseData,
    PaymentKind,
    servicePaymentType,
    wrapperStyleType
} from "shared-types/index";
import {IGroupedTablesBoxItem} from "shared-components/grouped-tables-box/types";
import {IntlService} from "shared-services/intl-service";
import {MessageService} from "shared-services/message-service";
import { ROUTE_NAMES } from 'app/models/route.types';
import {routeSlice} from "app/reducers/routeSlice";
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';


const NS = 'PaymentCompleteContainer';

const mapStateToProps = (all: RootState): IProps => {
    const {
        appInitReducer, paymentReducer
    } = all;

    const savedBooking = all.bookingReducer.savedBooking;
    const abcStaffFacing = !all.routeReducer.routeList.includes(ROUTE_NAMES.MANAGE_BOOKING);
    const payment = savedBooking.payment;
    const paymentKind = paymentReducer.paymentComplete.paymentKind;

    let amountPaid: number, transactionId: string;

    switch(paymentKind) {

        case PaymentKind.ewayStandard:
        case PaymentKind.stripe3DSecure:
        case PaymentKind.stripeStandard:
            const data = paymentReducer.paymentComplete.data as {amountPaid: number, transactionId: string};
            amountPaid = data.amountPaid;
            transactionId = data.transactionId;
            break;

        case PaymentKind.ewayPreauth:
        case PaymentKind.stripePreauth:
            amountPaid = (paymentReducer.paymentComplete.data as ISavePreAuthResponseData).amountPaid;
            break;
    }

    const isPreauth = payment.paymentType === servicePaymentType.preAuth;

    const activeVenue = appInitReducer.activeVenue;
    const venueCurrency = activeVenue.currency;

    const amountValue: IGroupedTablesBoxItem = {
        order: isPreauth ? 2 : 0,
        name: `Amount (${venueCurrency})`,
        value: (
            IntlService.currencyValue(isPreauth ? payment.price : amountPaid, venueCurrency)
        )
    };
    const bookingCreatedMsg = abcStaffFacing  ?  `Your customer's booking has been created.` : MessageService.get('finalWidgetScreenMessage', activeVenue);
    
    return {
        theme: appInitReducer.theme,
        wrapperStyle: wrapperStyleType.standard,
        message: bookingCreatedMsg ,
        currency: venueCurrency,
        payment,
        abcStaffFacing,
        tableData: {
            items: [
                amountValue, { order: 1, name: 'Transaction ID', value: transactionId }
            ]
        }
    };
};


/**
 * Note this has interface that will need to be updated
 */
 const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IHandlers => {
    return {
    handleRebook: () => {
        dispatch(routeSlice.actions.reload(true));
      }
    }
};

const PaymentCompleteContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentComplete as any);

export default PaymentCompleteContainer as ConnectedComponent<any, any>;
