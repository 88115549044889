import {RootState} from "app/main/rootReducer";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect, ConnectedComponent} from "react-redux";
import {IDispatchFromProps, IStateFromProps} from './types';
import {IBookingMenuOption} from 'app/services/booking/booking.types';
import ManageBooking from "shared-components/manage-booking";
import {BookingType, IVenue} from "shared-types/SharedTypes";
import {
    bookingAction,
    IErrorResponse,
    IManageBookingSubset,
    IPaymentSummaryMenuOption,
    IURLSettings
} from "shared-types/WidgetTypes";
import {BookingMethodType} from "shared-types/bookingStatusTypes";
import {addPaymentRoutes, routeSlice, updateRouteListForManageBooking} from "app/reducers/routeSlice";
import {footerNavTypes} from "shared-components/footer-nav/types";
import {StateHelperService} from "app/services/helpers/stateHelper.service";
import updateRoute = StateHelperService.updateRoute;
import {bookingSlice, cancelBooking, confirmBooking} from "app/reducers/bookingSlice";

const NS = 'ManageBookingContainer';

const mapStateToProps = (all: RootState): IStateFromProps => {

    const savedBooking = all.bookingReducer.savedBooking;
    const bookingResponse = all.bookingReducer.bookingResponse;
    const customer = savedBooking.customer;
    const cachedMenuOptionDetails = all.bookingOptionReducer.cachedMenuOptionDetails;
    const theme = all.appInitReducer.theme;
    const activeVenue = all.appInitReducer.activeVenue;
    const wrapperStyle = all.appInitReducer.wrapperStyle;

    let viewDate = null, covers = 0, viewTime = null;
    let selectedMenuOptions: IBookingMenuOption[] = [];

    if (savedBooking) {
        viewDate = savedBooking.viewDate;
        covers = savedBooking.covers;
        viewTime = savedBooking.viewTime;
        selectedMenuOptions = savedBooking.selectedMenuOptions;
    }

    let firstName, lastName, company, phone, email;
    if (customer) {
        firstName = customer.firstName || '';
        lastName = customer.lastName || '';
        company = customer.company || '';
        phone = customer.phone || '';
        email = customer.email || '';
    }

    const getChildLineItemDetails = (opts: IBookingMenuOption[]): IPaymentSummaryMenuOption[] => {
        if (!opts) {
            return [];
        }
        return opts.reduce((acc, {menuOptionId, quantity}) => {
            const details = cachedMenuOptionDetails.find(o => o.id === menuOptionId);
            if (details) {
                acc.push({label: details.label, price: details.price, quantity});
            }
            return acc;
        }, []);
    };

    const urlSettings: IURLSettings = {
        action: bookingAction.confirm,
        source: '2'
    }

    const manageBooking: IManageBookingSubset = {
        bookingType: BookingType.Booking,
        covers,
        locked: savedBooking.locked,
        onlineEditingDisabled: savedBooking.onlineEditingDisabled,
        customer: bookingResponse.customer,
        payment: savedBooking.payment,
        status: bookingResponse.status.statusType,
        viewDate,
        viewTime, // "12:00 pm"
        rhinoTime: savedBooking.rhinoTime, // "2021/09/29 12:00",
        onlineCancelDisabled: savedBooking.onlineCancelDisabled
    }

    return {
        theme,
        wrapperStyle,
        booking: manageBooking,
        appSettings: urlSettings,
        activeVenue: activeVenue as unknown as IVenue,
        tableData: {
            items: [
                {name: 'Venue Name', value: activeVenue.name},
                {name: 'Booking Date', value: viewDate},
                {name: 'For', value: covers.toString()},
                {name: 'At', value: viewTime},
                {name: 'First Name', value: firstName},
                {name: 'Surname', value: lastName},
                {name: 'Company Name', value: company},
                {name: 'Mobile', value: phone},
                {name: 'Email', value: email},
            ]
        },
        manageBookingErrorMessage: null,
        menuOptions: selectedMenuOptions.reduce((acc, {menuOptionId, quantity, extras}) => {

            const details = cachedMenuOptionDetails.find((o: { id: string; }) => o.id === menuOptionId);
            let childLineItems = extras && getChildLineItemDetails(extras.implicitChildMenuOptions);

            if (extras && extras.explicitChildMenuOptions) {
                extras.explicitChildMenuOptions.forEach(opts => {
                    childLineItems = childLineItems.concat(getChildLineItemDetails(opts));
                });
            }

            if (details) {
                acc.push({label: details.label, price: details.price, quantity, childLineItems});
            }
            return acc;
        }, []),
        bookingMethodType: BookingMethodType.Abc
    };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
    return {
        handleCancelBooking: () => {
            const success = () => {
                dispatch(bookingSlice.actions.setBookingCancelled({isCancelled: true, showBookAgainBtn: false}));
                dispatch(routeSlice.actions.addBookingCancelledRoute());
                dispatch(updateRoute(footerNavTypes.next));
            };

            return dispatch(cancelBooking())
                .then(() => {
                    success();
                })
                .catch((err: { response: IErrorResponse }) => {
                    console.log(NS, 'deleteBooking err', err)
                    if (err.response.status === 404) {
                        // booking already cancelled
                        success();
                    } else {
                        // @todo handle the delete booking fail message
                    }
                });
        },
        // handleEditBooking: () => {
        //   dispatch(BookingActionsNS.editBooking());
        // },
        handleConfirmBooking: () => {
            const success = () => {
                // Keep user at confirmation screen to make same user experience like Online Widget 2
                window.location.reload();
            };

            return dispatch(confirmBooking())
                .then((response: any) => {
                    console.log('response', response)
                    success();
                })
                .catch((err: { response: IErrorResponse }) => {
                    console.log(NS, 'confirmBooking err', err)
                });

        },
        handleGoToPayment: () => {
            dispatch(updateRouteListForManageBooking());
            dispatch(addPaymentRoutes(true));
            dispatch(updateRoute(footerNavTypes.manageBooking));
        }
    }
};

const ManageBookingContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageBooking as any);

export default ManageBookingContainer as ConnectedComponent<any, any>;
