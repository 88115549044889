export enum loadStatus {
  idle = 'idle',
  loading = 'loading',
  loaded = 'loaded',
  failed = 'failed',
  success = 'success'
}

export interface IResponse<T> {
  statusText: string; // OK
  status: number; // 200
  data: T;
}
