import React, {useState} from 'react';
import style from './style.module.scss';
import {IDispatchFromProps, IStateFromProps} from './types';
import {Table, TableBody, TableCell, TableRow, Typography, withStyles} from '@material-ui/core';
import {canCancelBooking, cancelExpired, canConfirmBooking, canEditBooking, getManageBookingConfig} from './helpers';
import MuiAlert from '@material-ui/lab/Alert';
import moment from "moment";
import {IntlService} from "app/services/intl/intlService";
import {BookingType, servicePaymentType} from "shared-types/SharedTypes";
import {BookingMethodType, bookingStatusType} from "shared-types/bookingStatusTypes";
import {renderIf} from "shared-services/react-utils-service";
import BookingService from "shared-services/booking-service";
import {PaymentService} from "shared-services/payment-service";
import {MessageService} from "shared-services/message-service";
import {bookingAction, themeTypes} from "shared-types/WidgetTypes";
import {lookType} from "shared-components/menu-option-summary/types";
import MenuOptionSummary from "shared-components/menu-option-summary";
import ConfirmModalDialog from "shared-components/confirm-modal-dialog";
import {ISimplePageButton} from "shared-components/simple-page/types";
import SimplePage from "shared-components/simple-page";
import LoaderOverlay from "shared-components/loader-overlay";

const NS = 'ManageBooking';

export const EDIT_BOOKING_BTN_TEXT = 'Edit Booking';
export const CONFIRM_BOOKING_BTN_TEXT = 'Confirm Booking';
export const CANCEL_BOOKING_BTN_TEXT = 'Cancel Booking';
export const ACCEPT_BOOKING_BTN_TEXT = 'Accept Booking';

const modalText = `A cancellation fee will be charged to the credit card used to guarantee this reservation should you proceed, according to the cancellation policy you agreed to when making your reservation.
To continue cancelling your reservation please press CONFIRM below and this charge will be processed.`;

const PRIMARY_CLR = 'primary';

const Alert = withStyles({
    icon: {
        alignItems: 'center'
    }
})(MuiAlert);

export default function ManageBooking({
    theme,
    booking, appSettings, activeVenue, tableData, manageBookingErrorMessage, menuOptions, standbyConfirmationTimeoutInMinutes,
    handleCancelBooking, handleEditBooking, handleConfirmBooking, wrapperStyle, handleGoToPayment, bookingMethodType
}: IStateFromProps & IDispatchFromProps) {

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [forceHideButtons, setForceHideButtons] = useState<boolean>(false);

    const cancelBooking = () => {
        setIsLoading(true);
        handleCancelBooking()
            // on success it will redirect, so don't need to update anything
            .catch(() => {
                setIsLoading(false);
                setErrorMessage('Sorry, there was a problem cancelling your booking. Please try again.');
            });
    }

    const confirmModal = () => {
        setShowModal(false);
        cancelBooking();
    };
    const cancelModal = () => {
        setShowModal(false);
    };

    /**
     * Timezone times are mainly handled on back end, but here we must check the current time at the venue against
     * the booking time to check it's validity.
     */
    const bookingTime: Date = new Date(booking.rhinoTime);
    const venueTime: Date = BookingService.getVenueTime(activeVenue);

    const canConfirm: boolean = canConfirmBooking(booking, bookingTime, venueTime);
    const canEdit = canEditBooking(booking, appSettings.source, activeVenue, bookingTime, venueTime);
    const canCancel = canCancelBooking(appSettings.source, activeVenue, booking.onlineCancelDisabled);

    /**
     * Currently user will see a 'Payment Error' screen if they have paid already. Would be nicer to show them the booking
     * info and hide the cancellation button.
     * @todo: check if Jem agrees with this and add logic to the 'canCancel' condition.
     */
    const cancellationTime = parseInt(activeVenue.widgetSettings.bookingCancellationWindow) > 0 ? activeVenue.widgetSettings.bookingCancellationWindow : null;

    const showPreAuthMsg = (bookingTime: Date) => moment().isAfter(moment(bookingTime).subtract(cancellationTime, 'hours'));

    const {viewDate, payment} = booking;

    const cnf = getManageBookingConfig(booking, appSettings, activeVenue, bookingTime, venueTime);
    let {title, message, hideDetails} = cnf;
    let {hideButtons} = cnf;
    let buttons: ISimplePageButton[];
    let bodyIsMarkDown = true;
    let linkExpired = false;
    const isPreAuth = payment && payment.paymentType === servicePaymentType.preAuth;
    const hasPayment = !!(payment && payment.paymentType !== servicePaymentType.noPayment && payment.price);
    const hasPaid = booking?.payment?.amountPaid > 0;
    const isPaidStandbyConfirm = hasPaid && appSettings.action === bookingAction.confirm && booking.bookingType === BookingType.StandbyList;

    const standbyListCancel = (details?: string, isStandBy = true) => {
        /**
         * This message is for when the venue sends the customer an email that they have been accepted for a booking
         * via the standby list, but then the customer clicks the cancel button in the email.
         */
        return {
            title: `${isStandBy ? 'Standby List' : 'Booking'} ${canCancel ? 'Confirmation/Cancellation' : 'Details'}`,
            message: hasPaid && !isPreAuth
                ? ` You have already paid for this booking. Please call us on ${activeVenue.phone} if you wish to cancel.`
                : canCancel
                    ? ` Would you like to confirm your request for a table at ${details}?`
                    : ` Please call us on ${activeVenue.phone} if you wish to cancel.`,
            showConfirmBtn: false,
            showCancelBtn: canCancel && (!hasPaid || isPreAuth)
        }
    }

    if (booking.bookingType === BookingType.StandbyList
        && booking.status !== bookingStatusType.standbyPending
        && booking.status !== bookingStatusType.confirmed
        && booking.status !== bookingStatusType.seated
        && appSettings.action === bookingAction.confirm) {

        /**
         * Scenario where confirmation has been sent, but then retracted by the venue
         */
        hideDetails = true;
        linkExpired = true;
    } else if (manageBookingErrorMessage) {
        title = manageBookingErrorMessage.title;
        message = MessageService.getMessage(manageBookingErrorMessage.message, activeVenue, '')
        hideDetails = true;
    } else {
        buttons = hideButtons ? null : [];

        const cancelBtn: ISimplePageButton = {
            buttonText: CANCEL_BOOKING_BTN_TEXT, color: 'default', buttonCallback: () => {

                let offset = (activeVenue.widgetSettings.disableEditingTimeWindow) ? activeVenue.widgetSettings.disableEditingTimeWindow : 0;

                if (isPreAuth) {
                    offset = 0;
                }

                const bookingTimeWithOffset = BookingService.getBookingTimeWithOffset(bookingTime, offset);
                if (isPreAuth && cancellationTime) {
                    showPreAuthMsg(bookingTime) ? setShowModal(true) : cancelBooking(); // Show PreAuth msg if cancel time is after the cancellationTimePeriod from the BookingTime or just cancel booking
                } else if (booking.bookingType === BookingType.StandbyList) {
                    cancelBooking();
                } else {
                    // Can cancel 15 mins into booking
                    if (bookingTimeWithOffset > venueTime) {
                        cancelBooking();
                    } else {
                        cancelExpired();
                    }
                }
            }, size: 'small'
        };
        const editBtn: ISimplePageButton = {
            buttonText: EDIT_BOOKING_BTN_TEXT,
            color: PRIMARY_CLR,
            buttonCallback: handleEditBooking,
            size: 'small'
        };
        const confirmBtn: ISimplePageButton = {
            buttonText: CONFIRM_BOOKING_BTN_TEXT, color: PRIMARY_CLR,
            buttonCallback: () => { // can get overridden by SB with payment further down
                handleConfirmBooking()
                    .then(() => {
                        if (isPaidStandbyConfirm) {
                            setForceHideButtons(true);
                        }
                    })
                    // on success it will redirect, so don't need to update anything
                    .catch(() => {
                        setIsLoading(false);
                        setErrorMessage('Sorry, there was a problem confirming your booking. Please try again.');
                    });
            },
            size: 'small'
        };


        /**
         * It's worth noting here that the confirm button will be visible for both "edit" and "confirm" actions,
         * but the edit button is only visible for the "edit" action (not the "confirm" action).
         */
        let showConfirmBtn: boolean = canConfirm &&
            (appSettings.action === bookingAction.confirm || appSettings.action === bookingAction.edit) &&
            booking.status !== bookingStatusType.confirmed &&
            booking.status !== bookingStatusType.seated &&
            booking.status !== bookingStatusType.cancelled;

        let showEditBtn: boolean = canEdit && appSettings.action === bookingAction.edit;
        let showCancelBtn: boolean = canCancel;

        if (bookingMethodType === BookingMethodType.Abc) {

            if (hasPayment && !hasPaid) {
                confirmBtn.buttonText = 'Pay Now';
                confirmBtn.buttonCallback = handleGoToPayment
            }

            if (hasPaid) {
                buttons = [];
                hideButtons = false;
            }

            showCancelBtn = false;
            showConfirmBtn = hasPaid;
            showEditBtn = false;

            let details;

            if (booking.status === bookingStatusType.pendingPayment ||
                booking.status === bookingStatusType.unconfirmed) {

                message = `Hello ${booking.customer.firstName}.`;
                bodyIsMarkDown = false;

                details = `${booking.viewTime} for ${booking.covers} ${booking.covers === 1 ? 'person' : 'people'}`;
            }

            if (booking.status === bookingStatusType.pendingPayment) {

                switch (appSettings.action) {
                    case bookingAction.confirm:
                        /**
                         * This message is for when the venue sends the customer an email that they have been accepted for a booking
                         * via the standby list and they click to accept the booking.
                         */
                        title = 'Booking Confirmation';

                        const inHours = standbyConfirmationTimeoutInMinutes / 60;
                        const hours = Math.floor(inHours);
                        const minutes = standbyConfirmationTimeoutInMinutes - (hours * 60)
                        let duration = hours ? `${hours} hours` : '';

                        duration += minutes ? (hours ? ` and ` : '') + `${minutes} minutes` : '';

                        message += `Your request for a table at ${details} is available!
            You have ${duration} to accept this booking${canCancel ? ` or you can cancel if you're unable to make it` : ''}.`;

                        showConfirmBtn = true;
                        showCancelBtn = canCancel && (!hasPaid || isPreAuth);
                        break;

                    case bookingAction.cancel:
                        const result = standbyListCancel(details, false);
                        title = result.title;
                        message += result.message;
                        showConfirmBtn = result.showConfirmBtn;
                        showCancelBtn = result.showCancelBtn;
                        break;

                    default:
                        title = 'Invalid URL';
                        message = 'There seems to be a problem with the URL you have entered.';
                        hideDetails = true;
                        hideButtons = true;
                }
            } else if (booking.status === bookingStatusType.unconfirmed && appSettings.action === bookingAction.cancel) {
                const result = standbyListCancel(details, false);
                title = result.title;
                message += result.message;
                showConfirmBtn = result.showConfirmBtn;
                showCancelBtn = result.showCancelBtn;
            } else if (booking.status === bookingStatusType.unconfirmed && appSettings.action === bookingAction.confirm) {
                const result = standbyListCancel(details, false);
                title = result.title;
                message += result.message;
                showConfirmBtn = canConfirmBooking(booking, bookingTime, venueTime);
                showCancelBtn = result.showCancelBtn;
            } else {
                message = MessageService.getMessage(message, activeVenue, '');
            }

        } else if (booking.bookingType === BookingType.StandbyList) {

            confirmBtn.buttonText = ACCEPT_BOOKING_BTN_TEXT;

            if (hasPayment && !hasPaid) {
                confirmBtn.buttonText = 'Pay Now';
                confirmBtn.buttonCallback = handleGoToPayment
            }

            if (hasPaid) {
                buttons = [];
                hideButtons = false;
            }

            showCancelBtn = false;
            showConfirmBtn = hasPaid;
            showEditBtn = false;

            let details;

            if (booking.status === bookingStatusType.standbyPending ||
                booking.status === bookingStatusType.unconfirmed) {

                message = `Hello ${booking.customer.firstName}.`;
                bodyIsMarkDown = false;

                details = `${booking.viewTime} for ${booking.covers} ${booking.covers === 1 ? 'person' : 'people'}`;
            }

            if (booking.status === bookingStatusType.standbyPending) {

                switch (appSettings.action) {
                    case bookingAction.confirm:
                        /**
                         * This message is for when the venue sends the customer an email that they have been accepted for a booking
                         * via the standby list and they click to accept the booking.
                         */
                        title = 'Standby List Confirmation';

                        const inHours = standbyConfirmationTimeoutInMinutes / 60;
                        const hours = Math.floor(inHours);
                        const minutes = standbyConfirmationTimeoutInMinutes - (hours * 60)
                        let duration = hours ? `${hours} hours` : '';

                        duration += minutes ? (hours ? ` and ` : '') + `${minutes} minutes` : '';

                        message += `Your request for a table at ${details} is available!
            You have ${duration} to accept this booking${canCancel ? ` or you can cancel if you're unable to make it` : ''}.`;

                        showConfirmBtn = true;
                        showCancelBtn = canCancel && (!hasPaid || isPreAuth);
                        break;

                    case bookingAction.cancel:
                        const result = standbyListCancel(details);
                        title = result.title;
                        message += result.message;
                        showConfirmBtn = result.showConfirmBtn;
                        showCancelBtn = result.showCancelBtn;
                        break;

                    default:
                        title = 'Invalid URL';
                        message = 'There seems to be a problem with the URL you have entered.';
                        hideDetails = true;
                        hideButtons = true;
                }
            } else if (booking.status === bookingStatusType.unconfirmed && appSettings.action === bookingAction.cancel) {
                const result = standbyListCancel(details);
                title = result.title;
                message += result.message;
                showConfirmBtn = result.showConfirmBtn;
                showCancelBtn = result.showCancelBtn;
            } else if (hasPaid && appSettings.action === bookingAction.edit) {
                /**
                 * This message is for when customer tries to remove themselves from the standyby list after adding themselves
                 * via the widget and receiving an email with details.
                 */
                title = 'Standby List Details';
                message = PaymentService.getStandbyPaidNoTableMessage(activeVenue.phone, activeVenue.currency, booking.payment.amountPaid, isPreAuth);
                showConfirmBtn = false;
                showCancelBtn = canCancel && isPreAuth;
            } else {
                message = MessageService.getMessage(message, activeVenue, '');
            }
        } else {
            message = MessageService.getMessage(message, activeVenue, '');
        }

        if (!hideButtons && !forceHideButtons) {
            if (showCancelBtn) {
                buttons.push(cancelBtn);
            }
            if (showEditBtn) {
                if (showConfirmBtn) {
                    editBtn.color = 'secondary';
                }
                buttons.push(editBtn);
            }
            if (showConfirmBtn) {
                buttons.push(confirmBtn);
            }
        }
    }

    return (
        <SimplePage
            theme={theme}
            title={linkExpired ? 'Link Expired.' : title}
            body={linkExpired ? '' : message}
            bodyIsMarkDown={bodyIsMarkDown}
            buttons={buttons}
            customButtonWrapClass={buttons?.length === 1 && buttons[0].color === PRIMARY_CLR ? style.buttonWrapStylesSingleButton : style.buttonWrapStyles}
            childContentBelowBody={true}
        >
            {renderIf(!bookingAction.cancel && !hideDetails && hasPayment && !hasPaid && booking.bookingType === BookingType.StandbyList, () => (
                <Typography className={style.paymentMessage}>
                    {PaymentService.getPaymentMessage(payment.paymentType, activeVenue.currency, payment.price, false)}
                </Typography>
            ))}

            {renderIf(!hideDetails && hasPayment && hasPaid && booking.bookingType === BookingType.StandbyList, () => (
                <Typography className={style.paymentMessage}>
                    Paid: {IntlService.currencyValue(payment.price, activeVenue.currency)}
                </Typography>
            ))}

            {renderIf(viewDate && !hideDetails, () => (
                <div className={style.tableWrap} data-testid="table">
                    <Table aria-label="summary" size="small">
                        <TableBody>
                            {tableData.items.map((item: any, j: any) => !item.value ? null : (
                                <TableRow key={`payment-conf-item-${j}`} data-testid="table-row">
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    {renderIf(menuOptions && menuOptions.length, () => (
                        <>
                            <Typography variant="h3" className={style.bookingOptHeading}>
                                Booking Options
                            </Typography>
                            <Table aria-label="booking options" size="small">
                                <TableBody>
                                    <MenuOptionSummary theme={theme} currency={activeVenue.currency}
                                                       menuOptions={menuOptions}
                                                       look={lookType.manageBooking}/>
                                </TableBody>
                            </Table>
                        </>
                    ))}
                </div>
            ))}

            {/*
        Needed to use a local loader, rather than global so that view doesn't get unmounted in the case of an error message
        needing to be shown.
       */}
            {renderIf(isLoading, () => (
                <LoaderOverlay isDark={theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark}
                               message="Processing, please don't refresh your browser."/>
            ))}

            <ConfirmModalDialog
                data-testid="preAuthMsgDialog"
                wrapperStyle={wrapperStyle} modalMsg={modalText}
                doShow={showModal} handleConfirm={confirmModal} handleCancel={cancelModal} cancelText={'Go Back'}
                modalHeader={'Cancellation Charge'}
            />

            {renderIf(errorMessage, () => (
                <Alert severity="error" variant="filled" className={style.errorMessage}>
                    {errorMessage}
                </Alert>
            ))}

        </SimplePage>
    )
}

