import React from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';

export interface PrivateRouteProps extends RouteProps {
    component: any;
    restricted: boolean
}

const PublicRoute = (props: PrivateRouteProps): any => {
    let {component, restricted, ...rest} = props;
    const Component = component;
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            restricted ?
                <Redirect to="/" />
                : <Component {...props} />
        )} />
    );
};

export default PublicRoute;