import React, { Component } from 'react';
import style from './style.module.scss';
import {StripeProvider, Elements, ReactStripeElements} from 'react-stripe-elements';
import { IPaymentDetailsStripe } from './types';
import PaymentCardStripe from '../paymentCardStripe/index';
import LocationService from 'shared-services/location-service/index';
import { IPaymentDetailsGenericData } from 'shared-types/index';
import { renderIf } from 'shared-services/react-utils-service/index';
import { Paper, CircularProgress } from '@material-ui/core';

const NS = 'PaymentDetailsStripe';

interface IState {
  fontFamilyUrlFriendly: string;
  protocol: string;
  loadStripeLoading: boolean;
  loadStripePromise: (publishableKey: string) => Promise<any>;
}

export default class PaymentDetailsStripe extends Component<IPaymentDetailsStripe & {children?: any}, IState> {
  
  readonly state: IState = {
    fontFamilyUrlFriendly: null,
    protocol: null,
    loadStripeLoading: false,
    loadStripePromise: null
  }

  componentDidMount() {

    const callLoadStripe = (loadStripe: (publishableKey: string) => Promise<any>) => {
      loadStripe(this.props.publishableKey)
        .then((stripe) => {
          this.props.handleStripeLoaded(stripe as unknown as  stripe.Stripe);
        });
    }

    if (!this.state.loadStripePromise && !this.state.loadStripeLoading) {
      this.setState({...this.state, loadStripeLoading: true});

      import('@stripe/stripe-js')
        .then(({loadStripe}) => {
          this.setState({...this.state, loadStripePromise: loadStripe, loadStripeLoading: false});
          callLoadStripe(loadStripe);
        });
    }

    if (this.state.fontFamilyUrlFriendly === null) {
      const fontFamilies = this.props.theme.typography && this.props.theme.typography.fontFamily ? this.props.theme.typography.fontFamily : null;
      const fontFamily = fontFamilies ? fontFamilies.split(',')[0] : this.props.theme.defaultFont;
  
      this.setState({
        fontFamilyUrlFriendly: fontFamily ? fontFamily.split(' ').join('+').split('"').join('') : '',
        protocol: 'http' + (LocationService.isSSH() ? 's' : '')
      });
    }
  }

  render() {
    const {
      stripeInstance, theme, triedNext, wrapperStyle, cvcImagePath,
      handleUpdate
    } = this.props;

    return (
      renderIf(stripeInstance && this.state.fontFamilyUrlFriendly && this.state.protocol, () => (
        <StripeProvider stripe={stripeInstance}>
          <Elements
            fonts={[
              {cssSrc: `${this.state.protocol}://fonts.googleapis.com/css?family=${this.state.fontFamilyUrlFriendly}`}
            ]} >
              
            <PaymentCardStripe
              stripe={stripeInstance as ReactStripeElements.StripeProps} 
              theme={theme}
              triedNext={triedNext}
              wrapperStyle={wrapperStyle}
              cvcImagePath={cvcImagePath}
              handleUpdate={(token: stripe.Token, paymentDetails: IPaymentDetailsGenericData, isValid: boolean, card: stripe.elements.Element) => {
                handleUpdate(card, token, paymentDetails, isValid);
              }}>
              {this.props.children}
            </PaymentCardStripe>
          </Elements>
        </StripeProvider>
      ), () => (
        <Paper elevation={1} className={style.loaderPanel}>
          <CircularProgress size={20} />
        </Paper>
      ))
    )
  }
}