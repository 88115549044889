import {BehaviorSubject,} from "rxjs"
import axios from 'axios';
import config from '../../_helpers/app.constants';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const authenticationService = {
    login: () => {
        const params = new URLSearchParams(window.location.search);
        var token = params.get('token');
        if (token) {
            // TODO: Validate token is correct - Send API request Backend - and also do a GET Me() info for account details.
            localStorage.setItem('token', token);
        }
    },
    getAccountInfo: () => {
        const reqConfig = {
            headers: {
                "X-NBI-Soruce": "CONNECT",
                "X-NBI-CorrelationId": `${uuidv4()}`,
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Content-Type": "application/json"
            },
        };
        axios.get(`${config.backendApiUrl}/me`, reqConfig)
            .then((result) => {
                if (result && result.data) {
                    localStorage.setItem('account', JSON.stringify(result.data));
                    currentUserSubject.next(result.data);
                    return result.data;
                }
            })
            .catch((error) => {
                if (error) {
                    localStorage.removeItem('account');
                }
            });
    },
    getAuthorizationToken: () => {
        const reqConfig = {
            headers: {
                "X-NBI-Soruce": "CONNECT",
                "X-NBI-CorrelationId": `${uuidv4()}`,
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Content-Type": "application/json"
            },
        };
        return axios.get(`${config.adminUrl}api/me/authorization`, reqConfig)
            .then((result) => {
                if (result && result.data) {
                    return result.data;
                }
            })
            .catch((error) => {
                console.error('error while calling Authorization', error)
            });
    },
    logout: () => {
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token');
        }
        if (localStorage.getItem('account')) {
            localStorage.removeItem('account');
            currentUserSubject.next(null);
        }
    },
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return this.currentUserSubject.value
    }
};

export default authenticationService;