
import {ITableItem} from "shared-types/SharedTypes";

export interface ITableSeater extends IProps, IHandlers {}

export interface IBaseProps {
  isWhiteTheme?: boolean; // this adds extra whiteness when useReversedColors is true
  useReversedColors?: boolean; // assumes the component is sitting on a dark colour, so all text, borders, etc are white
  manualJoinType?: ManualJoinType; // lock to a manualJoin or noJoin tyoe. If omitted the manualJoin button will appear
  label?: string;
  showEmptyTableValidationMessage?: boolean;
}

/**
 * Properties passed directly into the react component. If the container is responsible for fetching async data, via a
 * service, these may differ from IContainerProps.
 */
export interface IProps extends IBaseProps {
  tableSelectionList: ITableItem[];
  tableSelectionListWithJoins: ITableItem[];
  sectionsLookup: any; // object with dynamic keys
  preselectedTableIds: string[];
}

export interface ITableSelectionListItem extends ITableItem {
  isAltBg?: boolean;
}

/**
 * Handlers used to modify app state (via a service) from within the module's container.
 * Similar to redux mapDispatchToProps.
 */
export interface IHandlers {
  handleSelectedChanged: (selectedTableIds: string[], tables: ITableItem[], isManualJoin: boolean) => void;
}

export enum ManualJoinType {
  forceManualJoin = 'forceManualJoin',
  forceNoJoin = 'forceNoJoin'
}
