import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ITableItemABC} from "app/models";
import {ITableItem} from "shared-types/SharedTypes";


interface ITablePayload {
    selectedTableIds: string[],
    tableList: ITableItem[]
}

// Define a type for the slice state
interface TableSlice {
    selectedTableIds: string[],
    tableList: ITableItem[]
}

// Define the initial state using that type
const initialState: TableSlice = {
    selectedTableIds: [],
    tableList: []
}

export const tableSlice = createSlice({
    name: 'tables',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setSelectedTablesData: (state, action: PayloadAction<ITablePayload>) => {
            return {
                ...state,
                selectedTableIds: action.payload.selectedTableIds,
                tableList: action.payload.tableList
            }
        },
        preselectTableId: (state, action: PayloadAction<string>): TableSlice => {
            return {
                ...state,
                selectedTableIds: action.payload ? [action.payload] : []
            }
        }
    }
})

export const {setSelectedTablesData, preselectTableId} = tableSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default tableSlice.reducer