import React, { ReactElement } from 'react';
import { ICustomer } from 'shared-types/index';
import PhoneNumberService  from 'shared-services/phone-number-service/index';

export default class UtilsService {

  static rangeCheck(val: number, max: number, min: number): number {
    if (val < min) {
      val = min;
    } else if(val > max) {
      val = max;
    }
    return val;
  }

  // Maybe useful for future should takeaway mode be enabled on widget v2
  static getOrderType(isTakeAwayMode = false): string {
    return isTakeAwayMode ? 'order' : 'booking';
  }

  // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  static hexToRgb(hex: string): number[] {
    return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
        ,(m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16))
  }

  static getNumericalName(num: number) {
    if (num === null) {
      return '';
    }
    const special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
    const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

    const stringifyNumber = (n: number) => {
      if (n < 20) return special[n];
      if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
      return deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
    }
    return stringifyNumber(num + 1);
  }

  static getPhoneLinkHtml(phone: string) {
    return `<a class="underline-phone" href="tel:${phone.split(' ').join('')}">${phone}</a>`;
  }

  /**
   * Make links open in a new window when using ReactMarkdown
   * Use like this:
   * <ReactMarkdown source={text}
   renderers={{
        paragraph: 'span',
        link: UtilsService.reactMarkDownBlankTargets
      }}
   escapeHtml={false} />
   */
  static reactMarkDownBlankTargets(props:{href: string, children: string}): ReactElement {
    return (<a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>);
  }

  /**
     * Return a single line value represents the customer main informations
     * @param {Object} customer
     * @param {boolean} showPhone
     * @returns {String} possible formats are:
     * [firstName][ lastName][, company][, phone]
     * [company][, [firstName][ lastName][, phone]
     */
   static getDisplayName(customer: ICustomer, showPhone: boolean) {
    const nameParts = [];
    if (customer.firstName) {
        nameParts.push(customer.firstName);
    }
    if (customer.lastName) {
        nameParts.push(customer.lastName);
    }

    const displayParts = [nameParts.join(' ')];
    if (customer.company) {
        displayParts.push(customer.company);
    }
    if (showPhone && customer.phone) {
        displayParts.push(PhoneNumberService.formatNumber(customer.phone, 'AU', true));
    }

    return displayParts.join(', ');
  }
}
