import React, {useEffect} from 'react';
import {IPaymentOptions, paymentTypeOptions} from './types';
import {
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Input,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    withStyles
} from "@material-ui/core";
import {getMaterialStyles} from "./helpers";
import {renderIf} from "app/services/utils.service";
import {IServicePaymentDetails, servicePaymentType} from "shared-types/index";
import styles from './style.module.scss'
import MuiTypography from "@material-ui/core/Typography";
import {RootState} from "app/main/rootReducer";
import {useDispatch, useSelector} from "react-redux";
import {addPaymentRoutes} from 'app/reducers/routeSlice';
import style from "app/components/CustomerDetailsForm/style.module.scss";
import {IPaymentOverride, PaymentMethod} from "app/services/booking/booking.types";
import {setIsPaymentOverride, setPayment, setPaymentMethodState} from 'app/reducers/paymentSlice';
import {BookingService} from "app/services/booking/booking.service";
import {VenueGridService} from "app/services/venueGrid.service";

const NS = 'PaymentOptions';

export default function PaymentOptions({showMenuOptions}: IPaymentOptions) {

    const useReversedColors = false;
    const isWhiteTheme = false;

    const ITEM_HEIGHT = 48;
    const PADDING = 8;
    const ITEM_QTY = 20;

    const _tableSelectionList = []

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: window.innerHeight > 1000 ? (ITEM_HEIGHT * ITEM_QTY) + PADDING : 'calc(100vh - 40px)',
                minWidth: 300, // extra width to allow for scroll bars
                maxWidth: 400,
                paddingRight: _tableSelectionList.length > ITEM_QTY ? 10 : 0
            }
        },
        MenuListProps: {
            style: {
                paddingTop: 2,
                paddingBottom: 2
            }
        }
    };

    const labelMarginBot = 4;
    const LabelText = withStyles({
        caption: {
            color: 'rgba(0,0,0,0.54)',
            lineHeight: 1,
            marginBottom: labelMarginBot,
            display: 'block'
        }
    })(MuiTypography);

    const paymentOptions: servicePaymentType[] = [servicePaymentType.fullPayment, servicePaymentType.preAuth, servicePaymentType.deposit, servicePaymentType.noPayment];

    const paymentOverride = useSelector((state: RootState) => state.paymentReducer.paymentOverride);
    const paymentMethodVal = useSelector((state: RootState) => state.paymentReducer.paymentMethod);
    const originalPayment = useSelector((state: RootState) => state.paymentReducer.originalPayment);
    const selectedService = useSelector((state: RootState) => state.groupWidgetReducer.selectedService);
    const isPaymentSet = useSelector((state: RootState) => state.appInitReducer.isPaymentSet);
    const activeVenue = useSelector((state: RootState) => state.appInitReducer.activeVenue);
    const paxCount = useSelector((state: RootState) => state.paxCounterReducer.count);
    const customerDetails = useSelector((state: RootState) => state.customerReducer.customerDetails);

    const useStyles = getMaterialStyles(useReversedColors, isWhiteTheme);
    const classes = useStyles();
    const label = 'Select Payment';

    const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>(paymentMethodVal);
    const [price, setPrice] = React.useState<string>(paymentOverride.price.toString());
    const [paymentType, setPaymentType] = React.useState<servicePaymentType>(paymentOverride.paymentType);
    const [paymentOptionVal, setPaymentOptions] = React.useState<paymentTypeOptions[]>(BookingService.getPaymentDropdownOptions(paymentOptions));
    const [isPriceDisabled, setPriceDisabled] = React.useState<boolean>(false);

    const dispatch = useDispatch()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentMethod((event.target as HTMLInputElement).value as PaymentMethod);
        dispatch(setPaymentMethodState(((event.target as HTMLInputElement).value as PaymentMethod)));
    };

    function handlePaymentChange(event: React.ChangeEvent<{ value: unknown }>) {
        const paymentType = event.target.value as servicePaymentType;
        dispatch(addPaymentRoutes(paymentType !== servicePaymentType.noPayment && paymentMethodVal === PaymentMethod.Direct));

        // If same payment type selected as original - then use the original payment details stored on making the API call
        if (originalPayment && paymentType === originalPayment.paymentType) {
            dispatch(setPayment(originalPayment)); // Setting original Payment value if the same payment type selected as default.
            dispatch(setIsPaymentOverride(false)); // setIsPaymentOverride back to false cause original data selected

        } else { // if Payment type explicitly changed then we need to setIsPaymentOverride as true - cause modified from user
            dispatch(setIsPaymentOverride(true));
            setPaymentType(paymentType);
            const paymentDetails: IPaymentOverride = {
                paymentType,
                price: paymentType === servicePaymentType.noPayment ? 0 : parseFloat(price)
            }
            dispatch(setPayment(paymentDetails));
        }
    }

    function handlePriceChange(event: React.ChangeEvent<{ value: unknown }>) {
        const price = event.target.value as string;
        setPrice(price);
        const paymentDetails: IPaymentOverride = {
            paymentType,
            price: parseFloat(price)
        }
        dispatch(setPayment(paymentDetails));
        dispatch(setIsPaymentOverride(true));
    }


    useEffect(() => {

        // Setting the payment type and price received
        setPaymentType(paymentOverride.paymentType);
        setPrice(paymentOverride.paymentType !== servicePaymentType.noPayment ? paymentOverride.price.toString() : '0');

        const isPayment = VenueGridService.shouldShowDollarSignInBookingInterval(paxCount, selectedService.paymentDetails);

        // We now need to update the dropdown based on the conditions present in https://nowbookit.atlassian.net/browse/NBI-2138
        let paymentOptions: servicePaymentType[];
        const paymentDetails: IServicePaymentDetails = selectedService.paymentDetails;
        const isPaidBOPresent = paymentDetails?.options ? BookingService.checkIfPaidBOPresent(paymentDetails.options) : false;

        if (!isPayment || (paymentDetails && paymentDetails.paymentType === servicePaymentType.noPayment && !isPaidBOPresent)) {
            paymentOptions = [servicePaymentType.fullPayment, servicePaymentType.preAuth, servicePaymentType.deposit, servicePaymentType.noPayment]; // If its Free service or Free Service with Free BO then U can change to any payment types
            setPriceDisabled(false);
        } else {
            paymentOptions = [paymentDetails.paymentType, servicePaymentType.noPayment];
            setPriceDisabled(true);
        }
        setPaymentOptions(BookingService.getPaymentDropdownOptions(paymentOptions));

    }, [paymentOverride])

    useEffect(() => {
        if (paymentMethodVal) {
            paymentMethodVal === PaymentMethod.Direct ? dispatch(addPaymentRoutes(paymentType !== servicePaymentType.noPayment)) : dispatch(addPaymentRoutes(false))
        }
    }, [paymentMethodVal])

    useEffect(() => {
        if (!customerDetails.email) {
            setPaymentMethod(PaymentMethod.Direct);
            dispatch(setPaymentMethodState(PaymentMethod.Direct));
        } else {
            // Setting default to email if email exists
            setPaymentMethod(PaymentMethod.Email);
            dispatch(setPaymentMethodState(PaymentMethod.Email));
        }
    }, [customerDetails.email])

    return (
        <div className={styles.root}>
            {renderIf(isPaymentSet, () => (
                    <div>
                        <Grid container>
                            <Grid item xs={8}>
                                {renderIf(label, () => (
                                    <LabelText variant="caption">
                                        {label}
                                    </LabelText>
                                ))}
                                <FormControl className={classes.selectFC}>
                                    <Select
                                        data-testid="select-root"
                                        className={styles.paymentSelect}
                                        labelId="payment-options-label"
                                        id="payment-options"
                                        value={paymentType}
                                        disabled={showMenuOptions} // Disabling the ability to change payment type if BO exists
                                        input={<Input/>}
                                        onChange={handlePaymentChange}
                                        MenuProps={MenuProps}>
                                        {paymentOptionVal.map((paymentOptions, index) => (
                                            <MenuItem key={index}
                                                      value={paymentOptions.code}>{paymentOptions.name}</MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                {renderIf(paymentOverride.paymentType !== servicePaymentType.noPayment, () => (
                                    <FormControl component="fieldset">
                                        <TextField id="standard-secondary"
                                                   value={price}
                                                   disabled={isPriceDisabled}
                                                   onChange={handlePriceChange}
                                                   type="number"
                                                   label="Total price" color="primary"/>
                                    </FormControl>
                                ))}
                            </Grid>
                        </Grid>

                        {renderIf(paymentOverride.paymentType !== servicePaymentType.noPayment, () => (
                            <div>
                                <Divider className={[style.divider, style.specialDivider2].join(' ')}/>
                                <FormControl component="fieldset">

                                    <FormLabel component="legend">Payment Method</FormLabel>
                                    <RadioGroup aria-label="paymentMethod" name="paymentMethod" value={paymentMethod}
                                                onChange={handleChange}>
                                        <FormControlLabel value={PaymentMethod.Email} disabled={!customerDetails.email}
                                                          control={<Radio/>}
                                                          label="Email payment link to customer"/>
                                        <FormControlLabel value={PaymentMethod.Sms}
                                                          disabled={!activeVenue.smsEnabled} control={<Radio/>}
                                                          label="SMS payment link to customer"/>
                                        <FormControlLabel value={PaymentMethod.Direct} control={<Radio/>}
                                                          label="Open payment link and gateway"/>
                                    </RadioGroup>
                                </FormControl>
                            </div>

                        ))}
                    </div>
                ),
                <div>
                    <LabelText variant="caption">
                        Payments are required for this service. Please ensure your payment gateway is configured for
                        this venue.
                    </LabelText>
                </div>
            )}

        </div>
    )
}