import React, {useCallback, useEffect} from 'react';
import {IVenueGrid} from './types';
import style from './style.module.scss';
import {CircularProgress, createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import VenueGridRow from "app/components/VenueGridComponent/VenueGridRow";
import VenueGridHeader from "app/components/VenueGridComponent/VenueGridHeader";
import {RootState} from "app/main/rootReducer";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ISchedule, IScheduleTime} from 'shared-types/WidgetTypes';
import {GetSchedule, getStartAndEndTimes} from "app/reducers/venueGridSlice";
import {renderIf} from "app/services/utils.service";
import {VenueGridService} from "app/services/venueGrid.service";
import {debounce, isEmpty, isEqual} from "lodash-es";
import moment from "moment/moment";
import {Moment} from "moment";
import {checkForLoadedSchedule} from "app/reducers/routeSlice";
import {IGroupWidgetSchedule} from "app/models";
import {loadStatus} from "shared-types/index";
import { useState } from 'react';

const NS = 'VenueGrid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        venueGridRow: {
            flexWrap: 'nowrap',
            textAlign: 'left',
        },
        venueBodyRow: {
            minHeight: '40px',
        }
    }),
);

export interface IStartEndTimePayload {
    starDateTime: string;
    endDateTime: string;
    currentDate: string;
    biggestInterval: number;
}

export interface ScheduleParams {
    accountId: string;
    venueIds: number[];
    viewDate: Moment;
    count: number;
    isWalkIn: boolean;
}

export default function VenueGrid({isScheduleLoaded}: IVenueGrid) {
    const schedules = useSelector((state: RootState) => state.venueGridReducer.schedules);
    const status = useSelector((state: RootState) => state.venueGridReducer.status);
    const venueStartEndTimeArray = useSelector((state: RootState) => state.venueGridReducer.venueStartEndTimeArray);
    const isWalkIn = useSelector((state: RootState) => state.venueGridReducer.isWalkIn);
    const account = useSelector((state: RootState) => state.appInitReducer.account);
    const accountId = useSelector((state: RootState) => state.appInitReducer.accountId);
    const viewDate = useSelector((state: RootState) => state.dayPickerReducer.viewDate);
    const count = useSelector((state: RootState) => state.paxCounterReducer.count);
    const loadedInitialData = useSelector((state: RootState) => state.venueGridReducer.loadedInitialData);
    const classes = useStyles();
    
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(account) && !isScheduleLoaded) {
            const venueIds = VenueGridService.getVenueIdsFromAccount(account);
            const date = moment(viewDate, 'dddd, MMMM D, YYYY');

            const scheduleArg: ScheduleParams = {
                accountId,
                venueIds,
                viewDate: date,
                count,
                isWalkIn
            }
            callScheduleWithDelay(scheduleArg);
        }

    }, [account, count, viewDate, isWalkIn])

    useEffect(() => {
        if (!isEmpty(schedules)) {
            const allTimeVal = VenueGridService.getTimesFromAllServices(schedules);
            const startEndTimeUniqueArray: IScheduleTime[] = allTimeVal.times;
            const biggestInterval: number = allTimeVal.interval
            const startTime = startEndTimeUniqueArray.length > 0 ? VenueGridService.getStartTime(startEndTimeUniqueArray[0].time) : ''; // Start time would be rounded up to the nearest hour if time greater than the hour - 4:15 will be 4
            const endTime = startEndTimeUniqueArray.length > 0 ? VenueGridService.getEndTime(startEndTimeUniqueArray[startEndTimeUniqueArray.length - 1].time) : '';

            const payload: IStartEndTimePayload = {
                starDateTime: startTime,
                endDateTime: endTime,
                currentDate: viewDate,
                biggestInterval
            };
            dispatch(getStartAndEndTimes(payload));
            dispatch(checkForLoadedSchedule(false));
        }
    }, [schedules])

    const callScheduleWithDelay = useCallback(
        debounce(scheduleArg => {
            dispatch(GetSchedule(scheduleArg));
        }, 200),
        []
    );


    return (
        <div className={classNames({
            [classes.root]: true,
            [style.rowPosters]: true
        })}>
            {renderIf(status === loadStatus.idle && venueStartEndTimeArray.length > 0, () => (
                    <div className={classNames({[style.venueGridScroll]: true})}>
                        <Grid container item className={classNames({
                            [classes.venueGridRow]: true,
                            [style.gridContainer]: true
                        })}>
                            <VenueGridHeader venueStartEndTimeArray={venueStartEndTimeArray}/>
                        </Grid>
                        <Grid container className={style.gridContainer}>
                            {schedules.map((scheduleVal: IGroupWidgetSchedule, index: number) => (
                                <Grid key={index} container item className={classNames({
                                    [classes.venueGridRow]: true,
                                    [classes.venueBodyRow]: true,
                                    [style.gridRow]: true
                                })}>
                                    <VenueGridRow accountId={accountId} gridRow={scheduleVal} bookingInterval={VenueGridService.getSmallestBookingInterval(scheduleVal.services)}
                                                  venueStartEndTimes={venueStartEndTimeArray} viewDate={viewDate}/>
                                </Grid>
                            ))}
                        </Grid>

                    </div>
                ))}
            {renderIf(status === loadStatus.idle && venueStartEndTimeArray.length === 0 && loadedInitialData,()=>(
                <p>Sorry. There are no available tables for this date and patron count. Please try another.</p>
            ))}
            {renderIf(status === loadStatus.loading,()=><CircularProgress style={{marginLeft: '50%'}}/>)}
        </div>

    )

}