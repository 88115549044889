import React from 'react';
import {IVenueGridCell} from './types';
import {Chip, createStyles, Grid, Theme, withStyles} from "@material-ui/core";
import moment from "moment/moment";
import style from './style.module.scss';
import {
    CELL_WIDTH, IScheduleServiceABC,
    IVenueGridInfo
} from "app/models";
import {IScheduleTime} from 'shared-types/WidgetTypes';
import {servicePaymentType} from 'shared-types/SharedTypes';
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {saveSelectedGridInfo} from 'app/reducers/venueGridSlice';
import {RootState} from "app/main/rootReducer";
import {isEqual} from "lodash-es";
import {changeActiveService} from 'app/reducers/groupWidgetSlice';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import OnlySharedTablesIcon from '@material-ui/icons/PeopleAlt';
import {VenueGridService} from "app/services/venueGrid.service";
import {addPaymentRoutes} from "app/reducers/routeSlice";
import {setPayment} from 'app/reducers/paymentSlice';
import {IPaymentOverride} from "app/services/booking/booking.types";
import { setActiveVenue } from 'app/reducers/appInitSlice';

const NS = 'VenueGridCell';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timeChip: {
            backgroundColor: '#2196f3ba',
            color: 'white',
            zIndex: 8,

            '&:hover': {
                backgroundColor: '#3f51b5'
            },
            '&:focus': {
                backgroundColor: '#3f51b5'
            }
        },
        'isSelected': {
            backgroundColor: '#3f51b5'
        },
        standbyChip: {
            zIndex: 8,
            color: 'black',
            backgroundColor: 'white',
            border: '1px solid silver',

            '&:hover': {
                color: 'white',
                backgroundColor: '#3f51b5'
            },
            '&:focus': {
                color: 'white',
                backgroundColor: '#3f51b5'
            }
        },
        'isStandbyChipSelected': {
            color: 'white',
            backgroundColor: '#3f51b5'
        }
    }),
);

const StyleChip = withStyles({
    root: {
        '& .MuiChip-label': {
            overflow: 'visible'
        },

        '& .MuiSvgIcon-root': {
            color: '#000',
            fontSize: '1.4rem',
            top: '8px'
        },
        height: '34px',
        borderRadius: '3px'
    },


})(Chip);

const pxPerMinute = CELL_WIDTH / 30;
export default function VenueGridCell({times, interval, service, schedule}: IVenueGridCell) {

    const classes = useStyles();
    const dispatch = useDispatch();

    interval = interval || 15;

    const bookingTime = useSelector((state: RootState) => state.venueGridReducer.bookingTime);
    const isWalkIn = useSelector((state: RootState) => state.venueGridReducer.isWalkIn);
    const selectedService = useSelector((state: RootState) => state.groupWidgetReducer.selectedService);
    const paxCount = useSelector((state: RootState) => state.paxCounterReducer.count);

    function calculateStartingPoint(time: IScheduleTime): number {
        const minute = parseInt(moment(time.time).format('mm'));
        let point = 0;
        if (minute >= 30 && minute < 60) {
            point = (minute - 30) * pxPerMinute;
        } else {
            point = minute * pxPerMinute;
        }
        return point
    }

    function cellClicked(event: any, bookingTime: IScheduleTime, selectedService: IScheduleServiceABC) {

        const payload: IVenueGridInfo = {
            bookingTime,
            selectedSchedule: schedule
        }
        dispatch(saveSelectedGridInfo(payload));
        dispatch(changeActiveService(selectedService));
        dispatch(setActiveVenue(payload.selectedSchedule.venueDetails))

        const paymentType = selectedService.paymentDetails.paymentType;
        dispatch(addPaymentRoutes(paymentType !== servicePaymentType.noPayment));

        const paymentDetails: IPaymentOverride = {
            paymentType,
            price: selectedService.paymentDetails.price
        }

        dispatch(setPayment(paymentDetails));
    }

    function showIcons(time: IScheduleTime) {

        let returnVal = <React.Fragment/>;

        const isPayment = VenueGridService.shouldShowDollarSignInBookingInterval(paxCount, service.paymentDetails);

        if (isWalkIn || isPayment || time.onlySharedTablesRemain) {
            returnVal = <React.Fragment>
                <div className={style.leftTimeCellIcons}>
                    {isPayment && <AttachMoneyIcon fontSize="small"/>}
                    {time.includesWalkInTables && <DirectionsWalkIcon fontSize="small"/>}
                </div>
                <div className={style.rightTimeCellIcons}>
                    {time.onlySharedTablesRemain &&<OnlySharedTablesIcon fontSize="small"/>}
                </div>
            </React.Fragment>
        }

        return returnVal
    }

    /** Blockout time chip with disabled css style */
    const blockOutChip = () => {
        return <StyleChip
                    label="Blockout"
                    className={
                        classNames({
                            [style.root]: true
                        })
                    }
                />
    }

    /** Standby time chip with white background style */
    const standByChip = (time: IScheduleTime) => {
        return <StyleChip
                    label="Standby"
                    className={
                        classNames({
                            [style.root]: true,
                            [classes.standbyChip]: true,
                            [classes.isStandbyChipSelected]: isEqual(time, bookingTime) && isEqual(selectedService, service)
                        })
                    }
                    onClick={(event) => { cellClicked(event, time, service) }}
                />
    }

    /** Time chip with default css style */
    const timeChip = (time: IScheduleTime) => {
        return <StyleChip
                    icon={showIcons(time)}
                    label={moment(time.time).format('HH:mm')}
                    onClick={(event) => { cellClicked(event, time, service) }}
                    className={
                        classNames({
                            [style.root]: true,
                            [classes.timeChip]: true,
                            [classes.isSelected]: isEqual(time, bookingTime) && isEqual(selectedService, service)
                        })
                    }
                />
    }

    /** Render time chip as per the below conditions */
    const renderTimeChip = (time: IScheduleTime) => {

        if (time.isBlockOut) return blockOutChip();

        if (time.isStandByListAvailable) {
            // Do not render anything when standby booking is exhausted
            if (VenueGridService.isStandbyExhausted(schedule)) {
                return null;
            }
            else {
                return standByChip(time);
            }
        }

        return timeChip(time);
    }

    return (

        <Grid container item spacing={1} style={{margin: 0}}>
            {
                // time intervals roundup to the nearest hour - hence we need another check to hide expired times
                times.filter((time) => VenueGridService.shouldShowTimeInterval(time)).map((time, index) => (
                    <Grid item
                          style={{width: `${interval * pxPerMinute}px`, left: `${calculateStartingPoint(time)}px`}}
                          key={index} className={style.timeCell}>
                          {renderTimeChip(time)}
                    </Grid>
                ))
            }

        </Grid>

    )


}