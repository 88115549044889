import {IEwayInfo, IStripeInfo, IPaymentSettings, paymentProviderTypeUppercase, paymentProviderType} from "shared-types/WidgetTypes";
import {IntlService} from "shared-services/intl-service";
import {cloneDeep} from "lodash-es";

const NS = 'PaymentUtilsService';

export default class PaymentUtilsService {
    /**
     * Annoyingly IOwnedVenue has uppercase first letter.
     * So here we must convert it to lowercase before returning the payment into.
     */
    static getPaymentSettings(data: IPaymentSettings): IEwayInfo | IStripeInfo {
        data = cloneDeep(data);

        if (!data || !data.paymentProvider) {
            return null;
        }

        if (data.paymentProvider === paymentProviderTypeUppercase.Eway) {
            data.eway.paymentProvider = paymentProviderType.eway;
            return data.eway;
        }

        if (data.paymentProvider === paymentProviderTypeUppercase.Stripe) {
            data.stripe.paymentProvider = paymentProviderType.stripe;
            return data.stripe;
        }

        return null;
    }

    static getStandbyPaidNoTableMessage(phone: string, currency?: string, amountPaid = 0, isPreAuth = false): string {
        return `This booking is on the standby list and does not have an allocated table${
            amountPaid > 0
                ? `, but you have ${ isPreAuth ? 'pre-authorised' : 'made' } a payment of ${IntlService.currencyValueAsString(amountPaid, currency)}. Please check the status of your booking with the venue on ${phone}.`
                : `. Please confirm with the venue before making payment on ${phone}.`
        }`;
    }
}
