import React, {useEffect} from 'react';
import style from "./style.module.scss";
import ManageBookingContainer from "app/components/ManageBooking/container";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "app/main/rootReducer";
import {isEmpty} from "lodash-es";
import {GetManageBookingDetails} from "app/reducers/manageBookingSlice";
import {renderIf} from "app/services/utils.service";
import {CircularProgress} from "@material-ui/core";
import {
    bookingErrorMessageType,
    bookingErrorType,
    IBookingErrorMinimal,
    IBookingResponseData
} from "shared-types/WidgetTypes";
import {routeSlice} from "app/reducers/routeSlice";
import {footerNavTypes} from "shared-components/footer-nav/types";
import {StateHelperService} from "app/services/helpers/stateHelper.service";
import updateRoute = StateHelperService.updateRoute;
import {paymentSlice} from "app/reducers/paymentSlice";


const NS = 'SetUpManageBooking';

export default function SetUpManageBooking() {

    const manageBookingUrlArgs = useSelector((state: RootState) => state.manageBookingReducer.manageBookingUrlArgs);
    const status = useSelector((state: RootState) => state.manageBookingReducer.status);
    const manageBookingDetails = useSelector((state: RootState) => state.manageBookingReducer.manageBookingDetails);
    const dispatch: (args: any) => Promise<{ successData?: IBookingResponseData, errorData?: bookingErrorType }> = useDispatch();

    useEffect(() => {
        if (!isEmpty(manageBookingUrlArgs)) {
            dispatch(GetManageBookingDetails(manageBookingUrlArgs)).then((payload: any) => {
                if (payload.payload.errorData) {
                    const errorMessageDetails: IBookingErrorMinimal = {
                        name: bookingErrorType.linkExpired,
                        heading: 'Link Expired',
                        messageType: bookingErrorMessageType.linkExpired,
                        message: 'Sorry, this link has expired.',
                        buttonText: ''
                    }
                    dispatch(paymentSlice.actions.setBookingError(errorMessageDetails))
                    dispatch(routeSlice.actions.addBookingErrorRoute());
                    dispatch(updateRoute(footerNavTypes.next));
                }
            })
        }

    }, [manageBookingUrlArgs])

    return (

        <div className={style.root}>
            {renderIf(status === 'idle' && !isEmpty(manageBookingDetails), () => (
                    <ManageBookingContainer/>
                    // <SamplePage/>
                ),
                <CircularProgress style={{marginLeft: '0%'}}/>
            )}

        </div>

    )
}