import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import MenuOptions from 'shared-components/booking-options-section/MenuOptions/index';
import {IDispatchFromProps, IStateFromProps} from 'shared-components/booking-options-section/MenuOptions/types';
import {menuOptionType} from 'shared-components/booking-options-section/MenuOption/types';
import {BookingService} from 'app/services/booking/booking.service';
import {
    IBookingMenuOption,
    IServicePaymentDetails,
    PricingTypes,
    wrapperStyleType
} from 'shared-types/index';
import {RootState} from "app/main/rootReducer";
import {IParentMenuOption} from "shared-components/booking-options-section/ChildMenuOptionOverlay/types";
import {checkForValidBookingOptions, setBookingOptions} from 'app/reducers/bookingOptionSlice';
import {StateHelperService} from "app/services/helpers/stateHelper.service";
import {cloneDeep} from "lodash-es";


const NS = 'MenuOptionsContainer';

const mapStateToProps = (state: RootState): IStateFromProps => {

    const theme = state.appInitReducer.theme;
    const count = state.paxCounterReducer.count;
    const selectedService = state.groupWidgetReducer.selectedService;
    const selectedBookingOptions = state.bookingOptionReducer.selectedBookingOptions;

    const covers: number = count || 0;
    const paymentDetails: IServicePaymentDetails = selectedService ? selectedService.paymentDetails : null;
    const selectedMenuOptions: IBookingMenuOption[] = paymentDetails ? BookingService.getBookingOptions(
        paymentDetails.options, selectedBookingOptions, covers, paymentDetails.singleMenuPerBooking
    ) : [];
    
    return {
        theme,
        wrapperStyle: wrapperStyleType.standard,
        useMuiGrid: true,
        selectedMenuOptions: cloneDeep(selectedMenuOptions),
        prefillMenuOptions: [],
        menuOptions: paymentDetails?.options,
        type: paymentDetails.singleMenuPerBooking ? menuOptionType.radioButtons : (
          paymentDetails.pricingType === PricingTypes.PerPax
            ? menuOptionType.spinners
            : menuOptionType.checkboxes
        ),
        maxCovers: covers || 1,
        alertMessage: '',
        serviceId: selectedService ? selectedService.id : null,
        isUpsell: false // forces to use narrow version
    };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
    return {
        handleRadioSelect: (menuOptionId: string) => {
            if (menuOptionId) {
                dispatch(setBookingOptions({
                    singleMenuPerBooking: true,
                    bookingOptions: [{menuOptionId, quantity: 1}]
                }))
            }
        },
        handleMultiBookingOptionUpdate: (opts: IBookingMenuOption[]) => {
            dispatch(setBookingOptions({
                singleMenuPerBooking: false,
                bookingOptions: opts
            }));

        },
        handleActiveChildMenuOption: (parentMenuOption: IParentMenuOption) => {
            dispatch(StateHelperService.getBOForChildMenuOption(parentMenuOption));
        },
        handleMaxCovers: (isSuccess: boolean) => {
            dispatch(checkForValidBookingOptions(isSuccess));
        },
        // handleDone is handled inside ChildMenuOptionsOverlay
    }
};

const MenuOptionsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuOptions as any);

export default MenuOptionsContainer;
