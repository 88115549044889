import React, { useState } from 'react';
import style from './style.module.scss';
import { IProps, IHandlers } from './types';
import PaymentDetailsStripe from './paymentDetailsStripe/index';
import PaymentSummaryContainer from 'internal-components/PaymentSummary/container';
import ColumnWrap2 from './columnWrap2/index';
import { renderIf } from 'shared-services/react-utils-service/index';
import { PaymentDetailsGeneric } from './paymentDetailsGeneric/index';
import PaymentFooterNav from 'shared-components/payments-section/paymentFooterNav/index';
import { paymentFooterNavTypes } from 'shared-components/payments-section/paymentFooterNav/types';
import {
  IPrepareEwayData,
  servicePaymentType,
  IPrepareEwayFunctionData,
  IPaymentDetailsGenericData,
  themeTypes,
  IApplyPromoCode
} from 'shared-types/index';
import { IProgressStep } from 'shared-components/progress-stepper/types';
import ProgressStepper from 'shared-components/progress-stepper/index';
import GroupedTablesBox from 'shared-components/grouped-tables-box/index';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import PaymentAgree from "./paymentAgree/index";
import PaymentPromo from './paymentPromo/index';
import { getPaymentDetailGroup } from './helpers';
import LoaderOverlay from 'shared-components/loader-overlay/index';
import {Helmet} from 'react-helmet';
import IframeResizerService from "shared-services/iframe-resizer-service/index";

const NS = 'Payments';

export default function PaymentsSection({
  theme, wrapperStyle, isStripe, stripePublishableKey, venuePaymentSettings, currency, showPromoCode,
  customerDetails, bookingDetails, payment, footerNavShowCancel, useContainer, cvcImagePath,
  handlePrepareEwayPayment, handleEwayPaymentSubmit, handleStripePaymentSubmit,
  handleCancelBooking, handleConfirmState, handlePromotionCode, isManageBooking
}: IProps & IHandlers) {

  const formRef: React.RefObject<HTMLFormElement> = React.createRef();


  /**
   * No need to keep user's entered `paymentDetails` in the global store.
   * Local state in sufficient.
   */
  const [ paymentDetails, setPaymentDetails ] = useState<IPaymentDetailsGenericData>({
    name: '', cardNumber: '', expiryMonth: '', expiryYear: '', cvc: ''
  });
  const [ nextAttempted, setNextAttempted ] = useState<boolean>(false);
  const [ isAgreed, setIsAgreed ] = useState<boolean>(false);
  const [ isValid, setIsValid ] = useState<boolean>(false);
  const [ ewayPaymentStep1Data, setEwayPaymentStep1Data ] = useState<IPrepareEwayData | IPrepareEwayFunctionData>();
  const [ stripePaymentStep1Ready, setStripePaymentStep1Ready ] = useState<boolean>(false);

  const [stripeCard, setStripeCard] = useState<stripe.elements.Element>(null);
  const [stripeToken, setStripeToken] = useState<stripe.Token>(null);

  const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState<string>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * Seems to work alright saving the stripeInstance here, but could potentially need to be saved in an external service
   * if the component gets unmounted by a loader upon save.
   * Saving it in the store was not recommended as the object is not serializable.
   */
  const [stripeInstance, setStripeInstance] = useState<stripe.Stripe>(null);

  const stepIndex: number = isStripe ? (
    // stripe
    stripePaymentStep1Ready ? 1 : 0
  ) : (
    // eway
    ewayPaymentStep1Data ? 1 : 0
  );


  // payments route doesn't use the main router to switch steps, routeName not required
  const steps: IProgressStep[] = [{
    label: 'Payment',
    routeName: null
  }, {
    label: 'Confirmation',
    routeName: null
  }];

  const handleNavPressed = async (type: paymentFooterNavTypes) => {
    if (stepIndex === 0 && type === paymentFooterNavTypes.next) {
      if (isStripe) {
        handleConfirmState();
        setStripePaymentStep1Ready(true);
      } else {
        setIsLoading(true);
        handlePrepareEwayPayment()
          .then((paymentStep1Data: IPrepareEwayData | IPrepareEwayFunctionData) => {
            handleConfirmState();
            setIsLoading(false);
            setEwayPaymentStep1Data(paymentStep1Data);
          });
      }
    }

    if (stepIndex === 1 && type === paymentFooterNavTypes.prev) {
      if (isStripe) {
        setStripePaymentStep1Ready(false);
      } else {
        setEwayPaymentStep1Data(null);
      }
    }

    if (stepIndex === 1 && (
      type === paymentFooterNavTypes.pay ||
      type === paymentFooterNavTypes.preauth
    )) {
      setIsLoading(true);
      if (isStripe) {
        handleStripePaymentSubmit(stripeInstance, stripeCard, stripeToken, paymentDetails)
          .then(() => setIsLoading(false));
      } else {
        const formEl: HTMLFormElement = formRef.current;
        formEl.action = ewayPaymentStep1Data.formActionUrl;
        handleEwayPaymentSubmit(formEl)
          .then(() => setIsLoading(false));
      }
    }

    if (type === paymentFooterNavTypes.cancel) {
      handleCancelBooking(!isManageBooking);
    }

    IframeResizerService.notifyParentIFrameOfSectionChange("Payments");
  }


  /**
   * Form element reference is needed for the submission
   * Eway Documentation:
   * - https://eway.io/api-v3/?csharp#transparent-redirect
   * - https://eway.io/api-v3/?csharp#encrypt-function
   */
  const getEwayHiddenForm = ({accessCode}: IPrepareEwayData | IPrepareEwayFunctionData, {name, cardNumber, expiryMonth, expiryYear, cvc}: IPaymentDetailsGenericData) => (
    <form ref={formRef} >
      <input type="hidden" name="EWAY_ACCESSCODE" value={accessCode} />
      <input type="hidden" name="EWAY_PAYMENTTYPE" value="Credit Card" />
      <input type="hidden" name="EWAY_CARDNAME" value={name} />
      <input type="hidden" name="EWAY_CARDNUMBER" value={cardNumber} />
      <input type="hidden" name="EWAY_CARDEXPIRYMONTH" value={expiryMonth} />
      <input type="hidden" name="EWAY_CARDEXPIRYYEAR" value={expiryYear} />
      <input type="hidden" name="EWAY_CARDCVN" value={cvc} />
    </form>
  )

  return (
    <div className={classNames({
      [style.mainContainer]: useContainer
    })}>
      {/*
        Needed to use a local loader, rather than global because the Eway payment uses a Promise to change the local state,
        but a global redux-triggered loader would cause the page to unmount.
       */}
      {renderIf(isLoading, () => (
        <LoaderOverlay isDark={theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark} message="Loading" />
      ))}

      {renderIf(!isStripe, () => (
        <Helmet>
          <script src="https://api.ewaypayments.com/JSONP/v3/js" async></script>
          <script src="https://secure.ewaypayments.com/scripts/eCrypt.min.js" async></script>
        </Helmet>
      ))}

      <div className={style.progressStepper}>
        <ProgressStepper activeStep={stepIndex} steps={steps} wrapperStyle={wrapperStyle} theme={theme} />
      </div>
      <ColumnWrap2 isLandscape={!IframeResizerService.isStacked(wrapperStyle)}>
        {/* left */}
        <div x-ms-format-detection="none">
          {renderIf(venuePaymentSettings, () => (
            renderIf(stepIndex === 0, () => (
              <PaymentSummaryContainer />
            ), () => (
              <GroupedTablesBox groups={[bookingDetails, customerDetails]} wrapperStyle={wrapperStyle} />
            ))
          ))}
        </div>


        {/* right */}
        <div className={style.column2}>

          {renderIf(venuePaymentSettings, () => (
            <>
              <div>
                {renderIf(isStripe, () => (
                  // can't unmount the stripe elements until payment is confirmed, so hide them for step 2
                  <div className={classNames({
                    [style.stripeWrapIsHidden]: stepIndex !== 0
                  })}>
                    <PaymentDetailsStripe
                      wrapperStyle={wrapperStyle}
                      publishableKey={stripePublishableKey}
                      stripeInstance={stripeInstance}
                      theme={theme}
                      triedNext={nextAttempted}
                      cvcImagePath={cvcImagePath}
                      handleUpdate={(card: stripe.elements.Element, token: stripe.Token, paymentDetails: IPaymentDetailsGenericData, isValid: boolean) => {
                        setIsValid(isValid);
                        setStripeCard(isValid ? card : null);
                        setStripeToken(isValid ? token : null);
                        setPaymentDetails(isValid ? paymentDetails : null);
                      }}
                      handleStripeLoaded={(_stripeInstance) => {
                        if (!stripeInstance) {
                          setStripeInstance(_stripeInstance);
                        }
                      }}>
                      {renderIf(showPromoCode, () => (
                        <PaymentPromo
                          wrapperStyle={wrapperStyle}
                          handleApply={(promotionCode: string) => {
                            setIsLoading(true);
                            handlePromotionCode(promotionCode)
                              .then((data: IApplyPromoCode) => {
                                console.log(NS, 'handlePromotionCode data', data);
                                setIsLoading(false);
                                setPromoCodeErrorMessage(data.codeFailMsg);
                              });
                          }}
                          promotionCode={payment.promotionCode}
                          errorMessage={promoCodeErrorMessage}
                        />
                      ))}
                      <PaymentAgree
                        wrapperStyle={wrapperStyle}
                        showError={nextAttempted && !isAgreed}
                        paymentType={payment.paymentType}
                        handleChange={(checked: boolean) => {
                          setIsAgreed(checked);
                        }}/>
                    </PaymentDetailsStripe>
                  </div>
                ))}

                {renderIf(stepIndex === 0, () => (
                  <>
                    {renderIf(!isStripe, () => (
                      // EWAY: Enter payment details
                      <PaymentDetailsGeneric
                        wrapperStyle={wrapperStyle}
                        theme={theme}
                        paymentDetails={paymentDetails}
                        venuePaymentSettings={venuePaymentSettings}
                        cvcImagePath={cvcImagePath}
                        handleUpdate={(paymentDetails: IPaymentDetailsGenericData, isValid: boolean) => {
                          setIsValid(isValid);
                          setPaymentDetails(isValid ? paymentDetails : null);
                        }}
                      >
                        {renderIf(showPromoCode, () => (
                          <PaymentPromo
                            wrapperStyle={wrapperStyle}
                            handleApply={(promotionCode: string) => {
                              setIsLoading(true);
                              handlePromotionCode(promotionCode)
                                .then((data: IApplyPromoCode) => {
                                  console.log(NS, 'handlePromotionCode data', data);
                                  setIsLoading(false);
                                  setPromoCodeErrorMessage(data.codeFailMsg);
                                });
                            }}
                            promotionCode={payment.promotionCode}
                            errorMessage={promoCodeErrorMessage}
                          />
                        ))}
                        <PaymentAgree
                          wrapperStyle={wrapperStyle}
                          showError={nextAttempted && !isAgreed}
                          paymentType={payment.paymentType}
                          handleChange={(checked: boolean) => {
                            setIsAgreed(checked);
                          }}/>
                      </PaymentDetailsGeneric>
                    ))}
                  </>
                ), () => (
                  // else confirm payment details (step 2)
                  <div className={style.detailsSummaryTable}>
                    <GroupedTablesBox wrapperStyle={wrapperStyle}
                                      groups={[getPaymentDetailGroup(payment, paymentDetails, currency)]}>
                      <Typography variant="body1" className={style.agreeText}>
                        By clicking the {
                        renderIf(payment.paymentType === servicePaymentType.preAuth, <>authorise</>, <>make payment</>)
                      } button, you are agreeing to the terms and conditions.
                      </Typography>
                      {renderIf(!isStripe, !ewayPaymentStep1Data ? null : getEwayHiddenForm(ewayPaymentStep1Data, paymentDetails))}
                    </GroupedTablesBox>
                  </div>
                ))}
              </div>


              <PaymentFooterNav
                theme={theme}
                wrapperStyle={wrapperStyle}
                nextType={stepIndex === 0 ? paymentFooterNavTypes.next : (
                  payment.paymentType === servicePaymentType.preAuth
                    ? paymentFooterNavTypes.preauth
                    : paymentFooterNavTypes.pay
                )}
                nextEnabled={stepIndex === 0 ? (isValid && isAgreed) : true}
                prevEnabled={stepIndex === 1}
                showCancel={footerNavShowCancel}
                handleNavPressed={handleNavPressed}
                handleDisabledNextPressed={() => {
                  setNextAttempted(true);
                }}/>
            </>
          ))}
        </div>

      </ColumnWrap2>
    </div>
  )
}

