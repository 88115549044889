
import {makeStyles} from "@material-ui/core/styles";
import {createStyles, Theme} from "@material-ui/core";

const NS = 'PaymentOptionsHelpers';

/**
 * Material UI styles have high specificity than the style.module.scss classes, so modifications should be done here
 */
export function getMaterialStyles(useReversedColors: boolean, isWhiteTheme: boolean) {
  return makeStyles((theme: Theme) => {

    const styles: any = {
      checkboxFC: {
        margin: '0 5px 0 0'
      },
      checkbox: {
        color: theme.palette.primary.main,
        padding: 7
      },
      menuCheckbox: {
        padding: '5px 9px',
        marginLeft: -7
      },
      selectFC: {
        width: 175,
        // paddingTop: 8,
        flexGrow: 1,
        '& label + .MuiInput-formControl': {
          marginTop: 0,
        },
        '& label.MuiInputLabel-root': {
          transform: 'translateY(9px)'
        }
      },
      select: {
        marginBottom: '20px',
        '& .MuiSelect-icon': {
          right: 4
        },
        '& .MuiSelect-selectMenu': {
          paddingLeft: 4
        }
      },
      inputLabel: {
        transform: 'translateY(16px)',
        padding: '0 5px'
      },
      chip: {
        height: 22,
        maxWidth: 'calc(100% - 5px)',
        marginLeft: 4,
        '&:first-child': {
          marginLeft: 0
        },
        '& .MuiChip-label': {
          paddingLeft: 7,
          paddingRight: 7
        }
      },
      menuItem: {
        justifyContent: 'space-between',
        padding: '4px 10px'
      },
      isValidText: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main
      },
      isValidChip: {
        color: theme.palette.success.dark,
        borderColor: theme.palette.success.main,
        backgroundColor: theme.palette.success.light,
      },
      isFreeText: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main
      },
      isFreeChip: {
        color: theme.palette.warning.dark,
        borderColor: theme.palette.warning.main,
        backgroundColor: theme.palette.warning.light,
      },
      isInvalidText: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main
      },
      isInvalidChip: {
        color: theme.palette.error.dark,
        borderColor: theme.palette.error.main,
        backgroundColor: theme.palette.error.light,
      },
      isAltBg: {
        borderTop: 'solid rgba(0,0,0,0.2) 1px'
      }
    };

    if (useReversedColors) {
      styles.checkbox.color = 'rgba(255,255,255,0.8)';
      styles.checkboxFC.color = 'white';
      styles.select.color = 'white';
      styles.select['& .MuiSelect-icon'].color = 'white';
      styles.select['&.MuiInput-root:before'] = {
        borderBottomColor: 'rgba(255,255,255,0.5) !important'
      };
      styles.chip.color = 'rgba(255,255,255,0.8)';
      styles.chip.borderColor = 'rgba(255,255,255,0.8)';
      styles.chip.backgroundColor = 'rgba(255,255,255,0.08)';

      if (isWhiteTheme) {
        styles.select['&.MuiInput-underline:after'] = {
          borderBottomColor: '#fff'
        }
        styles.selectFC['& label.MuiInputLabel-root'].color = 'rgba(255,255,255,0.6)';
        styles.selectFC['& label.MuiInputLabel-root.Mui-focused'] = {
          color: '#fff'
        }
      }
    }


    return createStyles(styles);
  });
}
