
import {IScheduleServiceABC, ISectionOrder, ITableItemABC} from "app/models";
import {IScheduleService, IScheduleServiceAbstract, ITableItemAbstract, IWidgetTheme} from "shared-types/index";

import {IPaymentOverride} from "app/services/booking/booking.types";

export interface IDispatchFromProps {
  handleCoversCountChange: (coversCount: number) => void;
  handleServiceChange: (id: string, filteredServices: IScheduleServiceAbstract[]) => Promise<IScheduleServiceAbstract>;
  handleSectionChange: (section: ISectionOrder) => void;
  handleCoversCountPending: () => void;
  handleChange: (showBookingOption: boolean) => void;
}

export interface IStateFromProps {
  hasMoreThan1Section: boolean;
  coversAlertMessage: string;
  sectionAlertMessage: string;
  serviceAlertMessage: string;
  hasTimesAlertMessage: boolean;
  theme: IWidgetTheme;
  maxPeoplePerBooking: number;
  scheduleMessage: string;
  scheduleLoading: boolean;
  filteredServices: IScheduleServiceABC[];
  activeService: IScheduleServiceABC;
  filteredSections: ISectionOrder[];
  showMenuOptions: boolean;
  activeSectionId: string;
  getMaxBreachMessage: (maxPeoplePerBooking: number) => string;
  canCustomersChooseSection: boolean;
  coversPrefill: number;
  isPreviewMode: boolean;
  viewTime: string;
  hasMenuOptionAlert: boolean;
  isStandbyMode: boolean;
  noTablesAvailable: boolean;
  showBookingOptions: boolean;
  isBookingOptionsValid: boolean;
  selectedTables: ITableItemAbstract[];
  payment: IPaymentOverride;
  isPaymentSet: boolean;
}


export interface IState {
  servicesSelectorOpen: boolean,
  tableSelectorOpen: boolean,
  bookingOptionsSelectorOpen: boolean,
  paymentSelectorOpen: boolean,
}

export enum expandPanelType {
  services = 1,
  tables = 2,
  bookingOptions = 3,
  payment = 4
}
