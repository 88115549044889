import React, {Component, useEffect} from 'react';
import style from './style.module.scss';
import {withStyles} from '@material-ui/styles';
import MuiTextField from '@material-ui/core/TextField';
import {Checkbox, Divider, FormControlLabel, FormGroup, Paper, Typography, Box, FormControl, Select, InputLabel, MenuItem, FormHelperText} from '@material-ui/core';
import classNames from 'classnames';
import {ICommunicationConfirmation, ICustomerSearchParams, IDispatchFromProps, IStateFromProps} from './types';
import {FormikProps, withFormik} from 'formik';
import * as Yup from 'yup';
import {renderIf} from "app/services/utils.service";
import PhoneNumberService from "shared-services/phone-number-service/index";
import {phoneNumberTypes} from "shared-services/phone-number-service/phoneNumber.types";
import {isEqual} from "lodash-es";
import TagsSelector from "app/components/TagsSelector";
import {ICustomer} from "shared-types/index";
import {Autocomplete} from "@material-ui/lab";
import UtilsService from "shared-services/utils-service/index";
import {IBookingTag} from "shared-types/SharedTypes";
import FlagPhonePickerReact from "shared-components/flag-phone-picker-react";
import { IStringEvent } from 'shared-components/flag-phone-picker-react/types';
import { MessageService } from 'shared-services/message-service/index';

const NS = 'CustomerDetailsForm';

const TextField = withStyles({
    root: {
        '& > .MuiInputLabel-shrink': {
            transform: 'translate(0, 5px) scale(0.75)'
        },
        '& .MuiInputBase-input': {
            padding: '5px 0 6px',
            marginTop: '4px'
        },
        '& .MuiFormHelperText-root': {},
        '& label': {
            zIndex: 10,
            fontSize: '1.3rem'
        },
        '& label + .MuiInput-formControl': {
            marginTop: '12px'
        }
    }
})(MuiTextField);

interface IFormData {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    company?: string;
    notes?: string;
    country?: string;
    phoneWithoutPrefix?: string;
    subscribed?: boolean; // externally this is corresponds to 'subscribed' in ICustomer
    tags?: IBookingTag[];
    id?: string;
    membershipNumber?: string;
    postcode?: string;
    birthdayDate?: number;
    birthdayMonth?: number;
    birthdayYear?: number;
}

const daysOfMonth = Array.from({ length: 31 }, (_, i) => i + 1);
const monthsOfYear = Array.from({ length: 12 }, (_, i) => i + 1);


// workaround for initial checked state on load (https://github.com/mui-org/material-ui/issues/16434)
// const Checkbox = UtilsService.getMuiFormikCheckBox;

class InnerForm extends Component<FormikProps<IFormData> & IStateFromProps & IDispatchFromProps> {

    componentDidUpdate(prevProps: FormikProps<IFormData> & IStateFromProps & IDispatchFromProps) {
        if (!isEqual(prevProps.values, this.props.values) || !isEqual(prevProps.errors, this.props.errors) || !isEqual(prevProps.selectedTags, this.props.selectedTags)) {
            this.handleWholeFormChange();
        }
    }

    componentDidMount() {
        this.handleWholeFormChange();
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps = (nextProps: IStateFromProps & IDispatchFromProps) => {
        if (!isEqual(nextProps.communicationDetails, this.props.communicationDetails)) {
            this.updateSubscribeCheck(nextProps.communicationDetails)
        }
    }

    updateSubscribeCheck = (communicationDetails: ICommunicationConfirmation) => {
        if (!communicationDetails.emailConfirmation && !communicationDetails.smsConfirmation) {
            this.props.setFieldValue('subscribed', false)
        }
    }

    /**
     * Handler for form as a whole
     */
    handleWholeFormChange = () => {
        const {firstName, lastName, phone, email, company, notes, country, subscribed, id, membershipNumber, postcode, birthdayYear, birthdayDate, birthdayMonth} = this.props.values;
        const {isValid, handleUpdate} = this.props;

        const phoneNational: string = phone ? PhoneNumberService.formatNumber(phone, country, true, phoneNumberTypes.NATIONAL) : null;
        const tags = this.props.selectedTags;

        const phoneWithPrefix = phone ? PhoneNumberService.formatInterNationalPhoneNumber(phone, country) : null;
        const phoneWithoutPrefix = phone;

        handleUpdate({
            firstName,
            lastName,
            phone: phoneWithPrefix,
            phoneNational,
            email,
            company,
            notes,
            country,
            subscribed: subscribed,
            id,
            membershipNumber,
            postcode,
            birthdayYear,
            birthdayDate,
            birthdayMonth
        }, tags, isValid, country, phoneWithoutPrefix);
    }

    /**
     * Handler for form as a whole
     */
    handleWholeFormChangeForAutocomplete = (customer: ICustomer) => {
        const {firstName, lastName, phone, email, company, notes, id, subscribed, membershipNumber, postcode, birthdayYear, birthdayDate, birthdayMonth} = customer;
        const {country, isValid, handleUpdate, setIsExistingCustomer} = this.props;

        const customerCountry = customer.country || null;
        const customerFormData: IFormData = {
            firstName,
            lastName,
            phone,
            email,
            company: company || '',
            notes: notes || '',
            id,
            subscribed,
            membershipNumber: membershipNumber || '',
            postcode: postcode || '',
            birthdayYear,
            birthdayDate,
            birthdayMonth
        }

        this.props.setValues(customerFormData);
        
        const phoneNational: string = phone ? PhoneNumberService.formatNumber(phone, customerCountry || country, true, phoneNumberTypes.NATIONAL) : null;
        const tags = customer.tags;
        const phoneWithPrefix = PhoneNumberService.formatInterNationalPhoneNumber(phone, country);
        const phoneWithoutPrefix = phone;

        setIsExistingCustomer(true);
        handleUpdate({
            firstName,
            lastName,
            phone: phoneWithPrefix,
            phoneNational,
            email,
            company,
            notes,
            country: customerCountry || country,
            subscribed: subscribed,
            id,
            membershipNumber,
            postcode,
            birthdayYear,
            birthdayDate,
            birthdayMonth
        }, tags, isValid, customerCountry || country, phoneWithoutPrefix);
    }

    getLabelForField = (isEnabled: boolean, label: string) => {
        return isEnabled ? `${label} *` : label;
    }
  
    render() {
        const {
            // custom props
            country,
            loadLibPhoneNumber,
            updateTags,
            searchCustomerVal,
            tags,
            selectedTags,
            bookingNotes,
            updateBookingNotes,
            isExistingCustomer,
            communicationDetails,
            // formik props
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            getFieldProps, // convenience props for values, handleChange, handleBlur
            setFieldValue,
            accountId,
            customers,
            isStandByListAvailable,
            titleSubscription,
            venueName,
            additionalFields
        } = this.props;

        const hasTags = tags && tags.length;
        // const forceValidation: boolean = this.props.triedNext === ROUTE_NAMES.CUSTOMER_DETAILS;
        const forceValidation = false;
        // if (forceValidation) {
        // console.log(NS, this.props)
        // this.props.setTouched();
        // this.props.validateForm();
        // }

        loadLibPhoneNumber();

        function updateTagsInForm(tags: IBookingTag[]) {
            updateTags(tags);
        }

        const searchCustomer = (event: {target: IStringEvent}) => {
            if (event?.target && event.target.value?.length > 2) {
                const params: ICustomerSearchParams = {
                    accountId,
                    inputs: (event.target as HTMLInputElement).value
                }
                searchCustomerVal(params)
            }
        }


        /**
         * iPhone's zoom into input text fields if they are less than 16px, so we device sniff here
         * and give Apple what it wants.
         */

        return (
            <form autoComplete="new-password" noValidate className={classNames({
                [style.root]: true,
                [style.standbyForm]: isStandByListAvailable
            })}>
                {/* left */}


                <Paper elevation={1} className={classNames({
                    [style.paperBox]: true,
                    [style.paperBox1]: true,
                    [style.standbyFormCol1]: isStandByListAvailable
                })}>
                    <Autocomplete
                        freeSolo
                        inputValue={this.props.values.firstName || ''}
                        disableClearable
                        onInputChange={(e: any) => searchCustomer(e)}
                        onChange={(event: any, newValue: ICustomer) => {
                            this.handleWholeFormChangeForAutocomplete(newValue)
                        }}
                        renderOption={(option: ICustomer) => (
                            <React.Fragment>
                                <span>{UtilsService.getDisplayName(option, true)}</span>
                            </React.Fragment>
                        )}
                        getOptionLabel={(option) => option.firstName}
                        options={customers as ICustomer[]}
                        renderInput={(params) => (
                            <TextField
                                className={classNames({
                                    [style.formText]: true,

                                })}
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }}
                                name="firstName" label="First Name"
                                required fullWidth
                                {...getFieldProps('firstName')}
                                error={forceValidation && !values.firstName || (touched.firstName && Boolean(errors.firstName))}
                                helperText={touched.firstName ? errors.firstName : ''}
                            />
                        )}
                    />
                    <Autocomplete
                        freeSolo
                        disableClearable
                        inputValue={this.props.values.lastName || ''}
                        onInputChange={(e: any) => searchCustomer(e)}
                        onChange={(event: any, newValue: ICustomer) => {
                            this.handleWholeFormChangeForAutocomplete(newValue)
                        }}
                        renderOption={(option: ICustomer) => (
                            <React.Fragment>
                                <span>{UtilsService.getDisplayName(option, true)}</span>
                            </React.Fragment>
                        )}
                        getOptionLabel={(option) => option.lastName}
                        options={customers as ICustomer[]}
                        renderInput={(params) => (
                            <TextField
                                className={classNames({
                                    [style.formText]: true,

                                })}
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }}
                                name="lastName" label="Last Name"
                                required fullWidth
                                {...getFieldProps('lastName')}
                                error={forceValidation && !values.lastName || touched.lastName && Boolean(errors.lastName)}
                                helperText={touched.lastName ? errors.lastName : ''}
                            />
                        )}
                    />

                    {/* <Autocomplete
                        freeSolo
                        disableClearable
                        inputValue={this.props.values.phone || ''}
                        onInputChange={(e) => searchCustomer(e)}
                        onChange={(event: any, newValue: ICustomerDetails) => {
                            this.handleWholeFormChangeForAutocomplete(newValue)
                        }}
                        renderOption={(option: ICustomerDetails) => (
                            <React.Fragment>
                                <span>{CustomerService.getDisplayName(option, true)}</span>
                            </React.Fragment>
                        )}
                        getOptionLabel={(option) => option.phone}
                        options={customers as ICustomerDetails[]}
                        renderInput={(params) => (
                            <TextField
                                className={classNames({
                                    [style.formText]: true,

                                })}
                                {...params}
                                label={<span>Mobile <span
                                    className={style.smallLabel}>(including international code)</span></span>}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }}
                                name="phone"
                                required fullWidth
                                value={values.phone}
                                onChange={(evt) => {
                                    const international: string = country
                                        ? PhoneNumberService.formatNumber(evt.target.value, country, true, phoneNumberTypes.INTERNATIONAL)
                                        : '';
                                    handleChange({
                                        ...evt,
                                        target: {
                                            name: 'phone',
                                            // in theory, the country should always exist, but if it doesn't for some reason, we let them skip formating
                                            value: (country && international) ? international : evt.target.value
                                        }
                                    })
                                }}
                                onBlur={handleBlur}
                                error={forceValidation && !values.phone || touched.phone && Boolean(errors.phone)}
                                helperText={touched.phone ? errors.phone : ''}
                            />
                        )}
                    /> */}
                    <FlagPhonePickerReact
                        searchCustomer={searchCustomer}
                        customers={customers}
                        value={values.phone}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        country={country}
                        handleWholeFormChangeForAutocomplete={this.handleWholeFormChangeForAutocomplete}
                        forceValidation={forceValidation}
                        touched={touched.phone}
                        errors={errors.phone}
                    />
                    <Autocomplete
                        freeSolo
                        disableClearable
                        inputValue={this.props.values.email || ''}
                        onInputChange={(e: any) => searchCustomer(e)}
                        onChange={(event: any, newValue: ICustomer) => {
                            this.handleWholeFormChangeForAutocomplete(newValue)
                        }}
                        renderOption={(option: ICustomer) => (
                            <React.Fragment>
                                <span>{UtilsService.getDisplayName(option, true)}</span>
                            </React.Fragment>
                        )}
                        getOptionLabel={(option) => option.email}
                        options={customers as ICustomer[]}
                        renderInput={(params) => (
                            <TextField
                                className={classNames({
                                    [style.formText]: true,

                                })}
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }}
                                name="email" label="Email"
                                fullWidth required={isStandByListAvailable}
                                {...getFieldProps('email') || ''}
                                error={forceValidation && !values.email || touched.email && Boolean(errors.email)}
                                helperText={touched.email ? errors.email : ''}
                            />
                        )}
                    />
                    {
                        additionalFields.enableCompanyField &&
                        <TextField
                            className={classNames({
                                [style.formText]: true,

                            })}
                            fullWidth
                            name="company"
                            label={this.getLabelForField(additionalFields.isCompanyFieldRequired, "Company Name")}
                            {...getFieldProps('company') || ''}
                            error={touched.company && Boolean(errors.company)}
                            helperText={touched.company ? errors.company : ''}
                        />
                    }

                    {
                        additionalFields.enableMembershipNumberField &&
                        <TextField
                            className={classNames({
                                [style.formText]: true,
                            })}
                            fullWidth
                            inputProps={{maxLength: 30}}
                            name="membershipNumber"
                            label={this.getLabelForField(additionalFields.isMembershipNumberFieldRequired, "Membership Number")}
                            {...getFieldProps('membershipNumber') || ''}
                            error={touched.membershipNumber && Boolean(errors.membershipNumber)}
                            helperText={touched.membershipNumber ? errors.membershipNumber : ''}
                        />
                    }
                    {
                        additionalFields.enableDateOfBirthField &&
                        <div className={style.birthdayBox}>
                            <label className={style.birthdayLabel}>Date of birth</label>
                            <Box className={classNames({[style.dateBox]: true})}>

                            <FormControl variant="standard" error={touched.birthdayDate && Boolean(errors.birthdayDate)} className={classNames({[style.dayMonthList]: true})}>
                                <InputLabel id="birthdayDate-label">{this.getLabelForField(additionalFields.isDateOfBirthFieldRequired, "Date")}</InputLabel>
                                <Select
                                displayEmpty
                                labelId="birthdayDate-label"
                                id="birthdayDate"
                                name="birthdayDate"
                                value={values.birthdayDate || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}>
                                {
                                    daysOfMonth.map((day, index) => (
                                    <MenuItem key={index} value={day}>{day}</MenuItem>
                                    ))
                                }
                                </Select>
                                <FormHelperText>{touched.birthdayDate ? errors.birthdayDate : ''}</FormHelperText>
                            </FormControl>
                        
                            <FormControl variant="standard" error={touched.birthdayMonth && Boolean(errors.birthdayMonth)} className={classNames({[style.dayMonthList]: true})}>
                                <InputLabel id="birthdayMonth-label">{this.getLabelForField(additionalFields.isDateOfBirthFieldRequired, "Month")}</InputLabel>
                                <Select
                                displayEmpty
                                labelId="birthdayMonth-label"
                                id="birthdayMonth"
                                name="birthdayMonth"
                                value={values.birthdayMonth || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}>
                                {
                                    monthsOfYear.map((month, index) => (
                                    <MenuItem key={index} value={month}>{month}</MenuItem>
                                    ))
                                }
                                </Select>
                                <FormHelperText>{touched.birthdayMonth ? errors.birthdayMonth : ''}</FormHelperText>
                            </FormControl>
                        
                            <TextField 
                                type="number"
                                className={classNames({[style.yearText]: true})}
                                id="birthdayYear"
                                name="birthdayYear"
                                label={this.getLabelForField(additionalFields.isDateOfBirthFieldRequired, "Year")}
                                value={values.birthdayYear || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.birthdayYear && Boolean(errors.birthdayYear)}
                                helperText={touched.birthdayYear ? errors.birthdayYear : ''}
                            />
                            </Box>
                        </div>
                    }
                    {
                        additionalFields.enablePostCodeField &&
                        <TextField
                            className={classNames({
                                [style.formText]: true,
                            })}
                            fullWidth
                            name="postcode"
                            label={this.getLabelForField(additionalFields.isPostCodeFieldRequired, "Postcode")}
                            {...getFieldProps('postcode') || ''}
                            error={touched.postcode && Boolean(errors.postcode)}
                            helperText={touched.postcode ? errors.postcode : ''}
                        />
                    }            

                    {renderIf(!isStandByListAvailable, () => <TextField
                        className={classNames({
                            [style.terms]: true,
                            [style.formText]: true,

                        })}
                        id="notes" name="notes" label="Customer Notes" rows={hasTags ? 3 : 5}
                        fullWidth multiline
                        {...getFieldProps('notes') || ''} // todo fix the label alignment issue
                        error={touched.notes && Boolean(errors.notes)}
                        helperText={touched.notes ? errors.notes : ''}
                    />)}

                    {renderIf(!isExistingCustomer, () => (
                        <div>
                            <FormControlLabel className={classNames({
                                [style.rightAlign]: true
                            })} control={
                                <Checkbox checked={values.subscribed}
                                          id="subscribed"
                                          name="subscribed"
                                          {...getFieldProps('subscribed')}
                                />
                            } label={MessageService.getMessage(
                                titleSubscription || "",
                                { venueName },
                                null
                              )}
                            />
                        </div>
                    ))}
                </Paper>

                {/* right */}
                <div className={classNames({
                    [style.column2]: true,
                    [style.standbyFormCol2]: isStandByListAvailable
                })}>
                    <Paper elevation={1} className={classNames({
                        [style.paperBox]: true,
                        [style.paperBox2]: true,
                        [style.paperBoxCol2]: isStandByListAvailable
                    })}>

                        <TextField
                            className={classNames({
                                [style.terms]: true,
                                [style.formText]: true,

                            })}
                            id="bookingNotes" name="bookingNotes" label={isStandByListAvailable ? "Notes" : "Booking Notes"} rows={hasTags ? 3 : 5}
                            fullWidth multiline
                            value={bookingNotes}
                            onChange={(event) => {
                                updateBookingNotes(event.target.value)
                            }}
                            helperText={touched.notes ? errors.notes : ''}
                        />

                        {renderIf(hasTags, () => (
                            <div>
                                <div className={style.specialWrap}>
                                    <Typography variant="subtitle1">
                                        Special Requirements
                                    </Typography>
                                    <TagsSelector tags={tags} selectedTags={selectedTags}
                                                  updateTags={updateTagsInForm}/>
                                </div>
                                <Divider className={[style.divider, style.specialDivider2].join(' ')}/>
                            </div>
                        ))}
                    </Paper>
                </div>
            </form>
        );
    }
}


/**
 * Formik wrapper
 */
export const CustomerDetailsForm = withFormik({
    validateOnMount: true,
    mapPropsToValues: (mapProps: IStateFromProps & IDispatchFromProps) => {
        const {
            customerDetails, isExistingCustomer, country, phoneWithoutPrefix,
            defaultEmailSubscribed, isStandByListAvailable, additionalFields
          } = mapProps;
        const {firstName, lastName, phone, email, company, notes, subscribed, id, membershipNumber, postcode, birthdayDate, birthdayMonth, birthdayYear} = customerDetails;

        const customerCountry = customerDetails.country;

        // sets initial values in the form (must use empty strings, not null)
        const props = {
            firstName: firstName || '',
            lastName: lastName || '',
            email: email || '',
            notes: notes || '',
            phone: phoneWithoutPrefix || '',
            company: company || '',
            country: customerCountry || country || '',
            subscribed: isExistingCustomer ? subscribed : defaultEmailSubscribed,
            id,
            isStandByListAvailable,
            membershipNumber: membershipNumber || '',
            postcode: postcode || '',
            birthdayDate: birthdayDate,
            birthdayMonth: birthdayMonth,
            birthdayYear: birthdayYear,
            additionalFields
        };

        return props;
    },

    // Custom validation rules
    validate: (values: IFormData, props: IStateFromProps & IDispatchFromProps) => {
        const checkBirthday = (year: number, month: number, day: number) => {
            if (!day || !month || !year) {
              errors.birthdayDate = "Invalid birthday";
            }
            else if (year <= 0) { 
              errors.birthdayDate = 'Invalid year'; 
            }
            else
            {
              const daysInMonth = new Date(year, month, 0).getDate();
              if (day < 1 || day > daysInMonth) {
                errors.birthdayDate = 'Invalid day';
              }
              else {
                const today = new Date();
                const birthday = new Date(year, month - 1, day);
                
                if (birthday > today) {
                  errors.birthdayDate = 'Invalid birthday';
                }
              }
            }
          };

        const errors: any = {};

        const country = props.customerDetails.country || props.country;
        if (values.phone && !PhoneNumberService.formatNumber(values.phone, country, true, phoneNumberTypes.INTERNATIONAL)) {
            errors.phone = 'This is not a valid phone number';
        }

        if (props.additionalFields.isPostCodeFieldRequired && !values.postcode?.length) {
        errors.postcode = 'Postcode is required';
        }
        
        if (props.additionalFields.isCompanyFieldRequired && !values.company?.length) {
        errors.company = 'Company is required';
        }
        
        if (props.additionalFields.isMembershipNumberFieldRequired && !values.membershipNumber?.length) {
        errors.membershipNumber = 'Membership number is required';
        }

        if (props.additionalFields.isDateOfBirthFieldRequired) {
            if (!values.birthdayDate || !values.birthdayMonth || !values.birthdayYear) {
                errors.birthdayDate = 'Birthday is required';
            }
        }

        if (values.birthdayDate || values.birthdayMonth || values.birthdayYear) {
            checkBirthday(values.birthdayYear, values.birthdayMonth, values.birthdayDate);
        }

        return errors;
    },

    // auto validation rules
    validationSchema: Yup.object({
        firstName: Yup.string()
            .required('You forgot to enter your first name'),
        lastName: Yup.string()
            .required('You forgot to enter your last name'),
        phone: Yup.string()
            .required('You forgot to enter your mobile number'),
        isStandByListAvailable: Yup.boolean(),
        email: Yup.string()
            .email(`Sorry, that email address isn't valid`)
            .when("isStandByListAvailable", { is: true, then: Yup.string().required("You forgot to enter your email address") })
    }),

    // not using this, but it is mandatory property
    handleSubmit: (values, {setSubmitting}) => {
    }
})(InnerForm);
