import ObjectCopyService from "shared-services/object-copy-service";
import DateUtilsService from "shared-services/date-utils-service";
import {
    ITableItem,
    BookingMethods,
    TimeStatus,
    IBlockout,
    ISectionLayoutItem, ITableItemAbstract
} from "shared-types/SharedTypes";
import {IBookingStatus} from "shared-types/bookingStatusTypes";
import {IScheduleTime, ILayoutMinimal} from "shared-types/index";

const NS = 'BookingAvailabilityService';

export interface IBookingInfoAtTime {
    time: Date,
    maxCovers: number;
    coversReached: number;
    bookingsAtTime: IBookingSubset[];
    vaildTablesAtTime: ITableItem[];
    freeTablesAtTime: ITableItem[];
    vaidJoinsAtTime: ITableItem[];
    status: TimeStatus;
    errorMgs: string[];
    warningMsgs: string[];
    open: boolean;
    service: string; // name of the service
}

interface IBookingSubset {
    status: IBookingStatus;
    tables: {_id: string, shared: boolean}[];
    time: Date;
    serviceId: string;
    _id: string;
    people: number;
    duration: number;
    bookingEnd?: Date;
}

interface ILayoutSubset {
    tablesJoins: ITableItem[];
}

export interface IServiceItemSubset {
    _id: string; // "service_MWNR2VZS6BAO1_1587075416493"
    type: string; // "AllDay", 'Event', 'event'
    startTime: Date; // Fri Sep 04 2020 06:00:00 GMT+1000 (Australian Eastern Standard Time) {}
    endTime: Date; // Fri Sep 04 2020 22:00:00 GMT+1000 (Australian Eastern Standard Time) {}

    sectionLayouts: ISectionLayoutItem[];
    intervalsCustom: {times: string, values: string|number, intervalDuration: number}[];
    lastBookingTime: Date; // Fri Sep 04 2020 21:45:00 GMT+1000 (Australian Eastern Standard Time) {}
    name: string; // "All day"

    enableCustomPaxPerIntervals: boolean;
    maxCoversPerInterval: number;
    maxBookingsPerInterval: number;

    boookingInterval?: number;
    bookingInterval?: number;
    firstBookingTime?: Date;

    times: IScheduleTime[];

    bookingDuration: number;
}

export interface IScheduleSubset {

    /**
     * @todo: selectedSchedule on back end needs extra props on services
     * sectionLayouts: any[], type: string, _id: string, endTime: Date
     */

    services: {
        startTime: Date, sectionLayouts: ISectionLayoutItem[], type: string, _id: string, endTime: Date,
        times: IScheduleTime[]
    }[];
    blockouts?: IBlockout[];

    // compensating for stupid triple 'o' variable name in diary
    bookingInterval?: number;
    boookingInterval?: number;
}

interface ITableAvailableLookUp {
    table: ITableItem;
    available: boolean;
    walkinTable: boolean;
}


// use BookingAvailabilityService directly for static function references
export class BookingAvailabilityService {

    /**
     * Returns a list of avaible booking times
     * @param  {Object}  schedule           The schedule you are booking in
     * @param  {Object}  serviceStartMin    The service you are booking for
     * @param  {Number}  requestDuration    How long you wish to book for
     * @param  {Number}  requestedPeople    The amount of people you wish to book for
     * @param  {Object}  bookingsByTable    A object of bookings by table see getBookingsByTable
     * @param  {String} preferredSectionId  The section id the guest would like to sit it
     * @param  {String} requiredTableId     The id of the table the guest would like to sit at
     * @param  {Booelan} allowPassTimes     Allow bookings from the past
     * @return {Array}                      Returns a list of booking times with status
     */
    static calulateBookingTimes(
        schedule: IScheduleSubset, service: IServiceItemSubset, layouts: ILayoutSubset[], requestDuration: number, requestedPeople: number,
        tables: ITableItem[], bookingsByTable: any, preferredSectionId: string, requiredTableId: string,
        allowPassTimes: boolean, bookingMethod: BookingMethods): IBookingInfoAtTime[] {
        if (!bookingsByTable) {
            return [];
        }

        // compensating for stupid triple 'o' variable name in diary
        const bookingInterval: number = service.boookingInterval || schedule.bookingInterval || schedule.boookingInterval;
        // work out the total time
        const startDate: Date = this.getStartTime(schedule.services[0]);
        const serviceStartDate: Date = service.firstBookingTime || this.getStartTime(service);
        const serviceStartMin: number = DateUtilsService.dateTimeNum(startDate, serviceStartDate);

        const serviceEndDate: Date = this.getStartTime(service, true);
        const serviceEndMin: number = DateUtilsService.dateTimeNum(startDate, serviceEndDate);

        const totalTimes: number = Math.ceil((serviceEndMin - serviceStartMin) / bookingInterval);
        const bookingTimes: IBookingInfoAtTime[] = [];
        let tablesJoins: ITableItem[] = [];

        for (let j = layouts.length - 1; j >= 0; j--) {
            const layout = layouts[j];
            if (layout.tablesJoins) {
                tablesJoins = tablesJoins.concat(layout.tablesJoins);
            }
        }

        // create a list of possible booking times
        for (let i = 0; i < totalTimes; i++) {
            const time: Date = new Date(serviceStartDate.getTime());
            const minsToAdd: number = i * bookingInterval;
            time.setMinutes(time.getMinutes() + minsToAdd);
            time.setSeconds(0);
            time.setMilliseconds(0);

            const bookingInfo: IBookingInfoAtTime = this.getBookingInfoAtTime(
                schedule, time, startDate, service, tablesJoins, requestDuration, requestedPeople, tables,
                bookingsByTable, preferredSectionId, requiredTableId, allowPassTimes, bookingMethod
            );
            bookingTimes.push(bookingInfo);
        }
        return bookingTimes;

    }

    static checkBookingsAtTime(
        time: Date, bookingDate: Date, schedule: IScheduleSubset, service: IServiceItemSubset, layouts: ILayoutMinimal[],
        requestDuration: number, requestedPeople: number, tables: ITableItemAbstract[], bookingsByTable: any,
        preferredSectionId: string, requiredTableId: string, allowPassTimes: boolean, bookingMethod: BookingMethods
    ): IBookingInfoAtTime {
        // work out the total time
        const startDate: Date = this.getStartTime(schedule.services[0]);
        let tablesJoins: ITableItem[] = [];

        for (let r = layouts.length - 1; r >= 0; r--) {
            const layout = layouts[r];
            if (layout.tablesJoins) {
                tablesJoins = tablesJoins.concat(layout.tablesJoins);
            }
        }

        return this.getBookingInfoAtTime(schedule, time, startDate, service, tablesJoins, requestDuration,
            requestedPeople, tables, bookingsByTable, preferredSectionId, requiredTableId, allowPassTimes, bookingMethod);
    }

    private static getBookingInfoAtTime(
        schedule: IScheduleSubset, time: Date, startDate: Date, service: IServiceItemSubset, tablesJoins: ITableItem[],
        requestDuration: number, requestedPeople: number, tables: ITableItemAbstract[], bookingsByTable: any,
        preferredSectionId: string, requiredTableId: string, allowPassTimes: boolean, bookingMethod: BookingMethods): IBookingInfoAtTime {

        let errorMgs: string[] = [];
        let warningMsgs: string[] = [];
        // work out the total time
        startDate = this.getStartTime(schedule.services[0]);
        const serviceStartMin: number = DateUtilsService.dateTimeNum(startDate, this.getStartTime(service));
        const serviceEndMin: number = DateUtilsService.dateTimeNum(startDate, this.getStartTime(service, true));
        // work out the capacity of the restaurant at this time
        let maxCovers: number = service.maxCoversPerInterval;

        if (service.enableCustomPaxPerIntervals && service.enableCustomPaxPerIntervals == true) {
            for (let bx = service.intervalsCustom.length - 1; bx >= 0; bx--) {
                if (service.intervalsCustom[bx].times == this.formatDate(time)) {
                    if (service.intervalsCustom[bx].values == null || service.intervalsCustom[bx].values == "" || service.intervalsCustom[bx].values === 0) {

                        if (service.intervalsCustom[bx].values === 0) {
                            maxCovers = 0;
                        }
                    } else {
                        maxCovers = service.intervalsCustom[bx].values as number;
                    }
                }
            }
        }

        // Check what bookings are on at this time
        let coversReached = 0;
        let coversReachedAtTime = 0;
        const bookingsAtTime: IBookingSubset[] = [];
        const bookingsStartingAtTime: IBookingSubset[] = [];
        // work out which tables are appropriate
        const requestedStartMin: number = Math.ceil(DateUtilsService.dateTimeNum(startDate, time));
        const requestedEndMin: number = requestedStartMin + requestDuration;

        const endTime: Date = new Date(time.getTime());
        endTime.setMinutes(endTime.getMinutes() + requestDuration);

        let vaildTablesAtTime: ITableItem[] = [];
        let freeTablesAtTime: ITableItem[] = [];

        let blockedSectionIds: string[] = [];
        let blockedTableIds: string[] = [];
        // make sure the table is not blocked out
        const totalBlockouts = schedule.blockouts.length;
        for (let b = totalBlockouts - 1; b >= 0; b--) {
            const blockout = schedule.blockouts[b];
            if (DateUtilsService.isInBetweenDates(time, blockout.startDateTime, blockout.endDateTime)) {
                // the time overlaps a block out
                blockedSectionIds = blockedSectionIds.concat(blockout.blockedSections);
                blockedTableIds = blockedTableIds.concat(blockout.blockedTables.map(function (table) {
                    return table._id;
                }));
            }
        }

        // @todo: reinstate when events working for ABC back end
        // const eventBlockedSectionIds: string[] = this.getBlockedSectionIdsOnEvents(service._id, time, endTime, schedule.services);
        // if (eventBlockedSectionIds) {
        //     blockedSectionIds = eventBlockedSectionIds;
        // }

        const totalTables = tables.length;
        const tableAvailableLookUp: any = {}; // of ITableAvailableLookUp
        // @todo: requiredTable needs to have a type (when converting to TS) that extends ITableItem to include `available` and `validTable`. Severity: medium
        let requiredTable;
        let validTable;

        const bookingSlots: number[] = [];
        const bookingInterval = (service.intervalsCustom && service.intervalsCustom.length ? service.intervalsCustom[0].intervalDuration : 0);

        // configure the number of booking slots
        if (requestedEndMin > 0 && bookingInterval > 0) {
            const reqIntervals = (service.bookingDuration/bookingInterval) + 1;

            for (let i = 0; i < reqIntervals; i++) {
                const requestSliceEnd: number = requestedStartMin + (bookingInterval * (i+1));
                if (requestSliceEnd <= requestedEndMin) {
                    bookingSlots.push(0);
                }
                else {
                    break;
                }
            }
        }

        for (let f = totalTables - 1; f >= 0; f--) {
            const table: ITableItemAbstract = tables[f];
            const tableId: string = table._id;
            const tableObj = bookingsByTable[tableId]; // @todo: determine type
            const tableBookings: IBookingSubset[] = tableObj ? tableObj.bookings : [];
            // let tableCoversReached = 0;

            validTable = false;

            // cleans bookingSlot array if the table type is shared
            if (table.shared) {
                bookingSlots.forEach((item, j) => {
                    bookingSlots[j] = 0;
                });
            }

            // does the table have the right amout of capacity
            // Is the table in the right section
            const isByPhone = bookingMethod === BookingMethods.phone;
            const isByWalkin = bookingMethod === BookingMethods.walkin;
            if (
                (!isByPhone || (!isByWalkin && !table.walkinOnly)) &&
                (table.capacity >= requestedPeople || table.shared) &&
                (requestedPeople >= table.minCapacity) &&
                (preferredSectionId === 'any' || preferredSectionId === table.sectionId) &&
                (!requiredTableId || requiredTableId === 'auto' || requiredTableId === table._id)
            ) {
                validTable = true;
            }

            // table is approiate
            let available = false;
            // does the table have any bookings at all
            if (!tableBookings.length) {
                available = true;
            }

            // loop over the booking to find a gap
            const totalTableBookings = tableBookings.length;
            for (let t = 0; t < totalTableBookings; t++) {
                const booking: IBookingSubset = tableBookings[t];

                const bookingEnd: Date = booking.bookingEnd || DateUtilsService.getBookingEnd(booking);

                const bookingStartMin: number = Math.ceil(DateUtilsService.dateTimeNum(startDate, booking.time));
                const bookingEndMin: number = Math.ceil(DateUtilsService.dateTimeNum(startDate, bookingEnd));

                // if the table is valid check for free space
                // can the request booking fit before this one
                if (t === 0 && requestedStartMin < bookingStartMin && requestedEndMin <= bookingStartMin) {
                    available = true;
                }
                // can the request booking fit after this one
                const startGap = bookingEndMin;
                let endGap: number = null;
                if (t < tableBookings.length - 1) {
                    // change the gap end start of the next booking if there is one
                    endGap = DateUtilsService.dateTimeNum(startDate, tableBookings[t + 1].time);
                }

                if (requestedStartMin >= startGap) {
                    const noGapBetweenEndAndStart = startGap === endGap;
                    if (endGap && !noGapBetweenEndAndStart) {
                        if (requestedEndMin <= endGap) {
                            available = true;
                        }
                    } else if (endGap === null) {
                        available = true;
                    }
                }

                let index: number;
                if (bookingStartMin <= requestedStartMin && requestedStartMin < bookingEndMin) {
                    // check booking is not a copy
                    index = bookingsAtTime.map(function (e) {
                        return e._id;
                    }).indexOf(booking._id);
                    // make sure the booking is not already in the array
                    if (index === -1 && booking) {

                        // Added  hot fix for display triggering custom itnervals

                        if (booking.serviceId == service._id) {
                            coversReached += booking.people;
                        }
                        bookingsAtTime.push(booking);
                    }
                }

                if (bookingStartMin === requestedStartMin) {
                    // check booking is not a copy
                    index = bookingsStartingAtTime.map(function (e) {
                        return e._id;
                    }).indexOf(booking._id);
                    // make sure the booking is not already in the array
                    if (index === -1 && booking) {
                        if (booking.serviceId == service._id) {
                            coversReachedAtTime += booking.people;

                            bookingsStartingAtTime.push(booking);
                        }
                    }
                }

                // Shared table verification - count booking.people by slot intervals
                if (booking.tables[0].shared) {
                    if (bookingStartMin >= requestedStartMin && bookingStartMin < requestedEndMin || bookingEndMin > requestedStartMin && bookingEndMin <= requestedEndMin || bookingStartMin <= requestedStartMin && bookingEndMin > requestedEndMin) {
                        if (bookingSlots && bookingSlots.length > 0) {
                            bookingSlots.forEach((item, j) => {
                                const intersectionStartTime: number = requestedStartMin + (bookingInterval * j);
                                if (bookingStartMin <= intersectionStartTime && intersectionStartTime <= bookingEndMin) {
                                    bookingSlots[j] += booking.people;
                                }
                            });
                        }
                    }
                }
            }

            // Gets the maximum of people that will be booked on any interval
            const maxCoversOnSlots: number = (bookingSlots && bookingSlots.length > 0 ? Math.max(...bookingSlots) : 0);

            // check if the table is shared and it has enough covers left
            if (table.shared && ((table.capacity - maxCoversOnSlots) >= requestedPeople)) {
                available = true;
            } else if (table.shared) {
                validTable = false;
            }

            // make sure table is not blocked
            let blockedTable = false;
            if (available && blockedTableIds.indexOf(table._id) !== -1 || blockedSectionIds.indexOf(table.sectionId) !== -1) {
                available = false;
                blockedTable = true;
            }

            // make sure the table is not for walk-ins only
            let walkinTable = false;
            if (available && bookingMethod !== BookingMethods.walkin && table.walkinOnly) {
                available = false;
                walkinTable = true;
            }
            if (available && validTable && !blockedTable) {
                vaildTablesAtTime.push(table);
            } else if (available) {
                freeTablesAtTime.push(table);
            }
            tableAvailableLookUp[table._id] = {
                table: table,
                available: available,
                walkinTable: walkinTable
            } as ITableAvailableLookUp;

            if (requiredTableId === table._id) {
                requiredTable = ObjectCopyService.angularSafeCopy(table);
                requiredTable.validTable = validTable;
                requiredTable.available = available;
            }
        }

        // work out what joins are free
        const totalJoinTables = tablesJoins ? tablesJoins.length : 0;
        const vaidJoinsAtTime: ITableItemAbstract[] = [];
        for (let j = totalJoinTables - 1; j >= 0; j--) {
            validTable = false;
            const tablesJoin = tablesJoins[j];

            const isByPhone = bookingMethod === BookingMethods.phone;
            const isByWalkin = bookingMethod === BookingMethods.walkin;
            if ((!isByPhone || (!isByWalkin && !tablesJoin.walkinOnly)) && tablesJoin.capacity >= requestedPeople && requestedPeople >= tablesJoin.minCapacity) {
                validTable = true;
            }
            if (validTable) {
                let joinAvailable = true;
                const totalJoins = tablesJoin.tables.length;
                const tablesList: ITableItemAbstract[] = [];
                const tableNames: string[] = [];
                for (let c = 0; c < totalJoins; c++) {
                    const tableLookUp: ITableAvailableLookUp = tableAvailableLookUp[tablesJoin.tables[c]];
                    if (tableLookUp) {
                        tablesList.push(tableLookUp.table);
                        tableNames.push(tableLookUp.table.name);
                        // if table unavailable and not because it's reserved for walkins, or it's not in a preferred section then the join is unavailable
                        if ((!tableLookUp.available && !tableLookUp.walkinTable) || (preferredSectionId !== 'any' && preferredSectionId !== tableLookUp.table.sectionId)) {
                            // one of the tables are not available
                            joinAvailable = false;
                            break;
                        }
                    }

                }
                if (joinAvailable) {
                    // note: this may get called 3 or 4 times before getting added
                    tablesJoin.tablesList = tablesList;
                    tablesJoin.name = tableNames.sort().join('-');
                    vaidJoinsAtTime.push(tablesJoin);
                }
            }
        }

        let status = TimeStatus.available;

        if (vaildTablesAtTime.length === 0 && vaidJoinsAtTime.length === 0) {
            errorMgs.push('No tables available.');
        }
        if (errorMgs.length) {
            status = TimeStatus.unavailable;
        }
        if (requiredTable) {
            errorMgs = [];
            warningMsgs = [];
            if (!requiredTable.available) {
                errorMgs.push('T' + requiredTable.name + ' is not available.');
            }
            if (!requiredTable.validTable) {
                if (requestedPeople > requiredTable.capacity) {
                    warningMsgs.push('The capacity of T' + requiredTable.name + ' is ' + requiredTable.capacity + '.');
                }
                if (requestedPeople < requiredTable.minCapacity) {
                    warningMsgs.push('The min capacity of T' + requiredTable.name + ' is ' + requiredTable.minCapacity + '.');
                }
            }
        }


        if ((maxCovers || maxCovers === 0) && (coversReachedAtTime + requestedPeople) > maxCovers) {


            warningMsgs.push('You have reached your maximum covers at this time');
        }
        if (requestedEndMin > serviceEndMin) {
            //  warningMsgs.push('This booking ends after ' + service.name + ' has finished.');
        }

        const lastBookingTime: Date = DateUtilsService.dateStrToDateTime( service.times[service.times.length-1].time );
        if (time.getTime() > lastBookingTime.getTime()) {
            warningMsgs.push('This booking starts after your last booking time ' + DateUtilsService.dateToTimeStr(lastBookingTime));
            // warningMsgs.push('This booking starts after your last booking time ' + this.$filter('date')(service.lastBookingTime, 'hh:mm a'));
        }

        let open = true;
        if (requestedStartMin < serviceStartMin) {
            open = false;
        } else if (requestedStartMin > serviceEndMin) {
            open = false;
        }
        if (!open) {
            warningMsgs = [];
            errorMgs = ['You are closed at this time.'];
        }
        if (service.maxBookingsPerInterval && service.maxBookingsPerInterval !== 0 && bookingsStartingAtTime.length >= service.maxBookingsPerInterval) {
            warningMsgs.push('You have reached your maximum bookings at this time');
        }
        if (!open) {
            vaildTablesAtTime = [];
            freeTablesAtTime = [];
        }
        // add to the list of bookings times

        return {
            time,
            maxCovers,
            coversReached: coversReachedAtTime,
            bookingsAtTime,
            vaildTablesAtTime,
            freeTablesAtTime,
            vaidJoinsAtTime,
            status, // @todo: make of type TimeStatus (when converting to TS)
            errorMgs,
            warningMsgs,
            open,
            service: service.name,
        };
    }

    /**
     * Give a list of tables and bookings this will map them to bookings by table
     * @param  {Array} bookings
     * @param  {Array} tables
     * @return {Object} an object of tables with bookings array
     */
    static getBookingsByTable(bookings: IBookingSubset[]): any {
        // work out date
        // group bookings by table
        const bookingsByTable: any = {};
        const bookingsLength = bookings.length;
        for (let b = 0; b < bookingsLength; b++) {
            const booking = bookings[b];
            // set the booking end time

            if (!booking.status) {
                console.warn(NS, 'Detected booking without a status. Treating it as cancelled. This may be due to "dirty" data.', booking);
            }
            if (booking.status && booking.status.statusType !== 'cancelled') {
                // add the booking to its table
                if (booking.tables) {
                    for (let i = booking.tables.length - 1; i >= 0; i--) {
                        const table = booking.tables[i];
                        if (table) {
                            if (!bookingsByTable[table._id]) {
                                bookingsByTable[table._id] = {
                                    table: table,
                                    bookings: []
                                };
                            }
                            bookingsByTable[table._id].bookings.push(booking);
                        }
                    }
                }
            }
        }
        return bookingsByTable;
    }

    // make sure the table is not blocked by an event
    private static getBlockedSectionIdsOnEvents(currentServiceId: string, time: Date, endTime: Date, services: IServiceItemSubset[]): string[] {
        let blockedSectionIds: string[];
        for (let e = services.length - 1; e >= 0; e--) {
            const s = services[e];
            // Check to make sure the service is an event and it does not equal to the current service
            if (s.type && s.type === 'event' && s._id !== currentServiceId) {
                if (endTime.getTime() > s.startTime.getTime() && time.getTime() < s.endTime.getTime()) {
                    // the times over lap
                    const sectionsIdUsed = s.sectionLayouts.map(({sectionId}) => sectionId);
                    blockedSectionIds = blockedSectionIds.concat(sectionsIdUsed);
                }
            }
        }

        return blockedSectionIds;
    }

    private static getStartTime(service: {endTime?: Date, startTime?: Date, times?: IScheduleTime[]}, useEndTime = false): Date {
        const startDate: Date = (useEndTime ? service.endTime : service.startTime)
            || DateUtilsService.dateStrToDateTime(
                useEndTime
                    ? service.times[service.times.length-1].time
                    : service.times[0].time
            );
        return startDate;
    }

    private static formatDate(date: Date): string {
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutesAsString = minutes < 10 ? '0' + minutes : minutes;
        const strTime = hours + ':' + minutesAsString + ' ' + ampm;
        return strTime;
    }
}
