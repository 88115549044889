import {AnyAction} from 'redux';
import {connect, ConnectedComponent} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import ChildMenuOptionOverlay from 'shared-components/booking-options-section/ChildMenuOptionOverlay/index';
import {
    IDispatchFromProps,
    IOwnProps,
    IStateFromProps
} from 'shared-components/booking-options-section/ChildMenuOptionOverlay/types';
import {IBookingMenuOptionExtrasUpdater, IOwnedVenue, wrapperStyleType} from 'shared-types/index';
import {RootState} from "app/main/rootReducer";
import {StateHelperService} from "app/services/helpers/stateHelper.service";

const NS = 'ChildMenuOptionOverlayContainer';

const mapStateToProps = (state: RootState, ownProps: IOwnProps): IStateFromProps & IOwnProps & IDispatchFromProps => {
    const theme = state.appInitReducer.theme;
    const activeChildMenuOption = state.bookingOptionReducer.activeChildMenuOption;
    const activeVenue = state.appInitReducer.activeVenue;


    return {
        ...ownProps,
        wrapperStyle: wrapperStyleType.standard,
        theme,
        currency: activeVenue ? (activeVenue as IOwnedVenue).currency : null,
        menuOptionsImplicit: activeChildMenuOption ? activeChildMenuOption.menuOptionsImplicit : null,
        menuOptionsExplicit: activeChildMenuOption ? activeChildMenuOption.menuOptionsExplicit : null
    };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
    return {
        handleDone: (data: IBookingMenuOptionExtrasUpdater) => {
            dispatch(StateHelperService.updateBOExtras(data));
            // dispatch(updateMenuOptionExtras(data))
        }
    }
};

const ChildMenuOptionOverlayContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChildMenuOptionOverlay as any) as ConnectedComponent<any, IOwnProps & IDispatchFromProps>;

export default ChildMenuOptionOverlayContainer;