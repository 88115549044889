import React, {useState} from 'react';
import style from './style.module.scss';
import {IDispatchFromProps, IStateFromProps} from './types';
import {Fab, TextField} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "app/main/rootReducer";
import {increment, incrementByInput} from 'app/reducers/paxCounterSlice';

const NS = 'PaxComponent';

export default function PaxComponent({exampleProp}: IStateFromProps & IDispatchFromProps) {

    const [isValueValid, setValidity] = useState<boolean>(true);

    const {count} = useSelector((state: RootState) => state.paxCounterReducer);
    let coversValue = count > -1 ? count : 0;
    const dispatch = useDispatch();

    const handleIncrement = (countValue: number) => {
        if (count + countValue > 0) {
            dispatch(increment(countValue))
        }
    }

    const handleInput = (evt: any) => {

        const input: HTMLInputElement = evt.currentTarget.querySelector('input');
        const isValid = input.validity.valid;
        if (isValid) {
            // still possible for some invalid characters to get through, so we check for NaN as well
            setValidity(isValid);
            const num: number = parseInt(input.value, 10);
            coversValue = isNaN(num) ? 0 : num;
            dispatch(incrementByInput(coversValue))
        }
    }


    return (
        <div className={classNames({
            [style.root]: true
        })}>
            <div className={classNames({
                [style.inputWrap]: true
            })} data-testid="cover-value-change">
                <TextField
                    className={classNames({
                        [style.numberInput]: true,
                    })}
                    data-testid="cover-value-input"
                    label={'Booking for'}
                    type="number"
                    variant="outlined"
                    margin="normal"
                    value={coversValue}
                    error={false}
                    helperText={isValueValid ? '' : 'Invalid number'}
                    onInput={(evt) => handleInput(evt)}
                />

                <div className={classNames({
                    [style.incrementBtns]: true
                })}>
                    <Fab color="primary" size="small" aria-label="minus"
                         disabled={false}
                         className={classNames({
                             [style.minusBtn]: true,
                         })}
                         data-testid="cover-value-decrement"
                         onClick={() => handleIncrement(-1)}>
                        <RemoveIcon className={style.incrementIconIsSmall}/>
                    </Fab>
                    <Fab color="primary" size="small" aria-label="plus"
                         disabled={false}
                         className={classNames({
                             [style.plusBtn]: true,
                         })}
                         data-testid="cover-value-increment"
                         onClick={() => handleIncrement(1)}>
                        <AddIcon className={classNames({
                            [style.incrementIconIsSmall]: true
                        })}/>
                    </Fab>
                </div>
            </div>

        </div>

    )
}

