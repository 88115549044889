import { createSlice, PayloadAction } from '@reduxjs/toolkit'


// Define a type for the slice state
interface PaxCounterState {
    count: number
}

// Define the initial state using that type
const initialState: PaxCounterState = {
    count: 2
}

export const paxCounterSlice = createSlice({
    name: 'counter',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        increment: (state, action: PayloadAction<number>) => {
            state.count += action.payload
        },
        incrementByInput: (state, action: PayloadAction<number>)=> {
            state.count = action.payload;
        }
    }
})

export const { increment, incrementByInput } = paxCounterSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default paxCounterSlice.reducer