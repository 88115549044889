import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import { ICustomer, ICustomerDetails} from "app/models";
import {ClientService} from "app/services/client/client.service";
import {first, tap} from "rxjs/operators";
import {ICustomerSearchParams} from "app/components/CustomerDetailsForm/types";
import PhoneNumberService from "shared-services/phone-number-service/index";
import {IBookingTag} from "shared-types/SharedTypes";

export interface IChangedCustomerDetails {
    country: string;
    phoneWithoutPrefix?: string;
    customerDetails: ICustomer,
    tags: IBookingTag[],
    isValid: boolean
}

// Define a type for the slice state
interface CustomerSlice {
    country: string;
    phoneWithoutPrefix?: string;
    customerDetails: ICustomer;
    tags: IBookingTag[];
    isCustomerDetailsValid: boolean;
    customers: ICustomer[];
    bookingNotes: string;
    isExistingCustomer: boolean;
}

// Define the initial state using that type
const initialState: CustomerSlice = {
    country: 'AU',
    phoneWithoutPrefix: '',
    customerDetails: {} as ICustomer,
    tags: [],
    isCustomerDetailsValid: false,
    customers: [],
    bookingNotes: '',
    isExistingCustomer: false
}

export const searchCustomer = createAsyncThunk(
    'test/searchCustomer',
    async (arg: ICustomerSearchParams, {dispatch, getState}) => {
        return await ClientService.searchCustomer(arg.inputs, true, arg.accountId).pipe(
            first()
        ).toPromise();
    }
)

export const customerSlice = createSlice({
    name: 'customerDetails',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setCustomerDetails: (state, action: PayloadAction<IChangedCustomerDetails>) => {
            return {
                ...state,
                customerDetails: action.payload.customerDetails,
                tags: action.payload.tags,
                isCustomerDetailsValid: action.payload.isValid,
                country: action.payload.country,
                phoneWithoutPrefix: action.payload.phoneWithoutPrefix,
            }
        },
        setTags: (state, action: PayloadAction<IBookingTag[]>) => {
            state.tags = action.payload;
        },
        setBookingNotes: (state, action: PayloadAction<string>) => {
            state.bookingNotes = action.payload;
        },
        setIsExistingCustomer: (state, action: PayloadAction<boolean>) => {
            state.isExistingCustomer = action.payload;
        },

    },
    extraReducers: (builder) => {

        builder.addCase(searchCustomer.fulfilled,
            (state, action: PayloadAction<any>) => {
                const customers:ICustomerDetails[] = action.payload;
                customers.forEach((cs:ICustomerDetails) => {
                    cs.formatedPhone= PhoneNumberService.formatNumber(cs.phone, "AU", true);
                });
                state.customers = customers;
            });
    },
})

export const {setCustomerDetails, setTags, setBookingNotes, setIsExistingCustomer} = customerSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default customerSlice.reducer