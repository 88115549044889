import React, {useState} from 'react';
import {IProps} from './types';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  ReactStripeElements
} from 'react-stripe-elements';
import style from './style.module.scss';
import {Paper} from '@material-ui/core';
import {SimplePaletteColorOptions} from '@material-ui/core/styles';
import CvcPopover from '../cvcPopover/index';
import {IPaymentDetailsGenericData} from 'shared-types/index';
import classNames from 'classnames';
import {themeTypes, wrapperStyleType} from 'shared-types/index';
import AlertPanel from 'shared-components/alert-panel/index';
import {renderIf} from 'shared-services/react-utils-service/index';

const NS = 'PaymentCardStripe';

function PaymentCardStripe({
                             theme, stripe, elements, wrapperStyle, triedNext, children, cvcImagePath,
                             handleUpdate
                           }: IProps & { children?: any }) {

  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;
  const isTightFit = wrapperStyle === wrapperStyleType.standard;

  const createOptions = () => {
    return {
      style: {
        base: {
          color: isDark ? 'white' : 'rgba(0, 0, 0, 0.87)',
          fontFamily: theme.typography && theme.typography.fontFamily ? theme.typography.fontFamily.split(',')[0] : '',
          fontSize: '16px',
          '::placeholder': {
            color: isDark ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)'
          }
        },
        invalid: {
          color: theme.palette.error ? (theme.palette.error as SimplePaletteColorOptions).main : 'green'
        },
      },
    };
  };

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [cardName, setCardName] = useState<string>('');

  /**
   * triedPaying vs triedNext
   * Once the "Next" button has been pressed `triedNext` will always be true,
   * whereas `triedPaying` will be initially set to same as `triedNext`, but overwritten
   * by local state once user has interacted with the form.
   * The difference is important because we only want the AlertPanel to display if "Next"
   * has been attempted and there are still errors. But if they are filling out the form
   * and have not attempted to press "Next" yet, we don't want to display the error.
   */
  const [triedPaying, setTriedPaying] = useState<boolean>(triedNext);

  const getInvalidCard = (): IPaymentDetailsGenericData => {
    return {name: null, cardNumber: null, expiryMonth: null, expiryYear: null, cvc: null};
  }

  const handleChange = ({error, complete}: ReactStripeElements.ElementChangeResponse, name?: string) => {

    const card: stripe.elements.Element = elements.getElement('cardNumber');

    setTriedPaying(false);

    if (error) {
      setErrorMessage(error.message);
      handleUpdate(null, getInvalidCard(), false, card);
    } else if (stripe) {

      setErrorMessage('');

      // unless card is ready, block token creation
      if (!complete) {
        return;
      }

      stripe.createToken({
        name: name || cardName
      }).then((data: stripe.TokenResponse) => {

        // because request is async, we must check that errorMessages again here
        if (errorMessage) {
          return;
        }

        setErrorMessage(data.error ? data.error.message : '');

        const token: stripe.Token = data.token as stripe.Token;
        if (!token) {
          return;
        }

        /**
         * Don't pass full credit card details to redux, as it doesn't need them and could
         * potentially end up stored in dev tools (but only if you have dev tools installed).
         */
        const {exp_month, exp_year, last4, name} = token.card;
        handleUpdate(token, {
          name,
          cardNumber: (token.card.brand === 'American Express' ? 'XXXX XXXXXX X' : 'XXXX XXXX XXXX ') + last4,
          expiryMonth: exp_month.toString(),
          expiryYear: exp_year.toString(),
          cvc: 'XXX'
        }, !data.error, card);
      });
    } else {
      console.warn("Stripe.js hasn't loaded yet.");
      setErrorMessage('Loading payment gateway. Please wait.');
    }
  }

  /* DOCS: https://stripe.com/docs/payments/accept-a-payment#web */
  return (
    <Paper elevation={1} className={style.root}>
      <form>
        <label className={classNames({
          [style.cardNameWrap]: true,
          [style.cardNameWrapIsDark]: isDark
        })}>
          <span className={classNames({
            [style.fieldLabel]: true,
            [style.fieldLabelIsDark]: isDark
          })}>
            Cardholder&rsquo;s name*
          </span>
          <input type="text" name="card-name" placeholder="Name" required
                 value={cardName}
                 onChange={(evt) => {
                   const name = evt.target.value;
                   setCardName(name);
                   handleChange({
                     error: name
                       ? null
                       : {message: 'You forgot to enter your name'},
                     complete: !!name
                   } as ReactStripeElements.ElementChangeResponse, name);
                 }}
          />
        </label>

        <label className={style.cardNumberWrap}>
          <span className={classNames({
            [style.fieldLabel]: true,
            [style.fieldLabelIsDark]: isDark
          })}>
            Card number*
          </span>
          <CardNumberElement
            {...createOptions()}
            onChange={handleChange}
          />
        </label>
        <div className={classNames({
          [style.expiryCvcRow]: true,
          [style.expiryCvcRowIsTightFit]: isTightFit
        })}>
          <label className={style.expiryWrap}>
            <span className={classNames({
              [style.fieldLabel]: true,
              [style.fieldLabelIsDark]: isDark
            })}>
              Expiration date*
            </span>
            <CardExpiryElement
              {...createOptions()}
              onChange={handleChange}
            />
          </label>
          <label className={style.cvcWrap}>
            <span className={classNames({
              [style.fieldLabel]: true,
              [style.fieldLabelIsDark]: isDark
            })}>
              CVC*
            </span>
            <CardCVCElement
              {...createOptions()}
              placeholder='123'
              onChange={handleChange}
            />

            <CvcPopover theme={theme} cvcImagePath={cvcImagePath} />
          </label>
        </div>
        {renderIf(triedPaying || errorMessage && triedNext, () => (
          <div className={style.alert}>
            <AlertPanel wrapperStyle={wrapperStyle} message={errorMessage || 'Please enter your card details.'}/>
          </div>
        ))}
      </form>

      {children}

    </Paper>
  )
}

// export default PaymentCardStripe;
export default injectStripe(PaymentCardStripe);
