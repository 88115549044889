import {RootState} from "app/main/rootReducer";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect, ConnectedComponent} from "react-redux";

import ExternalLink from "shared-components/external-link/index";
import {IOwnProps, IProps} from "shared-components/external-link/types";
import {IHandlers} from "./types";
import {themeTypes} from "shared-types/WidgetTypes";

const NS = 'ExternalLinkContainer';

const mapStateToProps = (all: RootState): IProps => {
    const { appInitReducer } = all;
    const activeVenue = appInitReducer.activeVenue;

    return {
        accountId: appInitReducer.accountId,
        venueId: activeVenue.id,
        theme: appInitReducer.theme.type,
        accent: activeVenue.widgetSettings.accentColour,
        font: activeVenue.widgetSettings.font,
        isDark: activeVenue.widgetSettings.theme === (themeTypes.dark || themeTypes.outlinedDark)
    };
};

/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IHandlers => {
    return {

    }
};

const ExternalLinkContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExternalLink as any);

export default ExternalLinkContainer as ConnectedComponent<any, IOwnProps>;
