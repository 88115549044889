import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IBookingMenuOption} from "app/services/booking/booking.types";
import {IUpdateActiveChildMenuOptionPayload} from "app/models";
import {IServicePaymentOption, PricingTypes} from "shared-types/index";
import {IActiveChildMenuOption} from "shared-components/booking-options-section/ChildMenuOptionOverlay/types";
import {RootState} from "app/main/rootReducer";
import {StateHelperService} from "app/services/helpers/stateHelper.service";
import {isEmpty} from "lodash-es";


// Define a type for the slice state
interface BookingOptionSlice {
    selectedBookingOptions: IBookingMenuOption[];
    activeChildMenuOption: IActiveChildMenuOption;
    cachedMenuOptionDetails: IServicePaymentOption[];
    showBookingOptions: boolean;
    bookingOptionsValid: boolean;
}

// Define the initial state using that type
const initialState: BookingOptionSlice = {
    selectedBookingOptions: [],
    activeChildMenuOption: {} as IActiveChildMenuOption,
    cachedMenuOptionDetails: [],
    showBookingOptions: true,
    bookingOptionsValid: false
}

export interface IBookingOptionUpdated {
    singleMenuPerBooking: boolean;
    bookingOptions: IBookingMenuOption[];
}


export const setBookingOptions = createAsyncThunk(
    'test/setBookingOptions',
    async (payload: IBookingOptionUpdated, {dispatch, getState}): Promise<IBookingMenuOption[]> => {
        const {paxCounterReducer, groupWidgetReducer} = getState() as RootState;

        // if radio button has been selected, the quantity must be the same as the covers
        const bookingOptions = payload.bookingOptions;
        const hasBookingOptions = !isEmpty(bookingOptions);
        if (payload.singleMenuPerBooking) {
            if (hasBookingOptions) {
                bookingOptions[0].quantity = paxCounterReducer.count;
            }
        } else {
            const qty = bookingOptions.reduce((acc, opt) => acc + opt.quantity, 0);
            switch (groupWidgetReducer.selectedService.paymentDetails.pricingType) {
                case PricingTypes.PerPax:
                    dispatch(checkForValidBookingOptions(qty === paxCounterReducer.count));
                    break;

                case PricingTypes.PerBooking:
                    dispatch(checkForValidBookingOptions(qty > 0));
                    break;
            }
        }


        dispatch(StateHelperService.updatePaymentBasedOnBookingOptions(bookingOptions));

        return bookingOptions;
    }
)

export const checkForValidBookingOptions = createAsyncThunk(
  'test/checkForValidBookingOptions',
  async (payload: boolean, {dispatch, getState}): Promise<boolean> => {
      const {groupWidgetReducer} = getState() as RootState;

      if (groupWidgetReducer.selectedService?.paymentDetails.pricingType === PricingTypes.PerBooking) {
          return true;
      }

      return payload;
  }
)


function updateCachedMenuOptionDetailsByIds(newBookingOptions: IServicePaymentOption[], existingBookingOptions: IServicePaymentOption[]) {

    if (!existingBookingOptions || !existingBookingOptions.length) {
        return newBookingOptions;
    }

    const bookingOptions = [
        ...existingBookingOptions
    ];

    newBookingOptions.forEach(opt => {
        const ind: number = bookingOptions.findIndex(({id}) => id === opt.id);
        if (ind > -1) {
            bookingOptions[ind] = opt;
        } else {
            bookingOptions.push(opt);
        }
    });

    return bookingOptions;
}

export const bookingOptionSlice = createSlice({
    name: 'bookingOptionSlice',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        updateActiveChildMenuOptions: (state, action: PayloadAction<IUpdateActiveChildMenuOptionPayload>) => {
            const menuOptionsImplicit = action.payload ? action.payload.implicitChildMenuOptions : [];
            const menuOptionsExplicit = action.payload ? action.payload.explicitChildMenuOptions : [];
            state.activeChildMenuOption = {
                menuOptionsImplicit,
                menuOptionsExplicit
            };

            const cachedMenuOptionDetails = [
                ...state.cachedMenuOptionDetails
            ];

            // Here we update `state.cachedMenuOptionDetails` incase we need to request this menu option again (saves unnecessary backend calls)
            (menuOptionsImplicit || []).concat(menuOptionsExplicit || []).forEach(opt => {
                const ind: number = cachedMenuOptionDetails.findIndex(({id}) => id === opt.id);
                if (ind > -1) {
                    cachedMenuOptionDetails[ind] = opt;
                } else {
                    cachedMenuOptionDetails.push(opt);
                }
            });

            state.cachedMenuOptionDetails = cachedMenuOptionDetails;
        },
        updateCachedMenuOptionDetails: (state, action: PayloadAction<IServicePaymentOption[]>) => {
            const bookingOptions = updateCachedMenuOptionDetailsByIds(action.payload, state.cachedMenuOptionDetails);
            // Here we update `state.cachedMenuOptionDetails` incase we need to request this menu option again (saves unnecessary backend calls)
            state.cachedMenuOptionDetails = bookingOptions;
        },
        toggleBookingOption: (state, action: PayloadAction<boolean>) => {
            state.showBookingOptions = action.payload;
        }

    }, extraReducers: (builder) => {
        builder.addCase(setBookingOptions.fulfilled, (state, {payload}: PayloadAction<IBookingMenuOption[]>) => {
            state.selectedBookingOptions = payload;
        });
        builder.addCase(checkForValidBookingOptions.fulfilled, (state, {payload}: PayloadAction<boolean>) => {
            state.bookingOptionsValid = payload;
        });
    }
})

export const {updateActiveChildMenuOptions, updateCachedMenuOptionDetails, toggleBookingOption} = bookingOptionSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default bookingOptionSlice.reducer