import React from 'react';
import style from './style.module.scss';
import { Stepper, Step, StepLabel, makeStyles, createStyles } from '@material-ui/core';
import {IProgressStep, IProps} from './types';
import { IWidgetTheme } from 'shared-types/index';
import { ThemeColorsService } from 'shared-services/theme-colors-service/index';
import IframeResizerService from "shared-services/iframe-resizer-service";

const NS = 'ProgressStepper';

/**
 * NOTE:
 * This component gets used with container (steps) and without (payments)
 */

// MuiStepIcon-completed


const useStyles = makeStyles((theme: IWidgetTheme) => {
  const {secondary, textColor1} = ThemeColorsService.getCommonThemeStyles(theme);

  return createStyles({
    root: {
      '& .MuiStepIcon-completed': {
        color: secondary.main
      },
      '& .MuiStepIcon-text': {
        transform: 'translateY(1px)'
      }
    }
  })
});

export default function ProgressStepper({activeStep, wrapperStyle, steps, theme}: IProps) {
  const classes = useStyles({theme});
  return (
    <Stepper activeStep={activeStep}
       className={[style.root, classes.root].join(' ')}
       alternativeLabel={IframeResizerService.isStacked(wrapperStyle)}
       color="secondary">
      {steps.map(({label}: IProgressStep, index: number) => {
        return (
          <Step key={index} >
            <StepLabel>
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  )
}
