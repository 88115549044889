import {cloneDeep, sortBy} from 'lodash';
import {
    IBookingMenuOption, IBookingOutgoing,
    ISavedBooking,
} from "./booking.types";
import {
    IAccountDetails, IScheduleServiceABC,
    ISectionOrder
} from "app/models";
import moment, {Moment} from "moment";
import {
    IServicePaymentDetails, servicePaymentType, IServicePaymentOption,
    ISchedule, IScheduleTime, IScheduleService,
    IWidgetBooking,
    IBookingResponseData, IOwnedVenue, ISavedBookingSelectedOptions, IScheduleServiceAbstract, IBookingTag
} from "shared-types/index";
import {ClientService} from "app/services/client/client.service";
import {IResponse} from "app/models/common.types";
import {Observable} from "rxjs";


const NS = 'BookingService';

export const SECTION_ANY_ID = 'any';
export const SECTION_ANY_NAME = 'Any';

export function getSectionAny(): ISectionOrder {
    return {
        id: SECTION_ANY_ID,
        name: SECTION_ANY_NAME,
        order: 0
    }
}

export class BookingService {

    static filterTags(tags: IBookingTag[]): IBookingTag[] {
        const filteredTags: IBookingTag[] = [];

        for (let i = 0; i < tags.length; i++) {
            const tag = tags[i];
            if (tag.selected) {
                filteredTags.push(tag);
            }
        }
        return filteredTags;
    }


    static mergeTags(tags: IBookingTag[], savedTags: IBookingTag[]): IBookingTag[] {
        const offlineTags = savedTags.filter(tag => !tag.online);
        return tags.concat(offlineTags);
    }

    static getViewDateFromMoment(moment: Moment): string {
        return moment.format('dddd, MMMM D, YYYY');
    }

    // static isStandardEvent(appSettings: IAppSettings): boolean {
    //   const {payment, payments} = bookingAction;
    //   return appSettings.serviceids && (appSettings.action !== payment && appSettings.action !== payments);
    // }

    static getFirstFromCommaSepartedString(str: string): string {
        if (!str) {
            return null;
        }

        if (str.indexOf(',') === -1) {
            return str;
        }

        const arr = str.split(',');
        return arr[0];
    }

    static removeOfflineServices(schedule: ISchedule) {
        schedule.services = schedule.services.filter(s => s.online);
        return schedule;
    }

    static saveBooking(savedBooking: IBookingOutgoing, venueId: number): Observable<IResponse<IBookingResponseData>> {
        return ClientService.saveBooking(savedBooking, venueId)
    }


    /**
     * Filters out sections when covers are out of allowed ranges for the service.
     * @param service
     * @param covers
     * @param maxPeoplePerBookingDefault - comes from `widget.activeVenue.widgetSettings.maxPeoplePerBooking`
     */
    static getSections(service: IScheduleService, covers: number, maxPeoplePerBookingDefault: number): ISectionOrder[] {

        if (!service || !service.sections || !service.sections.length) {
            return [];
        }

        /**
         * Within 'schedule' -> 'payments & booking options' there are fields for:
         * - 'peopleRequired': which is the minimum covers required to show the booking options
         * - 'maxPeoplePerBookingOverride': which will override the default maximum covers (set in account/admin app)
         */
        const maxOverride = service.paymentDetails.maxPeoplePerBookingOverride;
        const peopleRequiredForPayment = service.paymentDetails.peopleRequired;
        const useOverride = maxOverride && covers >= peopleRequiredForPayment;

        /**
         * This check shouldn't be necessary because the CoversInput component restricts input, but
         * we're leaving it in there just in case input logic breaks (maybe in some untested/old browser).
         */
        if (
            /**
             * If service has no paymentDetails with special min/max cover rules
             */
            (!useOverride && covers <= maxPeoplePerBookingDefault) ||
            /**
             * If service does have special rules for min/max covers
             */
            (covers <= maxOverride && covers >= peopleRequiredForPayment)
        ) {
            return sortBy(service.sections, 'order');
        }

        return [];
    }

    /**
     * Filters out services that have either expired or have `times` set to null ('Allow in online booking system: No')
     */
    static getAvailableServicesFromSchedule(schedule: ISchedule, omitExpired = true): IScheduleService[] {
        if (!schedule) {
            return [];
        }
        if (omitExpired) {
            return this.omitExpiredServices(schedule.services);
        }
        return schedule.services;
    }

    static omitExpiredServices(services: IScheduleService[]): IScheduleService[] {
        return services.filter(s => s.times && s.times.find(t => !t.expired));
    }

    /**
     * If a service has any expired times it means that it has started or is in the past.
     * Could potentially need to use `BookingService.getVenueTime(activeVenue as IVenue)` in future if current time is needed.
     */
    static isServiceActiveOrComplete(times: IScheduleTime[]): boolean {
        return times.some(t => t.expired);
    }

    /**
     * If customers can choose a section, don't auto-select the first service.
     * This gives the user the chance to consciously open the next panel.
     */
    static preselectFirstService(services: IScheduleService[]): IScheduleService {
        return services && services.length
            ? services[0]
            : null;
    }

    /**
     * Quick check to see if service has a payment on it, not taking into account booking options or if price is more than
     * zero. For most cases you should use `isPaymentDetailsVisible` instead, as this contains checks for the above mentioned
     * scenarios.
     * @todo: replace instances of `hasPayment` and replace with `isPaymentDetailsVisible`, then rename to something more intuitive. severity: low
     */
    static hasPayment(service: IScheduleService, covers: number): boolean {
        if (!service) {
            return false;
        }
        return service.paymentDetails.paymentType !== servicePaymentType.noPayment && covers >= service.paymentDetails.peopleRequired;
    }

    /**
     * Determines if a service has booking options
     * @param service
     * @param covers
     * @param maxPeoplePerBookingDefault - comes from `widget.activeVenue.widgetSettings.maxPeoplePerBooking`
     */
    static hasMenuOptions(service: IScheduleServiceAbstract, covers: number, maxPeoplePerBookingDefault: number): boolean {
        if (!service) {
            return false;
        }

        const menuOptions: IServicePaymentOption[] = service.paymentDetails.options || [];
        const peopleRequired: number = service.paymentDetails.peopleRequired;
        return !!menuOptions.length && covers >= peopleRequired;
    }

    /**
     * Populates standbyData object based on properties in booking object
     * @param options
     * @param selectedOptions
     * @param covers
     * @param singleMenuPerBooking
     */

    static getBookingOptions(options: IServicePaymentOption[], selectedOptions: IBookingMenuOption[], covers: number, singleMenuPerBooking: boolean): IBookingMenuOption[] {

        if (!singleMenuPerBooking) {
            const result = options.map(option => {

                // if selectedOption exists, use it
                const item = selectedOptions && selectedOptions.find(s => s.menuOptionId === option.id);
                if (item) {
                    return item;
                }

                // otherwise create a new one
                return {
                    menuOptionId: option.id,
                    quantity: 0
                };
            });

            return result;
        }

        if (!selectedOptions || !selectedOptions.length) {
            return [];
        }

        // if the types of options are toggled so get the first and change quantity to covers
        let firstOptWithQuantity: IBookingMenuOption = selectedOptions.find(o => o.quantity > 0);
        if (firstOptWithQuantity) {
            // firstOptWithQuantity.quantity = covers; // Was returning TypeError: Cannot assign to read only property 'quantity' of object '#<Object>' Error
            firstOptWithQuantity = {...firstOptWithQuantity, quantity: covers};
            return [firstOptWithQuantity];
        }

        return [];
    }

    static getSectionName(filteredSections: ISectionOrder[], activeSectionId: string): string {

        if (activeSectionId === SECTION_ANY_ID) {
            return `${SECTION_ANY_NAME.toLowerCase()} section`;
        }

        const activeSection: ISectionOrder = filteredSections.find(o => o.id === activeSectionId);
        if (!activeSection) {
            return null;
        }

        return `"${activeSection.name}"`;
    }

    static getMenuOptionsPrice(selectedMenuOptions: IBookingMenuOption[], serviceOptions: IServicePaymentOption[]): number {

        if (!selectedMenuOptions || !selectedMenuOptions.length || !serviceOptions) {
            return null;
        }

        let price = 0;
        const priceMap: any = {};

        serviceOptions.forEach(opt => {
            priceMap[opt.id] = opt.price;
        });

        selectedMenuOptions.forEach(option => {
            price += priceMap[option.menuOptionId] * option.quantity;
        });

        return price;
    }


    static isPaymentDetailsVisible(covers: number, paymentDetails: IServicePaymentDetails): boolean {

        if (!paymentDetails || !covers) {
            return false;
        }

        if (paymentDetails.price === 0 || paymentDetails.paymentType === servicePaymentType.noPayment) {
            return false;
        }

        return covers >= paymentDetails.peopleRequired;
    }

    static shouldPay(activeService: IScheduleService, booking: IWidgetBooking): boolean {
        const paymentDetails: IServicePaymentDetails = activeService ? activeService.paymentDetails : null;

        const hasMenuOptions: boolean = booking.selectedMenuOptions && booking.selectedMenuOptions.length > 0;
        const selectedMenuOptions = booking ? booking.selectedMenuOptions : null;
        const menuOptionsPrice: number = BookingService.getMenuOptionsPrice(selectedMenuOptions, paymentDetails ? paymentDetails.options : null);
        const hasPayment = paymentDetails.paymentType !== servicePaymentType.noPayment;
        const noMenuOptionsAndEnoughCovers = !hasMenuOptions && booking.covers >= paymentDetails.peopleRequired;

        return !!(hasPayment &&
            (noMenuOptionsAndEnoughCovers || (hasMenuOptions && menuOptionsPrice)));

    }

    // static getSavedBookingObj(data: IBookingResponseData): ISavedBooking {
    //     const bookingMoment = BookingService.getBookingMoment(data.time);
    //
    //     const booking: ISavedBooking = {
    //         _id: (data as IBookingResponseData)._id || null,
    //         moment: bookingMoment,
    //         time: data.time,
    //         utcTime: BookingService.getUTCTime(bookingMoment),
    //         viewDate: bookingMoment.format('dddd, MMMM D, YYYY'),
    //         viewTime: bookingMoment.format('h:mm a'),
    //         rhinoTime: data.time,
    //         covers: data.people,
    //         serviceId: data.serviceId,
    //         serviceName: data.serviceName || null,
    //         sectionId: (data.tables.length) ? data.tables[0].sectionId : null,
    //         bookingId: data._id,
    //         duration: data.duration,
    //         tags: (data.tags) ? data.tags : [],
    //         notes: (data.notes) ? data.notes : '',
    //         payment: data.paymentPending,
    //         bookingType: data.bookingType || BookingType.Booking,
    //         tables: data.tables
    //     }
    //
    //     if (data.paymentPending && data.paymentSummary && data.paymentSummary.amountPaid > 0) {
    //         booking.payment.amountPaid = data.paymentSummary.amountPaid;
    //     }
    //
    //     if (data.selectedOptions) {
    //         booking.selectedMenuOptions = BookingService.convertSelectedBookingOptions(data.selectedOptions);
    //     }
    //
    //     return booking
    // }

    // Can cancel 15 mins into booking
    static getBookingTimeWithOffset(bookingTime: Date): Date {
        const bookingTimeWithOffset: Date = cloneDeep(bookingTime);
        bookingTimeWithOffset.setTime(bookingTimeWithOffset.getTime() + 1000 * 900);
        return bookingTimeWithOffset;
    }

    static canLoadBooking(schedule: ISchedule, savedBooking: ISavedBooking): boolean {
        const service: IScheduleService = schedule.services.find(s => s.id === savedBooking.serviceId);

        if (!service) {
            return false;
        }

        const timeIndex: number = service.times.findIndex(t => t.time === savedBooking.utcTime);

        return (timeIndex >= 0 && savedBooking.duration === service.duration);
    }

    /**
     * Booking moment displaying browser time so time will always
     * display correct even if the customer is in a different timezone
     */
    private static getBookingMoment(time: string): moment.Moment {
        return moment(time, 'YYYY/MM/DD HH:mm', true);
    }

    /**
     * Remove offset from utcTime as time as we dont know what timezone the
     * browser may be set to
     */
    private static getUTCTime(bookingMoment: moment.Moment): string {
        const utcTime: string = bookingMoment.format();
        return utcTime.substring(0, utcTime.length - 6);
    }

    static isStandby(isStandByList: boolean, day: string, standByListDaysWeekDays: string[]): boolean {
        if (!isStandByList) {
            return isStandByList;
        }
        return standByListDaysWeekDays.includes(day);
    }

    static getServiceFromId(services: IScheduleServiceABC[], serviceId: string) {
        return services.find(service => service.id === serviceId);
    }

    static getVenueDetailsFromAccount(account: IAccountDetails, venueId: number): IOwnedVenue {
        return account.ownedVenues.find(venue => venue.id === venueId)
    }

    static getBookingOptionIds(options: ISavedBookingSelectedOptions[]): string[] {
        return options.reduce((acc, opt) => {
            acc.push(opt.id);

            if (opt.extras) {
                acc = acc.concat(opt.extras.map(({id}) => id));
            }

            return acc;
        }, []);
    }

    static checkIfPaidBOPresent(serviceOptions: IServicePaymentOption[]): boolean {
        const total: number = serviceOptions
            ? serviceOptions.reduce((acc: number, item: IServicePaymentOption) => {
                return acc + (item.price);
            }, 0)
            : 0;
        return total > 0;
    }

    static paymentTypeLabel(paymentType: servicePaymentType): string {
        switch (paymentType) {
            case servicePaymentType.noPayment:
                return 'No Payment';
            case servicePaymentType.deposit:
                return 'Deposit';
            case servicePaymentType.fullPayment:
                return 'Full Payment';
            case servicePaymentType.preAuth:
                return 'Pre Authorisation';
        }
        return paymentType;
    }

    static getPaymentDropdownOptions(paymentOptions: servicePaymentType[]) {
        return paymentOptions.map((type: servicePaymentType) => {
            return {
                code: type,
                name: BookingService.paymentTypeLabel(type)
            }
        })
    }


}
