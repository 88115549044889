import {hot} from 'react-hot-loader/root'; // must come before react
import React, {useEffect} from 'react';
import styles from './App.module.scss'
import MainHeader from "app/components/MainHeader";
import {Provider, useDispatch, useSelector} from "react-redux";
import {RootState} from "app/main/rootReducer";
import {BrowserRouter, Router, Switch} from 'react-router-dom';
import PrivateRoute from "../../_helpers/privateRoute";
import FooterNavContainer from "app/components/FooterNav/container";
import {history} from '../../_helpers/history'
import {GetAccount, setAccountId, setWrapperStyle} from 'app/reducers/appInitSlice';
import HomePage from "app/components/HomePage";
import ThankYouContainer from "app/components/ThankYou/container";
import {renderIf} from "app/services/utils.service";
import FooterService from "app/services/footer/footer.service";
import PaymentsSectionContainer from 'app/components/PaymentsSection/container';
import PaymentCompleteContainer from 'app/components/PaymentComplete/container';
import BookingCancelledContainer from 'app/components/BookingCancelled/container';
import ErrorPageContainer from 'app/components/ErrorPage/container';

import {IntlProvider} from 'react-intl/dist';
import {IntlService} from "shared-services/intl-service";
import {ROUTE_NAMES} from "app/models/route.types";
import {MessageService} from "shared-services/message-service";
import {DEFAULT_MESSAGES} from "app/constants/appValues";
import {ErrorMessageService} from "shared-services/error-message-service";

import createMuiTheme, {ThemeOptions} from "@material-ui/core/styles/createMuiTheme";
import {ThemeColorsService} from "shared-services/theme-colors-service";
import {createStyles, CssBaseline, withStyles} from "@material-ui/core";
import {StylesProvider, ThemeProvider} from "@material-ui/core/styles";
import store from "app/main/store";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import PublicRoute from "../../_helpers/publicRoute";
import SetUpManageBooking from "app/components/SetUpManageBooking";
import {
    IManageBookingOutgoingDetails,
    setEmailToken,
    setManageBookingURLArguments
} from "app/reducers/manageBookingSlice";
import {IAccountDetails} from "app/models";
import {PayloadAction} from "@reduxjs/toolkit";
import BookingDetailsContainer from "app/components/BookingDetails/container";
import {wrapperStyleType} from "shared-types/WidgetTypes";
import IframeResizerService from "shared-services/iframe-resizer-service";

ErrorMessageService.setDefaultMessages(DEFAULT_MESSAGES);
MessageService.setDefaultMessages(DEFAULT_MESSAGES);

function App() {

    const redirectTo = useSelector((state: RootState) => state.routeReducer.redirectTo);
    const isManageBooking = useSelector((state: RootState) => state.manageBookingReducer.isManageBooking);
    const pathName = window.location.pathname;

    const dispatch: (args: any) => Promise<PayloadAction<IAccountDetails>> = useDispatch();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        console.log('token', params.get('token'))
        console.log('accountId', params.get('accountid'))

        if (params && params.get('accountid')) {
            dispatch(setAccountId(params.get('accountid')))
            dispatch(GetAccount(params.get('accountid'))).then((response) => {

                /**
                 * must get called immediately as `window.iFrameResizer` object needs to exist when
                 * `iframeResizer.contentWindow.min.js` is loaded.
                 */
                IframeResizerService.handleSizeChange()
                    // long running subscription
                    .subscribe((_wrapperStyle: wrapperStyleType) => {
                        dispatch(setWrapperStyle(_wrapperStyle));
                    });

                if (pathName === ROUTE_NAMES.MANAGE_BOOKING) {
                    const venueId = params.get('venueid');
                    const emailToken = params.get('token');
                    dispatch(setEmailToken(emailToken));
                    const manageBookingUrlArgs: IManageBookingOutgoingDetails = {
                        venueId,
                        emailToken
                    }
                    dispatch(setManageBookingURLArguments(manageBookingUrlArgs))
                }
            })
        }

        if (pathName !== ROUTE_NAMES.MANAGE_BOOKING && params && params.get('token')) {
            params.delete('token');
            window.history.pushState({}, document.title, `/?accountid=${params.get('accountid')}`)
        }
    }, [])

    return (
        // IntlProvider is used for currency formatting (but more could be added, such as language)
        <IntlProvider locale={IntlService.getLocale()}>
            <div className={styles.newApp}>
                <Router history={history}>
                    {
                        pathName === ROUTE_NAMES.MANAGE_BOOKING && redirectTo === ROUTE_NAMES.DEFAULT ? (
                            <Switch>
                                <PublicRoute exact path={ROUTE_NAMES.DEFAULT} restricted={false}
                                             component={SetUpManageBooking}/>
                            </Switch>
                        ) : (
                            <div>
                                <MainHeader/>
                                <Switch>
                                    <PrivateRoute exact path={ROUTE_NAMES.DEFAULT} component={HomePage}
                                                  restricted={true}/>
                                    <PrivateRoute exact path={ROUTE_NAMES.BOOKING} component={BookingDetailsContainer}
                                                  restricted={true}/>
                                    <PrivateRoute exact path={ROUTE_NAMES.PAYMENTS}
                                                  component={PaymentsSectionContainer} restricted={!isManageBooking}/>
                                    <PrivateRoute exact path={ROUTE_NAMES.PAYMENT_COMPLETE}
                                                  component={PaymentCompleteContainer} restricted={!isManageBooking}/>
                                    <PrivateRoute exact path={ROUTE_NAMES.THANK_YOU} component={ThankYouContainer}
                                                  restricted={!isManageBooking}/>
                                    <PrivateRoute exact path={ROUTE_NAMES.CANCEL}
                                                  component={BookingCancelledContainer} restricted={!isManageBooking}/>
                                    <PrivateRoute exact path={ROUTE_NAMES.ERROR_PAGE} component={ErrorPageContainer}
                                                  restricted={!isManageBooking}/>
                                </Switch>
                                {renderIf(FooterService.showFooter(redirectTo), () => (
                                    <FooterNavContainer/>
                                ))}
                            </div>

                        )
                    }

                </Router>
            </div>

        </IntlProvider>
    );
}

export default hot(App); // needs this on root element for hot module replacement to work


export const themeStyles = (theme: ThemeOptions) => {

    const {textColor1, textColor2, colorError, colorWarning, colorSuccess} = ThemeColorsService.getCommonThemeStyles(theme);

    return createStyles({
        '@global': {
            'a': {
                color: textColor2
            },
            '.error-text': {
                color: colorError
            },
            '.error-bg': {
                backgroundColor: colorError
            },
            '.success-text': {
                color: colorSuccess
            },
            '.warning-text': {
                color: colorWarning
            },
            '.primary-text': {
                color: textColor1
            },
            '.secondary-text': {
                color: textColor2
            }
        }
    });
}

/**
 * Can be used for any page that needs theming. Should not include any redux 'containers', just in case
 * it gets used for a simple instance that doesn't need redux (such as terms and conditions).
 */
export function AppWrap({theme, children}: { theme: ThemeOptions, children: any }) {
    const StyleWrap = withStyles(themeStyles)(({children}: { children: any }) => (<>{children}</>));
    let muiTheme = createMuiTheme(theme);
    muiTheme = responsiveFontSizes(muiTheme);
    return (
        <div className={'appIndex'}>
            <BrowserRouter>
                <ThemeProvider theme={muiTheme}>
                    <CssBaseline/>
                    <StylesProvider injectFirst>
                        <StyleWrap>
                            <Provider store={store}>
                                {children || null}
                            </Provider>
                        </StyleWrap>
                    </StylesProvider>
                </ThemeProvider>
            </BrowserRouter>
        </div>
    );
}
