export enum phoneNumberTypes {
  INTERNATIONAL = 'INTERNATIONAL',
  NATIONAL = 'NATIONAL',
}

export interface IParsedPhoneNumber {
  isValid: () => boolean;
  format: (value: string) => string;
}

// this is not a full intereface, just what we need in this app. You can add more as you need them.
export interface ILibPhoneNumber {
  parsePhoneNumberFromString: (phoneNumber: string, countryCode: string) => IParsedPhoneNumber;
  parsePhoneNumber: (phoneNumber: string) => any;
  formatInterNationalPhoneNumber: (phoneNumber: string, country: string) => any;
}
