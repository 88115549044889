import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { IStateFromProps, IDispatchFromProps } from './types';
import { menuOptionType } from '../MenuOption/types';
import { renderIf } from 'shared-services/react-utils-service/index';
import MenuOptionsRadio from '../MenuOptionsRadio';
import MenuOptionsSpinner from '../MenuOptionsSpinner';
import AlertPanel from 'shared-components/alert-panel/index';
import style from './style.module.scss';
import { IBookingMenuOption, IBookingMenuOptionExtrasUpdater } from 'shared-types/index';
import ChildMenuOptionOverlayContainer from 'internal-components/ChildMenuOptionOverlay/container';
import { MenuOptionsService } from 'shared-services/menu-options-service/index';
import MenuOptionsCheckbox from "shared-components/booking-options-section/MenuOptionsCheckbox";

const NS = 'MenuOptions';


export default function MenuOptions({
    theme, selectedMenuOptions, menuOptions, useMuiGrid,
    type, maxCovers, wrapperStyle, alertMessage, serviceId, bgColor,
    handleRadioSelect, handleMultiBookingOptionUpdate, handleActiveChildMenuOption,
    handleMaxCovers, handleDone, isUpsell
  }: IStateFromProps & IDispatchFromProps) {

  const [selectionData, setSelectionData] = useState<IBookingMenuOptionExtrasUpdater>(null);

  const gridXs = 12;
  const gridMd = 6;
  const gridGutter = useMuiGrid ? 2 : null; // Use flexbox layout or material ui grid

  const _handleOpenExtras = (index: number) => {

    // using a clone because we don't want to save the data until it has been accepted/confirmed
    const _extrasMenuOption = cloneDeep(menuOptions[index]);
    let _activeSelectedMenuOption: IBookingMenuOption = null;

    if (_extrasMenuOption && selectedMenuOptions && selectedMenuOptions.length) {
      const selectedMenuOption = selectedMenuOptions.find(o => o.menuOptionId === _extrasMenuOption.id);
      if (!selectedMenuOption.extras) {
        selectedMenuOption.extras = MenuOptionsService.getEmptyExtrasMenuOption();
      }
      _activeSelectedMenuOption = selectedMenuOption;
    }

    handleActiveChildMenuOption(_extrasMenuOption); // will fetch full menuOption details from back end and update store when ready

    setSelectionData(_extrasMenuOption && _activeSelectedMenuOption ? {
      ..._activeSelectedMenuOption.extras,
      parentId: _extrasMenuOption.id,
      parentLabel: _extrasMenuOption.label,
      parentQuantity: _activeSelectedMenuOption.quantity
    } : null);
  }

  return (
    <div className={style.root} key={serviceId} >
      {renderIf(type === menuOptionType.radioButtons, () => (
        <MenuOptionsRadio
          data-testid="booking-option-radio"
          selectedMenuOptions={selectedMenuOptions}
          menuOptions={menuOptions}
          type={type}
          gridXs={gridXs}
          gridMd={gridMd}
          gridGutter={gridGutter}
          handleRadioSelect={(menuOptionId: string) => {
            handleRadioSelect(menuOptionId);
            handleMaxCovers && handleMaxCovers(true);
          }}
          bgColor={bgColor}
          handleOpenExtras={_handleOpenExtras}
        />
      ))}

      {renderIf(type === menuOptionType.spinners, () => (
        <MenuOptionsSpinner
          theme={theme}
          wrapperStyle={wrapperStyle}
	        data-testid="booking-option-spinner"
          maxCovers={maxCovers}
          selectedMenuOptions={selectedMenuOptions}
          menuOptions={menuOptions}
          type={type}
          gridXs={gridXs}
          gridMd={gridMd}
          gridGutter={gridGutter}
          isUpsell={isUpsell}
          handleSpinnerUpdate={(selectedMenuOptions: IBookingMenuOption[])=> {
            handleMultiBookingOptionUpdate(selectedMenuOptions, isUpsell)
          }}
          bgColor={bgColor}
          handleOpenExtras={_handleOpenExtras}
          handleMaxCovers={handleMaxCovers}
        />
      ))}

      {renderIf(type === menuOptionType.checkboxes, () => (
        <MenuOptionsCheckbox
          data-testid="booking-option-checkbox"
          selectedMenuOptions={selectedMenuOptions}
          menuOptions={menuOptions}
          gridXs={gridXs}
          gridMd={gridMd}
          gridGutter={gridGutter}
          handleCheckedUpdate={(selectedMenuOptions: IBookingMenuOption[])=> {
            handleMultiBookingOptionUpdate(selectedMenuOptions, false)
          }}
          bgColor={bgColor}
          isUpsell={isUpsell}
          handleOpenExtras={_handleOpenExtras}
        />
      ))}

      {renderIf(alertMessage, () => (
        <div className={style.alert}>
          <AlertPanel wrapperStyle={wrapperStyle} message={alertMessage} />
        </div>
      ))}

      <ChildMenuOptionOverlayContainer
        selectionData={selectionData}
        gridGutter={gridGutter}
        handleDone={(data: any) => {
          if (handleDone) {
            handleDone(data);
          }
        }}
        handleClose={() => {
          handleActiveChildMenuOption(null);
          setSelectionData(null);
        }} />
    </div>
  )
}

