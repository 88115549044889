import {sortBy} from 'lodash';
import {RootState} from "app/main/rootReducer";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect, ConnectedComponent, useSelector} from "react-redux";
import PaymentsSection from "shared-components/payments-section/index";
import {IHandlers, IProps} from "shared-components/payments-section/types";
import {
    IApplyPromoCode, IErrorResponse, IEwaySummaryResponseData,
    IPaymentDetailsGenericData,
    IPrepareEwayData, ISavePreAuthResponseData,
    paymentProviderTypeUppercase,
    wrapperStyleType
} from "shared-types/index";
import {IGroupedTablesBoxItem} from "shared-components/grouped-tables-box/types";
import BookingService from "shared-services/booking-service";
import {
    applyPromoCode,
    paymentSlice,
    prepareEwayPayment,
    submitEwayPayment,
    submitStripePayment
} from "app/reducers/paymentSlice";
import {routeSlice} from "app/reducers/routeSlice";
import {footerNavTypes} from "shared-components/footer-nav/types";
import {StateHelperService} from "app/services/helpers/stateHelper.service";
import updateRoute = StateHelperService.updateRoute;
import {bookingSlice, deleteBooking} from "app/reducers/bookingSlice";

const NS = 'PaymentsSectionContainer';

const mapStateToProps = (all: RootState): IProps => {
    const {
        appInitReducer, bookingReducer, paymentReducer, manageBookingReducer
    } = all;

    const {
        customer, covers, serviceName, viewTime, viewDate
    } = BookingService.getBookingObj(bookingReducer.bookingResponse);

    const payment = bookingReducer.savedBooking.payment;

    const activeVenue = appInitReducer.activeVenue;
    const wrapperStyle = appInitReducer.wrapperStyle;

    const isManageBooking = manageBookingReducer.isManageBooking;

    const isStripe: boolean = activeVenue && activeVenue.paymentSettings
        ? activeVenue.paymentSettings.paymentProvider === paymentProviderTypeUppercase.Stripe
        : false;

    // maps props in customer to human friendly names
    const customerMap: IGroupedTablesBoxItem[] = [
        {order: 0, name: 'firstName', value: 'First Name'},
        {order: 1, name: 'lastName', value: 'Surname'},
        {order: 2, name: 'company', value: 'Company Name'},
        {order: 3, name: 'phone', value: 'Mobile'},
        {order: 4, name: 'email', value: 'Email'},
        // {order: 10, name: 'notes', value: 'Requests'}, // Do not display customer notes in the payment screen
    ]

    const customerDetailItems: IGroupedTablesBoxItem[] = customerMap.reduce((a: any, nameObj: IGroupedTablesBoxItem) => {
        const value: string = (customer as any)[nameObj.name];
        if (value) {
            a.push({
                name: nameObj.value,
                value,
                order: nameObj.order
            })
        }
        return a;
    }, []);

    return {
        theme: appInitReducer.theme,
        wrapperStyle: wrapperStyle,
        isStripe,
        stripePublishableKey: isStripe ? (activeVenue.paymentSettings?.stripe?.publishableKey || null) : null, // || 'pk_test_12345'
        venuePaymentSettings: isStripe ? activeVenue.paymentSettings.stripe :  activeVenue.paymentSettings.eway,
        currency: activeVenue.currency,
        payment,
        customerDetails: {
            heading: 'Your Details',
            items: sortBy(customerDetailItems, 'order')
        },
        bookingDetails: {
            heading: 'Booking Details',
            items: [{
                name: 'Restaurant',
                value: activeVenue?.name || ''
            }, {
                name: 'Booking date',
                value: viewDate || ''
            }, {
                name: 'Time',
                value: viewTime || ''
            }, {
                name: 'Number of guests',
                value: covers.toString()
            }, {
                name: 'Service Name',
                value: serviceName
            }]
        },
        footerNavShowCancel: true,
        showPromoCode: paymentReducer.showPromoCode,
        useContainer: true,
        cvcImagePath: "/images/cards-cvc.jpg",
        isManageBooking
    };
};

/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IHandlers => {
    return {
        handlePrepareEwayPayment: (): Promise<IPrepareEwayData> => {
            return dispatch(prepareEwayPayment())
                .then((action) => {
                    const {success, data} = action.payload as {success: boolean, data: IPrepareEwayData};
                    if (!success) {
                        dispatch(paymentSlice.actions.setTryAgainBtn(false));
                        dispatch(routeSlice.actions.addBookingErrorRoute());
                        dispatch(updateRoute(footerNavTypes.next));
                    }
                    return success ? data : null;
                });
        },
        handleEwayPaymentSubmit: (formEl: HTMLFormElement): Promise<void> => {
            return dispatch(submitEwayPayment(formEl))
              .then((data: {
                  payload: { success?: IEwaySummaryResponseData | ISavePreAuthResponseData }
              }) => {
                  if (data.payload.success) {
                    dispatch(updateRoute(footerNavTypes.next));
                  } else {
                    /**
                     * At this point there should be a bookingError set on the paymentReducer, so we change the routes
                     * to include the error page, then navigate to it.
                     */
                    dispatch(paymentSlice.actions.setTryAgainBtn(true));
                    dispatch(routeSlice.actions.addBookingErrorRoute());
                    dispatch(updateRoute(footerNavTypes.next));
                  }
              }); // don't care what the response was, just that it finished
        },
        handleConfirmState: () => {
            // just used for analytics. Not need for ABC.
        },
        handleStripePaymentSubmit: (
            stripeInstance: stripe.Stripe,
            card: stripe.elements.Element,
            token: stripe.Token,
            paymentDetails: IPaymentDetailsGenericData
        ): Promise<void> => {
            return dispatch(submitStripePayment({stripeInstance, card, token, paymentDetails}))
                .then((data: {
                    payload: { successPayload?: ISavePreAuthResponseData }
                }) => {
                    if (data.payload.successPayload) {
                        dispatch(updateRoute(footerNavTypes.next));
                    } else {
                        /**
                         * At this point there should be a bookingError set on the paymentReducer, so we change the routes
                         * to include the error page, then navigate to it.
                         */
                        dispatch(paymentSlice.actions.setTryAgainBtn(true));
                        dispatch(routeSlice.actions.addBookingErrorRoute());
                        dispatch(updateRoute(footerNavTypes.next));
                    }
                }); // don't care what the response was, just that it finished
        },
        handleCancelBooking: (showBookAgainBtn: boolean) => {
            const success = () => {
                dispatch(bookingSlice.actions.setBookingCancelled({isCancelled: true, showBookAgainBtn: showBookAgainBtn}));
                dispatch(routeSlice.actions.addBookingCancelledRoute());
                dispatch(updateRoute(footerNavTypes.next));
            };

            dispatch(deleteBooking())
              .then(() => {
                  success();
              })
              .catch((err: {response: IErrorResponse}) => {
                  console.log(NS, 'deleteBooking err', err)
                  if (err.response.status === 404) {
                      // booking already cancelled
                      success();
                  } else {
                      // @todo handle the delete booking fail message
                  }
              });
        },
        handlePromotionCode: (promotionCode: string): Promise<IApplyPromoCode> => {
            return dispatch(applyPromoCode(promotionCode))
              .then(data => (data.payload as IApplyPromoCode));
        },
    }
};


const PaymentsSectionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentsSection as any);

export default PaymentsSectionContainer as ConnectedComponent<any, any>;
