import React, {useEffect} from 'react';

import {IDispatchFromProps, IStateFromProps} from './types';
import {Grid} from "@material-ui/core";
import CustomerDetailsFormContainer from "app/components/CustomerDetailsForm/container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SittingPanelsContainer from "app/components/SittingPanels/container";
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'app/main/rootReducer';
import {VenueGridService} from 'app/services/venueGrid.service';
import {IPaymentOverride} from 'app/services/booking/booking.types';
import {servicePaymentType} from 'shared-types/SharedTypes';
import {setPayment} from 'app/reducers/paymentSlice';
import {StateHelperService} from "app/services/helpers/stateHelper.service";

const NS = 'BookingDetails';

const useStyles = makeStyles((theme) => ({
    topChildComponent: {
        padding: '20px',
    },
    customerDetailsForm: {
        padding: '20px',
        margin: '0 auto',
        width: '900px'
    }
}));


export default function BookingDetails({bookingTime}: IStateFromProps & IDispatchFromProps) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const paxCount = useSelector((state: RootState) => state.paxCounterReducer.count);
    const selectedService = useSelector((state: RootState) => state.groupWidgetReducer.selectedService);

    useEffect(() => {
        const paymentDetails: IPaymentOverride = {
            price: selectedService.paymentDetails.price,
            paymentType: selectedService.paymentDetails.paymentType
        }

        const isPayment = VenueGridService.shouldShowDollarSignInBookingInterval(paxCount, selectedService.paymentDetails);

        if (isPayment && selectedService.paymentDetails.options.length === 0) {
            dispatch(StateHelperService.getLatestPrice()); // Sets the price after making api call and getting price from backend
        } else if (!isPayment) {  // Set the payment type as "noPayment", when Pax criteria is not met.
            paymentDetails.price = 0;
            paymentDetails.paymentType = servicePaymentType.noPayment;
            dispatch(setPayment(paymentDetails));
        } else {
            dispatch(setPayment(paymentDetails)); // If none of the conditions match it will set the default price from the service
        }
    }, []);

    /**
     * When stand-by pill is clicked,
     * 1. Render the customer details form horizontally
     * 2. Do not render sitting panel
     */
    if (bookingTime?.isStandByListAvailable) {
        return (
            <div className={classes.customerDetailsForm}>
                <CustomerDetailsFormContainer/>
            </div>
        )
    }

    return (
        <Grid container justify="center">
            <Grid item xs={12} sm={6} xl={3} className={classes.topChildComponent}>
                <CustomerDetailsFormContainer/>
            </Grid>
            <Grid item xs={12} sm={6} xl={3} className={classes.topChildComponent}>
                <SittingPanelsContainer/>
            </Grid>
        </Grid>

    )
}

