import React, {useEffect} from 'react';
import {ITagsSelector} from './types';
import style from './style.module.scss';
import {
    Avatar,
    Chip,
    createStyles,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Theme,
    withStyles
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {RootState} from "app/main/rootReducer";
import {IBookingTag} from "shared-types/SharedTypes";


const NS = 'TagsSelector';

export default function TagsSelector({tags, updateTags, selectedTags}: ITagsSelector) {

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            formControl: {
                margin: theme.spacing(1),
                minWidth: 120,
                maxWidth: 300,
            },
            chips: {
                display: 'flex',
                flexWrap: 'wrap',
            },
            chip: {
                margin: 2,
            },
            noLabel: {
                marginTop: theme.spacing(3),
            },
        }),
    );

    const StyleAvatar = withStyles({
        root: {
            margin: '0 2px 0 0'
        }
    })(Avatar);

    const classes = useStyles();
    const [tagName, setTagName] = React.useState<number[]>([]);
    const {theme} = useSelector((state: RootState) => state.appInitReducer);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTagName(event.target.value as number[]);
        updateTags(getTagInfo(event.target.value as number[]))
    };

    useEffect(() => {
        if (selectedTags) {
            const selectedTagsIndex = selectedTags.map((tag) => {
                return tags.findIndex(t => t._id === tag._id)
            });
            setTagName(selectedTagsIndex)
        }
    }, [selectedTags])

    function getTagInfo(indexes: number[]): IBookingTag[] {
        return indexes.map(index => tags[index])
    }

    return (

        <div className={style.root}>
            <FormControl className={classes.formControl}>
                <InputLabel id="tags-mutiple-chip-label">Tags</InputLabel>
                <Select
                    labelId="tags-mutiple-chip-label"
                    id="tags-mutiple-chip"
                    multiple
                    value={tagName}
                    input={<Input id="select-tags-multiple-chip"/>}
                    onChange={handleChange}

                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {(selected as number[]).map((value) => {
                                    return (<Chip size='small' key={tags[value]._id} label={tags[value].name}
                                                  className={classes.chip}
                                                  avatar={<StyleAvatar alt="Natacha"
                                                                       src={`/images/tags/${tags[value].icon}.svg`}/>}
                                    />)
                                }
                            )}
                        </div>
                    )}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        variant: "menu"
                    }}
                >
                    {tags.map((tag, index) => (
                        <MenuItem key={tag._id} value={index}>
                            {tag.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>

    )
}