import React from 'react';
import {IMainHeader} from './types';
import style from './style.module.scss';
import classNames from "classnames";
import {createStyles, Link, Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import authenticationService from "app/services/authentication.service";
import config from "../../../_helpers/app.constants";
import {RootState} from "app/main/rootReducer";
import {useDispatch, useSelector} from "react-redux";
import {removeLoggedInUser} from 'app/reducers/authenticationSlice';
import moment from "moment";
import {ThemeColorsService} from "shared-services/theme-colors-service";
import {IWidgetTheme} from "shared-types/index";
import {VenueGridService} from "app/services/venueGrid.service";
import {renderIf} from "shared-services/react-utils-service";
import CountdownTimer from "app/components/CountdownTimer";
import {ROUTE_NAMES} from "app/models/route.types";
import {StateHelperService} from "app/services/helpers/stateHelper.service";
import appValues from "app/constants/appValues";

const NS = 'MainHeader';

const useStyles = makeStyles((theme: IWidgetTheme) => {
    const {secondary, textColor2} = ThemeColorsService.getCommonThemeStyles(theme);

    return createStyles({
        venueTitle: {
            color: textColor2
        },
        sessionBanner: {
            backgroundColor: secondary.main,
            color: theme.palette.getContrastText(secondary.main)
        }
    })
});

export default function MainHeader({}: IMainHeader) {

    const version = appValues.VERSION;

    const bookingTime = useSelector((state: RootState) => state.venueGridReducer.bookingTime);
    const count = useSelector((state: RootState) => state.paxCounterReducer.count);
    const viewDate = useSelector((state: RootState) => state.dayPickerReducer.viewDate);
    const theme = useSelector((state: RootState) => state.appInitReducer.theme);
    const activeVenue = useSelector((state: RootState) => state.appInitReducer.activeVenue);
    const accountId = useSelector((state: RootState) => state.appInitReducer.accountId);
    const account = useSelector((state: RootState) => state.appInitReducer.account);
    const redirectTo = useSelector((state: RootState) => state.routeReducer.redirectTo);
    const isManageBooking = useSelector((state: RootState) => state.manageBookingReducer.isManageBooking);

    const dispatch = useDispatch();

    const hideHeader = isManageBooking;
    const isLandscape = false;
    const isDarkAndLoading = false;
    const isStandard = false;
    const sessionRemainingTime = account.abcPaymentTimeoutInMinutes;
    const centerLogo = '';
    const logoUrl = '';
    const booking = {
        viewDate: '',
        covers: 10
    }

    const classes = useStyles({theme});

    function logOut() {
        authenticationService.logout();
        dispatch(removeLoggedInUser());
        const accountId = new URLSearchParams(window.location.search).get('accountid');
        window.location.href = `${config.redirectURL}?accountid=${accountId}`;
    }

    function timerExpired() {
        dispatch((StateHelperService.countdownTimerExpired()))
    }

    return (
        <header className={style.root}>
            <div className={classNames({
                [style.container]: true,
                [style.containerIsHidden]: hideHeader,
                [style.containerIsLandscape]: isLandscape,
                [style.containerHasCenteredLogo]: centerLogo
            })}>
                {logoUrl ?
                    <div className={classNames({
                        [style.logoWrap]: true,
                        [style.logoWrapIsHidden]: hideHeader,
                        [style.logoWrapIsLandscape]: isLandscape,
                        [style.logoWrapIsStandard]: isStandard
                    })}>
                        <img src={logoUrl} alt={'businessName'}/>
                    </div>
                    : ''
                }

                <Typography className={classNames({
                    [style.title]: true,
                    [style.titleIsHidden]: hideHeader,
                    [style.titleIsForcedLight]: isDarkAndLoading,
                    [style.titleIsLandscape]: isLandscape,
                    [style.titleIsLandscapeHasLogo]: !!logoUrl && isLandscape,
                    [style.titleIsStackedNoLogo]: !logoUrl && !isLandscape,
                })} variant="h6">
                    Booking {activeVenue && activeVenue.name ? 'at' : ''} <span
                    className={classNames({
                        [classes.venueTitle]: true,
                        [style.venueTitleIsForcedLight]: isDarkAndLoading
                    })}>
                    {/*{venue && venue.name ? venue.name : ''}*/}
                    {renderIf(activeVenue && activeVenue.name, () => (
                        <Link href="#" onClick={() => {
                            VenueGridService.goToDiary(activeVenue.id, accountId)
                        }}>{activeVenue.name}</Link>
                    ))}
                    </span>
                    {booking ?
                        <span>
                {logoUrl ? <br/> : ' '}
                            {booking.viewDate ?
                                <span>on {
                                    // showing a shorter version for mobile/stacked version
                                    isLandscape ? booking.viewDate : ''
                                }</span>
                                : ''
                            }
                            {count ?
                                <span> for {count} {count < 2 ? 'person' : 'people'}</span>
                                : ''
                            }
                            {viewDate ?
                                <span> on {viewDate},</span>
                                : ''
                            }
                            {bookingTime ? <span>{moment(bookingTime.time).format('HH:mm')}</span>
                                : ''
                            }
              </span>
                        : ''
                    }
                </Typography>
                {/*Logout commented out*/}
                {/*<div>*/}
                {/*    <ExitToAppIcon className={classNames({*/}
                {/*        [style.titleIsHidden]: hideHeader,*/}
                {/*        [style.logoutIcon]: true,*/}
                {/*    })} fontSize="default" titleAccess={`Logout`}*/}
                {/*                   color="inherit" onClick={() => {*/}
                {/*        logOut()*/}
                {/*    }}/>*/}
                {/*</div>*/}
                <div>
                    {renderIf(sessionRemainingTime && redirectTo === ROUTE_NAMES.PAYMENTS, () => (
                        <CountdownTimer initialMinute={sessionRemainingTime} theme={theme} timerExpired={timerExpired}/>
                    ))}
                </div>
            </div>
            <Typography className={style.version} variant={"h6"}>{version}</Typography>
        </header>
    )
}