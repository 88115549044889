import {combineReducers} from '@reduxjs/toolkit'
import paxCounterReducer from "app/reducers/paxCounterSlice";
import dayPickerReducer from "app/reducers/dayPickerSlice";
import appInitReducer from "app/reducers/appInitSlice";
import venueGridReducer from "app/reducers/venueGridSlice";
import authenticationReducer from "app/reducers/authenticationSlice";
import routeReducer from "app/reducers/routeSlice";
import groupWidgetReducer from "app/reducers/groupWidgetSlice";
import customerReducer from "app/reducers/customerSlice";
import bookingOptionReducer from "app/reducers/bookingOptionSlice";
import paymentReducer from "app/reducers/paymentSlice";
import tableReducer from "app/reducers/tableSlice";
import libPhoneReducer from "app/reducers/libPhoneSlice";
import bookingReducer from "app/reducers/bookingSlice";
import manageBookingReducer from "app/reducers/manageBookingSlice";

const rootReducer = combineReducers({
    paxCounterReducer,
    dayPickerReducer,
    appInitReducer,
    venueGridReducer,
    authenticationReducer,
    routeReducer,
    groupWidgetReducer,
    customerReducer,
    bookingOptionReducer,
    paymentReducer,
    tableReducer,
    libPhoneReducer,
    bookingReducer,
    manageBookingReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer