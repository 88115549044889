import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ClientService} from "app/services/client/client.service";
import {catchError, first, map} from "rxjs/operators";
import {of} from "rxjs";
import {bookingErrorType, IBookingResponseData, IOwnedVenue, loadStatus} from "shared-types/WidgetTypes";
import {IAccountDetails} from "app/models";
import {RootState} from "app/main/rootReducer";
import {BookingService} from "app/services/booking/booking.service";
import {IServicePaymentOption} from "shared-types/SharedTypes";
import {updateCachedMenuOptionDetails} from "app/reducers/bookingOptionSlice";
import {setSavedBooking} from './bookingSlice';
import {setActiveVenue} from './appInitSlice';

export interface IManageBookingOutgoingDetails {
    venueId: string;
    emailToken: string;
}

// Define a type for the slice state
interface ManageBookingSlice {
    status: loadStatus.loading | loadStatus.idle;
    manageBookingDetails: IBookingResponseData;
    manageBookingError: bookingErrorType;
    manageBookingUrlArgs: IManageBookingOutgoingDetails;
    isManageBooking: boolean;
    emailToken: string;
}

// Define the initial state using that type
const initialState: ManageBookingSlice = {
    status: loadStatus.idle,
    manageBookingDetails: {} as IBookingResponseData,
    manageBookingError: {} as bookingErrorType,
    manageBookingUrlArgs: {} as IManageBookingOutgoingDetails,
    isManageBooking: false,
    emailToken: ''
}

export const GetManageBookingDetails = createAsyncThunk(
    'test/GetManageBookingDetails',
    async (arg: IManageBookingOutgoingDetails, {dispatch, getState}) => {

        dispatch(setLoader(loadStatus.loading));
        return ClientService.getManageBookingDetails(arg.venueId, arg.emailToken)
            .pipe(
                first(),
                map((response: IBookingResponseData) => {
                    return {
                        successData: response
                    }
                }),
                catchError(err => {
                    console.log('err', err)
                    return of(
                        {
                            errorData: err.response.status === 500 ? bookingErrorType.badRequest : bookingErrorType.linkExpired
                        }); // if the token has expired, then the request will 404
                })
            ).toPromise().then((response: { successData?: IBookingResponseData, errorData?: bookingErrorType }) => {
                console.log('response', response)
                if (response.successData) {
                    const data = response.successData as IBookingResponseData;
                    const account: IAccountDetails = (getState() as RootState).appInitReducer.account;
                    const activeVenue: IOwnedVenue = BookingService.getVenueDetailsFromAccount(account, parseInt(arg.venueId));

                    dispatch(setActiveVenue(activeVenue));
                    dispatch(setSavedBooking(data));
                    // todo - save all this values in the bookingInfo object and retrieve data from there
                    // dispatch(setBookingTime(data.time));
                    // const viewDate: string = DateUtilsService.getViewDateFromMoment(moment(data.time))
                    // dispatch(dayUpdate(viewDate));

                    if (data.selectedOptions && data.selectedOptions.length) { // if it has booking options, we fetch their details so they can be displayed on the summary
                        const ids: string[] = BookingService.getBookingOptionIds(data.selectedOptions);
                        ClientService.getBookingOptions(ids.join(','), activeVenue.id)
                            .pipe(first())
                            .subscribe((response: IServicePaymentOption[]) => {
                                if (response) {
                                    dispatch(updateCachedMenuOptionDetails(response))
                                }
                            });
                    }

                }
                return response;
            });

    }
)

export const manageBookingSlice = createSlice({
    name: 'groupWidgetInit',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setLoader: (state, {payload}: PayloadAction<loadStatus.loading | loadStatus.idle>) => {
            state.status = payload;
        },
        setManageBookingURLArguments: (state, {payload}: PayloadAction<IManageBookingOutgoingDetails>) => {
            state.manageBookingUrlArgs = payload;
        },
        setEmailToken: (state, {payload}: PayloadAction<string>) => {
            state.emailToken = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(GetManageBookingDetails.pending, (state) => {
            // At that moment,
            // we change status to `loading`
            // and clear all the previous errors:
            state.status = loadStatus.loading;
        });

        // When a server responses with the data,
        // `GetSchedule.fulfilled` is fired:
        builder.addCase(GetManageBookingDetails.fulfilled,
            (state, action: PayloadAction<{ successData?: IBookingResponseData, errorData?: bookingErrorType }>) => {
                // We add the schedule into the state
                // and change `status` back to `idle`:
                if (action.payload.successData) {
                    state.manageBookingDetails = action.payload.successData;
                    state.status = loadStatus.idle;
                } else {
                    state.manageBookingError = action.payload.errorData
                    state.status = loadStatus.loading;
                }
                state.isManageBooking = true;

            });

        // When a server responses with an error:
        builder.addCase(GetManageBookingDetails.rejected,
            (state, {payload}) => {
                // We show the error message
                // and change `status` back to `idle` again.

                state.status = loadStatus.idle;
            });
    },
})

export const {setLoader, setManageBookingURLArguments, setEmailToken} = manageBookingSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default manageBookingSlice.reducer