interface IAppConstants {
  appId: string,
  loginUrl: string,
  backendApiUrl: string,
  redirectURL: string,
  diaryRedirectUrl: string,
  adminUrl: string
}

const local: IAppConstants = {
  appId: '5d991891-123e-45ba-9245-108bc0c7d98b',
  loginUrl: 'http://localhost:3000/login',
  backendApiUrl: 'http://localhost:52393/api',
  redirectURL: 'http://localhost:3006/',
  diaryRedirectUrl: 'http://localhost:3002/',
  adminUrl: 'http://localhost:3000/'
}

const mac: IAppConstants = {
  appId: '5d991891-123e-45ba-9245-108bc0c7d98b',
  loginUrl: 'http://localhost:3000/login',
  backendApiUrl: 'http://10.211.55.3:52393/api',
  redirectURL: 'http://localhost:3006/',
  diaryRedirectUrl: 'http://10.211.55.3:3002/',
  adminUrl: 'http://10.211.55.3:3000/'
}

const dev: IAppConstants = {
  appId: '5d991891-123e-45ba-9245-108bc0c7d98b',
  loginUrl: 'https://admin-dev.nowbookit.com/login',
  backendApiUrl: '/api',
  redirectURL: 'https://nbi-group-widget-dev.nowbookit.net/',
  diaryRedirectUrl: 'https://restaurant-dev.nowbookit.com/',
  adminUrl: 'https://admin-dev.nowbookit.com/'
};

const uat: IAppConstants = {
  appId: '5d991891-123e-45ba-9245-108bc0c7d98b',
  loginUrl: 'https://admin-uat.nowbookit.com/login',
  backendApiUrl: '/api',
  redirectURL: 'https://group-widget-uat.nowbookit.com/',
  diaryRedirectUrl: 'https://restaurant-uat.nowbookit.com/',
  adminUrl: 'https://admin-uat.nowbookit.com/'
};

const prod: IAppConstants = {
  appId: 'cffdd169-8c7e-465a-a1f6-3c49040489c4',
  loginUrl: 'https://admin.nowbookit.com/login',
  backendApiUrl: '/api',
  redirectURL: 'https://group-widget.nowbookit.com/',
  diaryRedirectUrl: 'https://restaurant.nowbookit.com/',
  adminUrl: 'https://admin.nowbookit.com/'
};


const getEnvConfig = (): IAppConstants => {
  switch (APP_ENV) {
    case 'dev':
      return dev;
    case 'prod':
      return prod;
    case 'uat':
      return uat;
    case 'mac':
      return mac;
    default:
      return local;
  }
};

const config = getEnvConfig();
export default {
  ...config
}