import React, {useEffect, useState} from 'react';
import style from './style.module.scss';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Paper} from '@material-ui/core';
import classNames from 'classnames';
import {getDynamicStyles} from "app/components/DayPicker/style.dynamic";
import DateUtilsService from "shared-services/date-utils-service/index";
import MomentUtils from "@date-io/moment";
import {useDispatch, useSelector} from "react-redux";
import {dayUpdate} from 'app/reducers/dayPickerSlice';
import moment, {Moment} from "moment-timezone";
import {RootState} from "app/main/rootReducer";

const NS = 'DayPicker';

export default function DayPicker() {

    const classes = getDynamicStyles(true);

    // timezone info is not relevant for selecting the date on the date picker, since that is handled on the back end
    const today: Moment = moment();

    const viewDate = useSelector((state: RootState) => state.dayPickerReducer.viewDate);

    const [date, setDate] = useState(moment(viewDate));

    const dispatch = useDispatch();

    const handleDateChange = (dateObj: any): void => {
        dateObj && setDate(dateObj);
    }
    const handleDateAccept = (dateObj: any): void => {
        const viewDate: string = DateUtilsService.getViewDateFromMoment(dateObj || moment())
        dispatch(dayUpdate(viewDate));
    }

    useEffect(()=>{
        setDate(moment(viewDate));
    }, [])

    return (
        <div>
            <Paper className={style.root
            } elevation={1}>

                <div className={style.calenderWrap}>
                    <div className={classNames({
                        [style.calender]: true,
                        [classes.calender]: true
                    })}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                value={date}
                                autoOk
                                disablePast
                                disableToolbar
                                orientation='landscape'
                                variant="inline"
                                openTo="date"
                                onChange={handleDateChange}
                                onAccept={handleDateAccept}
                                label={'Date'}
                                // onError={(err) => {
                                //   console.warn(NS, 'error', err);
                                // }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </Paper>
            {/*{renderIf(isDateFarInAdvance, (*/}
            {/*    <div className={style.errorMsg}>*/}
            {/*        <Typography component="span" variant="body2">*/}
            {/*            <ReactMarkdown source={tooFarInAdvanceMessage} renderers={{paragraph: 'p'}} escapeHtml={false}/>*/}
            {/*        </Typography>*/}
            {/*    </div>*/}
            {/*))}*/}
        </div>
    )
}
