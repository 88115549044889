import {RootState} from "app/main/rootReducer";
import {ROUTE_NAMES} from "app/models/route.types";
import {isEmpty} from "lodash-es";
import {IBookingMenuOption} from "app/services/booking/booking.types";
import {IOwnedVenue, IServicePaymentDetails, PricingTypes, servicePaymentType} from 'shared-types/index';
import {BookingService} from "app/services/booking/booking.service";
import {IScheduleServiceABC} from "app/models";

const NS = 'FooterService';

export default class FooterService {
//
    /**
     * Works out if next button should be enabled or not using states from store
     */

    // todo move this to helper service which has access to state
    static isNextEnabled(routeName: string, state: RootState): boolean {

        const bookingTime = state.venueGridReducer.bookingTime;
        const count = state.paxCounterReducer.count;

        switch (routeName) {
            case ROUTE_NAMES.DEFAULT:
                return !isEmpty(bookingTime) && count > 0;

            case ROUTE_NAMES.BOOKING:
                return FooterService.validateBookingPage(state);

            default:
                return false;
        }
    }

    static validateBookingPage(state: RootState) {

        const { selectedSchedule, bookingTime } = state.venueGridReducer;
        const isCustomerDetailsValid = state.customerReducer.isCustomerDetailsValid;

        // Validate only the customer details for stand-by booking
        if (bookingTime?.isStandByListAvailable) return isCustomerDetailsValid;

        const selectedService = state.groupWidgetReducer.selectedService;
        const paymentOverride = state.paymentReducer.paymentOverride;
        const paymentType = paymentOverride?.paymentType || servicePaymentType.noPayment;
        const count = state.paxCounterReducer.count;
        const selectedBookingOptions = state.bookingOptionReducer.selectedBookingOptions;
        const showBookingOptions = state.bookingOptionReducer.showBookingOptions;
        const isPaymentSet = paymentType == servicePaymentType.noPayment ? true : state.appInitReducer.isPaymentSet;
        const isValidPrice = paymentType && !isNaN(paymentOverride.price) &&
        ((paymentType == servicePaymentType.noPayment &&  paymentOverride.price == 0) ||
        (paymentType !== servicePaymentType.noPayment && paymentOverride.price >0));
        
        let isValid = !!(isCustomerDetailsValid && selectedService && isPaymentSet && isValidPrice);

        const hasMenuOptions: boolean = showBookingOptions && FooterService.showMenuOptions(selectedSchedule.venueDetails, selectedService, count);

        if (isValid && hasMenuOptions) {
            isValid = FooterService.isMenuOptionsValid(selectedService, count, selectedBookingOptions);
        }

        return isValid;

    }

    static showFooter(route: ROUTE_NAMES) {
        switch (route) {
            case ROUTE_NAMES.PAYMENTS:
            case ROUTE_NAMES.PAYMENT_COMPLETE:
            case ROUTE_NAMES.THANK_YOU:
            case ROUTE_NAMES.CANCEL:
            case ROUTE_NAMES.ERROR_PAGE:
                return false;
        }
        return true;
    }

    /**
     * Works out if next button should be enabled or not using states from store
     */
    static isPrevEnabled(routeName: string, state: RootState): boolean {

        switch (routeName) {
            case ROUTE_NAMES.DEFAULT:
                return false;

            case ROUTE_NAMES.BOOKING:
                return true;

            default:
                return true;
        }
    }

    static isMenuOptionsValid(activeService: IScheduleServiceABC, covers: number, selectedMenuOptions: IBookingMenuOption[]): boolean {
        const paymentDetails: IServicePaymentDetails = activeService ? activeService.paymentDetails : null;
        if (paymentDetails) {
            const selectedCovers: number = selectedMenuOptions ? selectedMenuOptions.reduce((a, o) => a + o.quantity, 0) : 0;

            if (paymentDetails.pricingType === PricingTypes.PerBooking) {
                return selectedCovers > 0; // checkbox only needs a single quantity
            }

            return (paymentDetails.singleMenuPerBooking)
                ? selectedCovers > 0 // if radio button, only needs a single quantity
                : selectedCovers === covers; // if spinners used, quantity needs to match covers
        }

        return false; // don't think this ever gets called
    }

    private static showMenuOptions(activeVenue: IOwnedVenue, activeService: IScheduleServiceABC, covers: number): boolean {
        if (!activeVenue) {
            return false;
        }
        // const maxPeoplePerBookingDefault = activeVenue.widgetSettings.maxPeoplePerBooking;
        const maxPeoplePerBookingDefault = 10;
        const paymentDetails: IServicePaymentDetails = activeService ? activeService.paymentDetails : null;
        return !!paymentDetails && BookingService.hasMenuOptions(activeService, covers, maxPeoplePerBookingDefault);
    }
}
