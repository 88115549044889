import React from 'react';
import {IVenueGridHeader} from './types';
import Grid from "@material-ui/core/Grid";
import {createStyles, Theme} from "@material-ui/core";
import moment from "moment/moment";
import Box from "@material-ui/core/Box";
import style from './style.module.scss';
import venueGridStyle from "app/components/VenueGridComponent/VenueGrid/style.module.scss";
import {CELL_WIDTH} from "app/models";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fixedColumnShift: {
            // marginLeft: `${CELL_WIDTH}px`
        },
        timeLineHeader: {
            backgroundColor: theme.palette.primary.main
        }
    }),
);
const NS = 'VenueGridHeader';

export default function VenueGridHeader({venueStartEndTimeArray}: IVenueGridHeader) {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid item className={venueGridStyle.venueCellSticky}>
                <Box style={{width: `${CELL_WIDTH}px`, boxShadow: 'none'}} className={classNames({
                    [style.headerCell]: true,
                    [style.gridHeader]: true,
                    [classes.timeLineHeader]: true
                })}>
                    <Box component="span" className={style.timeHeader}>Venue Name</Box>
                </Box>
            </Grid>
            <Box style={{display: 'inline-flex'}} className={classNames({
                [classes.fixedColumnShift]: true,
                [style.gridHeader]: true,
                [classes.timeLineHeader]: true
            })}>
                {venueStartEndTimeArray.map((time: string, index: number) => (
                    <Grid item key={index}>
                        <Box style={{width: `${CELL_WIDTH}px`}} className={style.headerCell}>
                            <Box component="span" className={style.timeHeader}>{moment(time).format('HH:mm')}</Box>
                        </Box>
                    </Grid>
                ))}
            </Box>
        </React.Fragment>
    )
}