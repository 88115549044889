
export interface IOwnProps {
  type: externalLinkType;
  label: string;
  useDullColor?: boolean;
  bgColor?: string;
}

export interface IProps {
  accountId: string;
  venueId: number;
  theme: string;
  accent: string;
  font: string;

  isDark?: boolean;
  textColor?: string; // mandatory only if useDullColor is false
}

export enum externalLinkType {
  preAuth = 'preAuth',
  termsAndCond = 'termsAndCond'
}