import React, {useEffect, useState} from 'react';
import { IMenuOptionsCheckbox } from './types';
import style from './style.module.scss';
import { IServicePaymentOption, IBookingMenuOption, IBookingMenuOptionExtras, IWidgetTheme } from 'shared-types/index';
import MenuOptionContainer from 'internal-components/MenuOption/container';
import BookingOptionsHelpers from "shared-components/booking-options-section/helpers";
import {menuOptionType} from "../MenuOption/types";


const NS = 'IMenuOptionsCheckbox';


function handleExtrasOnChange(quantity:number, extras: IBookingMenuOptionExtras) {

  if (extras) {
    const opts = extras.explicitChildMenuOptions;
    if (extras.isSameForAll) {
      if (opts && opts.length) {
        opts[0].forEach(o => o.quantity = quantity);
      }
    } else {
      if (quantity < opts.length) {
        // if quantity decreased, then we remove overspill on explicitChildMenuOptions
        opts.length = quantity;

      } else if (quantity > opts.length) {
        // if quantity increases, then needs empty slots added
        for (let i = opts.length; i < quantity; i++) {
          opts.push([]);
        }
      }
    }

  }
}

export default function MenuOptionsCheckbox({
  menuOptions, gridXs, gridMd, gridGutter, selectedMenuOptions,
  handleOpenExtras, handleCheckedUpdate, isUpsell
}: IMenuOptionsCheckbox) {

  const [combinedCovers, setCombinedCovers] = useState<IBookingMenuOption[]>([]);


  useEffect(() => {
    setCombinedCovers(menuOptions.map(({id}) => {
      const selectedOpt = !selectedMenuOptions ? null : selectedMenuOptions.find(o => o.menuOptionId === id);
      return {
        menuOptionId: id,
        quantity: selectedOpt ? selectedOpt.quantity : 0,
        extras: selectedOpt ? (selectedOpt.extras || null) : null,
        isUpsellItem: selectedOpt ? selectedOpt.isUpsellItem : false
      };
    }));
  }, [menuOptions]);


  let inputTO: any;

  const scrollGridItemIntoView = (itemEl: HTMLDivElement) => {
    if (itemEl.scrollIntoView) {
      if (inputTO) {
        clearTimeout(inputTO);
      }
      inputTO = setTimeout(() => {
        itemEl.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth'
        });
      }, 500);
    }
  }

  const useGrid = !!gridGutter;

  return (
    <>
      {(() => ( // Grid Outer wrapper
        BookingOptionsHelpers.muiGridOrCustomElementOuter(useGrid, "", style.box, gridGutter, (
          <>
            {menuOptions.map((opts: IServicePaymentOption, i: number) => {
              const {
                id, price, label, link, description, childMenuOptionIds,
                explicitChildMenuOptionIds, paymentType, paymentTypeOverride
              } = opts;
              const gridItemRef: React.RefObject<HTMLDivElement> = React.createRef();
              const gridSizes = {xs: gridXs, sm: false, md: gridMd, lg: false};
              const selectedOpt: IBookingMenuOption = combinedCovers.find(cc => cc.menuOptionId === id);
              const allChildMenuOptionIds: string[] = (childMenuOptionIds || []).concat(explicitChildMenuOptionIds || []);
              const hasChildMenuOptions: boolean = allChildMenuOptionIds.length > 0;
              const _handleOpenExtras = selectedOpt?.quantity && hasChildMenuOptions ? () => handleOpenExtras(i) : null;

              // Grid Inner Items
              return (
                selectedOpt && BookingOptionsHelpers.muiGridOrCustomElementInner(id, useGrid, gridSizes, "", style.boxItems, gridItemRef, (
                  <MenuOptionContainer
                    paymentType={paymentTypeOverride ? paymentTypeOverride.paymentType : paymentType}
                    paymentTypeError={paymentTypeOverride ? paymentTypeOverride.hasError : false}
                    id={id}
                    price={price}
                    label={label}
                    description={description}
                    link={link}
                    type={menuOptionType.checkboxes}
                    allChildMenuOptionIds={allChildMenuOptionIds}
                    isChecked={!!selectedOpt.quantity}
                    handleOpenExtras={_handleOpenExtras}
                    handleCheckboxChanged={(evt: any, checked: boolean): void => {

                      const quantity = checked ? 1 : 0;

                      const newValue = combinedCovers.map((item: IBookingMenuOption) => {
                        if (item.menuOptionId !== id) {
                          return item;
                        }

                        // scrolls a menu option into view so the whole thing can be seen
                        scrollGridItemIntoView(gridItemRef.current);

                        const { extras, menuOptionId } = item;
                        handleExtrasOnChange(quantity, checked ? extras : null);
                        return {menuOptionId, quantity, extras: checked ? extras : null, isUpsellItem: !!isUpsell};
                      });
                      setCombinedCovers(newValue);
                      handleCheckedUpdate(newValue);
                    }}
                  />
                ))
              )}
            )}
          </>
        ))
      ))()}

    </>
  )
}
