import React, {ChangeEvent, useEffect, useState} from 'react';
import style from './style.module.scss';
import { IHandlers, IProps, ITableSelectionListItem, ManualJoinType } from './types';
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem, MenuProps,
  Select, Tooltip, Typography,
  withStyles
} from '@material-ui/core';
import MuiTypography from '@material-ui/core/Typography';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import LinkIcon from '@material-ui/icons/Link';
import OnlySharedTablesIcon from '@material-ui/icons/PeopleAlt';
import WalkinIcon from "@material-ui/icons/DirectionsWalk";
import OnlineIcon from "@material-ui/icons/Public";
import ReactUtilsService, {renderIf} from "shared-services/react-utils-service";
import classNames from "classnames";
import {isEqual} from "lodash-es";
import {
  checkboxLabelClicks,
  getMaterialStyles,
  getOptionLabel,
  TableSeaterHelpers,
  getSectionName
} from "./helpers";
import {JOIN_PREFIX} from "shared-types/index";

const NS = 'TableSeater';

const theme = ReactUtilsService.getDiaryTheme(); // replace this

interface ITableDividerHeader extends ITableSelectionListItem {
  isDivider?: boolean;
}

export default function TableSeater({
  sectionsLookup, tableSelectionList, tableSelectionListWithJoins, preselectedTableIds,
  isWhiteTheme, useReversedColors, manualJoinType, label, showEmptyTableValidationMessage,
  handleSelectedChanged
}: IProps & IHandlers) {

  const [selectedTableIds, setSelectedTableIds] = useState<string[]>(preselectedTableIds || []);
  const [manualJoinTables, setManualJoinTables] = useState<boolean>(
    manualJoinType ? (manualJoinType === ManualJoinType.forceManualJoin) : false
  );
  const [moduleInited, setModuleInited] = useState<boolean>(false);
  const [listInited, setListInited] = useState<boolean>(false);
  const [selectInited, setSelectInited] = useState<boolean>(false);
  const [defaultList, setDefaultList] = useState<string[] | string>(manualJoinTables ? [] : '');

  const _tableSelectionList: ITableSelectionListItem[] = manualJoinTables ? tableSelectionList : tableSelectionListWithJoins;

  useEffect(() => {
    setSelectInited(false);

    // timeout allows list UI to refresh
    setTimeout(() => {

      if (_tableSelectionList?.length && preselectedTableIds?.length && !isEqual(selectedTableIds, preselectedTableIds)) {
        const idsExistsInList = preselectedTableIds.every(pId => _tableSelectionList.some(t => t._id === pId));

        setSelectedTableIds(idsExistsInList ? preselectedTableIds : []);
        if (idsExistsInList) {
          TableSeaterHelpers.setActiveMultiSelectTableIds(preselectedTableIds);
        } else {
          TableSeaterHelpers.setActiveMultiSelectTableIds(null);
        }
      }
      setSelectInited(true);

      if (_tableSelectionList?.length) {
        setModuleInited(true); // module now initialized and ready for updates
      }
    }, 0);
  }, [_tableSelectionList]);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {

    // ignore if divider is clicked
    if ((event.nativeEvent.target as HTMLDivElement).hasAttribute('data-divider')) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    const ids: string[] = checkboxLabelClicks(event, manualJoinTables);

    // manual joins need performance workaround (mainly on long lists on ipad)
    if (manualJoinTables) {
      TableSeaterHelpers.setActiveMultiSelectTableIds(ids);
    } else {
      setSelectedTableIds(ids);
      handleSelectedChanged(ids, tableSelectionListWithJoins, manualJoinTables);
    }
  };

  const handleClose = (event: ChangeEvent<{}>) => {

    // ignore if divider is clicked
    if ((event.nativeEvent.target as HTMLDivElement).hasAttribute('data-divider')) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    // single select doesn't need to do anything here, as all handled in handleChange
    const ids: string[] = TableSeaterHelpers.getActiveMultiSelectTableIds();
    if (manualJoinTables && ids) {
      setListInited(false);
      setSelectedTableIds(ids);
      handleSelectedChanged(ids, tableSelectionListWithJoins, manualJoinTables);
      TableSeaterHelpers.setActiveMultiSelectTableIds(null);

      // timeout allows list UI to refresh
      setTimeout(() => {
        setListInited(true);
      }, 0);
    }

  }

  const useStyles = getMaterialStyles(useReversedColors, isWhiteTheme);
  const classes = useStyles();


  let prevSection: string;
  _tableSelectionList.forEach(item => {
    const sectionId = item.sectionId || item.tablesList?.length && item.tablesList[0].sectionId;
    item.isAltBg = prevSection && sectionId !== prevSection;
    prevSection = sectionId;
  });

  const ITEM_HEIGHT = 48;
  const PADDING = 8;
  const ITEM_QTY = 20;
  const MenuProps: Partial<MenuProps> = {
    anchorOrigin: {
      vertical: "center",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "center",
      horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: window.innerHeight > 1000 ? (ITEM_HEIGHT * ITEM_QTY) + PADDING : 'calc(100vh - 115px)',
        minWidth: 200, // extra width to allow for scroll bars
        maxWidth: 400,
        paddingRight: _tableSelectionList.length > ITEM_QTY ? 10 : 0
      }
    },
    MenuListProps: {
      style: {
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  };

  let labelMarginBot = 4;
  let hasValidTableSelection = false;
  if (sectionsLookup && selectedTableIds.length) {
    hasValidTableSelection = selectedTableIds.some(id => _tableSelectionList.some(t => t._id === id));
    if (hasValidTableSelection) {
      labelMarginBot = 1;
    }
  }


  useEffect(() => {

    // sets the defaultList (to be used as defaultValue on Select)
    const setDefaultSelectedList = () => {
      const validSelectedTableIds = selectedTableIds.filter(sId => _tableSelectionList.some(t => t._id === sId));
      const idsExistsInList = selectedTableIds.every(sId => _tableSelectionList.some(t => t._id === sId));

      const defList = validSelectedTableIds?.length
        ? (manualJoinTables ? validSelectedTableIds : [validSelectedTableIds[0]]) // just grabs first item if manual join is off
        : (manualJoinTables ? [] : '');

      if (!isEqual(defList, defaultList)) {
        setDefaultList(defList);
      }

      return defList;
    }

    if (!moduleInited) return; // makes sure default values don't get set before fully initialized

    if (!selectInited) {
      setDefaultSelectedList(); // don't need timeout if a timeout is already in progress
      setListInited(true);
      return;
    }

    setSelectInited(false);
    setListInited(false);

    // timeout allows list UI to refresh
    setTimeout(() => {
      const defList = setDefaultSelectedList();
      if (defList || !manualJoinTables) { // && !preselectedTableIds.length) {
        setSelectInited(true);
        setListInited(true);
      }
    }, 0);
  }, [
    manualJoinTables, selectedTableIds, moduleInited
  ]);

  const LabelText = withStyles({
    caption: {
      color: 'rgba(0,0,0,0.54)',
      lineHeight: 1,
      marginBottom: labelMarginBot,
      display: 'block'
    }
  })(MuiTypography);

  const tableListWithDividerHeaders: ITableDividerHeader[] = _tableSelectionList.reduce((acc, table, i) => {
    if (i === 0 || table.isAltBg) {
      acc.push({...table, isDivider: true});
    }
    acc.push(table);
    return acc;
  }, []);

  return (
    <div className={style.root}>

      {renderIf(label, () => (
        <LabelText variant="caption">
          {label}
        </LabelText>
      ))}

      <div className={style.row2}>

        {renderIf(moduleInited && _tableSelectionList && sectionsLookup, () => {
          return (
            <FormControl className={classes.selectFC}>

              {renderIf(!selectedTableIds.length, () => (
                <InputLabel
                  data-testid="select-label"
                  id="table-seater-checkbox-label"
                  className={classes.inputLabel}
                  disableAnimation={true}
                  shrink={false}
                >Select tables</InputLabel>
              ))}
              {renderIf(selectInited, () => (
                <Select
                  data-testid="select-root"
                  className={classes.select}
                  labelId="table-seater-checkbox-label"
                  id="table-seater-checkbox"
                  multiple={manualJoinTables}
                  defaultValue={defaultList}
                  onChange={handleChange}
                  onClose={handleClose}
                  MenuProps={MenuProps}
                    renderValue={() => {
                      const tables = _tableSelectionList.filter(t => selectedTableIds.some(sId => sId === t._id));
                      return tables.length
                        ? tables.map(table => (
                          <Chip
                            key={'chip-item-'+table._id}
                            label={getOptionLabel(sectionsLookup, table, true)}
                            variant="outlined"
                            className={classNames({
                              [classes.chip]: true,
                              [classes.isInvalidChip]: !table.free && !table.valid,
                              [classes.isFreeChip]: table.free && !table.valid,
                              [classes.isValidChip]: table.valid
                            })}
                          />
                        ))
                        : '';
                    }}
                >
                  {tableListWithDividerHeaders.map((table, i) => {
                    const itemId = 'menu-item-'+table._id;

                    return (
                      renderIf(table.isDivider, () => (
                        <MenuItem key={`${itemId}-divider`}
                          className={style.sectionHeaderWrap}
                          data-divider disabled={true} >
                          <p className={style.sectionHeader}>
                            {getSectionName(sectionsLookup, table)}
                          </p>
                        </MenuItem>
                      ), () => (
                        <MenuItem
                          key={itemId}
                          value={table._id}
                          data-testid={itemId}
                          className={classNames({
                            [classes.menuItem]: true,
                            [classes.isInvalidText]: !table.free && !table.valid,
                            [classes.isFreeText]: table.free && !table.valid,
                            [classes.isValidText]: table.valid
                          })}>
                          <div className={style.menuItemMid}>
                            {renderIf(manualJoinTables && listInited, () => {
                                const isChecked = selectedTableIds.some(id => id === table._id);
                                const checkboxId = 'menu-item-checkbox-'+table._id;
                                return (
                                  <FormControlLabel
                                    className={classes.checkboxMenuItemFC}
                                    label={getOptionLabel(sectionsLookup, table)}
                                    control={
                                      <Checkbox
                                        defaultChecked={isChecked}
                                      color='primary'
                                      className={classes.menuCheckbox}
                                      data-testid={checkboxId}
                                      data-e2e-id={checkboxId}
                                      data-e2e-value={isChecked}
                                    />
                                  } />
                                )
                              }, () => (
                              <div className={style.menuItemText}>
                                {getOptionLabel(sectionsLookup, table)}
                              </div>
                            ))}
                          </div>
                          <div className={style.menuItemIconWrap}>
                            {renderIf(table.online, () =>
                              <Tooltip title="Online" aria-label="Online" arrow>
                                <OnlineIcon className={style.onlineIcon} />
                              </Tooltip>
                            )}
                            {renderIf(table.walkinOnly, () =>
                              <Tooltip title="Walk-in only" aria-label="Walk-in only" arrow>
                                <WalkinIcon className={style.walkinIcon} />
                              </Tooltip>
                            )}
                            {renderIf(table.shared, () =>
                              <Tooltip title="Shared" aria-label="Shared" arrow>
                                <OnlySharedTablesIcon />
                              </Tooltip>
                            )}
                            {renderIf(table.valid, () => (
                              <>
                                {table._id.indexOf(JOIN_PREFIX) === 0 && (
                                  <Tooltip title="Table join" aria-label="Table join" arrow>
                                    <LinkIcon className={style.tableJoinIcon} data-testid="link-icon"/>
                                  </Tooltip>
                                )}
                                <Tooltip title="Valid" aria-label="Valid" arrow>
                                  <CheckCircleOutlineIcon data-testid="tick-icon" />
                                </Tooltip>
                              </>
                            ), () => (
                              renderIf(table.free, () => (
                                <Tooltip title="Problem" aria-label="Problem" arrow>
                                  <ErrorOutlineIcon />
                                </Tooltip>
                              ), () => (
                                <Tooltip title="Invalid" aria-label="Invalid" arrow>
                                  <NotInterestedIcon />
                                </Tooltip>
                              ))
                            ))}
                          </div>
                        </MenuItem>
                      ) // end menuItem else
                    )) // end loop render
                  })} {/* end loop */}
                  </Select>
              ))} {/* end selectInited */}
            </FormControl>
          )
        }, () => ( // end moduleInited
          <div className={style.noTables}>
            No tables
          </div>
        ))}

        {renderIf(!manualJoinType, () => (
          <FormControlLabel
            className={classes.checkboxFC}
            control={
              <Checkbox
                data-testid="manual-join-checkbox"
                className={classes.checkbox}
                color="default"
                size="medium"
                icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                checkedIcon={<CheckBoxIcon fontSize="large" />}
                name="checkedI"
                checked={manualJoinTables}
                onChange={(evt, checked) => {
                  const isManualJoin = checked;
                  let ids: string[] = selectedTableIds;

                  if (selectedTableIds.length && isManualJoin) {
                    // removes joins from selected ids list
                    ids = selectedTableIds.filter(id => id.indexOf(JOIN_PREFIX) !== 0);
                  }

                  if (selectedTableIds.length > 1 && !isManualJoin) {
                    const firstNonJoinTable = selectedTableIds.find(id => id.indexOf(JOIN_PREFIX) !== 0);
                    ids = firstNonJoinTable ? [firstNonJoinTable] : [];
                  }

                  setSelectInited(false);
                  // timeout allows list UI to refresh
                  setTimeout(() => {
                    setManualJoinTables(isManualJoin);
                    setSelectedTableIds(ids);
                    handleSelectedChanged(ids, isManualJoin ? tableSelectionList : tableSelectionListWithJoins, isManualJoin);
                    setSelectInited(true);
                  }, 300);
                }}
              />
            }
            label="Manual Join"
          />
        ))}
      </div>
      {renderIf(showEmptyTableValidationMessage && !selectedTableIds.length, () => (
        <Typography style={{ color: theme.palette.error.main }} className={style.tableValidationMsg}>
          No tables selected.
        </Typography>
      ))}
      <div className={style.footerKey}>
        <div className={classNames({
          [style.footerKeyRow]: true,
          [style.footerKeyRow1]: true,
        })}>
          <div className={style.footerKeyOnline}>
            <OnlineIcon />
          &nbsp;Online</div>
          <div className={style.footerKeyWalkin}>
            <WalkinIcon viewBox="0 0 12 24" />
          &nbsp;Walk-in&nbsp;Only</div>
          <div className={style.footerKeyShared}>
            <OnlySharedTablesIcon />
          &nbsp;Shared</div>
          <div className={style.footerKeyCovers}><span>C</span>&nbsp;Covers</div>
        </div>
      </div>
    </div>
  )
}

