import React, {ComponentType} from 'react';
import {IConfirmModalDialog} from './types';
import style from './style.module.scss';
import MuiDialog, {DialogProps} from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/styles";
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import {BreakPoints, wrapperStyleType} from "shared-types/index";
import UtilsService from "shared-services/utils-service";


const NS = 'ConfirmModalDialog';

export default function ConfirmModalDialog({wrapperStyle, doShow, modalMsg, handleConfirm, handleCancel, confirmText, cancelText, modalHeader}: IConfirmModalDialog) {

  const Dialog: ComponentType<DialogProps> = withStyles({
    paper: {
      minWidth: (() => {
        switch (wrapperStyle) {
          case wrapperStyleType.standard:
            return `${BreakPoints.STACKED_BP - 20}px`;

          case wrapperStyleType.wide:
            return `600px`;
        }

        return null;
      })()
    }
  })(MuiDialog);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{
        paper: style.paper,
      }}
      maxWidth="md"
      open={doShow}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        disableTypography
        id="alert-dialog-title"
        classes={{
          root: style.title
        }}>
        <Typography variant="h2">
          {modalHeader || 'Important information'}
        </Typography>
      </DialogTitle>

      <DialogContent
        classes={{
          root: style.content
        }}>
        <DialogContentText id="alert-dialog-description" component="div" className={style.markdownText}>
          <ReactMarkdown
            source={modalMsg}
            renderers={{
              link: UtilsService.reactMarkDownBlankTargets
            }}
            escapeHtml={false}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{
        root: style.btnWrap
      }}>
        <Button onClick={handleCancel} color="default" autoFocus variant="contained">
          {cancelText || 'Cancel'}
        </Button>

        <Button onClick={handleConfirm} color="primary" autoFocus variant="contained">
          {confirmText || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
