import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IBookingOutgoing} from "app/services/booking/booking.types";
import {
    IBookingResponseData, IPromoCodeResponseData,
    IWidgetBooking
} from "shared-types/index";
import BookingService from "shared-services/booking-service";
import {RootState} from "app/main/rootReducer";
import {ClientService} from "app/services/client/client.service";

// Define a type for the slice state
interface BookingSlice {
    savedBooking: IWidgetBooking;
    bookingSaveLoader: boolean;
    bookingOutgoing: IBookingOutgoing;
    bookingResponse: IBookingResponseData;
    bookingCancelled: {
        isCancelled: boolean;
        showBookAgainBtn: boolean;
    };
}

// Define the initial state using that type
const initialState: BookingSlice = {
    savedBooking: {} as IWidgetBooking,
    bookingSaveLoader: false,
    bookingOutgoing: {} as IBookingOutgoing,
    bookingResponse: {} as IBookingResponseData,
    bookingCancelled: {
        isCancelled: false,
        showBookAgainBtn: false
    }
}

export const confirmBooking = createAsyncThunk(
    'test/confirmBooking',
    async (payload, {dispatch, getState}): Promise<IBookingResponseData> => {
        const {manageBookingReducer, appInitReducer} = getState() as RootState;

        const emailToken = manageBookingReducer.emailToken;
        const activeVenue = appInitReducer.activeVenue;

        return ClientService.confirmBooking(emailToken, activeVenue.id).toPromise().then(data => data.data);
    }
);

export const cancelBooking = createAsyncThunk(
    'test/confirmBooking',
    async (payload, {dispatch, getState}): Promise<void> => {
        const {manageBookingReducer, appInitReducer} = getState() as RootState;

        const emailToken = manageBookingReducer.emailToken;
        const activeVenue = appInitReducer.activeVenue;

        return ClientService.cancelBooking(emailToken, activeVenue.id).toPromise();
    }
);

export const deleteBooking = createAsyncThunk(
    'test/deleteBooking',
    async (payload, {dispatch, getState}): Promise<void> => {
        const {bookingReducer, appInitReducer} = getState() as RootState;

        const bookingId = bookingReducer.savedBooking._id;
        const activeVenue = appInitReducer.activeVenue;

        return ClientService.deleteBooking(bookingId, activeVenue.id).toPromise();
    }
);

export const bookingSlice = createSlice({
    name: 'bookingSlice',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setSavedBooking: (state, action: PayloadAction<IBookingResponseData>) => {
            state.savedBooking = BookingService.getBookingObj(action.payload);
            state.bookingResponse = action.payload;
        },
        setBookingSaveLoader: (state, action: PayloadAction<boolean>) => {
            state.bookingSaveLoader = action.payload
        },
        setOutgoingBooking: (state, action: PayloadAction<IBookingOutgoing>) => {
            state.bookingOutgoing = action.payload;
        },
        setBookingCancelled: (state, action: PayloadAction<{ isCancelled: boolean, showBookAgainBtn: boolean }>) => {
            return {
                ...state,
                bookingCancelled: action.payload
            }
        },
        applyPromoCodePayment: (state, action: PayloadAction<IPromoCodeResponseData>) => {
            const savedBooking: IWidgetBooking = {
                ...state.savedBooking,
                payment: {
                    ...state.savedBooking.payment,
                    amountDue: action.payload.amountDue,
                    discountAmount: action.payload.discountAmount,
                    discountApplied: true
                }
            };
            return {
                ...state,
                savedBooking
            }
        }
    }
})

export const {setSavedBooking, setBookingSaveLoader, setOutgoingBooking} = bookingSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default bookingSlice.reducer
