import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IGroupWidgetSchedule, IVenueGridInfo} from "app/models";
import {ISchedule, IScheduleTime} from "shared-types/WidgetTypes";
import {VenueGridService} from "app/services/venueGrid.service";
import {setLoader} from './appInitSlice';
import {RootState} from "app/main/rootReducer";
import {first} from "rxjs/operators";
import {IStartEndTimePayload, ScheduleParams} from "app/components/VenueGridComponent/VenueGrid";
import {ITableSectionList} from "shared-types/SharedTypes";


// Define a type for the slice state
interface VenueGridSlice {
    schedules: IGroupWidgetSchedule[];
    venueStartEndTimeArray: string[];
    status: "loading" | "idle";
    loadedInitialData: boolean; //Indicates the schedule has been loaded
    error: string | null;
    bookingTime: IScheduleTime;
    venueTimes: IScheduleTime[];
    selectedSchedule: IGroupWidgetSchedule;
    isWalkIn: boolean;
    tableSectionData: ITableSectionList;
    sectionsLookup: any;
    selectedServiceIsEvent: boolean;
}

// Define the initial state using that type
const initialState: VenueGridSlice = {
    schedules: [],
    venueStartEndTimeArray: [],
    status: 'idle',
    loadedInitialData: false,
    error: null,
    bookingTime: {} as IScheduleTime,
    venueTimes: [],
    selectedSchedule: {} as IGroupWidgetSchedule,
    isWalkIn: false,
    tableSectionData: {
        tableSelectionList: [],
        tableSelectionListWithJoins: [],
        tableStatusLookUps: {}
    },
    sectionsLookup: {},
    selectedServiceIsEvent: false
}

export const GetSchedule = createAsyncThunk(
    'test/getSchedule',
    async (scheduleParams: ScheduleParams, {dispatch, getState}) => {
        const {appInitReducer} = getState() as RootState;
        const account = appInitReducer.account;

        // const response: any = await VenueGridService.getMockSchedule();
        // const updatedSchedule = VenueGridService.addVenueToSchedule(account.ownedVenues, response.data);
        const response2: ISchedule[] = await VenueGridService.getABCSchedule(scheduleParams.viewDate, scheduleParams.count, scheduleParams.accountId, scheduleParams.isWalkIn, scheduleParams.venueIds)
            .pipe(first()).toPromise();
        const updatedSchedule = VenueGridService.addVenueToSchedule(account.ownedVenues, response2);

        dispatch(setLoader('loading'));
        return updatedSchedule;
    }
)


export const venueGridSlice = createSlice({
    name: 'venueGrid',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        getStartAndEndTimes: (state, action: PayloadAction<IStartEndTimePayload>) => {
            const venueStartEndTimeArray = VenueGridService.getTimesBetweenStartAndEndTimes(action.payload.starDateTime, action.payload.endDateTime, action.payload.currentDate, 30, action.payload.biggestInterval);
            const loadedInitialData = true;
            return {
                ...state,
                venueStartEndTimeArray,
                loadedInitialData
            }
        },
        saveSelectedGridInfo: (state, action: PayloadAction<IVenueGridInfo>) => {
            return {
                ...state,
                bookingTime: action.payload.bookingTime,
                selectedSchedule: action.payload.selectedSchedule,

            }
        },
        toggleWalkIns: (state, action: PayloadAction<boolean>) => {
            const isWalkIn = action.payload;
            return {
                ...state,
                isWalkIn
            }
        },
        setTablePickerData: (state: VenueGridSlice, action: PayloadAction<{
            data: ITableSectionList,
            sectionsLookup: any,
            isEvent: boolean
        }>): VenueGridSlice => {
            return {
                ...state,
                tableSectionData: action.payload.data,
                sectionsLookup: action.payload.sectionsLookup,
                selectedServiceIsEvent: action.payload.isEvent,
            }
        }
    },
    // In `extraReducers` we declare
    // all the actions:
    extraReducers: (builder) => {
        // const dispatch = useDispatch();
        // When we send a request,
        // `GetSchedule.pending` is being fired:
        builder.addCase(GetSchedule.pending, (state) => {
            // At that moment,
            // we change status to `loading`
            // and clear all the previous errors:
            const status = "loading";
            return {
                ...state,
                status
            }
            // dispatch(setLoader(true));
        });

        // When a server responses with the data,
        // `GetSchedule.fulfilled` is fired:
        builder.addCase(GetSchedule.fulfilled,
            (state, action: PayloadAction<any>) => {
                // We add the schedule into the state
                // and change `status` back to `idle`:
                const schedules = action.payload;
                const status = "idle";
                return {
                    ...state,
                    schedules,
                    status
                }
            });

        // When a server responses with an error:
        builder.addCase(GetSchedule.rejected,
            (state, {payload}) => {
                // We show the error message
                // and change `status` back to `idle` again.
                if (payload) state.error = 'Error';
                const status = "idle";
                return {
                    ...state,
                    status
                }
            });
    },
})

export const {getStartAndEndTimes, saveSelectedGridInfo, toggleWalkIns, setTablePickerData} = venueGridSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default venueGridSlice.reducer