import {RootState} from "app/main/rootReducer";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect, ConnectedComponent} from "react-redux";

import PaymentMessage from "shared-components/payment-message/index";
import {IProps} from "shared-components/payment-message/types";
import {IHandlers, IOwnProps} from "./types";
import {IOwnedVenue, IBookingPayment, IServicePaymentDetails} from "shared-types/index";
import BookingService from "shared-services/booking-service";

const NS = 'PaymentMessageContainer';

const mapStateToProps = (all: RootState, ownProps: IOwnProps): IProps => {
    const { bookingReducer, appInitReducer } = all;
    const {payment, selectedMenuOptions, isFromDiary, covers} = bookingReducer.savedBooking || BookingService.getBookingObj(bookingReducer.bookingResponse);

    const activeVenue = appInitReducer.activeVenue;

    return {
        hasMenuOptions: selectedMenuOptions?.length > 0,
        paymentType: payment?.paymentType || null,
        price: payment?.amountDue || 0,
        currency: activeVenue ? (activeVenue as IOwnedVenue).currency : null,
        covers,
        dividePriceByCovers: !ownProps.useActiveService,
        isPricePerPerson: !isFromDiary
    };
};

/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IHandlers => {
    return {

    }
};

const PaymentMessageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentMessage as any);

export default PaymentMessageContainer as ConnectedComponent<any, any>;
