import React from 'react';
import style from './style.module.scss';
import { noCase } from 'change-case';
import { IProps, IHandlers } from './types';
import { Typography, Table, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
import SimplePage from 'shared-components/simple-page/index';
import { servicePaymentType } from 'shared-types/index';
import { renderIf } from 'shared-services/react-utils-service/index';
import { IntlService } from 'shared-services/intl-service/index';

const NS = 'PaymentComplete';


export default function PaymentComplete({ theme, payment, currency, message, tableData, abcStaffFacing, handleRebook }: IProps & IHandlers) {

    console.log(NS, 'currency', currency);
    console.log(NS, 'payment', payment);

  const isPreauth = payment.paymentType === servicePaymentType.preAuth;
  const preAuthMessage = abcStaffFacing ? ` Your customer's credit card Booking Guarantee was successful.` : `Your credit card Booking Guarantee was successful.`
  const paymentMessage = `Your ${abcStaffFacing? "customer's":""} ${noCase(payment.paymentType)} was successful. ${payment.amountPaid ? IntlService.currencyValue(payment.amountPaid, currency): ""}`;
  return (
    <SimplePage 
      theme={theme}
      title={`${isPreauth ? 'Booking Guarantee' : 'Payment'} Confirmation`}
      body={message}
      bodyIsMarkDown={true}
      buttonText={abcStaffFacing ? "Make Another Booking" : ""}
      buttonCallback={abcStaffFacing ? handleRebook : undefined}
      >
      
      {renderIf(isPreauth, (
        <Typography variant="body1">
          {preAuthMessage}
        </Typography>
      ), (
        <Typography variant="body1">
          {paymentMessage}
        </Typography>
      ))}

      <div className={style.tableWrap}>
        <Table aria-label="summary" size="small" >
          <TableBody>
            {tableData.items.map((item, j) => !item.value ? null : (
              <TableRow key={`payment-conf-item-${j}`}>
                <TableCell>{item.name}</TableCell>
                <TableCell >{item.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      
    </SimplePage>
  )
}

