import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ROUTE_NAMES} from "app/models/route.types";
import LocationService from "shared-services/location-service";

const NS = 'RouteSlice';

// Define a type for the slice state
interface RouteSlice {
    redirectTo: ROUTE_NAMES,
    isScheduleLoaded: boolean,
    routeList: ROUTE_NAMES[]
}

// Define the initial state using that type
const initialState: RouteSlice = {
    redirectTo: ROUTE_NAMES.DEFAULT,
    isScheduleLoaded: false,
    routeList: [
        ROUTE_NAMES.DEFAULT,
        ROUTE_NAMES.BOOKING,
        ROUTE_NAMES.THANK_YOU
    ]
}

export const routeSlice = createSlice({
    name: 'route',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        goToRoute: (state, action: PayloadAction<ROUTE_NAMES>) => {

            // console.log('routeList', [...state.routeList], action.payload)

            return {
                ...state,
                redirectTo: action.payload
            }
            // state.redirectTo = action.payload;
        },
        checkForLoadedSchedule: (state, action: PayloadAction<boolean>) => {
            state.isScheduleLoaded = action.payload;
        },
        addPaymentRoutes: (state, action: PayloadAction<boolean>) => {
            const routeList = state.routeList;
            const addPaymentRoutes = action.payload;
            if (addPaymentRoutes) {
                attachPaymentRoutes(routeList);
            } else {
                detachPaymentRoutes(routeList);
            }
            return state;
        },
        addBookingCancelledRoute: (state): RouteSlice => {
            const routeList = insertNextRoute(state.routeList, ROUTE_NAMES.CANCEL, state.redirectTo);
            return {...state, routeList};
        },
        addBookingErrorRoute: (state): RouteSlice => {
            const routeList = insertNextRoute(state.routeList, ROUTE_NAMES.ERROR_PAGE, state.redirectTo);
            return {...state, routeList};
        },
        updateRouteListForManageBooking: (state): RouteSlice => {
            const routeList = updateForManageBooking(state.routeList, ROUTE_NAMES.BOOKING, state.redirectTo);
            return {...state, routeList};
        },
        reload: (state, action: PayloadAction<boolean>): RouteSlice => {
            const clearParams = action.payload;
            if (clearParams) {
                // strips out token and edit from url so it loads page in non-edit (standard) mode
                location.assign(LocationService.stripCurrentUrl(location.href, true, true));
            } else {
                location.assign(location.href);
            }
            return state;
        }
    }
});

/**
 * Removes all routes after current one and inserts a new one
 */
function insertNextRoute(routeList: ROUTE_NAMES[], targetRoute: ROUTE_NAMES, currentRoute: ROUTE_NAMES): ROUTE_NAMES[] {
    const list = [...routeList];
    const targetIndex = list.indexOf(targetRoute);
    // first remove it
    if (targetIndex !== -1) {
        list.splice(targetIndex, 1);
    }
    // then reapply to ensure all rules are consistent
    const currentIndex = list.indexOf(currentRoute);
    const deleteCount = list.length - currentIndex - 1;
    list.splice(currentIndex + 1, deleteCount, targetRoute);

    return list;
}

function updateForManageBooking(routeList: ROUTE_NAMES[], targetRoute: ROUTE_NAMES, currentRoute: ROUTE_NAMES): ROUTE_NAMES[] {
    const list = [...routeList];
    const targetIndex = list.indexOf(targetRoute);
    // first remove it
    if (targetIndex !== -1) {
        list.splice(targetIndex, 1);
    }
    const currentIndex = list.indexOf(currentRoute);
    list.splice(currentIndex + 1, 0, ROUTE_NAMES.MANAGE_BOOKING);
    return list
}


function attachPaymentRoutes(routeList: ROUTE_NAMES[]): void {
    const manageBookingIndex = routeList.indexOf(ROUTE_NAMES.MANAGE_BOOKING);
    const bookingIndex = routeList.indexOf(ROUTE_NAMES.BOOKING);

    const index = manageBookingIndex > 0 ? manageBookingIndex : bookingIndex; // Choose ManageBooking if it exist - cause then its manage booking screen where booking screen doesnt exists

    const paymentsIndex = routeList.indexOf(ROUTE_NAMES.PAYMENTS);
    if (paymentsIndex === -1) {
        routeList.splice(index + 1, 0, ROUTE_NAMES.PAYMENTS);
    }

    const paymentCompleteIndex = routeList.indexOf(ROUTE_NAMES.PAYMENT_COMPLETE);
    if (paymentCompleteIndex === -1) {
        const paymentsIndex = routeList.indexOf(ROUTE_NAMES.PAYMENTS);
        routeList.splice(paymentsIndex + 1, 0, ROUTE_NAMES.PAYMENT_COMPLETE);
    }
}


function detachPaymentRoutes(routeList: ROUTE_NAMES[]): void {
    const paymentCompleteIndex = routeList.indexOf(ROUTE_NAMES.PAYMENT_COMPLETE);
    if (paymentCompleteIndex !== -1) {
        routeList.splice(paymentCompleteIndex, 1);
    }
    const paymentsIndex = routeList.indexOf(ROUTE_NAMES.PAYMENTS);
    if (paymentsIndex !== -1) {
        routeList.splice(paymentsIndex, 1);
    }
}

export const {goToRoute, checkForLoadedSchedule, addPaymentRoutes, updateRouteListForManageBooking} = routeSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default routeSlice.reducer