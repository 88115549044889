import config from './app.constants';
import {Route, RouteProps} from "react-router-dom";
import React from "react";
import authenticationService from "app/services/authentication.service";

export interface PrivateRouteProps extends RouteProps {
    component: any;
    restricted: boolean;
}

const PrivateRoute = (props: PrivateRouteProps): any => {
    let {component, restricted, ...rest} = props;
    const Component = component;
    return (
        <Route exact {...rest} render={props => {
            if (restricted) {
                authenticationService.login();
                if (localStorage.getItem('token') !== null) {
                    let user = null;
                    if (localStorage.getItem('account')) {
                        user = JSON.parse(localStorage.getItem('account'));
                    } else {
                        user = authenticationService.getAccountInfo();
                    }
                    return <Component user={user} {...props} />
                } else {
                    authenticationService.logout();
                }
                
                const accountId = new URLSearchParams(window.location.search).get('accountid');
                var redirectUrl =encodeURIComponent(`${config.redirectURL}?accountid=${accountId}`);
                window.location.href = `${config.loginUrl}?appId=${config.appId}&redirectURL=${redirectUrl}`;
                return null;
            } else {
                return <Component user={{}} {...props} />
            }

        }}
        />
    );
}

export default PrivateRoute