import {BreakPoints, IDetectPhone} from "shared-types/index";

const NS = 'LocationService';

export default class LocationService {


    static isSSH(url: string = window.location.href): boolean {
        return url.indexOf('https') === 0;
    }

    /**
     * Detects if the outer iframe container (native HTML page) is using a phone, by
     * checking the window's outerWidth. Also checks for iPhone if you need iPhone specifically,
     * which works on XCode iPhone simulator as well.
     */
    static detectPhone(): IDetectPhone {
        return {
            isPhone: window.outerWidth <= BreakPoints.STACKED_BP,
            isIPhone: (/iPhone/.test(navigator.platform)),
        };
    }


    /**
     * RFC4122 compliant GUID generator for JS that is compact and "good enough" for what we want.
     * Should only get called once per session/app load.
     * Used for better SEQ logging.
     */
    static uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    /**
     * Accepts a url and removes either token param and/or edit query string
     */
    static stripCurrentUrl(url: string, removeToken: boolean, removeEdit: boolean): string {

        if (removeToken) {
            const splitUrl: string[] = url.split('&token=');
            url = splitUrl[0];

            if (splitUrl[1] && splitUrl[1].indexOf('&') !== -1) {
                const trailingParams = splitUrl[1].split('&')[1];
                url += '&' + trailingParams;
            }
        }

        if (removeEdit) {
            url = url.replace('edit?', '?');
        }

        return url;
    }
}