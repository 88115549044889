import {connect, ConnectedComponent} from 'react-redux';
import BookingCancelled from './index';
import { AnyAction } from 'redux';
import { IProps, IHandlers } from './types';
import { ThunkDispatch } from 'redux-thunk';
import {RootState} from "app/main/rootReducer";
import {routeSlice} from "app/reducers/routeSlice";

const NS = 'BookingCancelledContainer';

const mapStateToProps = (all: RootState): IProps => {
  const {
    appInitReducer, bookingReducer
  } = all;

  const activeVenue = appInitReducer.activeVenue;

  return {
    theme: appInitReducer.theme,
    venuePhone: activeVenue.phone || null,
    showBookAgainBtn: bookingReducer.bookingCancelled.showBookAgainBtn
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IHandlers => {
  return {
    handleRebook: () => {
      dispatch(routeSlice.actions.reload(true));
    }
  }
};

const BookingCancelledContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingCancelled as any);

export default BookingCancelledContainer as ConnectedComponent<any, any>;
