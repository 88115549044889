import {connect} from 'react-redux';
import SittingPanels from './index';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {RootState} from "app/main/rootReducer";
import {IScheduleServiceABC, ISectionOrder} from "app/models";
import {IScheduleTime, IScheduleService, IServicePaymentDetails, servicePaymentType} from 'shared-types/index';
import {isEqual} from "lodash-es";
import {BookingService} from "app/services/booking/booking.service";
import {changeActiveSection, changeActiveService} from 'app/reducers/groupWidgetSlice';
import {setBookingOptions, toggleBookingOption} from 'app/reducers/bookingOptionSlice';
import FooterService from "app/services/footer/footer.service";
import {addPaymentRoutes} from "app/reducers/routeSlice";
import {IPaymentOverride} from "app/services/booking/booking.types";
import {setIsPaymentOverride, setPayment} from 'app/reducers/paymentSlice';

const NS = 'SittingPanelsContainer';

const mapStateToProps = (state: RootState): IStateFromProps => {

    const bookingTime = state.venueGridReducer.bookingTime;
    const selectedService = state.groupWidgetReducer.selectedService
    const selectedSection = state.groupWidgetReducer.selectedSection
    const count = state.paxCounterReducer.count;
    const theme = state.appInitReducer.theme;
    const showBookingOptions = state.bookingOptionReducer.showBookingOptions
    const selectedBookingOptions = state.bookingOptionReducer.selectedBookingOptions
    const selectedTableIds = state.tableReducer.selectedTableIds;
    const tableList = state.tableReducer.tableList;
    const payment = state.paymentReducer.paymentOverride;
    const isPaymentSet = state.appInitReducer.isPaymentSet;

    const selectedTables = tableList.filter(({_id}) => selectedTableIds.includes(_id));
    //
    // const canCustomersChooseSection = activeVenue ? activeVenue.widgetSettings.canCustomersChooseSection : false;
    //
    // const maxPeoplePerBooking = BookingService.getMaxPeoplePerBooking(activeVenue as IVenue, activeService);
    //
    // // this is the setting from an admin/account level (not including the booking options 'override')
    const maxPeoplePerBookingDefault = 10;
    //
    // const isVenueOpen: boolean = selectedSchedule && selectedSchedule.isVenueOpen;
    // // const scheduleErrorMsg: string = schedule.blockoutMessage ? schedule.blockoutMessage : appMessages.getMessageText('dayClosedMessage', activeVenue);
    // const filteredSchedule: ISchedule = isVenueOpen ? BookingService.removeOfflineServices(selectedSchedule) : null;
    const covers: number = count ? count : 0;
    //
    // // filters out services that all times have expired in
    // const unfilteredServices = BookingService.getAvailableServicesFromSchedule(filteredSchedule, false);
    // const filteredServices = BookingService.getAvailableServicesFromSchedule(filteredSchedule);
    //
    const paymentDetails: IServicePaymentDetails = selectedService.paymentDetails;
    const showMenuOptions: boolean = paymentDetails && BookingService.hasMenuOptions(selectedService, covers, maxPeoplePerBookingDefault);
    const isBookingOptionsValid = showBookingOptions && showMenuOptions && FooterService.isMenuOptionsValid(selectedService, count, selectedBookingOptions);
    //
    // const isPreviewMode: boolean = appSettings.mode === modeType.preview;
    //
    //
    // // To check if any tables are available. If not then hide Additional Options Panel.
    // const percentUnavailable: number = filteredTimes ? (filteredTimes.filter(t => t.isDisabled && !t.expired).length / filteredTimes.length) * 100 : 0;
    // const noTablesAvailable: boolean = filteredTimes && filteredTimes.some(t => t.isDisabled && !t.expired) && percentUnavailable >= 100;
    //
    // let coversAlertMessage: string = null;
    // let serviceAlertMessage: string = null;
    // let sectionAlertMessage: string = null;
    // let hasTimesAlertMessage = false;
    // let hasMenuOptionAlert = false;
    // if (triedNext === ROUTE_NAMES.SITTING) {
    //   const item = FooterService.validateSittingPage(booking, activeService, activeVenue, filteredSections, standbyData);
    //
    //   coversAlertMessage = FooterService.getAlertMessage(item, ValidateSittingType.Covers);
    //   if (!item.isValid && item.items.find(o => o.type === ValidateSittingType.MenuOptions)) {
    //     hasMenuOptionAlert = true;
    //   }
    //
    //   sectionAlertMessage = FooterService.getAlertMessage(item, ValidateSittingType.Section);
    //   serviceAlertMessage = FooterService.getAlertMessage(item, ValidateSittingType.Service);
    //   hasTimesAlertMessage = !!FooterService.getAlertMessage(item, ValidateSittingType.Time);
    // }
    //
    // if (timeError) {
    //   hasTimesAlertMessage = true;
    // }

    // const filteredServicesBasedOnTime = getServicesBasedOnTime(selectedSchedule.services, bookingTime);

    return {
        theme,
        maxPeoplePerBooking: 10,
        scheduleMessage: '',
        filteredServices: [selectedService],
        scheduleLoading: false,
        activeService: selectedService,
        filteredSections: selectedService.sections,
        hasMoreThan1Section: true,
        showMenuOptions: showMenuOptions,
        activeSectionId: selectedSection ? selectedSection.id : selectedService.sections[0].id,
        getMaxBreachMessage: (maxPeoplePerBooking: number) => {
            // needs to be a function because the maximum can change depending on the service settings
            // return MessageService.get('maximumPeopleMessage', {
            //   ...activeVenue,
            //   maxPeoplePerBooking
            // });
            return `Max Breached`
        },
        canCustomersChooseSection: true,
        coversPrefill: count,
        isPreviewMode: false,
        viewTime: bookingTime.time,
        coversAlertMessage: '',
        sectionAlertMessage: '',
        serviceAlertMessage: '',
        hasTimesAlertMessage: false,
        hasMenuOptionAlert: false,
        isStandbyMode: false,
        noTablesAvailable: false,
        showBookingOptions,
        isBookingOptionsValid,
        selectedTables,
        payment,
        isPaymentSet
    };
};

/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
    return {
        handleCoversCountChange: (coversCount: number) => {
            // dispatch(BookingActionsNS.changedCoversCount(coversCount));
        },
        handleServiceChange: (id: string, filteredServices: IScheduleServiceABC[]): Promise<IScheduleService> => {
            // return dispatch(BookingActionsNS.changedActiveService(id));
            const service = BookingService.getServiceFromId(filteredServices, id);
            dispatch(changeActiveService(service));
            return new Promise<IScheduleService>(null);
        },
        handleSectionChange: (section: ISectionOrder) => {
            // dispatch(BookingActionsNS.changedActiveSection(id));
            dispatch(changeActiveSection(section));
        },
        handleCoversCountPending: () => {
            // dispatch(NavigationActionsNS.coversPending());
        },
        handleChange: (showBookingOption) => {
            dispatch(toggleBookingOption(showBookingOption))
            if (!showBookingOption) {
                dispatch(addPaymentRoutes(false));
                const paymentDetails: IPaymentOverride = {
                    paymentType: servicePaymentType.noPayment,
                    price: 0
                }
                dispatch(setIsPaymentOverride(true)); // Set payment override as true if BO toggled on - No Payment
                dispatch(setPayment(paymentDetails));
                dispatch(setBookingOptions({bookingOptions: [], singleMenuPerBooking: false}));
            } else {
                dispatch(setIsPaymentOverride(false)); // Set payment override as false if BO toggled off
            }
        }
    }
};

function getServicesBasedOnTime(services: IScheduleService[], bookingTime: IScheduleTime) {
    return services.filter((service) => {
        const servVal = service.times.map((time) => {
            return isEqual(time, bookingTime)
        });
        return servVal.filter(Boolean).length > 0;
    });
}

const SittingPanelsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SittingPanels as any);

export default SittingPanelsContainer;
