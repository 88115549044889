import {cloneDeep} from 'lodash';
import {
    IBookingMenuOption,
    IBookingMenuOptionExtras,
    ISavedBookingMenuOption,
    ISavedExtrasOption
} from "shared-types/SharedTypes";

const NS = 'MenuOptionsService';

export class MenuOptionsService {
    /**
     * Transforms menu options into a flat list, extracting child items from `extras` and
     * pushing them into an flat array property for backend to ingest.
     * Expects list of `options` matching `bookingService.booking.selectedMenuOptions` schema.
     */

    static getFlatExtras(options: IBookingMenuOption[]): ISavedBookingMenuOption[] {

        const menuOptionsWithFlatExtras: ISavedBookingMenuOption[] = options.reduce((acc1, originalOption: IBookingMenuOption) => {

            /**
             * here we merge `option.extras` data into a single `extras` property for back end to ingest
             **/
            const copiedOption: ISavedBookingMenuOption = {
                menuOptionId: originalOption.menuOptionId,
                quantity: originalOption.quantity
            };

            if (originalOption.extras) {
                const extras = cloneDeep(originalOption.extras);
                const isSameForAll = extras.isSameForAll;

                if (!isSameForAll) {
                    copiedOption.quantity = 1;
                }

                let explicitChildMenuOptions: ISavedExtrasOption[] = [];
                if (extras.explicitChildMenuOptions) {
                    explicitChildMenuOptions = extras.explicitChildMenuOptions
                        .reduce((acc2: ISavedExtrasOption[], _optionsList: IBookingMenuOption[], i: number) => {

                            const optionsList: ISavedExtrasOption[] = _optionsList.map(({menuOptionId, quantity}) => {
                                return { menuOptionId, quantity, isExplicit: true }
                            });

                            if (i === 0 || isSameForAll) {
                                /**
                                 * If same for all, then the quantity is defined on the parent and all should be 1.
                                 * Note: if checkboxes become spinners (at some point) this will have to change to have
                                 * custom quantities.
                                 */
                                if (isSameForAll) {
                                    optionsList.forEach((o) => {
                                        o.quantity = 1;
                                    });
                                }
                                acc2 = acc2.concat(optionsList);
                            } else {
                                /**
                                 * Here we clone the parent option for each extra quantity and pass the individual extras to it
                                 */
                                const copiedParentOption: ISavedBookingMenuOption = {
                                    menuOptionId: copiedOption.menuOptionId,
                                    quantity: 1,
                                    extras: optionsList
                                };
                                acc1.push(copiedParentOption);
                            }
                            return acc2;
                        }, []);
                }

                let implicitChildMenuOptions: ISavedExtrasOption[] = [];
                if (extras.implicitChildMenuOptions) {
                    implicitChildMenuOptions = extras.implicitChildMenuOptions.map(({menuOptionId, quantity}) => {
                        return { menuOptionId, quantity, isExplicit: false }
                    });
                }

                copiedOption.extras = implicitChildMenuOptions.concat(explicitChildMenuOptions);
            }
            acc1.push(copiedOption);

            return acc1;
        }, []);

        return this.combineEmptyExtras(menuOptionsWithFlatExtras);
    }

    static getEmptyExtrasMenuOption(): IBookingMenuOptionExtras {
        return {
            explicitChildMenuOptions: [],
            implicitChildMenuOptions: [],
            isSameForAll: true
        };
    }

    static populateExplicitMenuOptions(originalOpts: IBookingMenuOption[][], isSameForAll: boolean, parentQty: number): IBookingMenuOption[][] {
        const newOpts: IBookingMenuOption[][] = originalOpts || []; // multi-dimensional array
        if (!originalOpts) {
            if (isSameForAll) {
                newOpts.push([]);
            } else {
                for(let j=0; j<parentQty; j++) {
                    newOpts.push([]);
                }
            }
        }

        return newOpts;
    }

    /**
     * Finds parent options with empty extras and combines them
     */
    private static combineEmptyExtras(options: ISavedBookingMenuOption[]): ISavedBookingMenuOption[] {
        return options.reduce((acc: ISavedBookingMenuOption[], opt: ISavedBookingMenuOption, i: number) => {

            if (opt.extras && !opt.extras.length) {
                const existingSameEmptyOption: ISavedBookingMenuOption = acc.find(({menuOptionId, extras}) => opt.menuOptionId === menuOptionId && extras && !extras.length);
                if (existingSameEmptyOption) {
                    existingSameEmptyOption.quantity += opt.quantity;
                } else {
                    acc.push(opt);
                }
            } else {
                acc.push(opt);
            }

            return acc;
        }, []);
    }
}