import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import MenuOption from 'shared-components/booking-options-section/MenuOption/index';
import {IDispatchFromProps, IOwnProps, IStateFromProps} from 'shared-components/booking-options-section/MenuOption/types';
import {IMenuOptionsExtrasItem} from 'shared-components/booking-options-section/MenuOptionsExtras/types';
import {IOwnedVenue, servicePaymentType, IBookingMenuOption, loadStatus} from 'shared-types/index';
import {RootState} from "app/main/rootReducer";
import {StateHelperService} from "app/services/helpers/stateHelper.service";

const NS = 'MenuOptionContainer';

const mapStateToProps = (state: RootState, ownProps: IOwnProps): IStateFromProps & IOwnProps => {
    // const {wrapperStyle, theme, cachedMenuOptionDetails, booking} = widget;

    const theme = state.appInitReducer.theme;
    const cachedMenuOptionDetails = state.bookingOptionReducer.cachedMenuOptionDetails;
    const selectedBookingOptions = state.bookingOptionReducer.selectedBookingOptions;
    const activeVenue = state.appInitReducer.activeVenue;
    const selectedService = state.groupWidgetReducer.selectedService;

    /**
     * Finds menuOption details if they exist in `cachedMenuOptionDetails` and combines them with quantity & currency
     */
    const getExtrasDetails = ({menuOptionId, quantity}: IBookingMenuOption): IMenuOptionsExtrasItem => {
        const cachedOpt = cachedMenuOptionDetails.find(({id}) => menuOptionId === id);
        if (cachedOpt) {
            const {id, label, price, paymentType} = cachedOpt;
            return {id, label, price, paymentType, quantity, status: loadStatus.loaded};
        }

        // these will get loaded from backend and added to cachedMenuOptionDetails
        return {
            id: menuOptionId,
            label: null,
            price: null,
            paymentType: servicePaymentType.noPayment,
            quantity,
            status: loadStatus.idle
        };
    }

    const extraDetails: IMenuOptionsExtrasItem[] = [];
    if (selectedBookingOptions) {
        selectedBookingOptions.forEach(({menuOptionId, extras}) => {
            if (ownProps.id === menuOptionId && extras) {
                const {explicitChildMenuOptions, implicitChildMenuOptions} = extras;

                explicitChildMenuOptions && explicitChildMenuOptions.forEach(o1 => {
                    o1.forEach(o2 => {
                        extraDetails.push(getExtrasDetails(o2));
                    });
                });

                implicitChildMenuOptions && implicitChildMenuOptions.forEach(o => {
                    extraDetails.push(getExtrasDetails(o));
                });
            }
        });
    }

    const hasExtras = !!(ownProps.allChildMenuOptionIds && ownProps.allChildMenuOptionIds.length);

    let extrasHasPayment = hasExtras; // if children exist, assumes it has payment until checked with back end
    if (extrasHasPayment) {
        // gets details based on child ids, then if any child options have a payment, sets extrasHasPayment to true
        const details = ownProps.allChildMenuOptionIds.reduce((acc, id) => {
            const det = cachedMenuOptionDetails.find(o => o.id === id);
            if (det) {
                acc.push(det);
            }
            return acc;
        }, []);
        extrasHasPayment = details.some(o => o.paymentType !== servicePaymentType.noPayment)
    }

    return {
        theme,
        isStacked: false,
        currency: activeVenue ? (activeVenue as IOwnedVenue).currency : 'AUD',
        extraDetails: extraDetails.length ? extraDetails : null,
        extrasHasPayment,
        ...ownProps,
        allChildMenuOptionIds: ownProps.allChildMenuOptionIds.filter(id => !cachedMenuOptionDetails.some(o => o.id === id)),
        pricingType: selectedService?.paymentDetails?.pricingType || null
    };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
    return {
        handleIdleCachedMenuOptionDetails: (extraDetails: IMenuOptionsExtrasItem[]) => {
            const ids: string[] = [];
            extraDetails.forEach(o => {
                if (o.status === loadStatus.idle) {
                    ids.push(o.id);
                }
            });
            if (ids.length) {
                dispatch(StateHelperService.updateCachedBODetails(ids));
            }
        },
        updateCachedMenuOptionDetails: (ids: string[]) => {
            if (ids.length) {
                return new Promise((resolve, reject) => {
                    dispatch(StateHelperService.updateCachedBODetails(ids))
                      .then(() => resolve())
                      .catch(err => {
                          console.warn(NS, 'updateCachedMenuOptionDetails - failed to get booking options', err);
                          reject();
                      });
                })
            }
            return Promise.reject();
        }
    }
};

const MenuOptionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuOption as any);

export default MenuOptionContainer;
