import {of, interval, Observable} from "rxjs";
import {takeWhile, switchMap, tap} from "rxjs/operators";
import {phoneNumberTypes, ILibPhoneNumber, IParsedPhoneNumber} from "./phoneNumber.types";
import {CountriesWithCode} from  "./country";

const NS = 'PhoneNumberService';

export type Countries = Record<string, any>;

const countryLabels: Countries = {};
CountriesWithCode.forEach((c) => {
  countryLabels[c.code] = {
    displayCode: c.code + ' ' + c.dial_code,
    dial_code: c.dial_code,
  };
});

export default class PhoneNumberService {

    static loadLibPhoneNumber(): Observable<ILibPhoneNumber> {
        const scriptEl = document.createElement('script');
        scriptEl.src = '/other-scripts/libphonenumber-js/libphonenumber-max.js';
        document.body.appendChild(scriptEl);
        return interval(1000)
            .pipe(
                takeWhile((i: number) => !this.getLib() && i < 20, true), // try for 20 seconds - When the optional inclusive parameter is set to true it will also emit the first item that didn't pass the predicate.
                switchMap((i: number) => {
                    return of(this.getLib())
                })
            );
    }

    /**
     * Takes a phone number as a string and validates it.
     * @param phoneNumber phone number as string
     * @param countryCode ISO country code, such as 'AU', 'US', etc
     * @param checkValidity optionally pass `true` to do `isValid` check
     * @param type format type. Defaults to `INTERNATIONAL`, but can accept other values as well, such as `NATIONAL`.
     * @returns a phone number or null for invalid
     */
    static formatNumber(phoneNumber: string, countryCode: string, checkValidity: boolean, type: phoneNumberTypes = phoneNumberTypes.INTERNATIONAL): string {
        const libphonenumber: ILibPhoneNumber = this.getLib();

        if (!libphonenumber) {
            console.warn(NS, "Library 'libphonenumber' has not loaded yet.");
            return null;
        }

        if (!phoneNumber) {
            console.warn("Phone number is not defined");
            return null;
        }

        const parsedPhoneNumber: IParsedPhoneNumber = libphonenumber.parsePhoneNumberFromString(phoneNumber, countryCode);

        if (!parsedPhoneNumber || checkValidity && !parsedPhoneNumber.isValid()) {
            return null;
        }

        if((parsedPhoneNumber as any).country !== countryCode) return null;

        return parsedPhoneNumber.format(type);
    }

    static parseNumber(phoneNumber: string): any {
        const libphonenumber: ILibPhoneNumber = this.getLib();

        if (!libphonenumber) {
            console.warn(NS, "Library 'libphonenumber' has not loaded yet.");
            return null;
        }

        if (!phoneNumber) {
            console.warn("Phone number is not defined");
            return null;
        }

        try {
            return libphonenumber.parsePhoneNumber(phoneNumber);
        } catch (error) {
            return phoneNumber;
        }

    }

    static getInternationPhoneNumberWithoutPrefix(phoneNumberWithPrefix: string, countryCode: string): string {
        if(!phoneNumberWithPrefix) return phoneNumberWithPrefix;
        if(!countryCode) return phoneNumberWithPrefix;
        const dial_code: string = countryLabels[countryCode].dial_code;
        const phoneNumberWithoutPrefix = phoneNumberWithPrefix.replace(dial_code, '').trim();
        return phoneNumberWithoutPrefix;
    }

    static formatInterNationalPhoneNumber(phoneNumber: string, countryCode: string): string {
        try {
            if(!countryCode) return phoneNumber;

            if (!phoneNumber) {
                console.warn("Phone number is not defined");
                return phoneNumber;
            }

            const dial_code: string = countryLabels[countryCode].dial_code;
            let formattedNumber: string = phoneNumber;
            if(phoneNumber.indexOf(dial_code) < 0) formattedNumber = dial_code + ' ' + phoneNumber;

            return formattedNumber;
        } catch (error) {
            console.warn('formatInterNationalPhoneNumber error', error);
            return phoneNumber;
        }

    }

    static getCountryByDialCode(code: string): { name: string; dial_code: string; code: string; } {
        return CountriesWithCode.find((c) => c.dial_code === code);
    }

    static getCountryByCountryCode(code: string): { name: string; dial_code: string; code: string; } {
      return CountriesWithCode.find((c) => c.code === code);
    }

    private static getLib(): ILibPhoneNumber {
        return (window as any).libphonenumber;
    }
}
