import {connect} from 'react-redux';
import FooterNav from 'shared-components/footer-nav/index';
import {footerNavTypes, IProps} from 'shared-components/footer-nav/types';
import {AnyAction} from 'redux';
import {IHandlers} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {RootState} from "app/main/rootReducer";
import FooterService from "app/services/footer/footer.service";
import {ROUTE_NAMES} from "app/models/route.types";
import {StateHelperService} from "app/services/helpers/stateHelper.service";
import {wrapperStyleType} from "shared-types/WidgetTypes";
import updateRoute = StateHelperService.updateRoute;
import saveBooking = StateHelperService.saveBooking;
import { setTags } from 'app/reducers/customerSlice';

const NS = 'FooterNavContainer';

const mapStateToProps = (state: RootState): IProps => {

    const redirectTo = state.routeReducer.redirectTo;
    const bookingSaveLoader = state.bookingReducer.bookingSaveLoader;
    const theme = state.appInitReducer.theme;
    const bookingTime = state.venueGridReducer.bookingTime;

    let nextType = footerNavTypes.next;

    const isSitting: boolean = redirectTo === ROUTE_NAMES.BOOKING;

    if (isSitting) {
        nextType = footerNavTypes.book
    }

    return {
        theme,
        nextEnabled: FooterService.isNextEnabled(redirectTo, state),
        prevEnabled: FooterService.isPrevEnabled(redirectTo, state),
        nextType,
        showNextLoader: bookingSaveLoader,
        buttonsCentered: true,
        wrapperStyle: wrapperStyleType.standard,
        blockNav: null,
        venuePhone: null,
        isStandByListAvailable: bookingTime?.isStandByListAvailable
    };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IHandlers => {

    return {
        handleNavPressed: (type: footerNavTypes) => {

            switch (type) {
                case footerNavTypes.book: {
                    dispatch(saveBooking(footerNavTypes.book))
                    break;
                }
                case footerNavTypes.prev:
                    dispatch(updateRoute(footerNavTypes.prev))
                    dispatch(setTags([]));
                    break;

                case footerNavTypes.standby:
                    dispatch(StateHelperService.saveStandbyBooking(footerNavTypes.standby))
                    break;

                default:
                    dispatch(updateRoute(footerNavTypes.book));
            }

        },
        handleDisabledNextPressed: () => {
            // dispatch(NavigationActionsNS.triedNext());
        },
        handleCancelBooking: () => {
            // dispatch(BookingActionsNS.cancelBooking(true));
        }
    }
};

const FooterNavContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterNav as any);

export default FooterNavContainer;
