import {connect, ConnectedComponent} from 'react-redux';
import ThankYou from './index';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {RootState} from "app/main/rootReducer";
import moment from 'moment';
import { PaymentMethod } from 'app/services/booking/booking.types';
import {routeSlice} from "app/reducers/routeSlice";
import { ROUTE_NAMES } from 'app/models/route.types';

const NS = 'ThankYouContainer';

const mapStateToProps = (state: RootState): IStateFromProps => {

    const viewDate = state.dayPickerReducer.viewDate;
    const bookingTime = state.venueGridReducer.bookingTime;
    const bookingResponse = state.bookingReducer.bookingResponse;
    const bookingOutgoing =  state.bookingReducer.bookingOutgoing;
    const abcStaffFacing = !state.routeReducer.routeList.includes(ROUTE_NAMES.MANAGE_BOOKING);
    const bookingCreationMsg = abcStaffFacing  ?  `Your customer's booking has been created.` : `Thanks for your booking, we can't wait to see you. We've sent you an email with the details of this booking.`;
    const standbyBookingCreationMsg = `Your customer has been added to the standby list for ${bookingTime.time ? moment(bookingTime.time).format('hh:mm a') : 'any time'} on ${viewDate}.`;
    var notificationInfo ="";
    if(bookingOutgoing.paymentFlow){
        if(bookingOutgoing.paymentFlow == PaymentMethod.Email) {
            notificationInfo =  ` An email has been sent to the customer with instructions and payment link.`
        }

        if(bookingOutgoing.paymentFlow == PaymentMethod.Sms) {
            notificationInfo =  ` An sms message has been sent to the customer with instructions and with payment link.`
        }
    }
    else {

        if(bookingOutgoing.sendEmailNotifications) {
            notificationInfo =  ` An email has been sent to the customer with their details.`
        }
    
        if(bookingOutgoing.sendSmsNotifications){
            notificationInfo += ` An sms message has been sent to the customer with their details.`
        }
    }
    return {
        message: bookingTime?.isStandByListAvailable ? `${standbyBookingCreationMsg}${notificationInfo}`: `${bookingCreationMsg}${notificationInfo}`,
        title: bookingTime?.isStandByListAvailable ? `Booking added to the Standby List` : `Booking Created`,
        bodyIsMarkDown: false,
        booking: bookingResponse,
        shouldShowBookingReferenceNumber: bookingTime?.isStandByListAvailable ? false : true,
        shouldShowMakeAnotherBookingButton: abcStaffFacing
    };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
    return {
    handleRebook: () => {
        dispatch(routeSlice.actions.reload(true));
      }
    }
};

const ThankYouContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ThankYou as any);

export default ThankYouContainer as ConnectedComponent<any, any>;
