import {IOwnedVenue} from "shared-types/WidgetTypes";
import {IBookingTag, ISimpleBookingOption} from "shared-types/SharedTypes";


export interface IScheduleParams {
    date: string; // YYYY-M-D
    numOfPeople: number;
    showWalkInTables: boolean;
    venueIds: number[]
}


export interface ISectionOrder {
    id: string; // "section_GIYOEZ9CC963K"
    name: string; // "Main Room"
    order: number; // order in list compared with other sections
}

export interface IUpdateActiveChildMenuOptionPayload {
    implicitChildMenuOptions: ISimpleBookingOption[];
    explicitChildMenuOptions: ISimpleBookingOption[];
}

export interface IAccountDetails {
    id: string;
    firstName: string;
    surname: string;
    email: string;
    businessName: string;
    businessId: string;
    businessUrl: string;
    businessAddress: string;
    suburb: string;
    postcode: string;
    state: string;
    country: string;
    city: string;
    active: boolean;
    logoUrl: string;
    contactNumber: string;
    alternativePhone: string;
    currentBookingService: unknown;
    ownedVenues: IOwnedVenue[];
    accountPhone: string;
    tags: IBookingTag[];
    abcPaymentTimeoutInMinutes: number;
}

export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
