import {connect, ConnectedComponent} from 'react-redux';
import ErrorPage from './index';
import { AnyAction } from 'redux';
import { IProps, IHandlers } from './types';
import { ThunkDispatch } from 'redux-thunk';
import {RootState} from "app/main/rootReducer";
import {bookingErrorMessageType, bookingErrorType} from "shared-types/index";
import {routeSlice} from "app/reducers/routeSlice";
import {footerNavTypes} from "shared-components/footer-nav/types";
import {StateHelperService} from "app/services/helpers/stateHelper.service";
import updateRoute = StateHelperService.updateRoute;

const NS = 'ErrorPageContainer';

const mapStateToProps = (rootReducer: RootState): IProps => {
  const {appInitReducer, paymentReducer} = rootReducer;

  return {
    theme: appInitReducer.theme,
    bookingError: paymentReducer.bookingError,
    showTryAgainBtn: paymentReducer.showTryAgainBtn
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IHandlers => {
  return {
    handleReset: (messageType: bookingErrorMessageType, name: bookingErrorType) => {
      if (
        messageType === bookingErrorMessageType.paymentError ||
        name === bookingErrorType.paymentError // this covers quite a few different payment error types
      ) {
        dispatch(updateRoute(footerNavTypes.prev));
        dispatch(routeSlice.actions.addPaymentRoutes(true));
      } else {
        // reload page
        location.assign(location.href);
      }
    }
  }
};

const ErrorPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorPage as any);

export default ErrorPageContainer as ConnectedComponent<any, any>;
