import { wrapperStyleType, IWidgetTheme } from "shared-types/index";

export interface IPaymentFooterNav {
  handleNavPressed: (type: paymentFooterNavTypes) => void;
  handleDisabledNextPressed?: () => void;
  nextEnabled: boolean;
  prevEnabled: boolean;
  showCancel: boolean;
  nextType: paymentFooterNavTypes;
  wrapperStyle: wrapperStyleType;
  theme: IWidgetTheme;
}

export enum paymentFooterNavTypes {
  next = 'next',
  pay = 'pay',
  preauth = 'preauth',
  prev = 'prev',
  cancel = 'cancel'
}
