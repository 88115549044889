import React from 'react';
import { IPaymentCardLogos } from './types';
import style from './style.module.scss';
import { Typography } from '@material-ui/core';
import { renderIf } from 'shared-services/react-utils-service/index';

const NS = 'PaymentCardLogos';

export default function PaymentCardLogos({acceptAmericanExpress, acceptMasterCard, acceptVisa, acceptDiners, acceptJcb}: IPaymentCardLogos) {
  
  const SRC_BASE = 'https://nbistorageprod.blob.core.windows.net/nbistorageprod/creditcards/';
  return (
    <div>
      <Typography variant="body1" >
        Accepted Cards
      </Typography>

      <div className={style.logosWrap}>

        {renderIf(acceptAmericanExpress, (
          <img src={`${SRC_BASE}amex.png`} alt="Amex Card" />
        ))}

        {renderIf(acceptMasterCard, (
          <img src={`${SRC_BASE}mastercard.png`} alt="Mastercard" />
        ))}

        {renderIf(acceptVisa, (
          <img src={`${SRC_BASE}visa.png`} alt="Visa" />
        ))}

        {renderIf(acceptDiners, (
          <img src={`${SRC_BASE}diners.png`} alt="Diners" />
        ))}

        {renderIf(acceptJcb, (
          <img src={`${SRC_BASE}jcb.png`} alt="JCB" />
        ))}
      </div>
    </div>
  )
}