import React, {Component, createRef, RefObject} from 'react';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import {withStyles} from '@material-ui/styles';
import {CircularProgress, FormControlLabel, FormGroup, Switch, Typography} from '@material-ui/core';
import style from './style.module.scss';
import {expandPanelType, IDispatchFromProps, IState, IStateFromProps} from './types';
import classNames from 'classnames';
import {getDerivedStateFromProps, getStateForExpansionPanelChange} from './helpers';
import {renderIf} from "app/services/utils.service";
import {BookingService} from "app/services/booking/booking.service";
import ServiceSelector from "app/components/ServiceSelector";
import TableSeaterContainer from "app/components/TableSeater/container";
import MenuOptionsContainer from "app/components/MenuOptions/container";
import PaymentOptions from "app/components/PaymentOptions";
import {isEqual} from "lodash-es";
import {servicePaymentType} from "shared-types/SharedTypes";
import PaymentConfirmationType from "app/components/PaymentConfirmationType";

const NS = 'SittingPanels';

const ExpansionPanel = withStyles({
    root: {
        '&.Mui-expanded': {
            margin: '12px 0',
        }
    },
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles({
    root: {
        padding: '10px 24px 18px',
        display: 'block'
    },
})(MuiExpansionPanelDetails);


const ExpansionPanelSummary = withStyles({
    root: {
        '&.Mui-expanded': {
            minHeight: '40px'
        }
    },
    content: {
        margin: 0,
        '&.Mui-expanded': {
            margin: 0
        }
    }
})(MuiExpansionPanelSummary);


export default class SittingPanels extends Component<IStateFromProps & IDispatchFromProps, IState> {

    readonly state = {
        servicesSelectorOpen: false,
        tableSelectorOpen: true,
        bookingOptionsSelectorOpen: true,
        paymentSelectorOpen: !this.props.showMenuOptions || false,
    };

    private serviceAlertWrapRef: RefObject<HTMLDivElement> = createRef();
    private sectionAlertWrapRef: RefObject<HTMLDivElement> = createRef();

    constructor(props: IStateFromProps & IDispatchFromProps) {
        super(props);
        this.handleExpansionPanelChange = this.handleExpansionPanelChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps: IStateFromProps & IDispatchFromProps, currentState: IState) {
        return getDerivedStateFromProps(nextProps, currentState);
    }

    componentDidUpdate(prevProps: Readonly<IStateFromProps & IDispatchFromProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.serviceAlertMessage && this.serviceAlertWrapRef && this.serviceAlertWrapRef.current) {
            this.serviceAlertWrapRef.current.scrollIntoView({behavior: "smooth"});
        }
        if (this.props.sectionAlertMessage && this.sectionAlertWrapRef && this.sectionAlertWrapRef.current) {
            this.sectionAlertWrapRef.current.scrollIntoView({behavior: "smooth"});
        }

        if (this.props.isBookingOptionsValid && !isEqual(prevProps.isBookingOptionsValid, this.props.isBookingOptionsValid)) {
            this.handleExpansionPanelChange(expandPanelType.bookingOptions, expandPanelType.payment);
        }

        if (!this.props.showBookingOptions && !isEqual(prevProps.showBookingOptions, this.props.showBookingOptions)) {
            this.handleExpansionPanelChange(expandPanelType.bookingOptions, expandPanelType.payment);
        }
    }


    handleExpansionPanelChange(type: expandPanelType, typeToLeaveOpen?: expandPanelType): void {
        const state = getStateForExpansionPanelChange(this.state, type, typeToLeaveOpen);
        if (state) {
            this.setState(state);
        }
    }


    render() {

        // props comes from redux 'application' state
        const {
            isPreviewMode, scheduleLoading, scheduleMessage,
            filteredServices, activeService,
            showMenuOptions, coversPrefill, serviceAlertMessage,
            handleServiceChange, showBookingOptions, handleChange, selectedTables, payment, isPaymentSet
        } = this.props;

        // 'state' is handling panels expanded state and text
        const {
            servicesSelectorOpen
        } = this.state;

        const isLandscape = false;
        const showPaymentPanel = (isPaymentSet == true || payment.paymentType !== servicePaymentType.noPayment);

        return (
            <div className={style.root}>

                {renderIf(true, () => (
                    <ExpansionPanel expanded={servicesSelectorOpen}>
                        <ExpansionPanelSummary
                            onClick={() => this.handleExpansionPanelChange(expandPanelType.services)}>
                            <Typography className={classNames({
                                [style.expanderHeading]: true,
                                [style.expanderHeadingIsLandscape]: isLandscape
                            })}>
                                {!activeService || scheduleLoading ?
                                    'Which service are you booking for?'
                                    : `For ${activeService.name}`
                                }
                            </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails className={[style.expPnlDetServices, style.expPnlDet].join(' ')}>

                            {renderIf(isPreviewMode, () => (
                                    // <AlertPanel wrapperStyle={wrapperStyle} message="Not supported in preview mode" />
                                    <div></div>
                                ), () => renderIf(scheduleLoading, () => (
                                    // if loading
                                    <div className={classNames({
                                        [style.loaderWrap]: true,
                                        [style.loaderWrapIsCentered]: !isLandscape
                                    })}>
                                        <CircularProgress size={20}/>
                                    </div>
                                ), () => (
                                    // if loaded
                                    <>
                                        <ServiceSelector
                                            scheduleMessage={scheduleMessage}
                                            services={filteredServices}
                                            activeServiceId={activeService ? activeService.id : null}
                                            handleSelect={(id: string) => {
                                                if (isPreviewMode) {
                                                    console.warn(NS, 'disabled for Preview Mode');
                                                } else {
                                                    handleServiceChange(id, filteredServices)
                                                        .then((activeService) => {
                                                            const activeServiceHasMessage = activeService &&
                                                                (
                                                                    activeService.description
                                                                    || BookingService.isPaymentDetailsVisible(coversPrefill, activeService.paymentDetails)
                                                                );
                                                        });
                                                }
                                            }}
                                        />
                                        {/*<ServiceMessageContainer />*/}
                                        {renderIf(serviceAlertMessage, () => (
                                            <div className={style.servicesAlert} ref={this.serviceAlertWrapRef}>
                                                {/*<AlertPanel wrapperStyle={wrapperStyle} message={serviceAlertMessage} />*/}
                                            </div>
                                        ))}
                                    </>
                                )
                                )
                            )}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}

                {/*{renderIf(, (*/}
                <ExpansionPanel expanded={true}>
                    <ExpansionPanelSummary
                        onClick={() => this.handleExpansionPanelChange(expandPanelType.tables)}>
                        <Typography className={classNames({
                            [style.expanderHeading]: true,
                            [style.expanderHeadingIsLandscape]: isLandscape
                        })}>
                            Table selection
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={[style.expPnlDetSections, style.expPnlDet].join(' ')}>
                        <TableSeaterContainer/>

                        {/*  {renderIf(sectionAlertMessage, () => (*/}
                        {/*    <div className={style.sectionsAlert} ref={this.sectionAlertWrapRef}>*/}
                        {/*      <AlertPanel wrapperStyle={wrapperStyle} message={sectionAlertMessage} />*/}
                        {/*    </div>*/}
                        {/*  ))}*/}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {/*))}*/}

                {renderIf(showMenuOptions, (
                    <ExpansionPanel expanded={true}>
                        <ExpansionPanelSummary
                            onClick={() => this.handleExpansionPanelChange(expandPanelType.bookingOptions)}>
                            <Typography className={classNames({
                                [style.expanderHeading]: true,
                                [style.expanderHeadingIsLandscape]: isLandscape
                            })}>
                                Additional options
                            </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails className={style.expPnlDet}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Switch checked={showBookingOptions}
                                                     onChange={() => handleChange(!showBookingOptions)}
                                                     name="bookingOption"/>}
                                    label="Show Booking Options"
                                />
                            </FormGroup>
                            {renderIf(showBookingOptions, (
                                <MenuOptionsContainer/>
                            ))}

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}

                {renderIf(showPaymentPanel, (
                    <ExpansionPanel expanded={true}>
                        <ExpansionPanelSummary
                            onClick={() => this.handleExpansionPanelChange(expandPanelType.payment)}>
                            <Typography className={classNames({
                                [style.expanderHeading]: true,
                                [style.expanderHeadingIsLandscape]: isLandscape
                            })}>
                                Payment Options
                            </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails className={style.expPnlDet}>
                            <PaymentOptions showMenuOptions={showMenuOptions}/>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}

                {renderIf(payment.paymentType === servicePaymentType.noPayment, (
                    <ExpansionPanel expanded={true}>
                        <ExpansionPanelSummary
                            onClick={() => this.handleExpansionPanelChange(expandPanelType.payment)}>
                            <Typography className={classNames({
                                [style.expanderHeading]: true,
                                [style.expanderHeadingIsLandscape]: isLandscape
                            })}>
                                Customer communications
                            </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails className={style.expPnlDet}>
                            <PaymentConfirmationType handleSomeClick={() => void 0}/>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}

            </div>
        )
    }
}
