import React from 'react';
import {sortBy} from 'lodash';
import {IBookingPayment, IPaymentDetailsGenericData} from "shared-types/index";
import {IGroupedTablesBoxGroup, IGroupedTablesBoxItem} from "shared-components/grouped-tables-box/types";
import {IntlService} from "shared-services/intl-service";

export function getPaymentDetailGroup(payment: IBookingPayment, paymentDetails: IPaymentDetailsGenericData, currency: string): IGroupedTablesBoxGroup {

    const items: IGroupedTablesBoxItem[] = [
        {order: 0, name: `Cardholder Name`, value: paymentDetails.name},
        {order: 1, name: `Credit Card Number`, value: formatCC(paymentDetails.cardNumber)},
        {order: 10, name: `Total Amount (${currency})`, value: (
                <span className="secondary-text">
          {IntlService.currencyValue(payment.amountDue, currency)}
        </span>
            )}
    ];

    if (payment.promotionCode) {
        items.push(
            {order: 2, name: `Promotion Code`, value: payment.promotionCode}
        )
    }

    if (payment.discountAmount) {
        items.push(
            {order: 3, name: `Required Payment (${currency})`, value: (
                    <span className="secondary-text">
            {IntlService.currencyValue(payment.price, currency)}
          </span>
                )},
            {order: 4, name: `Discount Amount (${currency})`, value: (
                    <span className="secondary-text">
            {IntlService.currencyValue(payment.discountAmount, currency)}
          </span>
                )}
        )
    }

    return {
        heading: 'Payment Details',
        items: sortBy(items, 'order')
    }
}

function formatCC(cardNumber: string): string {

    //if the card number is already formatted, just return as is
    if (cardNumber.indexOf(' ') !== -1 && cardNumber.indexOf('X') !== -1) {
        return cardNumber;
    }

    const maskedNumber: string = cardNumber.split('')
        .map((ch: string, i: number) => i < 12 ? 'X' : ch).join('');

    const spacedNumber: string = maskedNumber.split('')
        .map((ch: string, i: number) => i > 0 && i % 4 === 0 ? ` ${ch}` : ch).join('');

    return spacedNumber;
}