import {servicePaymentType, IOwnedVenue, ISchedule, IServicePaymentOption} from 'shared-types/index';
import {MocksService} from "app/services/mocks/mocks.service";

export class MockScheduleService {

    static getAdiMaxOccupancySchedule(): ISchedule {
        return AdiMaxOccupancySchedule;
    }

    static getNewSchedule() {
        const mockSchedule: ISchedule = {
            venueDetails: {} as IOwnedVenue,
            venueId: 305,
            "isVenueOpen": true,
            "blockoutMessage": null,
            "currentBookingsOnStandbyList": 0,
            "bookingInterval": 15,
            "maxBookingsOnStandbyList": 1000,
            "tags": [
                {
                    "name": "Anniversary",
                    "icon": "anniversary",
                    "online": true,
                    "tagtype": {
                        "name": "Occasion",
                        "_id": "tagtype_occasion",
                        "_rev": "1-60f8905a7796145053aefeed11336e12",
                        "type": "tagtype"
                    },
                    "order": 1,
                    "colour": "#D79A20",
                    "permanent": false,
                    "_id": "tag_anniversary",
                    "_rev": "11-a333f32bbda85330460cd4b66c903c06",
                    "type": "tag"
                },
                {
                    "name": "Disabled",
                    "icon": "accessibility",
                    "online": true,
                    "tagtype": {
                        "name": "Accessibility",
                        "_id": "tagtype_accessibility",
                        "_rev": "1-74f0a69fd3585bf275aae440305f772c",
                        "type": "tagtype"
                    },
                    "order": 2,
                    "colour": "#000000",
                    "permanent": false,
                    "_id": "tag_disabled",
                    "_rev": "12-c0dc8c0b38c8b43c0470ce65532f1a17",
                    "type": "tag"
                },
                {
                    "name": "Pram",
                    "icon": "pram",
                    "online": true,
                    "tagtype": {
                        "name": "Accessibility",
                        "_id": "tagtype_accessibility",
                        "_rev": "1-74f0a69fd3585bf275aae440305f772c",
                        "type": "tagtype"
                    },
                    "order": 3,
                    "colour": "#000000",
                    "permanent": false,
                    "_id": "tag_pram",
                    "_rev": "13-b9dc6b65e618d23b30d89ad9439ed1bf",
                    "type": "tag"
                },
                {
                    "name": "Birthday",
                    "icon": "birthday",
                    "online": true,
                    "tagtype": {
                        "name": "Occasion",
                        "_id": "tagtype_occasion",
                        "_rev": "1-60f8905a7796145053aefeed11336e12",
                        "type": "tagtype"
                    },
                    "order": 4,
                    "colour": "#9220d7",
                    "permanent": false,
                    "_id": "tag_birthday",
                    "_rev": "14-f977885199d0ffafbbc06b4361efeda9",
                    "type": "tag"
                },
                {
                    "name": "Dietary Requirement",
                    "icon": "dietary",
                    "online": true,
                    "tagtype": {
                        "name": "Dietary",
                        "_id": "tagtype_dietary",
                        "_rev": "1-212ef6b4a6301893d0b161f63551bdda",
                        "type": "tagtype"
                    },
                    "order": 5,
                    "colour": "#000000",
                    "permanent": false,
                    "_id": "tag_dietary",
                    "_rev": "13-6af36548d1d4b65b8ed3cabed299c3d7",
                    "type": "tag"
                },
                {
                    "name": "Allergy",
                    "icon": "allergy",
                    "online": true,
                    "tagtype": {
                        "name": "Allergy",
                        "_id": "tagtype_allergy",
                        "_rev": "1-b7e0a5d2d4c8b92121f49f62ea85e962",
                        "type": "tagtype"
                    },
                    "order": 6,
                    "colour": "#b51b00",
                    "permanent": true,
                    "_id": "tag_allergy",
                    "_rev": "11-a10fa6bb32926cc02ae9c18ba7525c50",
                    "type": "tag"
                }
            ],
            "services": [
                {
                    "id": "service_8W1W7K48WP0TB_1612939996949",
                    "name": "Breakfast",
                    "online": true,
                    "duration": 90,
                    "sections": [
                        {
                            "id": "section_NF4HLG4WCO7Y1",
                            "name": "Upstairs",
                            "order": 0
                        },
                        {
                            "id": "section_OPYA12CRRNW4Q",
                            "name": "Beer Garden",
                            "order": 1
                        },
                        {
                            "id": "section_ENI3TUAFZOOG8",
                            "name": "Terrace",
                            "order": 2
                        }
                    ],
                    "times": [
                        {
                            "name": "8:00am",
                            "expired": false,
                            "time": "2021-05-26T08:00:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "8:15am",
                            "expired": false,

                            "time": "2021-05-26T08:15:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "8:30am",
                            "expired": false,

                            "time": "2021-05-26T08:30:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "8:45am",
                            "expired": false,

                            "time": "2021-05-26T08:45:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "9:00am",
                            "expired": false,

                            "time": "2021-05-26T09:00:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "9:15am",
                            "expired": false,

                            "time": "2021-05-26T09:15:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "9:30am",
                            "expired": false,

                            "time": "2021-05-26T09:30:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "9:45am",
                            "expired": false,

                            "time": "2021-05-26T09:45:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "10:00am",
                            "expired": false,

                            "time": "2021-05-26T10:00:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "10:15am",
                            "expired": false,

                            "time": "2021-05-26T10:15:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "10:30am",
                            "expired": false,

                            "time": "2021-05-26T10:30:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "10:45am",
                            "expired": false,

                            "time": "2021-05-26T10:45:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        }
                    ],
                    "paymentDetails": {
                        "paymentType": servicePaymentType.noPayment,
                        "peopleRequired": 1,
                        "price": 0.0,
                        "options": [],
                        "optionsFrom": null,
                        "optionsTo": null,
                        "maxPeoplePerBookingOverride": null,
                        "hasPromotion": false,
                        "singleMenuPerBooking": false
                    },
                    "description": null,
                    "policyAgreement": null,
                    "policyAgreementText": null,
                    "serviceType": "Breakfast"
                },
                {
                    "id": "service_G2LYII5MYPCLY_1612939996949",
                    "name": "Lunch",
                    "online": true,
                    "duration": 90,
                    "sections": [
                        {
                            "id": "section_NF4HLG4WCO7Y1",
                            "name": "Upstairs",
                            "order": 0
                        },
                        {
                            "id": "section_OPYA12CRRNW4Q",
                            "name": "Beer Garden",
                            "order": 1
                        },
                        {
                            "id": "section_ENI3TUAFZOOG8",
                            "name": "Terrace",
                            "order": 2
                        }
                    ],
                    "times": [
                        {
                            "name": "11:00am",
                            "expired": false,

                            "time": "2021-05-26T11:00:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "11:15am",
                            "expired": false,

                            "time": "2021-05-26T11:15:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "11:30am",
                            "expired": false,

                            "time": "2021-05-26T11:30:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "11:45am",
                            "expired": false,

                            "time": "2021-05-26T11:45:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "12:00pm",
                            "expired": false,

                            "time": "2021-05-26T12:00:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "12:15pm",
                            "expired": false,

                            "time": "2021-05-26T12:15:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "12:30pm",
                            "expired": false,

                            "time": "2021-05-26T12:30:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "12:45pm",
                            "expired": false,

                            "time": "2021-05-26T12:45:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "1:00pm",
                            "expired": false,

                            "time": "2021-05-26T13:00:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "1:15pm",
                            "expired": false,

                            "time": "2021-05-26T13:15:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "1:30pm",
                            "expired": false,

                            "time": "2021-05-26T13:30:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "1:45pm",
                            "expired": false,

                            "time": "2021-05-26T13:45:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "2:00pm",
                            "expired": false,

                            "time": "2021-05-26T14:00:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "2:15pm",
                            "expired": false,

                            "time": "2021-05-26T14:15:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "2:30pm",
                            "expired": false,

                            "time": "2021-05-26T14:30:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "2:45pm",
                            "expired": false,

                            "time": "2021-05-26T14:45:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "3:00pm",
                            "expired": false,

                            "time": "2021-05-26T15:00:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "3:15pm",
                            "expired": false,

                            "time": "2021-05-26T15:15:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "3:30pm",
                            "expired": false,

                            "time": "2021-05-26T15:30:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        }
                    ],
                    "paymentDetails": {
                        "paymentType": servicePaymentType.preAuth,
                        "peopleRequired": 1,
                        "price": 0.0,
                        "options": [
                            {
                                "id": "menuoption_EB80GWRQG9RKE_1617152382392",
                                "provider": "Nbi",
                                "name": "5 course degustation",
                                "label": "5 course degustation",
                                "description": null,
                                "link": null,
                                "tag": null,
                                "paymentType": servicePaymentType.preAuth,
                                "price": 150.0,
                                "childMenuOptionIds": [],
                                "explicitChildMenuOptionIds": []
                            },
                            {
                                "provider": "NBI",
                                "paymentType": servicePaymentType.noPayment,
                                "name": "Free test for child options displaying",
                                "label": "Free test for child options displaying",
                                "description": "Free test for child options displaying",
                                "tag": "tag_9ALSUIHYY2FK3_1579498671474",
                                "price": 0,
                                "childMenuOptionIds": [
                                    "menuoption_M4OY8L8HTCLOR_1588827614800",
                                ],
                                "explicitChildMenuOptionIds": [
                                    "menuoption_FPPHO43W8YGJ0_1579664125892"
                                ],
                                "id": "menuoption_Y811KJMGAK1WI_1593746952393",
                                "link": null,
                            }
                        ],
                        "optionsFrom": null,
                        "optionsTo": null,
                        "maxPeoplePerBookingOverride": null,
                        "hasPromotion": false,
                        "singleMenuPerBooking": false,
                    },
                    "description": null,
                    "policyAgreement": null,
                    "policyAgreementText": null,
                    "serviceType": "Lunch"
                },
                {
                    "id": "service_V7GLQMW0PFYSQ_1612939996949",
                    "name": "Dinner",
                    "online": true,
                    "duration": 90,
                    "sections": [
                        {
                            "id": "section_NF4HLG4WCO7Y1",
                            "name": "Upstairs",
                            "order": 0
                        },
                        {
                            "id": "section_OPYA12CRRNW4Q",
                            "name": "Beer Garden",
                            "order": 1
                        },
                        {
                            "id": "section_ENI3TUAFZOOG8",
                            "name": "Terrace",
                            "order": 2
                        }
                    ],
                    "times": [
                        {
                            "name": "4:00pm",
                            "expired": false,

                            "time": "2021-05-26T16:00:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "4:15pm",
                            "expired": false,

                            "time": "2021-05-26T16:15:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "4:30pm",
                            "expired": false,

                            "time": "2021-05-26T16:30:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },
                        {
                            "name": "4:45pm",
                            "expired": false,

                            "time": "2021-05-26T16:45:00",
                            "sections": [
                                {
                                    "id": "section_NF4HLG4WCO7Y1",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_OPYA12CRRNW4Q",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                },
                                {
                                    "id": "section_ENI3TUAFZOOG8",
                                    "sectionState": true,
                                    isSectionBlocked: false
                                }
                            ],
                            onlySharedTablesRemain: false
                        },

                    ],
                    "paymentDetails": {
                        "paymentType": servicePaymentType.noPayment,
                        "peopleRequired": 1,
                        "price": 0.0,
                        "options": [],
                        "optionsFrom": null,
                        "optionsTo": null,
                        "maxPeoplePerBookingOverride": null,
                        "hasPromotion": false,
                        "singleMenuPerBooking": false,
                    },
                    "description": null,
                    "policyAgreement": null,
                    "policyAgreementText": null,
                    "serviceType": "Dinner"
                }
            ]
        }
        return mockSchedule;
    }


    static getBookingOptionFromClient = (): IServicePaymentOption[] => {
        const clientBO: IServicePaymentOption[] = [{
            "id": "menuoption_M4OY8L8HTCLOR_1588827614800",
            "provider": "Nbi",
            "name": "Free wine",
            "label": "Free wine",
            "description": null,
            "link": null,
            "tag": null,
            "paymentType": servicePaymentType.noPayment,
            "price": 0.0,
            "childMenuOptionIds": [],
            "explicitChildMenuOptionIds": []
        }, {
            "id": "menuoption_FPPHO43W8YGJ0_1579664125892",
            "provider": "Nbi",
            "name": "HIGEST-TEA",
            "label": "HHIGH-TEA",
            description: null as string,
            "link": null as string,
            "tag": "tag_B0N9UWKSSSH7P_1579481230389",
            "paymentType": servicePaymentType.noPayment,
            "price": 0.0,
            "childMenuOptionIds": [] as string[],
            "explicitChildMenuOptionIds": [] as string[]
        }];
        return clientBO;
    }
}

const AdiMaxOccupancySchedule: ISchedule = {
    venueDetails: MocksService.getOwnedVenue(269, 'Adi Restaurant'),
    venueId: 269,
    "isVenueOpen": true,
    "blockoutMessage": null,
    "currentBookingsOnStandbyList": 0,
    "maxBookingsOnStandbyList": 0,
    "bookingInterval": 30,
    "tags": [],
    "services": [
        {
            "id": "service_G990GIJ200B5O_1619657536235",
            "name": "Dinner",
            "online": true,
            "duration": 90,
            "sections": [
                {
                    "id": "section_G7ZSB662ZNKOC",
                    "name": "The Dungeon",
                    "order": 0
                },
                {
                    "id": "section_YAGI34318YRUB",
                    "name": "The Caverns",
                    "order": 1
                }
            ],
            "times": [
                {
                    "name": "5:00pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T17:00:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "5:30pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T17:30:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "6:00pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T18:00:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "6:30pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T18:30:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "7:00pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T19:00:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "7:30pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T19:30:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "8:00pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T20:00:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "8:30pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T20:30:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "9:00pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T21:00:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "9:30pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T21:30:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "10:00pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T22:00:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "10:30pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T22:30:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "11:00pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T23:00:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                },
                {
                    "name": "11:30pm",
                    "expired": false,
                    "bookingOptionsCount": 0,
                    "time": "2021-05-20T23:30:00",
                    "sections": [
                        {
                            "id": "section_G7ZSB662ZNKOC",
                            "sectionState": true,
                                    isSectionBlocked: false
                        },
                        {
                            "id": "section_YAGI34318YRUB",
                            "sectionState": true,
                                    isSectionBlocked: false
                        }
                    ],
                    onlySharedTablesRemain: false
                }
            ],
            "paymentDetails": {
                "paymentType": servicePaymentType.noPayment,
                "peopleRequired": 1,
                "price": 0.0,
                "options": [],
                "optionsFrom": null,
                "optionsTo": null,
                "maxPeoplePerBookingOverride": null,
                "hasPromotion": false,
                "singleMenuPerBooking": false,
                "fee": 0.0
            },
            "description": null,
            "policyAgreement": null,
            "policyAgreementText": null,
            "serviceType": "Dinner"
        }
    ]
}

