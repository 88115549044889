import {connect} from 'react-redux';
import BookingDetails from './index';
import {RootState} from "app/main/rootReducer";
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps} from './types';
import {ThunkDispatch} from 'redux-thunk';

const NS = 'BookingDetailsContainer';

const mapStateToProps = (state: RootState): IStateFromProps => {

    const bookingTime = state.venueGridReducer.bookingTime;

    return {
        bookingTime
    };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
    return {
        handleSomeClick: () => {
            // dispatch(exampleSlice.actionName);
        }
    }
};

const BookingDetailsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingDetails as any);

export default BookingDetailsContainer;