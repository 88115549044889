import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IScheduleServiceABC, ISectionOrder} from "app/models";
import {IWidgetBooking} from "shared-types/WidgetTypes"


// Define a type for the slice state
interface GroupWidgetSlice {
    booking: IWidgetBooking,
    selectedService: IScheduleServiceABC,
    selectedSection: ISectionOrder
}

// Define the initial state using that type
const initialState: GroupWidgetSlice = {
    booking: {} as IWidgetBooking,
    selectedService: {} as IScheduleServiceABC,
    selectedSection: {} as ISectionOrder,
}

export const groupWidgetSlice = createSlice({
    name: 'booking',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        changeActiveService: (state, action: PayloadAction<IScheduleServiceABC>) => {
            return {
                ...state,
                selectedService: action.payload
            }
        },
        changeActiveSection: (state, action: PayloadAction<ISectionOrder>) => {
            return {
                ...state,
                selectedSection: action.payload
            }
        }
    }
})

export const {changeActiveService, changeActiveSection} = groupWidgetSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default groupWidgetSlice.reducer