import moment, {Moment} from "moment-timezone";

const NS = 'DateUtilsService';

export default class DateUtilsService {

    static dateTimeNum(startDate: Date, date: Date): number {
        const startMinNum = ((startDate.getTime() / 1000) / 60);
        const dateMinNum = ((date.getTime() / 1000) / 60);
        const num = dateMinNum - startMinNum;
        return num;
    }

    static isInBetweenDates(date: Date, startDate: Date, endDate: Date, equalOk?: boolean): boolean {
        if (equalOk) {
            return (date < endDate && date > startDate);
        }
        return (date < endDate && date >= startDate);
    }

    static dateToTimeStr(date: Date, format?: string): string  {
        const momentDate = moment(date);
        return momentDate.format(format || 'hh:mm a');
        // return $filter('date')(date, 'HH:mm');
    }

    static dateToDateTimeStr(viewDate: string) {
        const momentDate = moment(viewDate, 'HH:mm');
        return momentDate.format('YYYY-MM-DD HH:mm');
        // return $filter('date')(date, 'yyyy/MM/dd HH:mm');
    }

    static dateStrToDateTime(dateStr: string): Date {
        const momentDate = moment(dateStr);
        // console.log(NS, 'dateStr', dateStr, momentDate, momentDate.toDate())
        return momentDate.toDate();
    }

    static dateStrToDate(dateStr: string, join = '-') {
        // console.log(NS, 'dateStr', dateStr)
        const dateParts: string[] = dateStr.split(join);
        const year = Number(dateParts[0]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[2]);

        return new Date(year, month, day);
    }

    /**
     * gets the current 24 hour time as a string at venue
     */
    static getCorrectTime(timeZone: string): string {
        const browserTime: string = moment().format();
        const updatedTime = moment.tz(browserTime, timeZone).format('YYYY-MM-DD H:mm:ss');
        return updatedTime;
    }

    static getJsDate = function (date: string): Date {
        const a: string[] = date.split(/[^0-9]/);
        const year: number = parseInt(a[0], 10);
        const month: number = parseInt(a[1], 10);
        const dayDate: number = parseInt(a[2], 10);
        const hours: number = parseInt(a[3], 10);
        const mins: number = parseInt(a[4], 10);
        const secs: number = parseInt(a[5], 10);

        return new Date(year, month - 1, dayDate, hours, mins, secs);
    }


    /**
     * Takes a moment object and timezone id and converts the moment to be relative to the venue's timezone.
     * If you were to call `.toDate()` after this you should get your local time with the offset added to it.
     * For example:
     *  - a booking in Perth at 'Thu Sep 03 2020 12:23:11'
     *  - would look like this for a Sydney user 'Thu Sep 03 2020 14:23:11 GMT+1000 (Australian Eastern Standard Time)'
     *  which has a 2 hour offset added to it
     * @param dateTime: eg a maxDate or a booking time
     * @param timeZone: eg 'Australia/Perth'
     */
    static convertMomentToVenueDateTime(dateTime: Moment, timeZone: string): void {
        dateTime.tz(timeZone);

        const userTZOffset = moment().utcOffset();
        const venueTZOffset = dateTime.utcOffset();
        const offsetInMins = userTZOffset - venueTZOffset;

        dateTime.add(offsetInMins, "minutes");
    }

    static getBookingEnd(b: {time: Date, duration: number}): Date {
        const bookingEnd = moment(b.time).add(b.duration, 'minutes');
        return bookingEnd.toDate();
    }

    static getViewDateFromMoment(moment: Moment): string {
        return moment.format('dddd, MMMM D, YYYY');
    }


}
