import {ThemeOptions} from "@material-ui/core/styles/createMuiTheme";
import {TypographyOptions} from "@material-ui/core/styles/createTypography";
import {Moment} from "moment";
import {SimplePaletteColorOptions} from "@material-ui/core/styles/createPalette";
import {
    IEwayInfo, IStripeInfo,
    ISchedule, IScheduleTime, IBlockout,
    ISectionLayoutItem, IWidgetPalette,
    IBookingAbstract,
    IBookingResponseData,
    ILayoutMinimalAbstract,
    IScheduleServiceAbstract,
    ITableItem, ITableItemAbstract, IScheduleAbstract
} from "shared-types/index";

export enum themeTypes {
    outlinedLight = 'outlined-light',
    outlinedDark = 'outlined-dark',
    light = 'light',
    dark = 'dark'
}

export interface IWidgetTypography extends TypographyOptions {
    fontFamily?: string;
}

export interface IWidgetTheme extends ThemeOptions {
    primary?: string;
    error: any;
    type: themeTypes;
    defaultColors: boolean;
    typography?: IWidgetTypography;
    palette?: IWidgetPalette;
    warning?: SimplePaletteColorOptions;
    success?: SimplePaletteColorOptions;
}

export enum blockNavType {
    becauseOfPayment = 1,
    becauseOfCoversPending = 2
}

export interface ILoggedInUser {
    id: string,
    firstName: string,
    surname: string,
    email: string,
    country: string,
    suburb: string,
    state: string,
    postcode: string,
    phone: string,
    pin: string,
    createdAt: Date,
    roles: IRole[],
    widgetVersion: number,
}

interface IRole {
    name: string
}

//
// export interface IAppQueryStringParams {
//   colors?: string; // interchangable with 'accent'
//   accent?: string; // interchangable with 'colors'
//   font?: string;
//   theme?: string;
//   accountid: string;
//   venueid?: string;
//   fromurl?: string;
//   bookingid: string;
//   token?: string;
//   date?: string;
//   serviceids?: string;
//   covers?: string;
//   source?: string;
//   debug?: string;
//   bg?: string;
//   banner?: string;
// }

export interface ColumnData {
    dataKey: string;
    label: string;
    numeric?: boolean;
    width: number;
}

export interface Row {
    index: number;
}

export interface IGridRow {
    venueId: string;
    venueName: string;
    venueData: string[];
}

export interface IVenueGridInfo {
    bookingTime: IScheduleTime;
    selectedSchedule?: IGroupWidgetSchedule;
}

export interface ICustomer {
    id?: string;
    company?: string;
    email: string;
    firstName: string;
    lastName: string;
    notes?: string;
    phone: string;
    phoneNational?: string;
    subscribed?: boolean; // this corresponds to the terms and conditions checbox
    country?: string;
}

export interface ICustomerDetails {
    birthdayDate: null
    birthdayMonth: null
    birthdayYear: null
    company: string;
    email: string; // "peter@parker.com"
    firstName: string; // "Peter"
    fullName: string; // "Peter Parker"
    hasVisitedMultipleVenues: boolean;
    lastModifiedDate: string; // "2020-09-22T03:33:10.3767529+00:00"
    lastName: string; // "Parker"
    managerNotes: unknown;
    noShowCount: number;
    notes: string;
    phone: string;// "+61414000111"
    formatedPhone?: string;// "+61 414 000 111"
    phone2: string;
    phoneNational: string; //"0414562166"
    postcode: unknown;
    streetAddress: string;
    subscribed: boolean;
    suburb: string;
    tags: IBookingTag[];
    type: string; // "customer"
    venueIds: unknown[];
    id: string; // "customer_8b75784d-b292-46dd-bec6-ba92daa7c911"
    _rev?: string;
}


export interface IBookingTagType {
    name: string; // "Occasion"
    type: string; // "tagtype"
    _id: string; // "tagtype_occasion"
}

export interface IBookingTag {
    colour: string; //"#000000"
    icon: string; //"birthday"
    name: string; //"Birthday"
    online: boolean;
    order: number;
    permanent: boolean;
    tagtype: IBookingTagType;
    type: string; // "tag"
    _id: string; // "tag_birthday"
    selected?: boolean;
}


export interface IVenueMinimal {
    active: boolean;
    name: string;
    phone: string;
    city: string;
    state: string;
    country: string;
    email: string;
    logoUrl?: string;
    paymentSettings: IEwayInfo | IStripeInfo;
    canCancelBookingBySms: boolean;
    canCustomersCancelBookings: boolean;
    canCustomersEditBookings: boolean;
    canEditBookingBySms: boolean;
}

// very similar to IOwnedVenue, but not identical
export interface IVenue extends IVenueMinimal {
    id: number;
    accountId?: string;
    currency: string; // USD, AUD, GBP, etc
    timeZoneId: string;
    clientSideEncryptKey: string;
    preAuthReleasingWindow?: number;
}

export interface IAction {
    type: string;
    payload?: any;
}

export interface IBooking extends IBookingAbstract {
    orgDate?: Moment;
    customer: ICustomer;
}

export const CELL_WIDTH = 200;
export const CELL_WIDTH_VENUE = 230;

export interface ITableItemABC extends ITableItemAbstract {
    tables?: ITableItem[]; // this is a list of table ids, used when a table join exists. ABC passes this as table objects though.
}

export interface ILayoutMinimalABC extends ILayoutMinimalAbstract {
    "tablesJoins": ITableItemABC[];
}
export interface IScheduleServiceABC extends IScheduleServiceAbstract {
    layouts?: ILayoutMinimalABC[];
}


/**
 * @todo: selectedSchedule on back end needs extra props on services
 * sectionLayouts: any[], type: string, _id: string, endTime: Date
 */
export interface IGroupWidgetService extends IScheduleServiceABC {
    sectionLayouts: ISectionLayoutItem[];
    intervalsCustom: {times: string, values: string|number, intervalDuration: number}[];
    lastBookingTime: Date; // Fri Sep 04 2020 21:45:00 GMT+1000 (Australian Eastern Standard Time) {}
    name: string; // "All day"
    enableCustomPaxPerIntervals: boolean;
    maxCoversPerInterval: number;
    maxBookingsPerInterval: number;
    bookingInterval: number;
    firstBookingTime?: Date;
    type: string;
    _id: string; // @todo: sort out whether we're using id or _id - not both!
    startTime: Date;
    endTime: Date;
    bookingDuration: number;
}

export interface ITablePickerData {
    "service": IGroupWidgetService;
    "bookings": IBookingResponseData[];
}


export interface IGroupWidgetSchedule extends IScheduleAbstract {
    services: IGroupWidgetService[];
    blockouts?: IBlockout[];
}