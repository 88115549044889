import React, { Component } from "react";
import style from './style.module.scss';
import MuiTextField from '@material-ui/core/TextField';
import { Fab, Tooltip, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { ICoverInput, coverInputVariant, classPrefix } from "./types";
import classNames from 'classnames';
import { themeTypes } from "shared-types/index";
import UtilsService from "shared-services/utils-service/index";
import {renderIf} from "shared-services/react-utils-service/index";
import IframeResizerService from "shared-services/iframe-resizer-service/index";
import AppThemeService from "internal-services/theme/appTheme.service";

// Note: import didn't work due to missing type definitions
const ReactMarkdown = require('react-markdown/with-html');

const NS = 'CoverInput';
const CLASS_PREFIX = AppThemeService.useClassPrefix() ? classPrefix + '-' : '';

const TextField = withStyles({
  root: {
    [`& .${CLASS_PREFIX}MuiFormHelperText-root.Mui-error`]: {
      marginLeft: 0,
      marginRight: 0,
      fontSize: '1.2rem',
      textAlign: 'center',
      lineHeight: 1.3,
      marginTop: 5
    }
  }
})(MuiTextField);

const SmallTextField = withStyles({
  root: {
    [`& .${CLASS_PREFIX}MuiFormHelperText-root.Mui-error`]: {
      fontSize: '1.1rem',
      lineHeight: 1.2
    },
    [`& .${CLASS_PREFIX}MuiOutlinedInput-notchedOutline > legend > span`]: {
      display: 'none' // removes outline empty section when no label
    }
  }
})(TextField);

interface IState {
  coversValidity: boolean;
  coversValue: number;
  waiting: boolean;
  plusBtnEnabled: boolean;
  minusBtnEnabled: boolean;
  overLimit: boolean;
  showAtLimitMsg: boolean;
}


// just using class component for consistency with PlusBtn, which needs it for Tooltip
class MinusBtn extends Component<{btnEnabled: boolean, isSmall: boolean, onClick: () => void}> {
  render() {
    const {btnEnabled, isSmall, onClick} = this.props;
    return (
      <Fab data-testid="minusBtn"
           color="primary" size="small" aria-label="minus"
           disabled={!btnEnabled}
           className={classNames({
             [style.minusBtn]: true,
             [style.incrementBtnIsSmall]: isSmall
           })}
           onClick={onClick} >
        <RemoveIcon className={style.incrementIconIsSmall} />
      </Fab>
    );
  }
}

// must use class component to keep Tooltip happy
class PlusBtn extends Component<{btnEnabled: boolean, isSmall: boolean, onClick: () => void}> {

  render() {
    const {btnEnabled, isSmall, onClick} = this.props;

    return (
      <Fab data-testid="plusBtn"
           color="primary" size="small" aria-label="plus"
           disabled={!btnEnabled}
           className={classNames({
             [style.plusBtn]: true,
             [style.incrementBtnIsSmall]: isSmall
           })}
           onClick={onClick} >
        <AddIcon className={classNames({
          [style.incrementIconIsSmall]: isSmall
        })} />
      </Fab>
    );
  }
}

export default class CoverInput extends Component<ICoverInput, IState> {

  readonly state = {
    coversValidity: true,
    coversValue: -1,
    waiting: false,
    plusBtnEnabled: true,
    minusBtnEnabled: false,
    overLimit: false,
    showAtLimitMsg: false
  };

  private waitTimeout: number;
  private inputHandler: (evt : Event) => void;

  constructor(props: ICoverInput) {
    super(props);

    this.handleInput = this.handleInput.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  static getDerivedStateFromProps(nextProps: ICoverInput, currentState: IState) {

    const coversValue = CoverInput.getCovers(currentState, nextProps)

    return {
      ...currentState,
      overLimit: coversValue > nextProps.maxPeoplePerBooking,
      showAtLimitMsg: false,
      plusBtnEnabled: nextProps.allowMaxBreach ? currentState.plusBtnEnabled : coversValue < nextProps.maxPeoplePerBooking,
      minusBtnEnabled: coversValue > nextProps.minPeoplePerBooking
    }
  }

  static getCovers(state: IState, props: ICoverInput): number {
    return state.coversValue > -1 ? state.coversValue : props.coversPrefill;
  }


  isValueOverMaxLimit(num: number): boolean {
    return (num > this.props.maxPeoplePerBooking);
  }

  handleFocus(evt: any) {
    if (this.waitTimeout) {
      window.clearInterval(this.waitTimeout);
    }
    const input: HTMLInputElement = evt.currentTarget.querySelector('input');
    input.select();
  }

  handleInput(evt: any) {

    const input: HTMLInputElement = evt.currentTarget.querySelector('input');
    const isValid = input.validity.valid;
    let coversValue = CoverInput.getCovers(this.state, this.props);
    let valueOverLimit = false;
    let inputOverLimit = false;

    if (isValid) {
      // still possible for some invalid characters to get through, so we check for NaN as well
      const num: number = parseInt(input.value, 10);

      valueOverLimit = this.props.allowMaxBreach
        ? this.isValueOverMaxLimit(coversValue)
        : (num > this.props.maxPeoplePerBooking ? true : false);

      // If allowMaxBreach is allowed, then accepts the typed number that has been entered otherwise sticks with maxPeoplePerBooking as maximum
      const maxNumberAllowed = (this.props.allowMaxBreach ? num : this.props.maxPeoplePerBooking);
      coversValue = isNaN(num) ? this.props.minPeoplePerBooking : UtilsService.rangeCheck(num, maxNumberAllowed, this.props.minPeoplePerBooking);
      inputOverLimit = (coversValue > this.props.maxPeoplePerBooking);
    }

    this.setState({
      coversValidity: isValid,
      coversValue,
      waiting: (isValid),
      plusBtnEnabled: this.props.allowMaxBreach ? !inputOverLimit : !valueOverLimit,
      minusBtnEnabled: coversValue > this.props.minPeoplePerBooking,
      overLimit: valueOverLimit,
      showAtLimitMsg: this.props.allowMaxBreach ? coversValue == (this.props.maxPeoplePerBooking + 1) : valueOverLimit
    });

    // used for blocking navigation until covers is complete
    if (this.props.handlePendingChange) {
      this.props.handlePendingChange();
    }

    // doesn't close the panel while input still has focus
    if (document.activeElement !== input) {
      this.startWaiting(this.getDelay());
    } else {
      // instead waits for input's focus to be lost
      this.setInputFocusHandlder(input);
    }
  }

  setInputFocusHandlder(input: HTMLInputElement): void {
    const EVT = 'blur';
    this.inputHandler = (evt: Event) => {
      this.startWaiting(300);
      input.removeEventListener(EVT, this.inputHandler);
    };

    input.removeEventListener(EVT, this.inputHandler);
    input.addEventListener(EVT, this.inputHandler);
  }

  handleIncrement(value: number) {

    let coversValue = CoverInput.getCovers(this.state, this.props);

    const tryToPassLimit = (coversValue === this.props.maxPeoplePerBooking && value === 1);

    coversValue = UtilsService.rangeCheck(coversValue + value, coversValue + value, this.props.minPeoplePerBooking);

    const valueOverLimit = this.isValueOverMaxLimit(coversValue);

    const plusBtnEnabled = this.props.allowMaxBreach
      ? !valueOverLimit
      : (this.props.omitMaxReachedMsg
          ? coversValue < this.props.maxPeoplePerBooking // if no message, we disable the plus button instead
          : true
      );

    this.setState({
      ...this.state,
      coversValue,
      coversValidity: true,
      waiting: true,
      plusBtnEnabled,
      minusBtnEnabled: coversValue > this.props.minPeoplePerBooking,
      overLimit: valueOverLimit,
      showAtLimitMsg: tryToPassLimit
    });

    // used for blocking navigation until covers is complete
    if (this.props.handlePendingChange) {
      this.props.handlePendingChange();
    }

    this.startWaiting(this.getDelay());
  }

  /**
   * Waits for a period of time before notifying the rest of the app of the covers value change.
   * Clears timeout so the latest interaction starts the 'wait' again
   */
  startWaiting(delay: number): void {

    const doChange = (): void => {
      const coversValue = this.state.coversValue;
      const overLimitValue = this.state.overLimit;

      this.setState({
        ...this.state,
        waiting: false,
        coversValue: -1
      });

      const isShowingMessage = !this.props.omitMaxReachedMsg && overLimitValue;
      this.props.handleChange(coversValue, isShowingMessage);
    }

    // needs timeout so that `this.state` is up to date
    window.setTimeout(() => {
      if (!this.state.waiting) {
        return;
      }

      if (!delay) {
        doChange();
        return;
      }

      if (this.waitTimeout) {
        window.clearInterval(this.waitTimeout);
      }
      this.waitTimeout = window.setTimeout(() => {
        doChange();
      }, delay);
    }, 0);
  }

  getDelay(): number {
    return this.props.hasDelay ? 1100 : 0;
  }


  render() {
    const {theme, variant, maxPeoplePerBooking, getMaxBreachMessage, minPaxPerBookingMessage, omitMaxReachedMsg, coversPrefill, wrapperStyle, allowMaxBreach} = this.props;

    const {coversValue, coversValidity, minusBtnEnabled, plusBtnEnabled, overLimit, showAtLimitMsg } = this.state;

    const isSmall = variant === coverInputVariant.small;
    const isLight = theme.type === (themeTypes.light || themeTypes.outlinedLight);
    let calcCoversValue = coversValue > -1 ? coversValue : coversPrefill;
    const isLandscape = !IframeResizerService.isStacked(wrapperStyle);

    if (calcCoversValue < 0) {
      calcCoversValue = 0;
    }

    const CustomTextField = isSmall ? SmallTextField : TextField;
    const maxReachedMsg = showAtLimitMsg ? "Maximum reached" : "";
    const helperText = allowMaxBreach
      ? (coversValidity && !overLimit ? '' : 'Invalid number')
      : (coversValidity ? '' : 'Invalid number');

    return (
      <div data-testid="root"
           className={classNames({
             [style.root]: true,
             [style.rootIsSmall]: isSmall
           })}>
        <div className={classNames({
          [style.inputWrap]: true,
          [style.inputWrapIsLandscape]: isLandscape
        })}>
          <CustomTextField
            data-testid="numberInput"
            className={classNames({
              [style.numberInput]: true,
              [style.numberInputIsSmall]: isSmall,
              [style.numberInputIsLight]: isLight
            })}
            label={isSmall ? '' : 'Booking for'}
            type="number"
            margin="normal"
            variant="outlined"
            value={calcCoversValue}
            error={allowMaxBreach ? !coversValidity || overLimit : !coversValidity}
            helperText={helperText}
            onInput={this.handleInput}
            onClick={this.handleFocus}
          />

          <div className={classNames({
            [style.incrementBtns]: true,
            [style.incrementBtnsIsSmall]: isSmall,
            [style.incrementBtnsIsLandscape]: isLandscape
          })}>

            <MinusBtn btnEnabled={minusBtnEnabled} isSmall={isSmall} onClick={() => this.handleIncrement(-1)} />
            {renderIf(showAtLimitMsg, () => (
              <Tooltip title={maxReachedMsg}>
                <PlusBtn btnEnabled={plusBtnEnabled} isSmall={isSmall} onClick={() => this.handleIncrement(1)} />
              </Tooltip>
            ), () => (
              <PlusBtn btnEnabled={plusBtnEnabled} isSmall={isSmall} onClick={() => this.handleIncrement(1)} />
            ))}
          </div>
        </div>

        {!omitMaxReachedMsg && (overLimit || showAtLimitMsg) ?
          <Typography data-testid="errorMessage" component="div" className={style.message} variant="body1">
            <span className="error-text">Maximum {overLimit ? 'Exceeded' : 'Reached'}</span>
            {renderIf(getMaxBreachMessage, () => (
              <>
                <br />
                <ReactMarkdown source={getMaxBreachMessage(maxPeoplePerBooking)} escapeHtml={false} />
              </>
            ))}
          </Typography>
          : ''
        }
        {
          minPaxPerBookingMessage &&
          <Typography className={style.message} data-testid="minPaxPerBookingMessage" variant="body1" >
                  {minPaxPerBookingMessage}
          </Typography>
        }

      </div>
    )
  }
}
