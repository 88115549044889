import React, {useEffect, useState} from 'react';
import {IHomePage} from './types';
import style from './style.module.scss';
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import PaxComponent from "app/components/PaxComponent";
import VenueGrid from "app/components/VenueGridComponent/VenueGrid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch, useSelector} from "react-redux";
import {setLoggedInUser} from 'app/reducers/authenticationSlice';
import TimeSelector from "app/components/TimeSelector";
import WalkInToggle from "app/components/WalkInToggle";
import {RootState} from "app/main/rootReducer";
import DayPicker from "app/components/DayPicker";
import BrandName from '../../../images/BrandName.png';
import BrandImage from '../../../images/BrandImage.png';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        minHeight: '75px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    topChildComponent: {
        padding: '20px',
    }
}));

const NS = 'HomePage';

export default function HomePage({user}: IHomePage) {

    const isScheduleLoaded = useSelector((state: RootState) => state.routeReducer.isScheduleLoaded);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoggedInUser(user));
    }, [])

    const classes = useStyles();
    return (

        <div className={style.homePage}>
            <img className={style.brandImage} src={BrandImage}/>
            <Grid container justify="center">
                <Grid item className={classes.topChildComponent}>
                    <DayPicker/>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={2} className={classes.topChildComponent}>
                    <Paper className={classes.paper}>
                        <PaxComponent exampleProp={'10'}/>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={3} md={2} className={classes.topChildComponent}>
                    <Paper className={classes.paper}>
                        <TimeSelector/>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={3} md={2} className={classes.topChildComponent}>
                    <Paper className={classes.paper}>
                        <WalkInToggle/>
                    </Paper>
                </Grid>
            </Grid>
            <img className={style.brandName} src={BrandName}/>
            <Grid>
                <VenueGrid isScheduleLoaded={isScheduleLoaded}/>
            </Grid>
        </div>

    )
}