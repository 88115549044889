import {cloneDeep, isObject} from 'lodash-es';

const NS = 'ObjectCopyService';
export const HASHKEY = '$$hashKey';

export default class ObjectCopyService {

    /**
     * AngularJS adds a unique $$hashKey that we don't want when making a copy.
     * Removes it from object properties, array values and objects within arrays.
     * @param originalObject - any object or array
     */
    static angularSafeCopy(originalObject: any): any {
        if (!isObject(originalObject)) {
            return originalObject;
        }
        let copiedObject: any = cloneDeep(originalObject);
        if (Array.isArray(copiedObject)) {
            copiedObject = copiedObject.filter(item => item !== HASHKEY);
            (copiedObject as Array<any>).map(item => {
                if (isObject(item)) {
                    return this.angularSafeCopy(item);
                }
                return item;
            });
        } else if (copiedObject[HASHKEY]) {
            delete copiedObject[HASHKEY];
        }

        for (const propName in copiedObject) {
            if (isObject(copiedObject[propName])) {
                copiedObject[propName] = this.angularSafeCopy(copiedObject[propName]);
            }
        }
        return copiedObject;
    }
}
