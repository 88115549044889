import {ITableItem} from "shared-types/SharedTypes";
import {makeStyles} from "@material-ui/core/styles";
import {createStyles, Theme} from "@material-ui/core";
import React from "react";

const NS = 'TableSeaterHelpers';

/**
 * Material UI styles have high specificity than the style.module.scss classes, so modifications should be done here
 */
export function getMaterialStyles(useReversedColors: boolean, isWhiteTheme: boolean) {
  return makeStyles((theme: Theme) => {

    const styles: any = {
      checkboxMenuItemFC: {
        margin: '0 5px 0 0',
        width: '100%',
        padding: '4px 0'
      },
      checkboxManualJoinFC: {
        margin: '0 5px 0 0'
      },
      checkbox: {
        color: theme.palette.primary.main,
        padding: 7
      },
      menuCheckbox: {
        padding: '5px 9px',
        marginLeft: -7
      },
      selectFC: {
        width: 175,
        // paddingTop: 8,
        flexGrow: 1,
        '& label + .MuiInput-formControl': {
          marginTop: 0,
        },
        '& label.MuiInputLabel-root': {
          transform: 'translateY(9px)'
        }
      },
      select: {
        '& .MuiSelect-icon': {
          right: 4
        },
        '& .MuiSelect-selectMenu': {
          '-webkit-mask-image': `linear-gradient(to right, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0))`
        }
      },
      inputLabel: {
        transform: 'translateY(16px)',
        padding: '0 5px'
      },
      chip: {
        height: 22,
        maxWidth: 'calc(100% - 5px)',
        marginLeft: 4,
        '&:first-child': {
          marginLeft: 0
        },
        '& .MuiChip-label': {
          paddingLeft: 7,
          paddingRight: 7
        }
      },
      menuItem: {
        justifyContent: 'space-between',
        padding: '0 10px'
      },
      isValidText: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main
      },
      isValidChip: {
        color: theme.palette.success.dark,
        borderColor: theme.palette.success.main,
        backgroundColor: theme.palette.success.light,
      },
      isFreeText: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main
      },
      isFreeChip: {
        color: theme.palette.warning.dark,
        borderColor: theme.palette.warning.main,
        backgroundColor: theme.palette.warning.light,
      },
      isInvalidText: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main
      },
      isInvalidChip: {
        color: theme.palette.error.dark,
        borderColor: theme.palette.error.main,
        backgroundColor: theme.palette.error.light,
      },
      isAltBg: {
        borderTop: 'solid rgba(0,0,0,0.2) 1px'
      }
    };

    if (useReversedColors) {
      styles.checkbox.color = 'rgba(255,255,255,0.8)';
      styles.checkboxManualJoinFC.color = 'white';
      styles.select.color = 'white';
      styles.select['& .MuiSelect-icon'].color = 'white';
      styles.select['&.MuiInput-root:before'] = {
        borderBottomColor: 'rgba(255,255,255,0.5) !important'
      };
      styles.chip.color = 'rgba(255,255,255,0.8)';
      styles.chip.borderColor = 'rgba(255,255,255,0.8)';
      styles.chip.backgroundColor = 'rgba(255,255,255,0.08)';

      if (isWhiteTheme) {
        styles.select['&.MuiInput-underline:after'] = {
          borderBottomColor: '#fff'
        }
        styles.selectFC['& label.MuiInputLabel-root'].color = 'rgba(255,255,255,0.6)';
        styles.selectFC['& label.MuiInputLabel-root.Mui-focused'] = {
          color: '#fff'
        }
      }
    }


    return createStyles(styles);
  });
}

export function getSectionName(
  sectionsLookup: any, // object with dynamic keys
  table: ITableItem
  ): string {
  let sectionId = table.sectionId;
  if (!table.sectionId && table.tablesList?.length) { // table joins need to get sectionId from first tablesList item
    sectionId = table.tablesList[0].sectionId;
  }
  const specifiedTable: ITableItem = sectionsLookup[sectionId];
  return specifiedTable?.name || '';
}

export function getOptionLabel(
  sectionsLookup: any, // object with dynamic keys
  table: ITableItem,
  includeSectionName?: boolean
): string {

  const {name, online, walkinOnly, shared, capacity, tablesList} = table;

  const prefix = `T${name} – C${capacity} `;
  // const prefix = `T${name}
  //    ${online ? ' *' : ''}
  //    ${walkinOnly ? ' ~' : ''}
  //    ${shared ? ' ^' : ''}
  //    C${capacity} `;

  let sectionId = table.sectionId;
  if (!table.sectionId && tablesList?.length) { // table joins need to get sectionId from first tablesList item
    sectionId = tablesList[0].sectionId;
  }

  const specifiedTable: ITableItem = sectionsLookup[sectionId];
  if (specifiedTable) {
    return prefix + (includeSectionName ? ` ${specifiedTable.name}` : '');
  }

  console.warn(NS, 'Table label error', name, sectionId, sectionsLookup);
  return 'TABLE ERROR';
}


// manually finds the clicked id in case the label was clicked and the handler hasn't registered it
function getClickedMenuItemId(event: React.ChangeEvent<{ value: unknown }>): {clickedId: string, checkbox: HTMLInputElement} {

  const target: HTMLLIElement = event.nativeEvent.target as HTMLLIElement;
  const listEl: HTMLLIElement = target.closest('li') as HTMLLIElement;
  const checkbox: HTMLInputElement = listEl.querySelector('input[type="checkbox"]') as HTMLInputElement;
  const clickedId: string = listEl.getAttribute('data-value');
  return {clickedId, checkbox};
}

/**
 * Returns ids from clicked checkbox or label around checkbox.
 * Detects if label was clicked without it being registered properly and fixes it
 */
export function checkboxLabelClicks(event: React.ChangeEvent<{ value: unknown }>, manualJoinTables: boolean): string[] {
  const {checkbox, clickedId} = getClickedMenuItemId(event);

  const target = event.target;
  const ids: string[] = manualJoinTables ? target.value as string[] : [target.value as string];

  if (manualJoinTables) {
    const clickedIndex = ids.findIndex(id => id === clickedId);
    if (checkbox.checked && clickedIndex === -1) {
      ids.push(clickedId);
    } else if (!checkbox.checked && clickedIndex !== -1) {
      ids.splice(clickedIndex, 1);
    }
  }

  return ids;
}

class TableSeaterHelpers {
  // stores active mutli-select table ids temporarily outside of react component for better performance on long lists
  private activeMultiSelectTableIds: string[] = null;

  setActiveMultiSelectTableIds(ids: string[]): void {
    this.activeMultiSelectTableIds = ids;
  }

  getActiveMultiSelectTableIds(): string[] {
    return this.activeMultiSelectTableIds;
  }

}

// singleton pattern
const instance = new TableSeaterHelpers();
export {instance as TableSeaterHelpers};