import {Checkbox, Paper, Typography} from "@material-ui/core";
import classNames from "classnames";
import style from "./menuOptionNarrow.style.module.scss";
import UtilsService from "shared-services/utils-service/index";
import {renderIf} from "shared-services//react-utils-service/index";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import MenuOptionsExtras from "../../MenuOptionsExtras/index";
import React from "react";
import {IMenuOption} from "../types";
import {MenuOptionPaymentType} from "../paymentType/menuOptionPaymentType";
import {servicePaymentType} from "shared-types/index";


// Note: import didn't work due to missing type definitions
const ReactMarkdown = require('react-markdown/with-html');

export const MenuOptionNarrow: React.FC<IMenuOption> = ({
  isDark, hasCb, extrasHasPayment, isChecked, bgColor, isStacked, theme, currency, linkUrl, currencyValue, bookingCancellationTimeWindowInHours, hoursText,
  children, description, id, label, link, paymentType, pricingType, price, paymentTypeError, handleCheckboxChanged, handleOpenExtras, extraDetails, hideLabel
}: IMenuOption & { children?: any }) => {
  return (
    <Paper elevation={2} className={classNames({
      [style.root]: true,
      [style.rootIsDark]: isDark,
      [style.rootHasCb]: hasCb
    })}>

      {renderIf(label && !hideLabel, (
        <Typography
          data-testid="label"
          variant="subtitle1"
          className={classNames({
            [style.label]: true,
            [style.labelHasCb]: hasCb,
            [style.labelIsStacked]: isStacked
          })}>
          <ReactMarkdown source={label}
                         renderers={{
                           paragraph: 'span'
                         }}
                         escapeHtml={false}/>
        </Typography>
      ))}

      {renderIf(hasCb, () => (
        <Checkbox
          data-testid="checkbox"
          checked={isChecked}
          className={style.checkbox}
          onChange={handleCheckboxChanged}
          icon={<CheckBoxOutlineBlankIcon fontSize="default"/>}
          checkedIcon={<CheckBoxIcon fontSize="default" color="secondary"/>}
        />
      ))}

      <div className={style.contentWrap}>
        {children}
      </div>

      {renderIf(description, (
        // using span because could contain 'ul'/'ol' elements, which can't be nested in 'p'
        <Typography
          data-testid="description"
          component="span" variant="body1" className={classNames({
          [style.description]: true,
          [style.descriptionHasCb]: hasCb
        })}>
          <ReactMarkdown source={description}
                         renderers={{
                           link: UtilsService.reactMarkDownBlankTargets
                         }}
                         escapeHtml={false}/>
        </Typography>
      ))}

      {renderIf(price && paymentType !== servicePaymentType.preAuth, (
        <Typography variant="h4" className={classNames({
          [style.price]: true,
          [style.priceHasCb]: hasCb
        })}>
          {currencyValue}
        </Typography>
      ))}

      <MenuOptionPaymentType theme={theme} extrasHasPayment={extrasHasPayment} bgColor={bgColor}
                             paymentTypeError={paymentTypeError} paymentType={paymentType}
                             currencyValue={currencyValue} hoursText={hoursText} pricingType={pricingType}
                             bookingCancellationTimeWindowInHours={bookingCancellationTimeWindowInHours}/>


      {renderIf(link, () => (
        <div>
          <a className={classNames({
            [style.link]: true,
            [style.linkHasCb]: hasCb
          })} href={linkUrl} target="_blank" rel="noopener noreferrer">{link}</a>
        </div>
      ))}

      {renderIf(handleOpenExtras, () => (
        <MenuOptionsExtras
          handleOpen={handleOpenExtras}
          items={extraDetails?.length > 0 ? extraDetails : null}
          currency={currency}
          parentMenuOptionId={id}
        />
      ))}

    </Paper>
  )
}
