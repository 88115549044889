import {cloneDeep, sortBy, uniqBy} from "lodash-es";
import {
    ITableDate,
    ITableItem,
    ITableItemAbstract,
    ITableOptionValidityRef,
    ITableSectionList
} from "shared-types/SharedTypes";
import SectionsService from "shared-services/sections-service";

const NS = 'TableSelectionService';

export interface ITableDateSubset {
    vaildTablesAtTime: ITableItem[];
    vaidJoinsAtTime: ITableItem[];
    freeTablesAtTime: ITableItem[];
}

export default class TableSelectionService {

    /**
     * Returns lists of tables with and without table joins, plus object literal with properties based on table ids
     * @param selectedTime - time data
     * @param tables - list of tables to add
     * @param sectionsLookup - only needs to be passed in if the order of the sections needs to be specific to the `order`
     *  property of sectionsLookup
     */
    static createTableSectionList(selectedTime: ITableDateSubset, tables: ITableItemAbstract[], sectionsLookup?: any): ITableSectionList {

        // @todo: see if there is a better way than using 'tableStatusLookUps' (maybe WeakMap). severity: low
        const tableStatusLookUps: any = {};

        // marks tables as free at a certain time
        selectedTime.freeTablesAtTime.forEach(table => {
            if (!tableStatusLookUps[table._id]) {
                const tableOpt: ITableOptionValidityRef = {
                    free: false,
                    valid: false,
                    table
                };
                tableStatusLookUps[table._id] = tableOpt; // creates new lookup item if doesn't exist
            }
            tableStatusLookUps[table._id].free = true;
        });

        // marks tables as valid at a certain time
        selectedTime.vaildTablesAtTime.forEach(table => {
            if (!tableStatusLookUps[table._id]) {
                const tableOpt: ITableOptionValidityRef = {
                    free: false,
                    valid: false,
                    table
                };
                tableStatusLookUps[table._id] = tableOpt; // creates new lookup item if doesn't exist
            }
            tableStatusLookUps[table._id].valid = true;
            tableStatusLookUps[table._id].free = true;
        });

        // creates list of tables (excluding table joins)
        let tablesList: ITableItemAbstract[] = [];
        tables.forEach(table => {
            const tableOpt: ITableOptionValidityRef = tableStatusLookUps[table._id];
            if (tableOpt) {
                table.free = tableOpt.free;
                table.valid = tableOpt.valid;
            } else {
                table.free = false;
                table.valid = false;
            }
            tablesList.push(cloneDeep(table));
        });


        // then orders by section
        if (sectionsLookup) {
            sectionsLookup = SectionsService.manageDuplicateOrder(sectionsLookup);
            tablesList = sortBy(tablesList, t => {
                if (sectionsLookup[t.sectionId]) {
                    return sectionsLookup[t.sectionId].order;
                }
                return t.sectionId;
            });
        }

        const tableSelectionList = uniqBy(cloneDeep(tablesList), "_id");

        // now copies the tablesList and includes the table joins

        selectedTime.vaidJoinsAtTime.forEach((tableJoin, i) => {
            tableJoin.free = true;
            tableJoin.valid = true;
            tableJoin._id = 'join_' + i;

            /**
             * The `tablesList` property doesn't get added to the `selectedTime.vaidJoinsAtTime` items immediately.
             * It is added inside `bookingsCalculations.service` (about half way through `getBookingInfoAtTime` mega function),
             * which gets called around 4 times before it is added. So we don't add to the `tablesList` until `tableJoin.tablesList`
             * has been populated.
             * @todo: This seems pretty inefficient, thrashing 'getBookingInfoAtTime' so many times. Look into if there is a
             * way of skipping the calls until ready. severity: low
             */
            const hasTables = tableJoin.tablesList && tableJoin.tablesList.length;
            if (hasTables) {
                // if tables have been populated, pushes them to the top of their section in the list
                const indexOfFirstSection = tablesList.findIndex(t => t.sectionId === tableJoin.tablesList[0].sectionId);
                tablesList.splice(indexOfFirstSection, 0, cloneDeep(tableJoin));
            }
        });

        const tableSelectionListWithJoins = uniqBy(tablesList, "_id");

        return {
            // does not include table joins (only for 'manual join' list). _id starts with "table_"
            tableSelectionList,

            // includes table joins (to be used when 'manual join' is deselected). _id starts with "join_".
            tableSelectionListWithJoins,

            tableStatusLookUps
        }
    }

    static getTableByTime(availableTime: ITableDateSubset): ITableItem {
        let table = this.getTableByCapacityAndPriority(availableTime.vaildTablesAtTime);
        if (!table) {
            table = this.getTableByCapacityAndPriority(availableTime.vaidJoinsAtTime);
        }

        return table;
    }

    static getTableByCapacityAndPriority(tables: ITableItemAbstract[]): ITableItem {
        const filteredTables = sortBy(tables, ['capacity', 'priority']);
        if (filteredTables?.length) {
            // get all the tables with same priority
            const lowestPriority = filteredTables[0].priority;
            const lowestCapacity = filteredTables[0].capacity;
            const samePriorityTables = [];
            for (let i = 0; i < filteredTables.length; i++) {
                const t = filteredTables[i];
                if (t.priority === lowestPriority && t.capacity === lowestCapacity) {
                    samePriorityTables.push(t);
                } else {
                    break; // break the loop early so it doesn't need to loop through every other table
                }
            }

            // Select a random one
            return samePriorityTables[Math.floor(Math.random() * samePriorityTables.length)];
        }

        return null;
    }
}