/**
 * Some of the cases are mixed between snake and kebab case, but this looks like it is due to
 * some kind of automatic appending of 'statusType', which uses kebab case (best guess).

 *** `BookingStatusIds` static `_id` values are unreliable because some older venues (eg shorehouse on uat) have them dynamically created. ***
 */
enum BookingStatusIds {
  StatusPendingPayment = 'status_pending_payment',
  StatusUnconfirmed = 'status_unconfirmed',
  StatusConfirmed = 'status_confirmed',
  StatusConfirmedLeftMessage = 'status_confirmed_left-message',
  StatusCancelled = 'status_cancelled',
  StatusCancelledNoShow = 'status_cancelled_no-show',
  StatusSentSms = 'status_sent_sms',
  StatusSmsFailed = 'status_sms_failed',
  StatusPartiallySeated = 'status_partially-seated',
  StatusSeated = 'status_seated',
  StatusSeatedOrdered = 'status_seated_ordered',
  StatusSeatedMain = 'status_seated_main',
  StatusSeatedDessert = 'status_seated_dessert',
  StatusSeatedBilled = 'status_seated_billed',
  StatusSeatedPaid = 'status_seated_paid',
  StatusFinished = 'status_finished',
  StatusStandbyPending = 'status_standby_pending'
}

/**
 * In UAT (and I assume prod) the status id values are sometimes GUID-like values, rather than the static values in the
 * defaultStatus.json. We have many conditions that use the BookingStatusIds enum values throughout the app,
 * but these are no longer reliable, so we must use a different property. We can’t use Code because TX is used twice, so it has to be name.
 */

export enum BookingStatusNames {
  PendingPayment = 'Pending Payment',
  Unconfirmed = 'Unconfirmed',
  Confirmed = 'Confirmed',
  ConfirmedLeftMessage = 'Left Message',
  Cancelled = 'Cancelled',
  CancelledNoShow = 'No Show',
  SentSms = 'Sent SMS',
  SmsFailed = 'SMS Failed',
  PartiallySeated = 'Partially Seated',
  Seated = 'Seated',
  SeatedOrdered = 'Ordered',
  SeatedMain = 'Main',
  SeatedDessert = 'Dessert',
  SeatedBilled = 'Billed',
  SeatedPaid = 'Paid',
  Finished = 'Finished',
  StandbyPending = 'Standby Pending'
}

/**
 * These statuses are actually not concrete, since you can add more in diary
 * Same as BookingStatusTypes present in the widget
 */
export enum bookingStatusType {
  unconfirmed = 'unconfirmed',
  confirmed = 'confirmed',
  cancelled = 'cancelled',
  seated = 'seated',
  finished = 'finished',
  standbyPending = 'standby-pending',
  pendingPayment = 'pending-payment',
  partiallySeated = 'partially-seated'
}


export enum bookingStatusCode {
  unconfirmed = 'U',
  confirmed = 'C',
  cancelled = 'X',
  noShow = 'NS', // this doesn't exist in bookingStatusType
  seated = 'S',
  finished = 'F',
  standbyPending = 'SP'
}

/**
 * These status objects should be the same as 'src/json/defaultStatus.json'.
 * The json gets added to couch when a new venue is created (via wizard) and also, in
 * 'src/app/services/venue.service.js' in 'setUp()', some statuses are added if they don't
 * already exist. This is the case for new statuses that were added for various features,
 * so that existing venues get their couchDB updated.
 */

export interface IBookingStatus {
  _id: BookingStatusIds | string; // "status_unconfirmed";
  code: string; //"U",
  name: BookingStatusNames; //"Unconfirmed",
  statusType: bookingStatusType;
  enabled: boolean; //true,
  editable: boolean; //false
  hide?: boolean;
  statusEditable?: boolean;
  order?: number; // 0
  type?: string; //"status"
  _rev?: string;
}


export const StatusSentSms: IBookingStatus = {
  "_id": BookingStatusIds.StatusSentSms,
  "code": "TX",
  "name": BookingStatusNames.SentSms,
  "statusType": bookingStatusType.partiallySeated,
  "enabled": true,
  "editable": false,
  "hide": true
}

export const StatusSmsFailed: IBookingStatus = {
  "_id": BookingStatusIds.StatusSmsFailed,
  "code": "TX",
  "name": BookingStatusNames.SmsFailed,
  "statusType": bookingStatusType.unconfirmed,
  "enabled": true,
  "editable": false,
  "hide": true
}

export const StatusStandbyPending: IBookingStatus = {
  "_id": BookingStatusIds.StatusStandbyPending,
  "code": "SP",
  "name": BookingStatusNames.StandbyPending,
  "statusType": bookingStatusType.standbyPending,
  "enabled": true,
  "editable": false
}

export const StatusPendingPayment: IBookingStatus = {
  "_id": BookingStatusIds.StatusPendingPayment,
  "code": "P$",
  "name": BookingStatusNames.PendingPayment,
  "statusType": bookingStatusType.pendingPayment,
  "enabled": true,
  "editable": false
}

export const StatusUnconfirmed: IBookingStatus = {
  "_id": BookingStatusIds.StatusUnconfirmed,
  "code": "U",
  "name": BookingStatusNames.Unconfirmed,
  "statusType": bookingStatusType.unconfirmed,
  "enabled": true,
  "editable": false
}

export const StatusConfirmed: IBookingStatus = {
  "_id": BookingStatusIds.StatusConfirmed,
  "code": "C",
  "name": BookingStatusNames.Confirmed,
  "statusType": bookingStatusType.confirmed,
  "enabled": true,
  "editable": false
}


export const StatusSeated: IBookingStatus = {
  "_id": BookingStatusIds.StatusSeated,
  "code": "S",
  "name": BookingStatusNames.Seated,
  "statusType": bookingStatusType.seated,
  "enabled": true,
  "editable": false
}

export const StatusNoShow: IBookingStatus = {
  "_id": BookingStatusIds.StatusCancelledNoShow,
  "code": "NS",
  "name": BookingStatusNames.CancelledNoShow,
  "statusType": bookingStatusType.cancelled,
  "enabled": true,
  "editable": true
}

export const StatusCancelled: IBookingStatus = {
  "_id": BookingStatusIds.StatusCancelled,
  "code": "X",
  "name": BookingStatusNames.Cancelled,
  "statusType": bookingStatusType.cancelled,
  "enabled": true,
  "editable": false
}

export const StatusFinished: IBookingStatus = {
  "_id": BookingStatusIds.StatusFinished,
  "code": "F",
  "name": BookingStatusNames.Finished,
  "statusType": bookingStatusType.finished,
  "enabled": true,
  "editable": false
}

export enum BookingMethodType {
  Abc = 'Abc',
  Widget = 'Widget'
}
