
import React from 'react';
import { IProps, IHandlers } from './types';
import SimplePage from 'shared-components/simple-page/index';

const NS = 'ErrorPage';

export default function ErrorPage({ theme, bookingError, showTryAgainBtn, handleReset }: IProps & IHandlers) {
  
  return (
    <SimplePage 
      theme={theme}
      title={bookingError ? bookingError.heading : ''} 
      body={bookingError ? bookingError.message : ''}
      buttonText={bookingError ? bookingError.buttonText : ''}
      bodyIsMarkDown={true}
      buttonCallback={showTryAgainBtn ? () => {
        if (bookingError) {
          handleReset(bookingError.messageType, bookingError.name);
        }
      } : null} />
  )
}

