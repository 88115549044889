import React, {useEffect} from 'react';
import style from './style.module.scss';
import {IPaymentConfirmationType} from "app/components/PaymentConfirmationType/types";
import {Checkbox, FormControl, FormControlLabel} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "app/main/rootReducer";
import {setEmailConfirmation, setSmsConfirmation} from 'app/reducers/paymentSlice';


const NS = 'PaymentConfirmationType';

export default function PaymentConfirmationType({handleSomeClick}: IPaymentConfirmationType) {

    const emailConfirmation = useSelector((state: RootState) => state.paymentReducer.emailConfirmation);
    const smsConfirmation = useSelector((state: RootState) => state.paymentReducer.smsConfirmation);
    const activeVenue = useSelector((state: RootState) => state.appInitReducer.activeVenue);
    const customerDetails = useSelector((state: RootState) => state.customerReducer.customerDetails);

    const dispatch = useDispatch();

    function updateConfirmation(type: string, value: boolean) {
        switch (type) {
            case 'emailConfirmation':
                dispatch(setEmailConfirmation(value));
                break;
            case 'smsConfirmation':
                dispatch(setSmsConfirmation(value));
                break;
        }
    }

    useEffect(() => {
        customerDetails.email ? dispatch(setEmailConfirmation(true)) : dispatch(setEmailConfirmation(false)); // if email is not available disable email communication
    }, [customerDetails.email])

    useEffect(() => {
        if (!activeVenue.smsEnabled) {
            dispatch(setSmsConfirmation(false)); // make sms as false if sms is not setup
        }

    }, [])

    return (

        <FormControl component="fieldset">

            <FormControlLabel
                className={style.termsControl}
                control={
                    <Checkbox
                        id="emailConfirmation" name="emailConfirmation"
                        className={style.checkbox}
                        disabled={!customerDetails.email}
                        checked={emailConfirmation}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateConfirmation('emailConfirmation', event.target.checked)}
                    />
                }
                label="Send email notifications"
            />


            <FormControlLabel
                className={style.termsControl}
                control={
                    <Checkbox
                        id="smsConfirmation" name="smsConfirmation"
                        className={style.checkbox}
                        checked={activeVenue.smsEnabled ? smsConfirmation : false}
                        disabled={!activeVenue.smsEnabled}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateConfirmation('smsConfirmation', event.target.checked)}
                    />
                }
                label="Send sms notifications"
            />
        </FormControl>

    )
}