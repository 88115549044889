import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import moment from "moment/moment";

// Define a type for the slice state
interface DayPickerSlice {
    viewDate: string;
}

// Define the initial state using that type
const initialState: DayPickerSlice = {
    viewDate: moment().format('dddd, MMMM D, YYYY')
}

export const dayPickerSlice = createSlice({
    name: 'dateChange',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        dayUpdate: (state, action: PayloadAction<string>) => {
            state.viewDate = action.payload
        }
    }
})

export const {dayUpdate} = dayPickerSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default dayPickerSlice.reducer