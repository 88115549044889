import {
  ICustomer, themeTypes} from 'shared-types/index';


export interface IStringEvent {
  name: string;
  value: string;
}

export enum FlagPhonePickerStyle {
  diary = 'diary',
  abc = 'abc',
  widget = 'widget',
}

export interface IHandlers {

}

export interface IProps {
    isPhoneIcon?: boolean;
    uiStyle?: FlagPhonePickerStyle;
    theme?: {
      type: themeTypes;
    };

    value: string;
    handleBlur: (e: any) => void;
    handleChange: (e: {target: IStringEvent}) => void;
    country: string;
    searchCustomer: (e: {target: IStringEvent}) => void;
    customers: ICustomer[],
    handleWholeFormChangeForAutocomplete: (value: ICustomer) => void;
    forceValidation: boolean;
    touched?: boolean;
    errors?: string;

}
