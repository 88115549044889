import React, {useEffect} from 'react';
import {ITimeSelector} from './types';
import {Button, createStyles, FormControl, Grid, InputLabel, MenuItem, Select, Theme} from "@material-ui/core";
import {useSelector, shallowEqual} from "react-redux";
import {RootState} from "app/main/rootReducer";
import style from './style.module.scss';
import {renderIf} from "app/services/utils.service";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {isEqual} from "lodash-es";

const NS = 'TimeSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        navigationIcon: {
            position: 'relative',
            top: '30px'
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        select: {
            '& .MuiMenu-list': {
                maxHeight: '400px',
            }
        }
    }),
);

export default function TimeSelector() {

    const venueStartEndTimeArray = useSelector((state: RootState) => state.venueGridReducer.venueStartEndTimeArray);

    const classes = useStyles();
    const [timeVal, setTime] = React.useState<string>('');
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (venueStartEndTimeArray.length > 0) {
            setTime(venueStartEndTimeArray[0])
        }
    }, [venueStartEndTimeArray])

    const scrollIntoView = (val: string) => {
        const timeElement = document.getElementById(val);
        if (timeElement) {
            timeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }
    }

    const handleChange = (time: string) => {
        setTime(time);
        const index = venueStartEndTimeArray.findIndex(t => t === time)
        setTimeout(() => {
            scrollIntoView(`gridCell_${index}_${(time)}`)
        }, 0)

    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Grid container justify={"center"}>
            {renderIf(venueStartEndTimeArray.length > 0, () => (

                <div className={style.root}>
                    <NavigateBeforeIcon className={classes.navigationIcon} onClick={() => {
                        const timeIndex = venueStartEndTimeArray.findIndex(time => time === timeVal);
                        if (timeIndex > 0) {
                            handleChange(venueStartEndTimeArray[timeIndex - 1])
                        }
                    }}>
                        Previous
                    </NavigateBeforeIcon>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-controlled-open-select-label">Select Time</InputLabel>
                        <Select className={classes.select}
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            value={timeVal}
                            onChange={(event) => {
                                const time = event.target.value as string;
                                handleChange(time)
                            }}>
                            {venueStartEndTimeArray.map((time, index) => (
                                <MenuItem key={index} value={time}>{moment(time).format('HH:mm')}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <NavigateNextIcon className={classes.navigationIcon} onClick={() => {
                        const timeIndex = venueStartEndTimeArray.findIndex(time => time === timeVal);
                        if (timeIndex < venueStartEndTimeArray.length - 1) {
                            handleChange(venueStartEndTimeArray[timeIndex + 1])
                        }
                    }}>
                        Next
                    </NavigateNextIcon>
                </div>
            ))}

        </Grid>
    )
}