import React, { ReactElement } from 'react';
import { FormattedNumber, createIntl, createIntlCache } from 'react-intl/dist';

const cache = createIntlCache();

export class IntlService {

    static getLocale = () => navigator.language || (navigator as any).browserLanguage || (navigator.languages || ["en"])[0];

    static intl = createIntl({ locale: IntlService.getLocale() }, cache);

    private static isDollarCurrency(currency: string): boolean {
        return currency === 'AUD' || currency === 'USD' || currency === 'NZD' || currency === 'CAD';
    }

    static currencyValue(val: number, currency: string): ReactElement {
        if (!val) {
            return null;
        }

        // for these countries just use a dollar symbol. Everyone else gets internationalization
        if (IntlService.isDollarCurrency(currency)) {
            return <span>${val.toFixed(2)}</span>;
        }

        const isSGD = currency === 'SGD'; // bug in intl renders as `SGD` instead of `$S` so we need to add exception
        return isSGD ?
            <span>S${val.toFixed(2)}</span>
            : <FormattedNumber
                currencyDisplay='symbol'
                value={val}
                style="currency"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                currency={currency} />
    }

    static currencyValueAsString(val: number, currency: string): string {
        if (!val) {
            return '';
        }

        // for these countries just use a dollar symbol. Everyone else gets internationalization
        if (IntlService.isDollarCurrency(currency)) {
            return `$${val.toFixed(2)}`;
        }
        return IntlService.intl.formatNumber(val, {style: 'currency', currency: currency});
    }

}